import React, { useEffect, useRef } from 'react';

const appId = process.env.REACT_APP_SQUARE_APP_ID;
const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

const PaymentForm = ({setCard, setPayments}) => {
  const cardContainerRef = useRef(null);


  useEffect(() => {
    const loadSquareScript = () => {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_SQUARE_URL;
      script.async = true;
      script.onload = () => initializeSquare();
      script.onerror = () => console.error('Square.js failed to load properly');
      document.head.appendChild(script);
    };

    const initializeSquare = async () => {
      if (!window.Square) {
        console.error('Square.js failed to load properly');
        return;
      }

      let paymentsInstance;
      try {
        paymentsInstance = window.Square.payments(appId, locationId);
        setPayments(paymentsInstance);
      } catch (error) {
        console.error('Error initializing Square payments', error);
        return;
      }

      try {
        const cardInstance = await paymentsInstance.card();
        await cardInstance.attach(cardContainerRef.current);
        setCard(cardInstance);
      } catch (error) {
        console.error('Error initializing card', error);
      }
    };

    loadSquareScript();
  }, [setPayments, setCard]);

  return (
    <div>
      <form id="payment-form">
        <div id="card-container" ref={cardContainerRef}></div>
      </form>
    </div>
  );
};

export default PaymentForm;
