import React from "react"
import { Box } from "@material-ui/core"
import { useSelector } from "react-redux"

import AddressCard from "@components/addressCard/AddressCard"
import AddNewItemCard from "./AddNewItemCard"
import { Heading2 } from "@components/Text/Text"
import RadioGroupCard from "@components/radioGroupCard/RadioGroupCard"
import { CartType } from "@models/Cart"
import { clientSetting } from "config"
import RadioPayByCard from "@modules/address/components/payByCard"
import { venueOrderType } from "@helpers/utilsHelper"

interface InnerCard {
  _id: string
  title: string
  description: string
  brand?: string
  address?: boolean
  isValidAddress?: boolean
}

interface Props {
  title: string
  innerCards: InnerCard[]
  addClickHandler: () => void
  itemClickHandler: (item: any) => void
  addTitle: string
  selectedValue?: string
  typography: string
  paymentOptionCard?: boolean
  selectedPaymentValue?: string
  onRadioChange?: (e: any) => void
  address?: boolean
}

const CartOptionsCard = ({
  innerCards,
  addClickHandler,
  itemClickHandler,
  title,
  addTitle,
  selectedValue,
  typography,
  paymentOptionCard,
  selectedPaymentValue,
  onRadioChange,
  address,
}: Props) => {
  const settings = useSelector(
    ({ venues }: RootState) => venues.restaurant.settings,
  )
  const { selectedOption = "", venueDetail = {} } = useSelector(
    ({ venues }: RootState) => venues,
  )
  const hasRedsys = settings?.redsys ? settings?.redsys : false
  const showPaymentMethods = () => {
    if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      venueOrderType(venueDetail).isVirtualBasket
    ) {
      return false
    }
    return paymentOptionCard
  }

  return (
    <div className="bg-white pb-2">
      <Box
        className={
          "flex flex-col items-center md:items-start md:pl-16 mb-12 pt-6"
        }
      >
        <Heading2
          style={{ color: "rgb(105, 105, 105)", marginTop: "0.8rem" }}
          className={"m-0"}
        >
          {title}
        </Heading2>
      </Box>

      {showPaymentMethods() && (
        <div className="mb-8">
          {venueOrderType(venueDetail).isVirtualBasket &&
          clientSetting.showPayByCard ? (
            <RadioPayByCard
              typography={typography}
              selectedValue={selectedPaymentValue}
              onRadioChange={onRadioChange}
            />
          ) : (
            <RadioGroupCard
              typography={typography}
              selectedValue={selectedPaymentValue}
              onRadioChange={onRadioChange}
            />
          )}
        </div>
      )}
      {showPaymentMethods() && selectedPaymentValue === "withCash" ? (
        <div className="mb-8"></div>
      ) : (
        <>
          {settings && settings.payByCard && (!hasRedsys || address) ? (
            <Box
              className={"flex flex-wrap w-full justify-center md:pl-12 pb-12"}
            >
              {innerCards.map((item) => {
                return (
                  <AddressCard
                    isSelected={selectedValue === item._id}
                    isValidAddress={item.isValidAddress}
                    key={item._id}
                    address={address}
                    title={item.title}
                    description={item.description}
                    brand={item.brand && item.brand}
                    onClick={() => itemClickHandler(item)}
                    typography={typography}
                  />
                )
              })}
              <AddNewItemCard title={addTitle} onClick={addClickHandler} />
            </Box>
          ) : hasRedsys && address && innerCards.length <= 0 ? (
            <AddNewItemCard title={addTitle} onClick={addClickHandler} />
          ) : null}
        </>
      )}
    </div>
  )
}

export default CartOptionsCard
