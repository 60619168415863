import React, { CSSProperties, FC } from "react"
import Classes from "./buttons.module.css"
import { theme } from "config/theme"
import { useBrand } from "@hooks/useBrand"
import { AppParagraph2Bold } from "@components/Text/AppText"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"

interface Props {
  title?: string
  quantity?: number
  price?: string
  classes?: string
  onClick?: (e?: any) => void
  disabled?: boolean
  value?: string
  leftText?: string
  backgroundColor?: string
  quantityStyle?: CSSProperties
  style?: CSSProperties
  titleStyle?: CSSProperties
  priceStyle?: CSSProperties
  children?: React.ReactNode
}

const PriceButton: FC<Props> = ({
  title,
  quantity = 0,
  price,
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
  leftText,
  style,
}) => {
  const { secondaryColor } = useBrand()
  const { languageStrings } = useLanguageHelper()
  const _itemTxt =
    quantity <= 1 ? languageStrings["ITEM"] : languageStrings["ITEMS"]
  const { clientCurrency } = useClientCurrency()
  return (
    <button
      style={{ backgroundColor, zIndex: 1000 }}
      onClick={onClick}
      className={[Classes.button, classes, "cursor-pointer outline-none"].join(
        " ",
      )}
      value={value}
      disabled={disabled}
    >
      <div
        className="flex flex-row h-32 items-center"
        style={{
          justifyContent: "space-evenly ",
          ...style,
        }}
      >
        <AppParagraph2Bold
          style={{
            color: theme.main.primary,
            textTransform: "none",
            minWidth: "25%",
            marginRight: 3,
          }}
        >
          {leftText || `${quantity} ${_itemTxt}`}
        </AppParagraph2Bold>
        <AppParagraph2Bold
          style={{
            color: secondaryColor,
            textTransform: "none",
            flex: 1,
          }}
        >
          {title}
        </AppParagraph2Bold>
        <AppParagraph2Bold
          style={{
            color: theme.main.primary,
            textTransform: "none",
            minWidth: "25%",
            marginLeft: 3,
          }}
        >
          {price} {clientCurrency.symbol}
        </AppParagraph2Bold>
      </div>
    </button>
  )
}
export const PriceButtonV2: FC<Props> = ({
  title,
  quantity,
  price,
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
  quantityStyle,
  titleStyle,
  priceStyle,
}) => {
  const { clientCurrency } = useClientCurrency()
  return (
    <button
      style={{ backgroundColor, zIndex: 1000 }}
      onClick={onClick}
      className={[Classes.button, classes, "cursor-pointer outline-none"].join(
        " ",
      )}
      value={value}
      disabled={disabled}
    >
      <div className="flex flex-row w-full justify-between">
        <span className="" style={quantityStyle}>
          {quantity}
        </span>
        <span className="" style={titleStyle}>
          {title}
        </span>
        <span className="" style={priceStyle}>
          {price ? `${price} ${clientCurrency.symbol}` : ""}
        </span>
      </div>
    </button>
  )
}
export const PriceButtonV3: FC<Props> = ({
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
  children,
}) => {
  const { clientCurrency } = useClientCurrency()
  return (
    <button
      style={{ backgroundColor, zIndex: 1000 }}
      onClick={onClick}
      className={[Classes.button, classes, "cursor-pointer outline-none"].join(
        " ",
      )}
      value={value}
      disabled={disabled}
    >
      <div className="flex flex-row px-12">{children}</div>
    </button>
  )
}

export default PriceButton
