import SplitBillModal from "@components/modals/SplitBillModal"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { calculatePrices, getTaxes } from "@helpers/utilsHelper"
import { virtualBasketCounter } from "@store/cart/cartHelpers"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import VirtualBasketCard from "../VirtualBasketCard"
import EmptyBasketCard from "../EmptyBasketCard"
import BackButton from "@components/backButton/BackButton"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import {
  setSelectedOption,
  getClientDetail,
  emptyVenueDetail,
} from "@store/venues/VenueActions"
const VirtualBasketDetails = ({
  orders = [],
  typography,
  tableId = "",
  venueId,
  permalink,
}) => {
  const { primaryColor, thirdColor } = useBrand()
  const { orderFromTable } = useSelector(({ orderFromTable }) => orderFromTable)
  const venueDetail = useSelector(({ venues }) => venues.venueDetail)
  const menus = useSelector(({ menu }) => menu.menus)
  const history = useHistory()
  const [showaModal, setShowModal] = useState(false)
  const { totalPrice, promotionPrice } = virtualBasketCounter(orders)
  const isHosteltactil = orderFromTable?.hosteltactil?.codigo ? true : false
  const pricePos = orderFromTable?.pendingOrdersIds?.[0]?.price || 0
  const isFrontrest = orderFromTable?.frontRestData?.tableId ? true : false
  const originalPrice = orderFromTable?.originalPrice || null
  let total = parseFloat(totalPrice)
  if (isHosteltactil) total = parseFloat(pricePos)
  if (isFrontrest && originalPrice) total = originalPrice
  const { taxId } = venueDetail || {}
  const { totalTax } = getTaxes(taxId)
  const { languageStrings } = useLanguageHelper()
  const venueList = useSelector(({ venues }) => venues.venueList)
  const dispatch = useDispatch()
  const serviceFee =
    venueDetail &&
    venueDetail.venueService &&
    venueDetail.venueService.serviceFee
      ? venueDetail.venueService.serviceFee
      : 0

  const service =
    venueDetail && venueDetail.venueService && venueDetail.venueService.service
      ? venueDetail.venueService.service
      : ""
  const { taxApplied: _taxApplied, _total } = calculatePrices(total, totalTax, {
    serviceFee,
    service,
  })

  useEffect(() => {
    dispatch(getClientDetail(permalink))
  }, [])

  const menuExtrnalUrl = (_menu) => {
    if (_menu && _menu.length === 1 && _menu[0] && _menu[0].menuUrl) {
      window.location.href = `${_menu[0].menuUrl}`
    } else {
      history.push(
        `/${permalink}/menu/${orderFromTable.venueId}/${_menu[0]._id}/${tableId}`,
      )
    }
  }
  const navigationPath = () => {
    if (
      orderFromTable &&
      Object.keys(orderFromTable).length > 0 &&
      menus &&
      menus.length > 0
    ) {
      const menuFiltered = menus.filter((menu) => {
        return (
          menu.venues.includes(orderFromTable.venueId) &&
          menu.availableOnOrderFromTable === true
        )
      })

      if (menuFiltered && menuFiltered.length === 1) {
        menuExtrnalUrl(menuFiltered)
      } else {
        history.push(`/${permalink}/${tableId}?openmenu=true`)
      }
    }
  }
  return (
    <>
      <BackButton
        onBackClick={() => {
          const permalink = localStorage.getItem("clientPermalink")
          window.location.href = `/${permalink}/${tableId}`
        }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className={"flex w-full flex-col bg-transparent relative bg-white"}
        >
          <div className={"flex flex-col w-full"}>
            {orders?.length ? (
              <VirtualBasketCard
                items={orders}
                discountVoucherId={""}
                discountVoucherAmount={""}
                typography={typography}
                AmountPaid={orderFromTable.amountPaid}
                service={service}
                serviceFee={serviceFee}
              />
            ) : (
              <EmptyBasketCard />
            )}
          </div>

          <div
            style={{ backgroundColor: theme.secondary.light }}
            className="flex flex-col py-12 w-full fixed z-10 overflow-hidden bottom-0"
          >
            <div className="flex flex-col justify-center items-center w-11/12 self-center">
              {orders?.length ? (
                <ButtonIcon
                  title={languageStrings["HOME_PAGE.PAY_ORDER"]}
                  style={{
                    backgroundColor: theme.main.newOrange,
                    marginBottom: 0,
                  }}
                  textStyle={{
                    color: theme.main.white,
                  }}
                  onClick={() => {
                    setShowModal(
                      orderFromTable.amountPaid === total - promotionPrice
                        ? false
                        : true,
                    )
                  }}
                />
              ) : null}
              <ButtonIcon
                title={languageStrings["HOME_PAGE.SEE_THE_MENU_VB"]}
                style={{
                  marginTop: "1.2rem",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.main.newOrange}`,
                }}
                textStyle={{
                  color: theme.main.newOrange,
                }}
                onClick={() => {
                  navigationPath()

                  const permalink = localStorage.getItem("clientPermalink")

                  return
                  if (menus?.length === 1 && menus[0]?.menuUrl) {
                    window.location.href = `${menus[0].menuUrl}`
                  } else {
                    history.push({
                      pathname: `/${permalink}/${tableId}`,
                      state: { isVirtualbasketMenu: true },
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </BackButton>

      <SplitBillModal
        typography={typography}
        totalAmount={_total - promotionPrice}
        showModal={showaModal}
        tableId={tableId}
        venueId={venueId}
        amountPaid={orderFromTable.amountPaid}
        onCloseModal={() => {
          setShowModal(false)
        }}
      />
    </>
  )
}

export default VirtualBasketDetails
