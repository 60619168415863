import React, { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import MenuItemsCartDetails from "./components/MenuItemsCartDetails"
import ComplexItemModal from "../components/ComplexItemModal"
import SimpleItemModal from "../components/SimpleItemModal"
import ComboItemModal from "../components/ComboItemModal"
import classes from "../menuItem.module.css"

import { useBrand } from "@hooks/useBrand"
import { ItemDivider } from "./components/ItemDivider"
import { MenuItemCard } from "./components/MenuItemCard"
import { getComboDetail, getProductDetail } from "@store/menu/MenuActions"
import { setItemSelected } from "@store/cart/cartActions"

interface Props {
  item: MenuItem
  onClick: () => void
  quantity?: number
  onItemDecrease: () => void
  iconFontSize?: "inherit" | "default" | "small" | "large"
  categoryId: string
}

const MenuItemMobile: FC<Props> = ({
  item,
  onClick,
  quantity,
  onItemDecrease,
  iconFontSize = "default",
  categoryId,
}) => {
  const { extraCategories } = item
  const [modal, setModal] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [showComboDetail, setShowComboDetail] = useState(false)
  const { primaryColor } = useBrand()
  const typography = primaryColor
  const dispatch = useDispatch()
  const cartItems = useSelector(({ cart }: RootState) => cart.cart.items)
  const filteredCombos = cartItems.filter(
    (cartItem) => cartItem._id === item._id,
  )

  const { orderFromTable, programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const filteredProducts = cartItems.filter(
    (cartItem) =>
      cartItem._id.substring(0, cartItem._id.indexOf("-")) === item._id,
  )
  const itemsToRender = item?.isCombo ? filteredCombos : filteredProducts
  const handleClick = () => {
    const payload = {
      item,
      extraCategories: [],
    }
    dispatch(setItemSelected(payload))

    dispatch(
      getProductDetail(
        item?._id,
        "",
        programmedOrderDate,
        orderFromTable?._id,
        categoryId,
      ),
    )
    setModal(true)
  }
  const showItemDetail = () => {
    if (item?.isCombo) {
      dispatch(
        getComboDetail(
          item?._id,
          "",
          programmedOrderDate,
          orderFromTable?._id,
          categoryId,
        ),
      )
      setShowComboDetail(true)
    } else {
      handleClick()
    }
  }
  const onAdd = () => {
    dispatch(
      getComboDetail(
        item?._id,
        "",
        programmedOrderDate,
        orderFromTable?._id,
        categoryId,
      ),
    )

    setShowComboDetail(true)
  }

  return (
    <>
      <div className={classes.menu_item}>
        {modal && (
          <ComplexItemModal
            typography={typography}
            item={item}
            onClose={() => {
              dispatch(
                getComboDetail(
                  item?._id,
                  "",
                  programmedOrderDate,
                  orderFromTable?._id,
                  categoryId,
                ),
              )
              setModal(false)
            }}
            isMobile={true}
          />
        )}

        {showDetail && (
          <SimpleItemModal
            typography={typography}
            item={item}
            onClose={() => setShowDetail(false)}
            isMobile={true}
          />
        )}
        {showComboDetail && (
          <ComboItemModal
            item={item}
            onClose={() => setShowComboDetail(false)}
            isMobile={true}
            typography={typography}
            show={showComboDetail}
          />
        )}
        <MenuItemCard
          item={item}
          onClick={handleClick}
          onItemDecrease={onItemDecrease}
          showItemDetail={showItemDetail}
          iconFontSize={iconFontSize}
          quantity={quantity}
          onAdd={item?.isCombo ? onAdd : undefined}
        />
      </div>
      {itemsToRender.map((item, index) => (
        <MenuItemsCartDetails typography={typography} item={item} key={index} />
      ))}
      <ItemDivider />
    </>
  )
}

export default MenuItemMobile
