import { axiosInstance } from "@api/axios"
import { VenueActionTypes, CartActionTypes } from "@store/redux/actionTypes"
import { logout } from "@store/auth/AuthActions"
import { toast } from "react-toastify"

import {
  getVenueListUrl,
  VENUE,
  AUTH_CLIENT,
  getCheckAvailabilityCollectionUrl,
  getCheckAvailabilityDeliveryUrl,
  RATING,
} from "@api/endPoints"
import {
  getRedsysParam,
  submitOrder,
  setCartPendingPayment,
} from "@store/cart/cartActions"
import { CartType } from "@models/Cart"
import History from "@history"
import { getAllMenuList } from "@store/menu/MenuActions"
import {
  getDeviceToken,
  getSelectedOptionData,
  redirectFromRating,
} from "@helpers/utilsHelper"
import { store } from "@store/index"
import { getUser } from "@store/user/UserActions"
import { RatingPayload } from "@models/Rating"

export const setSelectedOption = (value) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.SET_SELECT_OPTION,
      payload: value,
    })
    const paymentData = getSelectedOptionData(value)

    if (paymentData) {
      dispatch({
        type: VenueActionTypes.SET_PAYMENT_SETTING,
        payload: paymentData,
      })
    }
    dispatch({
      type: CartActionTypes.SET_SELECTED_ADDRESS,
      payload: {},
    })
  }
}

export const emptyVenueDetail = () => ({
  type: VenueActionTypes.EMPTY_VENUE_DETAIL,
})

export const getVenueList = (permalink) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.GET_VENUE_LIST_START,
    })
    const url = getVenueListUrl()
    axiosInstance
      .get(url)
      .then((res) => {
        getVenueListSuccess(dispatch, res.data, permalink)
      })
      .catch(() => {
        getVenueListFail(dispatch, "There was an error connection")
      })
  }
}

const getVenueListFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenueActionTypes.GET_VENUE_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getVenueListSuccess = (dispatch, { data }, permalink) => {
  dispatch({
    type: VenueActionTypes.GET_VENUE_LIST_SUCCESS,
    payload: data,
  })
  if (data.length === 1) {
    let venue = data[0]
    const { collectionAvailable, deliveryAvailable } = venue
    const check = collectionAvailable && deliveryAvailable

    if (!check) {
      if (venue.deliveryAvailable) {
        dispatch(setSelectedOption(CartType.DELIVERY))
      }

      if (venue.collectionAvailable) {
        dispatch(setSelectedOption(CartType.COLLECT))
      }
    }
    // History.push(`/${permalink}/menu/${venue._id}`)
  }
}

export const getVenueDetail = (venueId: string) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.GET_VENUE_DETAIL_START,
    })

    const url = VENUE + "/" + venueId
    axiosInstance
      .get(url)
      .then((res) => {
        getVenueDetailSuccess(dispatch, res.data)
      })
      .catch(() => {
        getVenueDetailFail(dispatch, "There was an error connection")
      })
  }
}

const getVenueDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenueActionTypes.GET_VENUE_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getVenueDetailSuccess = (dispatch, { data }) => {
  dispatch({
    type: VenueActionTypes.GET_VENUE_DETAIL_SUCCESS,
    payload: data,
  })
}

export const getClientDetail = (permalink: string) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.GET_CLIENT_DETAIL_START,
    })

    const url = AUTH_CLIENT + permalink
    axiosInstance
      .get(url)
      .then((res) => {
        localStorage.setItem("clientId", res.data.data._id)
        localStorage.setItem("clientPermalink", permalink)
        dispatch(getUser(res.data.data._id))
        dispatch(getAllMenuList(res.data.data._id))
        getClientDetailSuccess(dispatch, res.data, permalink)
      })
      .catch(() => {
        getClientDetailFail(dispatch, "There was an error connection")
      })
  }
}

const getClientDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenueActionTypes.GET_CLIENT_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getClientDetailSuccess = (dispatch, data, permalink) => {
  let _data = data
  dispatch(getVenueList(permalink))
  const { venues = {} } = store.getState()
  const { selectedOption = "" } = venues
  const _paymentData = getSelectedOptionData(selectedOption) || {}

  _data = {
    ..._data,
    data: {
      ..._data.data,
      settings: {
        ..._data.data.settings,
        ..._paymentData,
      },
    },
  }

  dispatch({
    type: VenueActionTypes.GET_CLIENT_DETAIL_SUCCESS,
    payload: _data,
  })
}

export const checkCollectionAvailability = (
  venueId?: string,
  isBasket?: boolean,
  history?: any,
  isAutoService?: boolean,
  isOrder?: boolean,
  payload?: any,
  stripe?: any,
) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_START,
    })
    const clientId = localStorage.getItem("clientId")
    const url = getCheckAvailabilityCollectionUrl(clientId)
    axiosInstance
      .get(url)
      .then((res) => {
        checkCollectionAvailabilitySuccess(
          dispatch,
          res.data.data,
          venueId,
          isBasket,
          history,
          isOrder,
          payload,
          stripe,
          isAutoService,
        )
      })
      .catch(() => {
        checkCollectionAvailabilityFail(
          dispatch,
          "There was an error connection",
        )
      })
  }
}

const checkCollectionAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const checkCollectionAvailabilitySuccess = (
  dispatch,
  data,
  venueId,
  isBasket,
  history,
  isOrder,
  payload,
  stripe,
  isAutoService,
) => {
  const exist = data.venuesId.find((v) => v == venueId)

  dispatch({
    type: VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_SUCCESS,
    payload: exist ? true : false,
  })
  if (!exist) {
    toast.error("Lo sentimos nos encontramos cerrados ahora mismo")
  } else if (isBasket) {
    history.push(`/menu/basket/${venueId}`)
  } else if (isOrder) {
    if (payload.payByRedsys) {
      dispatch(setCartPendingPayment(payload))
      dispatch(getRedsysParam(payload))
    } else {
      dispatch(submitOrder(payload, stripe, isAutoService, null))
    }
  }
}

export const checkDeliveryAvailability = (
  venueId?: string,
  isBasket?: boolean,
  history?: any,
  isAutoService?: boolean,
  isOrder?: boolean,
  payload?: any,
  stripe?: any,
) => {
  return (dispatch) => {
    dispatch({
      type: VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_START,
    })
    const clientId = localStorage.getItem("clientId")
    const url = getCheckAvailabilityDeliveryUrl(clientId)
    axiosInstance
      .get(url)
      .then((res) => {
        checkDeliveryAvailabilitySuccess(
          dispatch,
          res.data.data,
          venueId,
          isBasket,
          history,
          isOrder,
          payload,
          stripe,
          isAutoService,
        )
      })
      .catch((error) => {
        checkDeliveryAvailabilityFail(dispatch, "There was an error connection")
      })
  }
}

const checkDeliveryAvailabilityFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos nos encontramos cerrados ahora mismo")
  dispatch({
    type: VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const checkDeliveryAvailabilitySuccess = (
  dispatch,
  data,
  venueId,
  isBasket,
  history,
  isOrder,
  payload,
  stripe,
  isAutoService,
) => {
  const exist = data.venuesId.find((v) => v == venueId)

  dispatch({
    type: VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_SUCCESS,
    payload: exist ? true : false,
  })
  if (!exist) {
    toast.error("Lo sentimos nos encontramos cerrados ahora mismo")
  } else if (isBasket) {
    history.push(`/menu/basket/${venueId}`)
  } else if (isOrder) {
    if (payload.payByRedsys) {
      dispatch(setCartPendingPayment(payload))
      dispatch(getRedsysParam(payload))
    } else {
      dispatch(submitOrder(payload, stripe, isAutoService, null))
    }
  }
}

export const createRating = ({ tableId, rating, review }: RatingPayload) => {
  return (dispatch, state) => {
    const _venueDetail = state?.()?.venues?.venueDetail || null
    const clientDetail = state?.()?.venues?.restaurant || null
    dispatch({
      type: VenueActionTypes.CREATE_RATING_START,
    })
    const deviceId = getDeviceToken()
    const data = {
      tableId,
      rating,
      deviceId,
      review,
    }
    axiosInstance
      .post(RATING, data)
      .then((res) => {
        dispatch({
          type: VenueActionTypes.CREATE_RATING_SUCCESS,
        })
        if (rating === 5) {
          window.location.href = `${_venueDetail.ratings.ratingUrl}`
        } else {
          redirectFromRating({
            _orderType: _venueDetail?.orderType,
            permalink: clientDetail?.settings?.permalink || "",
            tableId: tableId,
            venueId: _venueDetail?._id,
          })
        }
      })
      .catch((error) => {
        console.log({ error })
        dispatch({
          type: VenueActionTypes.CREATE_RATING_FAIL,
        })
      })
  }
}
