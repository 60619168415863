import React, { CSSProperties, FC } from "react"
import classes from "./appText.module.css"

interface TextProps {
  children: any
  style?: CSSProperties
  onClick?: (e: any) => void
  className?: string
}

//Heading1
export const AppHeading1: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h1 onClick={onClick} style={style} className={classes.heading1}>
      {children}
    </h1>
  )
}
export const AppHeading1Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h1 onClick={onClick} style={style} className={classes.heading1Black}>
      {children}
    </h1>
  )
}
export const AppHeading1Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h1 onClick={onClick} style={style} className={classes.heading1Bold}>
      {children}
    </h1>
  )
}
export const AppHeading1Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h1 onClick={onClick} style={style} className={classes.heading1Medium}>
      {children}
    </h1>
  )
}

//Heading2
export const AppHeading2: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h2 onClick={onClick} style={style} className={classes.heading2}>
      {children}
    </h2>
  )
}
export const AppHeading2Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h2 onClick={onClick} style={style} className={classes.heading2Black}>
      {children}
    </h2>
  )
}
export const AppHeading2Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h2 onClick={onClick} style={style} className={classes.heading2Bold}>
      {children}
    </h2>
  )
}
export const AppHeading2Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h2 onClick={onClick} style={style} className={classes.heading2Medium}>
      {children}
    </h2>
  )
}

//Heading3
export const AppHeading3: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h3 onClick={onClick} style={style} className={classes.heading2}>
      {children}
    </h3>
  )
}
export const AppHeading3Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h3 onClick={onClick} style={style} className={classes.heading3Black}>
      {children}
    </h3>
  )
}
export const AppHeading3Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h3 onClick={onClick} style={style} className={classes.heading3Bold}>
      {children}
    </h3>
  )
}
export const AppHeading3Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h3 onClick={onClick} style={style} className={classes.heading3Medium}>
      {children}
    </h3>
  )
}

//Heading4
export const AppHeading4: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h4 onClick={onClick} style={style} className={classes.heading4}>
      {children}
    </h4>
  )
}
export const AppHeading4Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h4 onClick={onClick} style={style} className={classes.heading4Black}>
      {children}
    </h4>
  )
}
export const AppHeading4Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h4 onClick={onClick} style={style} className={classes.heading4Bold}>
      {children}
    </h4>
  )
}
export const AppHeading4Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h4 onClick={onClick} style={style} className={classes.heading4Medium}>
      {children}
    </h4>
  )
}

//Heading5
export const AppHeading5: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h5 onClick={onClick} style={style} className={classes.heading5}>
      {children}
    </h5>
  )
}
export const AppHeading5Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h5 onClick={onClick} style={style} className={classes.heading5Black}>
      {children}
    </h5>
  )
}
export const AppHeading5Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h5 onClick={onClick} style={style} className={classes.heading5Bold}>
      {children}
    </h5>
  )
}
export const AppHeading5Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h5 onClick={onClick} style={style} className={classes.heading5Medium}>
      {children}
    </h5>
  )
}

//Heading6
export const AppHeading6: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <h6 onClick={onClick} style={style} className={classes.heading6}>
      {children}
    </h6>
  )
}
export const AppHeading6Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h6 onClick={onClick} style={style} className={classes.heading6Black}>
      {children}
    </h6>
  )
}
export const AppHeading6Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h6 onClick={onClick} style={style} className={classes.heading6Bold}>
      {children}
    </h6>
  )
}
export const AppHeading6Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <h6 onClick={onClick} style={style} className={classes.heading6Medium}>
      {children}
    </h6>
  )
}

//Paragraph1
export const AppParagraph1: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph1}>
      {children}
    </span>
  )
}
export const AppParagraph1Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph1Black}>
      {children}
    </span>
  )
}
export const AppParagraph1Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph1Bold}>
      {children}
    </span>
  )
}
export const AppParagraph1Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph1Medium}>
      {children}
    </span>
  )
}

//Paragraph2
export const AppParagraph2: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph2}>
      {children}
    </span>
  )
}
export const AppParagraph2Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph2Black}>
      {children}
    </span>
  )
}
export const AppParagraph2Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph2Bold}>
      {children}
    </span>
  )
}
export const AppParagraph2Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph2Medium}>
      {children}
    </span>
  )
}

//Paragraph3
export const AppParagraph3: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph3}>
      {children}
    </span>
  )
}
export const AppParagraph3Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph3Black}>
      {children}
    </span>
  )
}
export const AppParagraph3Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph3Bold}>
      {children}
    </span>
  )
}
export const AppParagraph3Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph3Medium}>
      {children}
    </span>
  )
}

//Paragraph4
export const AppParagraph4: FC<TextProps> = ({
  children,
  onClick,
  style,
  className,
}) => {
  return (
    <span
      onClick={onClick}
      style={style}
      className={`${classes.paragraph4} ${className}`}
    >
      {children}
    </span>
  )
}
export const AppParagraph4Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph4Black}>
      {children}
    </span>
  )
}
export const AppParagraph4Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph4Bold}>
      {children}
    </span>
  )
}
export const AppParagraph4Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph4Medium}>
      {children}
    </span>
  )
}

//Paragraph5
export const AppParagraph5: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph5}>
      {children}
    </span>
  )
}
export const AppParagraph5Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph5Black}>
      {children}
    </span>
  )
}
export const AppParagraph5Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph5Bold}>
      {children}
    </span>
  )
}
export const AppParagraph5Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph5Medium}>
      {children}
    </span>
  )
}

//Paragraph6
export const AppParagraph6: FC<TextProps> = ({
  children,
  onClick,
  style,
  className,
}) => {
  return (
    <span
      onClick={onClick}
      style={style}
      className={`${classes.paragraph6} ${className}`}
    >
      {children}
    </span>
  )
}
export const AppParagraph6Black: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph6Black}>
      {children}
    </span>
  )
}
export const AppParagraph6Bold: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph6Bold}>
      {children}
    </span>
  )
}
export const AppParagraph6Medium: FC<TextProps> = ({
  children,
  onClick,
  style,
}) => {
  return (
    <span onClick={onClick} style={style} className={classes.paragraph6Medium}>
      {children}
    </span>
  )
}
export const AppBtnTxt: FC<TextProps> = ({ children, onClick, style }) => {
  return (
    <span onClick={onClick} style={style} className={classes.appBtnTxt}>
      {children}
    </span>
  )
}
