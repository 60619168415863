import React from "react"
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"

import CustomModal from "@components/modals/CustomModal"
import { useDispatch } from "react-redux"
import { Heading3 } from "@components/Text/Text"
import { showPendingOrderDetailsModel } from "@store/orderFromTable/OrderFromTableActions"
import { useHistory } from "react-router-dom"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import OrdersStatus from "@components/OrderConfirmed/Components/OrdersStatus"
import useClientCurrency from "@hooks/useClientCurrency"
interface StyleProps {
  typography?: string
}
interface Props {
  open: boolean
  items?: any[]
}
const PendingOrderDetailsModel = ({ open = false, items = [] }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { clientCurrency } = useClientCurrency()
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <CustomModal
      showModal={open}
      onCloseModal={() => {
        showPendingOrderDetailsModel(dispatch, false)
      }}
    >
      <Box
        className={
          "flex flex-col items-center w-11/12 md:w-2/5 bg-white rounded px-8 pb-8 pt-2 md:pb-16 md:pt-2"
        }
        style={{ maxHeight: "80%" }}
      >
        <Heading3
          onClick={() => {
            showPendingOrderDetailsModel(dispatch, false)
          }}
          className={"m-0 self-end cursor-pointer"}
        >
          X
        </Heading3>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {languageStrings["TICKET_NO"]}
                </TableCell>
                <TableCell align="center">
                  {languageStrings["TOTAL_AMOUNT"]}
                </TableCell>
                <TableCell align="center">
                  {languageStrings["STATUS"]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => {
                return (
                  <TableRow
                    onClick={() => {
                      history.push(`/pending-order-details/${item._id}`)
                    }}
                  >
                    <TableCell align="center">{item.ticketNo}</TableCell>
                    <TableCell align="center">
                      {clientCurrency.symbol} {item.price.toFixed(2)}
                    </TableCell>
                    {OrdersStatus(item.status[0])}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CustomModal>
  )
}

export default PendingOrderDetailsModel
