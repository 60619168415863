import React from "react"
import { axiosInstance as axios } from "@api/axios"
import ReactDOM from "react-dom"
import "./styles/index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "@assets/fonts/Gilroy-Bold.ttf"
import "@assets/fonts/Gilroy-Medium.ttf"
import "@assets/fonts/Gilroy-Light.ttf"
import "@assets/fonts/ProductSans-Black.ttf"
import "@assets/fonts/ProductSans-Bold.ttf"
import "@assets/fonts/ProductSans-Light.ttf"
import "@assets/fonts/ProductSans-Medium.ttf"
import "@assets/fonts/ProductSans-Regular.ttf"

import ReactGA from "react-ga"
import { clientSetting } from "config"

axios.defaults.headers.common["Accept-Language"] = "es"

if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
  ReactGA.initialize(clientSetting.googleAnalyticKey)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
