import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import ExpandMoreIcon from "@assets/accordion.png"
import { Label14 } from "@components/Text/Text"
import MenuItemMobile from "@modules/menuitem/mobile/MenuItemMobile"
import { useDispatch } from "react-redux"
import { decreaseItemAmountOrderPage } from "@store/cart/cartActions"
import classes from "@components/tables/tablecenter/tableCenter.module.css"
import { theme } from "config/theme"
import { AppHeading4Bold } from "@components/Text/AppText"
import { useBrand } from "@hooks/useBrand"
export interface Props {
  val: any
  handleMenuItemClick: (item: any) => void
  cartItems?: any
  categoryTitle?: string
  categoryDescription?: string
  iconFontSize?: "inherit" | "default" | "small" | "large"
}

export const AccordionCell = ({
  val,
  handleMenuItemClick,
  cartItems,
  categoryTitle,
  categoryDescription,
  iconFontSize,
}: Props) => {
  const { secondaryColor } = useBrand()
  const dispatch = useDispatch()

  const [expanded, setExpanded] = React.useState(" ")

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <Accordion expanded={!!expanded} onChange={handleChange(categoryTitle)}>
      <AccordionSummary
        style={{
          backgroundColor: theme.main.dark5,
        }}
        expandIcon={
          <img
            src={ExpandMoreIcon}
            style={{
              minHeight: "1.188rem",
              maxHeight: "1.188rem",
              minWidth: "1.188rem",
              maxWidth: "1.188rem",
              transform: "rotate(180deg) scaleX(-1)",
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={"w-full flex  flex-col"}>
          <AppHeading4Bold style={{ color: secondaryColor }}>
            {categoryTitle}
          </AppHeading4Bold>
          {categoryTitle === expanded || !!expanded ? (
            <Label14>{categoryDescription}</Label14>
          ) : null}
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <div className={classes.item_list}>
          {val.menuList &&
            val.menuList.length > 0 &&
            val.menuList.map((item, i) => (
              <ul>
                <li>
                  <MenuItemMobile
                    item={item}
                    onClick={() => handleMenuItemClick(item)}
                    quantity={
                      cartItems.find((cartItem) => cartItem._id === item._id)
                        ?.quantity
                    }
                    onItemDecrease={() =>
                      dispatch(decreaseItemAmountOrderPage({ item }))
                    }
                    iconFontSize={iconFontSize}
                    categoryId={val?._id}
                  />
                </li>
              </ul>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
