import React, { useEffect, useState } from "react"
import { Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { useDispatch, useSelector } from "react-redux"

import Button from "@components/buttons/Button"
import { updateUser } from "@store/user/UserActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {
  typography?: string
  form?: any
  onChange?: (e?: any) => void
  onSubmit?: () => void
  canSubmit?: boolean
}

interface StyleProps {
  typography?: string
}
const UpdateProfileForm = ({ typography }: Props) => {
  const dispatch = useDispatch()
  const { user } = useSelector(({ user }: any) => user)
  const [form, setForm] = useState<any>(user)

  useEffect(() => {
    setForm(user)
  }, [user])

  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const handleSubmit = () => {
    dispatch(updateUser(form))
  }
  const canBeSubmit = () => {
    const { firstName, lastName } = form
    if (firstName && firstName.length > 3 && lastName && lastName.length > 3) {
      return false
    }
    return true
  }
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: "8px",
    }),
  }))
  const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      "& .MuiInput-underline:after": {
        borderBottomColor: props.typography,
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: props.typography,
      },
    }),
  }))
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)
  return (
    <div className={"flex flex-col w-full"}>
      <Card
        className={[
          "flex flex-col bg-white md:p-16 p-8 pb-10",
          Classes.root,
        ].join(" ")}
      >
        <form className={"flex flex-col"} noValidate autoComplete="off">
          <TextField
            className={TextFieldClasses.root}
            id="firstName"
            name="firstName"
            label={languageStrings["PROFILE.NAME"]}
            value={form.firstName}
            onChange={handleChange}
            error={form.firstName.length < 3}
            helperText={
              form.firstName.length < 3 ? "un mínimo de 3 caracteres" : ""
            }
            required
          />
          <TextField
            className={TextFieldClasses.root}
            style={{ marginTop: 10 }}
            id="lastName"
            name="lastName"
            label={languageStrings["PROFILE.SURNAME"]}
            value={form.lastName}
            onChange={handleChange}
            error={form.lastName.length < 3}
            helperText={
              form.lastName.length < 3 ? "un mínimo de 3 caracteres" : ""
            }
            required
          />
          <TextField
            className={TextFieldClasses.root}
            style={{ marginTop: 10 }}
            id="dateOfBirth"
            name="dateOfBirth"
            type="date"
            label={languageStrings["PROFILE.DATE_OF_BIRTH"]}
            value={form.dateOfBirth}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={TextFieldClasses.root}
            style={{ marginTop: 10 }}
            id="phone"
            name="phone"
            label={languageStrings["PROFILE.PHONE"]}
            type="phone"
            value={form.phone}
            onChange={handleChange}
          />
        </form>
      </Card>
      <Button
        title={languageStrings["PROFILE.SAVE_CHANGES"]}
        backgroundColor={typography}
        classes={
          "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none md:relative fixed mt-0 md:mt-10"
        }
        style={{
          borderRadius: "25px",
          fontSize: "14px",
          bottom: 10,
        }}
        onClick={handleSubmit}
        disabled={canBeSubmit()}
      />
    </div>
  )
}

export default UpdateProfileForm
