const clientSetting = {
  showRegistration: false,
  registrationRequired: false,
  footerDescription: "Check",
  orderConfirmedButton: false,
  showProfileTable: false,
  isCheck: true,
  showPayByCard: false,
  searchByAddress: true,
  googleAnalyticKey: "UA-235667598-1",
  showAddress: false,
  showHeaderAddress: false,
  showHeaderTitle: false,
  showHeaderLogo: false,
  showOrderSubmitToast: false,
  getCardList: false,
  showVenueCards:false

};

const productColors = {
  PRIMARY_BRAND_COLOR: "#97DBDD",
  SECONDARY_BRAND_COLOR: "#FE6D4E",
};

export { clientSetting, productColors };
