import React from "react"
import { Card, CardMedia } from "@material-ui/core"
import CustomModal from "@components/modals/CustomModal"
import Button from "@components/buttons/Button"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { makeStyles } from "@material-ui/core/styles"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { Body15Bold } from "@components/Text/Text"

interface Props {
  open: boolean
  onClose: () => void
  onViewMenu: () => void
  isClosedKitchen?: boolean
  isMenuVisible?: boolean
  isCheckoutScreen?: boolean
}

export const HideMenuModal = ({
  open = false,
  onClose = () => {},
  onViewMenu = () => {},
  isClosedKitchen = false,
  isMenuVisible = true,
  isCheckoutScreen = false,
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const { primaryColor } = useBrand()
  const { restaurantName = "" } = useBrand()
  const useStyles = makeStyles({
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "20px",
    },
  })
  const imageClasses = useStyles()

  return (
    <CustomModal showModal={open} onCloseModal={onClose}>
      <div className={imageClasses.imageContainer}>
        <Card className="mb-12 p-12 w-full h-full items-center justify-center cursor-pointer text-center">
          {isMenuVisible && isClosedKitchen ? (
            <Body15Bold>{languageStrings["CLOSED_KITCHEN_MESSAGE"]}</Body15Bold>
          ) : (
            !isCheckoutScreen && (
              <Body15Bold>
                {languageStrings["MENU_HIDE_MESSAGE_PART_1"]}{" "}
                <strong>{restaurantName}</strong>{" "}
                {languageStrings["MENU_HIDE_MESSAGE_PART_2"]}
              </Body15Bold>
            )
          )}
          <Button
            backgroundColor={primaryColor}
            txtColor={theme.main.primary}
            classes={
              "w-4/5 md:w-11/12 h-32 mt-3 md:mt-10 mb-2 font-semibold py-2 px-4 border-none rounded outline-none"
            }
            title={
              isCheckoutScreen
                ? languageStrings["GENERAL_CLOSE"]
                : languageStrings["VIEW_MENU"]
            }
            onClick={() => {
              onClose()
              if (!isCheckoutScreen) onViewMenu()
            }}
            style={{
              borderRadius: "25px",
            }}
          />
        </Card>
      </div>
    </CustomModal>
  )
}
