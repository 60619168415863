import React, { useState } from "react"
import { useSelector } from "react-redux"
import { virtualBasketCounter } from "@store/cart/cartHelpers"
import { Typography } from "@material-ui/core"
import {
  calculatePrices,
  getTaxObject,
  getTaxes,
  getMultiLanguageTitle,
} from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { theme } from "config/theme"
import { useBrand } from "@hooks/useBrand"
import { AmountChip } from "@modules/basket/components/AmountChip"
import {
  AppHeading4,
  AppHeading4Bold,
  AppHeading4Medium,
  AppHeading5,
  AppParagraph5,
  AppParagraph4,
} from "@components/Text/AppText"
import { ItemDivider } from "@modules/menuitem/mobile/components/ItemDivider"
import useClientCurrency from "@hooks/useClientCurrency"

const commonStyles = {
  className: "flex flex-row w-full items-center justify-between mt-4",
}
const commonTextStyles = {
  className: "w-2/4 text-right",
}

const VirtualBasketCard = ({
  items,
  discountVoucherId,
  discountVoucherAmount,
  AmountPaid,
  serviceFee = 0,
  service = "",
}) => {
  const { secondaryColor } = useBrand()
  const { languageStrings, menuLanguage, locale } = useLanguageHelper()
  const { orderFromTable } = useSelector(({ orderFromTable }) => orderFromTable)
  const selectedOption = useSelector(({ venues }) => venues.selectedOption)
  const taxDetail = useSelector(({ venues }) => venues.venueDetail)
  const restaurant = useSelector(({ venues }) => venues.restaurant)
  const { taxId } = taxDetail || {}
  const { taxObj } = getTaxObject(taxId, orderFromTable)
  const { taxType, taxPercentage, totalTax } = getTaxes(taxObj)
  const [deliveryPrice, setDeliveryPrice] = useState(0)
  const pricePos = orderFromTable?.pendingOrdersIds?.[0]?.price || 0
  const isHosteltactil = restaurant?.hosteltactil?.IDLocal ? true : false
  const isFrontrest = restaurant?.frontrest?.restaurantId ? true : false
  const originalPrice = orderFromTable?.originalPrice || null
  const { totalPrice, netPrice, taxApplied, promotionPrice } =
    virtualBasketCounter(
      items,
      totalTax,
      isHosteltactil,
      pricePos,
      isFrontrest,
      originalPrice,
    )
  const isOrderMixAndMatch = orderFromTable.orderMixAndMatch || null
  const isFrontrestMixAndMatch = restaurant?.frontrest?.mixAndMatch || null
  const { clientCurrency } = useClientCurrency()
  let total = parseFloat(totalPrice)
  if (isHosteltactil) total = parseFloat(pricePos)
  if (originalPrice && isFrontrest) total = parseFloat(originalPrice)

  const {
    taxApplied: _taxApplied,
    netServiceFee,
    _total,
  } = calculatePrices(total, totalTax, { serviceFee, service })

  const getTip = () => {
    let totalTip = 0
    if (
      orderFromTable &&
      orderFromTable.totalTip &&
      Number(orderFromTable.totalTip)
    )
      totalTip = Number(orderFromTable.totalTip)

    return totalTip.toFixed(2)
  }
  const renderComboCard = (combo) => {
    return (
      <div key={combo._id}>
        {combo.comboCategories?.map((comboCategory) => (
          <div key={comboCategory._id}>
            {comboCategory.products
              .filter((p) => p.selected === true)
              .map((product) => (
                <div key={product._id}>
                  <Typography variant="subtitle2">{product.title}</Typography>
                  {product.extraCategories &&
                    product.extraCategories.length > 0 &&
                    product.extraCategories.map(({ items }) => {
                      return (
                        <>
                          {items.map(
                            ({
                              title,
                              selected,
                              selectedQuantity = 0,
                              multiTitle = [],
                            }) => {
                              const itemLang = getMultiLanguageTitle(
                                multiTitle,
                                menuLanguage,
                              )
                              const _title = itemLang?.title || title
                              if (selected) {
                                let quantity = ""
                                if (selectedQuantity && selectedQuantity > 0)
                                  quantity = `x ${selectedQuantity}`
                                return (
                                  <Typography
                                    style={{ color: "#c3c3c3" }}
                                    variant="body2"
                                  >
                                    {_title} {quantity}
                                  </Typography>
                                )
                              }
                              return null
                            },
                          )}
                        </>
                      )
                    })}
                </div>
              ))}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div
      className={[
        "flex flex-col self-center justify-start w-full bg-white",
      ].join()}
    >
      <ScreenHeading
        heading={languageStrings["VIRTUAL_BASKET.MY_ORDER"]}
        style={styles.heading}
        subHeading={""}
      />
      <div
        className="flex flex-row items-center mb-8 w-full py-4"
        style={{ backgroundColor: theme.main.blackOpacity }}
      >
        <AppHeading4Bold
          style={{
            marginLeft: 14,
            marginRight: 14,
            color: theme.status.danger2,
          }}
        >
          {languageStrings["ORDER_LIST"]}
        </AppHeading4Bold>
        <AppParagraph5 style={{ color: theme.main.black }}>
          {items?.length || 0} {languageStrings["GENERAL_ITEMS"]}
        </AppParagraph5>
      </div>
      <>
        <>
          {items.map((item) => {
            const itemMultiTitle = item?.multiTitle || []
            const itemLang = getMultiLanguageTitle(itemMultiTitle, menuLanguage)
            const _title = itemLang?.title || item.title
            return (
              <>
                <div className={"flex flex-row w-full p-6"}>
                  <AppHeading4
                    style={{
                      color: theme.status.danger2,
                      marginLeft: 5,
                      marginRight: "1rem",
                    }}
                  >
                    {Math.round(
                      Number(item && item.quantity > 0 ? item.quantity : 0),
                    )}
                  </AppHeading4>
                  <div className={"flex flex-row w-full justify-between"}>
                    <div className={"flex flex-1 flex-col ml-2"}>
                      <AppHeading4Medium style={{ color: theme.main.primary }}>
                        {_title}
                        {item && item.discountedQuantity > 0 && (
                          <AppParagraph4
                            style={{
                              color: "red",
                              marginBottom: "0.6rem",
                              marginTop: "0.2rem",
                              marginLeft: "0.25rem",
                            }}
                          >
                            {` (${item.discountedQuantity} ${languageStrings["FOR_FREE"]})`}
                          </AppParagraph4>
                        )}
                      </AppHeading4Medium>
                      {item?.isCombo
                        ? renderComboCard(item)
                        : item.extraCategories &&
                          item.extraCategories.length > 0 &&
                          item.extraCategories.map(
                            ({ title, items, multiTitle = [] }) => {
                              const itemLang = getMultiLanguageTitle(
                                multiTitle,
                                menuLanguage,
                              )
                              const _title = itemLang?.title || title
                              const allItems =
                                items && items.length ? items : []
                              const __ = allItems.filter(
                                (item) => item.selected,
                              )
                              return (
                                <>
                                  {__ && __.length ? (
                                    <Typography
                                      style={{ color: theme.status.danger2 }}
                                      variant="subtitle2"
                                    >
                                      {_title}
                                    </Typography>
                                  ) : null}
                                  {items.map(
                                    ({
                                      title,
                                      selected,
                                      selectedQuantity = 0,
                                      multiTitle = [],
                                    }) => {
                                      const itemLang = getMultiLanguageTitle(
                                        multiTitle,
                                        menuLanguage,
                                      )
                                      const _title = itemLang?.title || title
                                      if (selected) {
                                        let quantity = ""
                                        if (
                                          selectedQuantity &&
                                          selectedQuantity > 0
                                        )
                                          quantity = `x ${selectedQuantity}`
                                        return (
                                          <Typography
                                            style={{ color: "#c3c3c3" }}
                                            variant="body2"
                                          >
                                            {_title} {quantity}
                                          </Typography>
                                        )
                                      }
                                      return null
                                    },
                                  )}
                                </>
                              )
                            },
                          )}
                    </div>
                    <div className={"flex flex-1 flex-col items-end"}>
                      <AppHeading5 style={{ color: theme.main.blackLight }}>
                        {Number(item.price - promotionPrice).toFixed(2)}{" "}
                        {clientCurrency.symbol}
                      </AppHeading5>
                    </div>
                  </div>
                </div>
                <ItemDivider />
              </>
            )
          })}
        </>
        <div className={"flex flex-col mt-8 items-end mr-8 mb-8"}>
          <AmountChip
            containerClassName={commonStyles.className}
            headingClassName={"text-black"}
            heading={languageStrings["VIRTUAL_BASKET.BASE_PRICE"]}
            value={netPrice + ` ${clientCurrency.symbol}`}
            valueClassName={"text-black"}
            width={"w-3/4"}
          />

          {netServiceFee && service ? (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-black"}
              heading={service}
              value={netServiceFee.toFixed(2) + ` ${clientCurrency.symbol}`}
              valueClassName={"text-black"}
              width={"w-3/4"}
            />
          ) : null}

          {taxPercentage !== 0 ? (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-black"}
              heading={`${taxType} (${taxPercentage}%)`}
              value={_taxApplied.toFixed(2) + ` ${clientCurrency.symbol}`}
              valueClassName={"text-black"}
              width={"w-3/4"}
            />
          ) : null}

          {isFrontrestMixAndMatch && isOrderMixAndMatch && originalPrice && (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-red"}
              heading={languageStrings["TOTAL_WITHOUT_PROMO"]}
              value={
                deliveryPrice > 0 && selectedOption && selectedOption == "2"
                  ? originalPrice + deliveryPrice + ` ${clientCurrency.symbol}`
                  : originalPrice.toFixed(2) + ` ${clientCurrency.symbol}`
              }
              valueClassName={"text-red line-through"}
              width={"w-3/4"}
            />
          )}
          {promotionPrice > 0 && (
            <AmountChip
              containerClassName={commonStyles.className}
              heading={`${languageStrings["BASKET.DISCOUNT"]}`}
              value={`${(total - promotionPrice).toFixed(
                2,
              )} ${clientCurrency.symbol}`}
              txtColor={theme.status.red}
              width={"w-3/4"}
            />
          )}
          <AmountChip
            containerClassName={commonStyles.className}
            headingClassName={"text-black"}
            heading={languageStrings["GENERAL.TOTAL"]}
            value={
              promotionPrice > 0
                ? (_total - promotionPrice).toFixed(2)
                : deliveryPrice > 0 && selectedOption && selectedOption == "2"
                ? _total + deliveryPrice + ` ${clientCurrency.symbol}`
                : _total.toFixed(2) + ` ${clientCurrency.symbol}`
            }
            valueClassName={"text-black"}
            width={"w-3/4"}
          />

          {getTip() > 0 && (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-black"}
              heading={languageStrings["TIP"]}
              value={`${getTip()} ${clientCurrency.symbol}`}
              valueClassName={"text-black"}
              width={"w-3/4"}
            />
          )}
          {AmountPaid > 0 && (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-black"}
              heading={languageStrings["VIRTUAL_BASKET.AMOUNT_PAID"]}
              value={AmountPaid.toFixed(2) + ` ${clientCurrency.symbol}`}
              valueClassName={"text-black"}
              width={"w-3/4"}
            />
          )}
          {AmountPaid > 0 && (
            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={"text-black"}
              heading={languageStrings["VIRTUAL_BASKET.PENDING_AMOUNT"]}
              value={`${(_total - AmountPaid).toFixed(2)} ${
                clientCurrency.symbol
              }`}
              valueClassName={"text-black"}
              width={"w-3/4"}
            />
          )}

          {discountVoucherId ? (
            <>
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={"text-red"}
                heading={languageStrings["VIRTUAL_BASKET.DISCOUNT"]}
                value={`-${discountVoucherAmount} ${clientCurrency.symbol}`}
                valueClassName={"text-red"}
                width={"w-3/4"}
              />
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={"text-black"}
                heading={languageStrings["VIRTUAL_BASKET.GRAND_TOTAL"]}
                value={
                  deliveryPrice > 0 && selectedOption && selectedOption == "2"
                    ? (
                        _total +
                        deliveryPrice -
                        Number(discountVoucherAmount)
                      ).toFixed(2) + `${clientCurrency.symbol}`
                    : (_total - Number(discountVoucherAmount)).toFixed(2) +
                      ` ${clientCurrency.symbol}`
                }
                valueClassName={"text-black"}
              />
            </>
          ) : null}
        </div>
      </>
      <div className="flex bg-white min-h-128 max-h-128" />
    </div>
  )
}

export default VirtualBasketCard
const styles = {
  heading: {
    marginLeft: "0.6rem",
  },
}
