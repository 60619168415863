import { AppHeading3 } from "@components/Text/AppText"
import { Heading3 } from "@components/Text/Text"
import { AddCircle, RemoveCircle } from "@components/addCircle/AddCircle"
import PriceButton, {
  PriceButtonV2,
  PriceButtonV3,
} from "@components/buttons/PriceButton"
import { useBrand } from "@hooks/useBrand"
import useClientCurrency from "@hooks/useClientCurrency"
import { theme } from "config/theme"
import React, { FC } from "react"

interface IProps {
  onRemove: () => void
  onAdd: () => void
  onSubmit: () => void
  classes?: string
  quantityClasses?: string
  btnTitle?: string
  price: string
  quantity: number
  disabled?: boolean
}
const commonStyle = {
  height: "2.4rem",
  width: "2.4rem",
}
export const OrderStates: FC<IProps> = ({
  onRemove,
  onAdd,
  onSubmit,
  classes,
  btnTitle,
  price,
  quantity,
  disabled,
}) => {
  const { primaryColor, secondaryColor, thirdColor, disabledColor } = useBrand()
  const { clientCurrency } = useClientCurrency()
  return (
    <div
      className="flex flex-col w-full py-12"
      style={{
        backgroundColor: thirdColor,
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
      }}
    >
      <div className="flex items-center w-2/5 self-center pb-12 justify-evenly">
        <RemoveCircle
          containerStyle={{
            ...commonStyle,
            backgroundColor: theme.main.white,
          }}
          onClick={onRemove}
        />
        <div
          className={"flex bg-white h-24 w-24 items-center justify-center"}
          style={{ borderRadius: "12px" }}
        >
          <AppHeading3 style={{ color: secondaryColor }}>
            {quantity}
          </AppHeading3>
        </div>
        <AddCircle
          containerStyle={{
            ...commonStyle,
          }}
          onClick={onAdd}
        />
      </div>
      <div className="flex justify-center w-11/12 self-center">
        <PriceButtonV3
          backgroundColor={disabled ? disabledColor : primaryColor}
          onClick={onSubmit}
          classes={classes}
          disabled={disabled}
        >
          <div className="flex flex-row justify-center items-center h-full w-full">
            <span
              style={{
                fontWeight: 700,
                fontSize: "16px",
                fontFamily: "ProductSansRegular",
                textTransform: "none",
                color: theme.main.primary,
              }}
            >
              {`${btnTitle}`}
            </span>
            <div className="w-4" />
            <span
              style={{
                fontWeight: 700,
                fontSize: "16px",
                fontFamily: "ProductSansRegular",
                color: secondaryColor,
              }}
            >
              {price} {clientCurrency.symbol}
            </span>
          </div>
        </PriceButtonV3>
      </div>
    </div>
  )
}
