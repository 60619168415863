import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"
import { StickyContainer, Sticky } from "react-sticky"
import { orderCancel } from "@store/user/UserActions"

import Button from "@components/buttons/Button"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { CartType } from "@models/Cart"
import ItemsCard from "../../MobileProfile/MyOrders/components/ItemsCard"
import LocationCard from "../../MobileProfile/MyOrders/components/LocationCard"
import OrderStepper from "../../MobileProfile/MyOrders/components/OrderStepper"
import VenueCard from "../../../basket/VenueCard"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {
  order?: any
  typography?: string
}

const MyOrderDetails = ({ order, typography }) => {
  const dispatch = useDispatch()
  const [orderStatus, setOrderStatus] = useState(0)

  const useStyles = makeStyles(() => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
    }),
  }))
  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      let status = parseInt(order.status[0].status)
      if (order.type == CartType.DELIVERY) {
        switch (status) {
          case 2:
            setOrderStatus(1)
            break
          case 3:
            setOrderStatus(2)
            break
          case 4:
            setOrderStatus(3)
            break
          case 5:
            setOrderStatus(4)
            break
        }
      } else if (order.type == CartType.COLLECT) {
        switch (status) {
          case 2:
            setOrderStatus(1)
            break
          case 3:
            setOrderStatus(2)
            break
          case 7:
            setOrderStatus(3)
            break
          case 8:
            setOrderStatus(4)
            break
        }
      } else if (order.type == CartType.ORDER_FROM_TABLE) {
        switch (status) {
          case 0:
            setOrderStatus(0)
            break
          case 1:
            setOrderStatus(0)
            break
          case 7:
            setOrderStatus(1)
            break
          default:
            setOrderStatus(status)
        }
      } else {
        setOrderStatus(status)
      }
    }
  }, [order])
  const Classes = useStyles()
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <div className={"flex w-full relative"}>
      <div className={"flex flex-col w-10/12 self-center mb-8"}>
        {order && Object.keys(order).length > 0 && (
          <>
            <Card className={Classes.root}>
              <OrderStepper
                typography={typography}
                status={orderStatus}
                delivery={order.type == "2" ? true : false}
                order={order}
              />
            </Card>
            <div className={"flex flex-col w-full"}>
              {order.type == CartType.DELIVERY && (
                <LocationCard
                  addressItem={order.address}
                  typography={typography}
                />
              )}

              {order.type == CartType.COLLECT && (
                <VenueCard
                  icon={
                    <LocationOnIcon
                      style={{ color: typography }}
                      className={"ml-4 mr-6"}
                    />
                  }
                  venueName={order.restaurantTitle}
                  typography={typography}
                />
              )}
              <ItemsCard
                items={order.items}
                discountVoucherId={order.discountVoucherId}
                discountVoucherAmount={order.discountVoucherAmount}
                typography={typography}
              />
            </div>
          </>
        )}
      </div>
      {Object.keys(order).length > 0 && order.status[0].status == "0" && (
        <StickyContainer>
          <Sticky disableCompensation>
            {({ style, isSticky }) => {
              return (
                <div className={"flex"}>
                  <Button
                    title={languageStrings["PROFILE.TO_CALCEL_AN_ORDER"]}
                    backgroundColor={typography}
                    classes={
                      "flex items-center justify-center self-center ml-8 w-4/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none absolute"
                    }
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      bottom: 10,
                      top: 0,
                    }}
                    onClick={() => {
                      dispatch(orderCancel(order._id, "6"))
                    }}
                  />
                </div>
              )
            }}
          </Sticky>
        </StickyContainer>
      )}
    </div>
  )
}

export default MyOrderDetails
