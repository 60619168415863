import React from "react"
import { Card } from "@material-ui/core"
import { AppHeading4Medium } from "@components/Text/AppText"
export interface Props {
  venueName: string
  typography?: string
  icon: any
}

const VenueCard = ({ venueName, typography, icon }: Props) => {
  return (
    <Card
      elevation={0}
      className={
        "flex flex-row self-center items-center md:w-full w-11/12 h-32 rounded-4"
      }
    >
      {icon}
      <AppHeading4Medium>{venueName}</AppHeading4Medium>
    </Card>
  )
}

export default VenueCard
