import { axiosInstance } from "@api/axios"
import { axiosInstanceV2 } from "@api/axiosV2"
import { toast } from "react-toastify"
import { CartType, AvailabilityData } from "@models/Cart"
import { CartActionTypes } from "@store/redux/actionTypes"
import {
  ORDER,
  SECRET,
  APPLY_DISCOUNT_VOUCHER,
  CONFIRM_PENDING_ORDER,
  REDSYS_FORM_WEB,
  VALIDATE_STOCK,
  CHECK_CART_ITEMS_AVAILABILITY,
} from "@api/endPoints"
import { setOrderId, setLoyaltyClientSuccess } from "@store/user/UserActions"
import { cartCounter } from "./cartHelpers"
import { getDeviceToken, randomString } from "@helpers/utilsHelper"
import History from "@history"
import {
  showOrderTimingEstimationModel,
  setProgrammedOrderDate,
} from "@store/orderFromTable/OrderFromTableActions"
import { clientSetting } from "config"
import { Venue } from "@models/Venue"
import moment from "moment"

export const setSelectedAddress = (payload) => ({
  type: CartActionTypes.SET_SELECTED_ADDRESS,
  payload,
})

export const setCartEmpty = () => ({
  type: CartActionTypes.SET_CART_EMPTY,
})
export const setUniProperty = (payload) => ({
  type: CartActionTypes.SELECT_UNI_SELECT,
  payload,
})

export const getOrderMenuList = (payload) => ({
  type: CartActionTypes.GET_ORDER_MENU_LIST_SUCCESS,
  payload,
})

export const setItemSelected = (payload) => ({
  type: CartActionTypes.SET_ITEM_SELECTED,
  payload,
})

export const addItemInCart = (payload) => ({
  type: CartActionTypes.ADD_ITEM_CART_START,
  payload,
})

export const addMultiItemCart = () => ({
  type: CartActionTypes.ADD_MULTI_ITEM_CART_START,
})

export const increaseNewItemCart = () => ({
  type: CartActionTypes.INCREASE_NEW_ITEM_CART_START,
})

export const decreaseNewItemCart = () => ({
  type: CartActionTypes.DECREASE_NEW_ITEM_CART_START,
})

export const increaseItemAmountOrderPage = (payload) => ({
  type: CartActionTypes.INCREASE_ITEM_AMOUNT_ORDER_PAGE,
  payload,
})

export const decreaseItemAmountOrderPage = (payload) => ({
  type: CartActionTypes.DECREASE_ITEM_AMOUNT_ORDER_PAGE,
  payload,
})

export const addItemWithError = () => ({
  type: CartActionTypes.ADD_ITEM_WITH_ERROR,
})

export const selectCard = (cardId: string) => ({
  type: CartActionTypes.SET_SELECTED_CARD,
  payload: cardId,
})

export const emptyCart = () => {
  return (dispatch) => {
    dispatch({
      type: CartActionTypes.SET_CART_EMPTY,
    })
  }
}
export const emptyCartItems = () => {
  return (dispatch) => {
    dispatch({
      type: CartActionTypes.EMPTY_ITEM_CART_START,
    })
  }
}

export const submitOrder = (payload, stripe, autoServices, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: CartActionTypes.ORDER_SUBMIT_START,
    })
    const deviceId = getDeviceToken()
    const _payload = { ...payload, deviceId }
    const loyaltyClient =
      payload && payload.loyaltyClient && payload.loyaltyClient.email
        ? payload.loyaltyClient
        : null
    if (loyaltyClient) {
      dispatch(setLoyaltyClientSuccess(loyaltyClient))
    }

    dispatch(setOrderId(""))
    autoServices && showOrderTimingEstimationModel(dispatch, true, _payload)
    if (_payload.payByCard) {
      if (_payload.payByRedsys) {
        dispatch(payByRedsys(payload, languageStrings))
      } else {
        dispatch(payByCard(stripe, _payload, languageStrings))
      }
    } else {
      dispatch(payByCash(_payload, languageStrings))
    }
  }
}

const payByCard = (stripe, payload, languageStrings) => {
  return (dispatch, getState) => {
    const store = getState()

    const venueDetail = store.venues.venueDetail
    const selectedOption = store.venues.selectedOption
    let deliveryPrice = 0
    if (
      selectedOption === CartType.DELIVERY &&
      venueDetail.orderInfo &&
      venueDetail.orderInfo.minDeliveryPrice
    ) {
      deliveryPrice = Number(venueDetail.orderInfo.minDeliveryPrice)
    }
    let totalPrice = 0
    store.cart.cart.items.map((item) => {
      totalPrice = totalPrice + Number(item.price) * item.quantity
    })

    dispatch(
      getPaymentSecret(
        totalPrice,
        deliveryPrice,
        undefined,
        payload.discountVoucherId,
      ),
    )
      .then((res) => {
        stripe
          .confirmCardPayment(res.data.data.secret, {
            payment_method: store.cart.selectedCard,
          })
          .then(function (response) {
            if (response.error) {
              submitOrderFail(
                dispatch,
                "There was an error connection",
                languageStrings,
              )
            } else if (
              response.paymentIntent &&
              response.paymentIntent.status === "succeeded"
            ) {
              axiosInstance
                .post(ORDER, {
                  ...payload,
                  chargeId: response.paymentIntent.id,
                })
                .then((res) => {
                  submitOrderSuccess(dispatch, res.data)
                })
                .catch(() => {
                  submitOrderFail(
                    dispatch,
                    "There was an error connection",
                    languageStrings,
                  )
                })
            }
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

const payByRedsys = (payload, languageStrings) => {
  return (dispatch) => {
    axiosInstance
      .post(ORDER, { ...payload })
      .then((res) => {
        submitOrderSuccess(dispatch, res.data)
      })
      .catch(() => {
        submitOrderFail(
          dispatch,
          "There was an error connection",
          languageStrings,
        )
      })
  }
}

const payByCash = (payload, languageStrings) => {
  return (dispatch, state) => {
    const _venueDetail = state?.()?.venues?.venueDetail || null
    axiosInstance
      .post(ORDER, { ...payload })
      .then((res) => {
        submitOrderSuccess(dispatch, res.data, _venueDetail)
      })
      .catch((error) => {
        const message =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : "There was an error connection"
        submitOrderFail(dispatch, message, languageStrings)
      })
  }
}

export const submitOrderFail = (dispatch, errorMessage, languageStrings) => {
  toast.error(
    errorMessage === "Busy" && languageStrings
      ? languageStrings["RESTAURANT_BUSY"]
      : errorMessage,
  )
  dispatch({
    type: CartActionTypes.ORDER_SUBMIT_FAIL,
    payload: {
      errorMessage,
    },
  })
}

export const submitOrderSuccess = (dispatch, data, venueDetail?: Venue) => {
  if (clientSetting.showOrderSubmitToast) {
    toast.success("Order submitted successfully")
  }
  const isRatingEnabled =
    venueDetail && venueDetail.ratings && venueDetail.ratings.isRatingEnabled
      ? true
      : false
  dispatch({
    type: CartActionTypes.ORDER_SUBMIT_SUCCESS,
    payload: data,
  })
  dispatch(setOrderId(data.data._id))
  if (
    isRatingEnabled &&
    venueDetail?.orderType !== "4" &&
    venueDetail?.orderType !== "5"
  ) {
    History.push("/rating")
  } else {
    History.push("/orderConfirmed")
  }
  if (venueDetail?.orderType === "1") {
    dispatch(setLoyaltyClientSuccess({ email: "", name: "" }))
  }
  // window.location.href = "/orderConfirmed"
}

export const getPaymentSecret = (
  price: number,
  deliveryPrice: number,
  paymentId?: string,
  discountVoucherId?: string,
) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CartActionTypes.GET_CART_SECRET_START,
      })

      const url = SECRET
      axiosInstance
        .post(url, { price, deliveryPrice, paymentId, discountVoucherId })
        .then((res) => {
          resolve(res)
          getPaymentSecretSuccess(dispatch, res.data)
        })
        .catch((err) => {
          reject(err)
          getPaymentSecretFail(dispatch, "There was an error connection")
        })
    })
}

const getPaymentSecretFail = (dispatch, errorMessage) => {
  dispatch({
    type: CartActionTypes.GET_CART_SECRET_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getPaymentSecretSuccess = (dispatch, { data }) => {
  dispatch({
    type: CartActionTypes.GET_CART_SECRET_SUCCESS,
    payload: { ...data },
  })
}

export const applyDiscountVoucher = (
  voucherCode: string,
  orderType?: string,
  venueId?: string,
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CartActionTypes.APPLY_DISCOUNT_VOUCHER_START,
    })
    const store = getState()

    let { totalItems, totalPrice } = cartCounter(store.cart.cart.items)

    const url = APPLY_DISCOUNT_VOUCHER

    const params = {
      discountCode: voucherCode,
      clientId: localStorage.getItem("clientId"),
      totalItems,
      totalPrice,
      orderType: orderType ?? "",
      venueId,
    }

    axiosInstance
      .get(url, { params })
      .then((res) => {
        applyDiscountVoucherSuccess(dispatch, res.data.data)
      })
      .catch(() => {
        applyDiscountVoucherFail(dispatch, "There was an error connection")
      })
  }
}

const applyDiscountVoucherFail = (dispatch, errorMessage) => {
  dispatch({
    type: CartActionTypes.APPLY_DISCOUNT_VOUCHER_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const applyDiscountVoucherSuccess = (dispatch, data) => {
  dispatch({
    type: CartActionTypes.APPLY_DISCOUNT_VOUCHER_SUCCESS,
    payload: data,
  })
}

export const setCartPendingPayment = (payload) => {
  return (dispatch) =>
    dispatch({
      type: CartActionTypes.SET_CART_PENDING_PAYMENT,
      payload,
    })
}

export const getRedsysParam = (payload) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CartActionTypes.GET_REDSYS_FORM_START,
      })

      const orderId = randomString(10)
      const data = { ...payload, orderId }

      axiosInstance
        .post(REDSYS_FORM_WEB, data)
        .then((res) => {
          resolve(res)
          getRedsysParamSuccess(dispatch, res.data)
        })
        .catch((err) => {
          reject(err)
          getRedsysParamFail(dispatch, "There was an error connection")
        })
    })
}

const getRedsysParamFail = (dispatch, errorMessage) => {
  dispatch({
    type: CartActionTypes.GET_REDSYS_FORM_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getRedsysParamSuccess = (dispatch, { data }) => {
  console.log(data)
  History.push(`/redsysFormPage`, {
    signature: data.Ds_Signature,
    merchant: data.Ds_MerchantParameters,
    isProd: data.isProd,
  })
  dispatch({
    type: CartActionTypes.GET_REDSYS_FORM_SUCCESS,
    payload: { ...data },
  })
}

export const confirmPendingOrder = (orderId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CartActionTypes.CONFIRM_PENDING_ORDER_START,
      })

      const data = { orderId }

      axiosInstance
        .post(CONFIRM_PENDING_ORDER, data)
        .then((res) => {
          resolve(res)
          confirmPendingOrderSuccess(dispatch, res.data)
        })
        .catch((err) => {
          reject(err)
          confirmPendingOrderFail(dispatch, "There was an error connection")
        })
    })
}

const confirmPendingOrderFail = (dispatch, errorMessage) => {
  dispatch({
    type: CartActionTypes.CONFIRM_PENDING_ORDER_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const confirmPendingOrderSuccess = (dispatch, { data }) => {
  // History.push(`/redsysFormPage`, {
  //   signature: data.Ds_Signature,
  //   merchant: data.Ds_MerchantParameters,
  //   isProd: data.isProd,
  // })
  console.log("confirmPendingOrderSuccess", data.pendingOrder)
  dispatch(submitOrder(data.pendingOrder, "", false, null))

  dispatch({
    type: CartActionTypes.CONFIRM_PENDING_ORDER_SUCCESS,
    payload: { ...data },
  })
}

export const validateStock = (cartItems, callback) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CartActionTypes.VALIDATE_STOCK_START,
      })

      const data = { cartItems }

      axiosInstance
        .post(VALIDATE_STOCK, data)
        .then((res) => {
          resolve(res)
          validateStockSuccess(dispatch, res.data.data)
          if (!res.data.data.stockError) {
            callback()
          }
        })
        .catch((err) => {
          reject(err)
          validateStockFail(dispatch)
        })
    })
}

const validateStockSuccess = (dispatch, data) => {
  dispatch({
    type: CartActionTypes.VALIDATE_STOCK_SUCCESS,
    payload: { ...data },
  })
}
const validateStockFail = (dispatch) => {
  dispatch({
    type: CartActionTypes.VALIDATE_STOCK_FAIL,
  })
}

export const setShowStockModal = (show: boolean) => ({
  type: CartActionTypes.SET_SHOW_STOCK_MODAL,
  payload: show,
})
export const updateCartItems = (cartItems) => ({
  type: CartActionTypes.SET_CART_ITEMS,
  payload: cartItems,
})
const checkCartItemsAvailabilitySuccess = (dispatch) => {
  dispatch({
    type: CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_SUCCESS,
  })
}
const checkCartItemsAvailabilityFail = (dispatch) => {
  dispatch({
    type: CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_FAIL,
  })
}
export const validateCartItemsAvailability = (
  cartItems,
  clientId,
  programmedOrderDate,
  menuId,
  callback,
) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_START,
      })

      const data: AvailabilityData = { orderItems: cartItems, clientId, menuId }

      if (programmedOrderDate && moment(programmedOrderDate, true).isValid()) {
        data.programmedOrderDate = programmedOrderDate
      }
      axiosInstanceV2
        .post(CHECK_CART_ITEMS_AVAILABILITY, data)
        .then((res) => {
          resolve(res)
          checkCartItemsAvailabilitySuccess(dispatch)
          callback(res.data.result)
        })
        .catch((err) => {
          reject(err)
          checkCartItemsAvailabilityFail(dispatch)
        })
    })
}
