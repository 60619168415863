import React from "react"
import { Card } from "@material-ui/core"
import { Heading2 } from "@components/Text/Text"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {
  typography?: string
  value?: string
  onValueChange?: (v: any) => void
}
interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "0px",
  }),
}))

const useCardStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
}))

const CommentsCard = ({ typography, value, onValueChange }: Props) => {
  const styleProps: StyleProps = { typography }
  const commentClasses = useCardStyles(styleProps)
  const classes = useStyles(styleProps)
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center justify-start w-full mt-16 bg-white shadow-md",
        classes.root,
      ].join()}
    >
      <div className={"flex flex-row mt-8 pl-16"}>
        <Heading2
          style={{ color: "rgb(105, 105, 105)", marginTop: "0.8rem" }}
          className={"m-0"}
        >
          {languageStrings["GENERAL.COMMENTS"]}
        </Heading2>
      </div>
      <form
        className={"flex self-center w-11/12 mt-6 mb-5"}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-basic"
          label={languageStrings["GENERAL.COMMENTS"]}
          className={["w-full", commentClasses.root].join(" ")}
          multiline
          value={value}
          onChange={onValueChange}
          rowsMax={3}
        />
      </form>
    </Card>
  )
}

export default CommentsCard
