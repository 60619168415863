import React, { FC, useState } from "react"
import {
  AppHeading6Medium,
  AppParagraph4,
  AppParagraph5,
} from "@components/Text/AppText"
import {
  formatImageUrl,
  getMultiLanguageTitle,
  checkClosedKitchenTime,
} from "@helpers/utilsHelper"
import { useBrand } from "@hooks/useBrand"
import { Card } from "@material-ui/core"
import { theme } from "config/theme"
import ComplexItemModal from "../../menuitem/components/ComplexItemModal"
import SimpleItemModal from "../../menuitem/components/SimpleItemModal"
import { AddCircle } from "@components/addCircle/AddCircle"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useDispatch, useSelector } from "react-redux"
import { getProductDetail, getComboDetail } from "@store/menu/MenuActions"
import { setItemSelected } from "@store/cart/cartActions"
import ComboItemModal from "@modules/menuitem/components/ComboItemModal"
import useClientCurrency from "@hooks/useClientCurrency"

interface IProps {
  item: MenuItem
  onClick?: () => void
  onAddClick?: () => void
}
export const PopularCard: FC<IProps> = ({ item, onAddClick }) => {
  const { primaryColor } = useBrand()
  const { menuLanguage } = useLanguageHelper()
  const [modal, setModal] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const itemMultiTitle = item?.multiTitle || []
  const itemMultiDescription = item?.multiDescription || []
  const itemLang = getMultiLanguageTitle(itemMultiTitle, menuLanguage)
  const itemLangDescription = getMultiLanguageTitle(
    itemMultiDescription,
    menuLanguage,
  )
  const dispatch = useDispatch()
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { clientCurrency } = useClientCurrency()
  const { programmedOrderDate = "", orderFromTable } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen
  const _title = itemLang?.title || item?.title
  const _description = itemLangDescription?.description || item?.description
  const [showComboDetail, setShowComboDetail] = useState(false)
  const typography = primaryColor
  const onCardPress = () => {
    if (item?.isCombo) {
      dispatch(
        getComboDetail(
          item?._id,
          "",
          programmedOrderDate,
          orderFromTable?._id,
          item?.categoryId,
        ),
      )
      setShowComboDetail(true)
    } else {
      const payload = {
        item,
        extraCategories: [],
      }
      dispatch(setItemSelected(payload))
      dispatch(
        getProductDetail(
          item?._id,
          "extraCategories",
          programmedOrderDate,
          orderFromTable?._id,
          "",
        ),
      )
      setModal(true)
    }
  }
  if (!item) return null
  return (
    <>
      <Card
        elevation={3}
        onClick={onCardPress}
        className="mb-12 mr-4"
        style={{
          borderRadius: 10,
          minWidth: "45%",
          maxWidth: "45%",
        }}
      >
        <div className="h-72 w-full relative">
          <img
            src={formatImageUrl(item?.image || "")}
            alt="Menu"
            className="w-full h-full object-cover"
          />
          {isMenuHide ? (
            <AddCircle
              iconFontSize={"small"}
              iconStyle={{ color: theme.main.primary }}
              containerClass="flex h-16 w-16 justify-center items-center rounded-full absolute top-0 right-0 mr-4 mt-4"
              onClick={onCardPress}
            />
          ) : null}
        </div>

        <div className="flex flex-col justify-center mt-4 p-6 h-4/12">
          <AppHeading6Medium style={{ color: theme.main.primary }}>
            {_title || ""}
          </AppHeading6Medium>
          {_description ? (
            <>
              <div style={{ margin: "0.188rem 0px" }} />
              <AppParagraph4
                style={{
                  color: theme.main.black,
                }}
                className={"lineClamp2"}
              >
                {_description || ""}
              </AppParagraph4>
            </>
          ) : null}
          <div style={{ margin: "0.188rem 0px" }} />
          <AppParagraph5 style={{ color: theme.main.primary }}>
            {item?.price || ""} {clientCurrency.symbol}
          </AppParagraph5>
        </div>
      </Card>
      {modal && (
        <ComplexItemModal
          typography={primaryColor}
          item={item}
          onClose={() => setModal(false)}
          isMobile={true}
        />
      )}
      {showComboDetail && (
        <ComboItemModal
          item={item}
          onClose={() => setShowComboDetail(false)}
          isMobile={true}
          typography={typography}
          show={showComboDetail}
        />
      )}
      {showDetail && (
        <SimpleItemModal
          typography={primaryColor}
          item={item}
          onClose={() => setShowDetail(false)}
          isMobile={true}
        />
      )}
    </>
  )
}
