import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
function useClientCurrency() {
  const user = useSelector(({ user }: RootState) => user.user)
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const [clientCurrency, setClientCurrency] = useState({
    symbol: "€",
    title: '"Euro"',
  })
  useEffect(() => {
    if (
      restaurant.currencyId &&
      restaurant.currencyId.symbol &&
      restaurant.currencyId.symbol !== clientCurrency.symbol
    ) {
      setClientCurrency(restaurant.currencyId)
    }
  }, [user])

  return { clientCurrency }
}

export default useClientCurrency
