import React, { FC } from "react"
import { useSelector } from "react-redux"
import { FormControlLabel, Radio, makeStyles, Theme } from "@material-ui/core"
import {
  arrangePrice,
  formatImageUrl,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import { useBrand } from "@hooks/useBrand"
import { AppHeading6Medium } from "@components/Text/AppText"
import { getMultiLanguageTitle } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"
interface Props {
  item: ComboProduct
}
interface StyleProps {
  typography?: string
}
const hideRadioStyles = makeStyles((theme) => ({
  hideRadio: {
    visibility: "hidden",
  },
}))
const ComboExtraCategoriesUni: FC<Props> = ({ item }) => {
  const { menuLanguage } = useLanguageHelper()
  const { clientCurrency } = useClientCurrency()
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "1rem 0",
    },
    rootForm: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: 0,
    },
    checked: (props) => ({
      color: `${props.typography} !important`,
    }),
    focused: {
      color: "black !important",
    },
  }))
  const _multiTitle = item?.multiTitle || []
  const itemLang = getMultiLanguageTitle(_multiTitle, menuLanguage)
  const _title = itemLang?.title || item.title
  const { primaryColor, secondaryColor } = useBrand()
  const typography = primaryColor
  const styleProps: StyleProps = { typography: secondaryColor }
  const classes = useStyles(styleProps)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const hideRadio = hideRadioStyles()
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false

  return (
    <>
      <div className="flex flex-row justify-center py-4 items-center">
        <FormControlLabel
          className="flex text-xs w-full"
          classes={{ root: classes.rootForm }}
          labelPlacement="start"
          control={
            <div
              className="flex items-center w-1/4 justify-end"
              style={{ minWidth: "20%" }}
            >
              <Radio
                value={item._id}
                size="medium"
                classes={{
                  root:
                    excludeOrderType.includes(venueDetail?.orderType || "") ||
                    menuDetail?.isVisible === false ||
                    isClosedKitchen
                      ? hideRadio.hideRadio
                      : "",
                  checked: classes.checked,
                }}
              />
            </div>
          }
          label={
            <div className={"flex items-center justify-center pl-8"}>
              {item.image ? (
                <img
                  src={formatImageUrl(item.image)}
                  style={{
                    maxHeight: "100%",
                    objectFit: "cover",
                    borderRadius: 4,
                    width: "2.5rem",
                  }}
                />
              ) : null}
              <AppHeading6Medium
                style={{
                  marginLeft: item.image ? "0.8rem" : 0,
                }}
              >
                {_title}
                {item?.comboSupplementPrice ? (
                  <span className="pl-4" style={{ color: "grey" }}>
                    {arrangePrice(item?.comboSupplementPrice).sign}{" "}
                    {arrangePrice(item?.comboSupplementPrice)._price}{" "}
                    {clientCurrency.symbol}
                  </span>
                ) : null}
              </AppHeading6Medium>
            </div>
          }
        />
      </div>
    </>
  )
}
export default ComboExtraCategoriesUni
