import React from "react"

import MenuItem from "@modules/menuitem/desktop/MenuItem"
import { Body14, Heading5, Label13 } from "@components/Text/Text"
import classes from "../tableCenter.module.css"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

interface Props {
  category: any
  handleMenuItemClick: (item: any) => void
  tabValue: number
  scrollToRef: (ref: any) => void
}

const DesktopCenter = ({
  category,
  handleMenuItemClick,
  tabValue,
  scrollToRef,
}: Props) => {
  const [expanded, setExpanded] = React.useState(null)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  let content
  if (category) {
    content = category.map((val, idx) =>
      val && val.menuList && val.menuList.length ? (
        <Accordion
          expanded={expanded === val.title}
          onChange={handleChange(val.title)}
        >
          <AccordionSummary
            style={{ backgroundColor: "#f9f9f9" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className={[classes.header_title, "mt-0"].join(" ")}
              ref={(ref) => {
                if (tabValue === idx) {
                  if (ref) {
                    scrollToRef(ref)
                  }
                }
              }}
            >
              <Heading5>{val.title}</Heading5>
              {val.description && val.title === expanded && (
                <Body14>{val.description}</Body14>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.item_list}>
              <ul className={"m-0"}>
                {val.menuList.map((item) => (
                  <li className={"mb-1"}>
                    <MenuItem
                      item={item}
                      title={item.title}
                      subtitle={item.description}
                      price={item.price}
                      image={item.image}
                      extraCategories={item.extraCategories}
                      onClick={() => handleMenuItemClick(item)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null,
    )
  } else {
    content = null
  }

  return content
}

export default DesktopCenter
