import React, { useState } from "react"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import CreditCardInput from "react-credit-card-input"
import { PaymentType, maskCardNumber } from "@helpers/utilsHelper"
import { PaymentMethodState } from "@models/Location"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { Box } from "@material-ui/core"
import { Body13 } from "@components/Text/Text"
import { CardSelectionModal } from "@components/modals/CardSelectionModal"
import { useSelector } from "react-redux"
import SquareForm from "./SquareForm"
interface Props {
  paymentMethod: PaymentMethodState
  socialButtons?: React.ReactNode
  autoServices?: boolean
  state: {
    cvc: string
    expiry: string
    name: string
    number: string
    spaces_number: string
    month: string
    year: string
  }

  handleCardChange: (event: any, key: string) => any
  availableMethods: (val: PaymentType) => any
  setShowSaveCard?: (val: boolean) => any
  showSaveCard?: boolean
  check?: boolean
  setCheck?: (val: boolean) => any
  setCard?: any
  setPayments?: any
}

export const PaymentSelection = ({
  state,
  paymentMethod,
  handleCardChange,
  availableMethods = (method = PaymentType.CARD) => {},
  socialButtons = null,
  autoServices,
  setShowSaveCard,
  showSaveCard,
  check,
  setCheck,
  setCard,
  setPayments,
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const userCards = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.userCards,
  )
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const data = userCards && userCards.length ? userCards : []
  const { selectedPayment } = paymentMethod
  const { primaryColor, isCashAvailable, thirdColor } = useBrand()
  const isCard = selectedPayment === PaymentType.CARD
  const isCash = selectedPayment === PaymentType.CASH
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isSetNewCard, setIsSetNewCard] = useState<boolean>(false)

  return (
    <>
      <div className="flex flex-row justify-center items-center px-6 w-full self-center">
        {socialButtons}
        {isCashAvailable && (
          <div
            style={{
              height: "15vh",
            }}
            className="border border-solid border-custom-grey w-full rounded-lg mx-1"
          >
            <button
              type="button"
              className="w-full h-full p-0 border-0 rounded-lg"
              onClick={() => availableMethods(PaymentType.CASH)}
            >
              {isCash ? (
                <div
                  style={{
                    backgroundColor: theme.main.newOrange,
                  }}
                  className="flex flex-col h-full rounded-lg justify-center items-center text-white"
                >
                  <img
                    src={require("@assets/selected_payment.png")}
                    className="h-1/3 max-h-10vh"
                  />
                  <h3>{languageStrings["PAY_IN_CASH"]}</h3>
                </div>
              ) : (
                <>
                  <div className="h-3/5 justify-center items-center flex">
                    <img
                      src={require("@assets/cash_payment_icon.png")}
                      className="h-3/4 max-h-10vh"
                    />
                  </div>
                  <div className="h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
                    <h3>{languageStrings["PAY_IN_CASH"]}</h3>
                  </div>
                </>
              )}
            </button>
          </div>
        )}
        <div
          style={{
            height: "15vh",
          }}
          className="border border-solid border-custom-grey w-full rounded-lg mx-1"
        >
          <button
            type="button"
            className="w-full h-full p-0 border-0 rounded-lg"
            onClick={() => {
              if (data && data.length) {
                setShowModal(true)
              }
              availableMethods(PaymentType.CARD)
            }}
          >
            {isCard ? (
              <div
                style={{
                  backgroundColor: theme.main.newOrange,
                }}
                className="flex flex-col h-full rounded-lg justify-center items-center text-white"
              >
                <img
                  src={require("@assets/selected_payment.png")}
                  className="h-1/3 max-h-10vh"
                />
                <h3>{languageStrings["CREDIT_CARD"]}</h3>
              </div>
            ) : (
              <>
                <div className="h-3/5 justify-center items-center flex">
                  <img
                    src={require("@assets/credit_card_icon.png")}
                    className="h-3/4 max-h-10vh"
                  />
                </div>
                <div className="h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
                  <h3>{languageStrings["CREDIT_CARD"]}</h3>
                </div>
              </>
            )}
          </button>
        </div>
      </div>
      {isCard ? (
        <div className="flex flex-col w-full my-8 px-8">
          {venueDetail?.squareAccessToken ? (
            <SquareForm setCard={setCard} setPayments={setPayments} />
          ) : (
            <CreditCardInput
              fieldStyle={{
                width: "100%",
                height: "60px",
                minHeight: "60px",
                border:
                  selectedPayment === PaymentType.CARD
                    ? `2px solid ${primaryColor}`
                    : `2px solid ${theme.main.black}`,
                borderRadius: "10px",
              }}
              cardNumberInputProps={{
                value: isSetNewCard
                  ? maskCardNumber(state.spaces_number)
                  : state.spaces_number,
                onChange: (event) => {
                  const value = event.target.value
                  handleCardChange(value, "number")
                },
                disabled: isSetNewCard,
              }}
              cardExpiryInputProps={{
                value: state.expiry,
                onChange: (event) => {
                  const value = event.target.value
                  handleCardChange(value, "expiry")
                },
              }}
              cardCVCInputProps={{
                value: state.cvc,
                onChange: (event) => {
                  const value = event.target.value

                  handleCardChange(value, "cvc")
                },
              }}
              customTextLabels={{
                invalidCardNumber: languageStrings["INVALID_CARD_NUMBER"],
                expiryError: {
                  invalidExpiryDate: languageStrings["INVALID_EXPIRY_DATE"],
                  monthOutOfRange: languageStrings["MONTH_RANGE"],
                  yearOutOfRange: languageStrings["YEAR_RANGE"],
                  dateOutOfRange: languageStrings["DATE_RANGE"],
                },
                invalidCvc: languageStrings["INVALID_CVV"],
                invalidZipCode: "El código postal es inválido",
                cardNumberPlaceholder: languageStrings["VIRTUAL_BASKET.NUMBER"],
                expiryPlaceholder: "MM/YY",
                cvcPlaceholder: languageStrings["VIRTUAL_BASKET.CVC"],
                zipPlaceholder: "C.P.",
              }}
              fieldClassName="input"
            />
          )}
          {((!isSetNewCard && showSaveCard) ||
            (autoServices && !data.length)) &&
          !venueDetail?.squareAccessToken ? (
            <Box
              className={
                "flex flex-row items-center mt-2 md:mt-4 w-5/6 md:w-4/5 md:mr-40"
              }
            >
              <input
                style={{ backgroundColor: primaryColor }}
                className={"w-12 h-12 border border-white border-solid mr-6"}
                type="checkbox"
                onChange={(e) => {
                  setCheck?.(!check)
                }}
              />
              <Body13>{languageStrings["SECURE_STORE_CARD"]}</Body13>
            </Box>
          ) : null}
        </div>
      ) : null}
      <CardSelectionModal
        onCardSelect={(value: any) => {
          if (value) {
            handleCardChange(value, "number")
            setCheck?.(true)
            setIsSetNewCard(true)
          }
          setShowModal(false)
        }}
        onNewCardPress={() => {
          if (setShowSaveCard) {
            setCheck?.(false)
            handleCardChange("", "number")
            setShowSaveCard(true)
            setIsSetNewCard(false)
          }
        }}
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false)
        }}
      />
    </>
  )
}
