import React, { useCallback, useEffect, useRef, useState } from "react"
import { Modal } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { addItemInCart, addItemWithError } from "@store/cart/cartActions"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import {
  formatImageUrl,
  checkClosedKitchenTime,
  excludeOrderType,
  getMultiLanguageTitle,
} from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import CrossImage from "@components/buttons/CrossImage"
import { OrderStates } from "./OrderStates"
import { ModalDetail } from "./ModalDetail"
import ComboCategory from "./ComboCategory"
import classes from "../modals.module.css"
import _ from "lodash"
import { useBrand } from "@hooks/useBrand"
import { BarLoader } from "react-spinners"

interface Props {
  item: MenuItem
  onClose: () => void
  isMobile?: boolean
  show?: boolean
  typography: string
}

const ComboItemModal = ({
  show = false,
  onClose,
  item,
  isMobile,
  typography,
}: Props) => {
  const [combo, setCombo] = useState<MenuItem>(item)
  const { primaryColor } = useBrand()

  const dispatch = useDispatch()
  let refs: any = []
  const [quantity, setQuantity] = useState(1)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { menuList: menuDetail = {}, detailLoading } = useSelector(
    ({ menu }: RootState) => menu,
  )
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const [comboCategories, setComboCategories] = useState<ComboCategory[]>([])
  const [isScrolled, setIsScrolled] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen

  const { languageStrings, menuLanguage } = useLanguageHelper()

  useEffect(() => {
    if (item?.comboCategories) {
      const clonedItem = _.cloneDeep(item)
      setCombo({ ...clonedItem })
      setComboCategories(clonedItem?.comboCategories ?? [])
    }
  }, [item])
  const {
    title,
    description,
    multiTitle,
    multiDescription,
    image,
    allergenes,
  } = combo
  const itemLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const itemLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = itemLang?.title || title
  const _description = itemLangDescription?.description || description
  const onCloseModal = () => {
    setComboCategories([])
    onClose()
  }

  const onSubmit = () => {
    let hasError = false
    const selectedComboCategories = comboCategories
      ?.map((category: ComboCategory) => {
        const hasCategoryError =
          category.products.filter((p) => p.selected)?.length === 0
            ? true
            : false
        if (hasCategoryError) {
          hasError = true
        }
        const updatedProducts = category.products?.map((product) => ({
          ...product,
          extraCategories: product.selected
            ? product.extraCategories?.map((extraCategory) => {
                const selectedCount =
                  extraCategory.items?.filter((item) => item.selected)
                    ?.length || 0
                if (extraCategory.type === "uniSelect" && selectedCount === 0) {
                  extraCategory.hasError = true
                  hasError = true
                } else if (
                  extraCategory.type !== "uniSelect" &&
                  extraCategory.hasMaxMin
                ) {
                  if (
                    (extraCategory.minQuantity !== undefined &&
                      selectedCount < extraCategory.minQuantity) ||
                    (extraCategory.maxQuantity !== undefined &&
                      selectedCount > extraCategory.maxQuantity)
                  ) {
                    extraCategory.hasError = true
                    hasError = true
                  }
                }

                return {
                  ...extraCategory,
                }
              })
            : product.extraCategories,
        }))
        return hasCategoryError
          ? { ...category, hasError: true, products: updatedProducts }
          : {
              ...category,
              products: updatedProducts,
            }
      })
      .filter((category) => category !== null)
    if (hasError) {
      setCombo({ ...item, comboCategories: selectedComboCategories })
      setComboCategories(selectedComboCategories)
      dispatch(addItemWithError())
    } else {
      const selectedProductsCategories = selectedComboCategories.map(
        (category) => ({
          ...category,
          products: category.products?.filter((product) => product.selected),
        }),
      )
      const payload = {
        item: {
          ...item,
          comboCategories: selectedProductsCategories,
          quantity,
        },
      }
      dispatch(addItemInCart(payload))
      onCloseModal()
    }
  }
  const executeScroll = (r?: any) => {
    if (r && r.offsetTop) {
      modalRef?.current?.scrollTo({ top: r.offsetTop, behavior: "smooth" })
    } else {
      modalRef?.current?.scrollTo(0, modalRef.current.offsetTop)
    }
  }
  const handleRefChange = (r, title) => {
    refs.push(r)
    executeScroll(refs[0])
  }
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }
  const onUpdateComboCategory = (updatedCategory: ComboCategory) => {
    if (combo) {
      const categoriesToUpdate = [...comboCategories]
      categoriesToUpdate?.forEach((category, index) => {
        if (category._id === updatedCategory._id) {
          categoriesToUpdate[index] = { ...updatedCategory, hasError: false }
        }
      })
      setComboCategories(categoriesToUpdate)
      setCombo({ ...combo, comboCategories: categoriesToUpdate })
    }
  }
  const onUpdateComboCategoryProduct = (
    updatedExtraCategory: ExtraCategory,
    productId: string,
    categoryId: string,
  ) => {
    if (!combo) return

    const updatedComboCategories = comboCategories.map((category) => {
      if (categoryId !== category._id) return category

      const updatedProducts = category.products?.map((product) => {
        if (productId !== product._id) return product

        const updatedExtraCategories = product.extraCategories?.map(
          (extraCategory) =>
            extraCategory._id === updatedExtraCategory._id
              ? updatedExtraCategory
              : extraCategory,
        )

        return {
          ...product,
          extraCategories: updatedExtraCategories,
        }
      })
      const amountSelected = updatedProducts.filter(
        (item) => item.selected,
      ).length
      return {
        ...category,
        products: updatedProducts,
        hasError: amountSelected === 0,
      }
    })

    setComboCategories(updatedComboCategories)
    setCombo({ ...combo, comboCategories: updatedComboCategories })
  }
  const handleScroll = useCallback((element: HTMLDivElement) => {
    if (modalRef?.current && modalRef?.current?.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [])
  return (
    <Modal
      className="flex justify-between items-center"
      open={show}
      onClose={onCloseModal}
    >
      <div
        style={{ width: isMobile ? "90%" : "20rem" }}
        className={[classes.category_right, "outline-none relative"].join(" ")}
        ref={parentRef}
      >
        <div
          style={{
            position: "relative",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            maxHeight:
              restaurant.accessLevel && restaurant.accessLevel !== "1"
                ? "75%"
                : "100%",
          }}
          ref={modalRef}
          className={classes.modal_right}
          onScroll={(e) => handleScroll(e.currentTarget)}
        >
          <div
            className={`h-20 top-[6px] right-[5px] px-4 fixed flex items-center justify-end z-10 rounded-2 bg-${
              isScrolled ? "white" : "transparent"
            }`}
            style={{ width: isMobile ? "90%" : "20rem" }}
          >
            <CrossImage margin onClick={onCloseModal} />
          </div>

          <div className="relative">
            {image && (
              <div className={"flex w-full"} style={{ minHeight: "30%" }}>
                <img
                  src={formatImageUrl(image)}
                  className="w-full object-cover"
                  style={{ minHeight: "12.5rem", maxHeight: "12.5rem" }}
                />
              </div>
            )}
          </div>

          <ModalDetail
            title={_title}
            description={_description}
            image={image}
            allergenes={allergenes}
          />
          <BarLoader
            color={primaryColor}
            loading={detailLoading}
            width={"100%"}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          {comboCategories &&
            comboCategories.map((category: ComboCategory, index: number) => {
              return (
                <ComboCategory
                  key={index.toString()}
                  item={category}
                  typography={typography}
                  onUpdateComboCategory={onUpdateComboCategory}
                  onUpdateComboCategoryProduct={(
                    ec: ExtraCategory,
                    pId: string,
                  ) => onUpdateComboCategoryProduct(ec, pId, category._id)}
                  onRefChange={handleRefChange}
                />
              )
            })}
        </div>
        {!excludeOrderType.includes(venueDetail?.orderType || "") &&
        isMenuHide &&
        combo ? (
          <div className={`w-full sticky bottom-0`}>
            <OrderStates
              onAdd={() => setQuantity(quantity + 1)}
              onRemove={decreaseQuantity}
              onSubmit={onSubmit}
              price={(quantity * totalPriceCustomItem(combo)).toFixed(2)}
              quantity={quantity}
              disabled={detailLoading}
              btnTitle={languageStrings["MENU_ITEM.ADD_TO_ORDER"]}
            />
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default ComboItemModal
