import { LocationState } from "@models/Location"
import React from "react"
import { useLocation } from "react-router-dom"
import MobileOrderConfirmedAutoService from "./components/MobileOrderConfirmeAutoService"
import BackButton from "@components/backButton/BackButton"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"

const NotificationType = (props) => {
  const { orderId = "" } = props.match.params
  const { languageStrings } = useLanguageHelper()
  const location = useLocation<LocationState>()
  const data = location.state

  const state = data
    ? data
    : {
        payload: undefined,
        totalPrice: 0,
        tableId: "",
        venueId: "",
      }

  return (
    <BackButton isMobile={true} backTitle={""}>
      <div className="flex flex-col">
        <ScreenHeading
          heading={languageStrings["HOW_DO_YOU_WANT_TO_BE_NOTIFIED"]}
          style={styles.heading}
          subHeading={languageStrings["GENERAL_VENUE_MESSAGE"]}
        />
        <MobileOrderConfirmedAutoService orderId={orderId} orderState={state} />
      </div>
    </BackButton>
  )
}
export default NotificationType
const styles = {
  heading: {
    marginLeft: "0.6rem",
  },
}
