import React, { useEffect, useMemo, useState, useRef, useCallback } from "react"
import throttle from "lodash/throttle"
import { withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { PopularCard } from "@modules/venueDetails/components/PopularCard"
import { theme as appTheme, theme } from "config/theme"
import { AppHeading4Bold } from "@components/Text/AppText"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { SegmentedControl } from "@components/segmentedControll/SegmentedControl"
import FoodType from "@assets/food_type.png"
import DrinkType from "@assets/drink_type.png"
interface StyleTabsProps {
  value?: string
}
interface StyleTabProps {
  label?: string
  onClick?: any
  value?: any
  style?: React.CSSProperties
}

const tabHeight = 39
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
})((props: StyleTabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    {...props}
    // TabIndicatorProps={{ children: <div /> }}
  />
))

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: appTheme.main.primary,
    height: tabHeight,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props: StyleTabProps) => <Tab disableRipple {...props} />)

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true
    return uniqueHash
  }

  return makeUnique(hash, unique, i + 1)
}

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, ""),
    ),
    unique,
  )
}
const noop = () => {}

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  )

  useEffect(() => {
    if (throttledCallback === noop) return undefined

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

const ScrollSpyTabs = (props) => {
  const { languageStrings } = useLanguageHelper()

  const [activeState, setActiveState] = useState(null)
  const {
    tabsInScroll,
    typography,
    popularItems = [],
    onPopularAdd = () => {},
    onPopularCardClick = () => {},
    setSelectedValue = () => {},
    showFoodDivision = false,
  } = props || {}

  const [menuCategoryIdState, setMenuCategoryIdState] = useState(
    tabsInScroll[0].cateroryId,
  )
  const [y, setY] = useState(window.scrollY)

  const [scrollPosition, setScrollPosition] = useState("")

  let itemsServer = tabsInScroll.map((tab) => {
    const hash = textToHash(tab.text)
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash),
      cateroryId: tab.cateroryId,
      heading: tab.heading,
      description: tab.description,
    }
  })

  const itemsClientRef = useRef([])
  useEffect(() => {
    itemsClientRef.current = itemsServer
  }, [itemsServer])

  const clickedRef = useRef(false)
  const unsetClickedRef = useRef<any>(null)
  const findActiveIndex = useCallback(() => {
    // set default if activeState is null

    if (menuCategoryIdState === null)
      setMenuCategoryIdState(itemsServer[0].cateroryId)
    if (activeState === null) setActiveState(itemsServer[0].hash)

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return

    let active
    let scrollFrom =
      scrollPosition == "down"
        ? document.documentElement.clientHeight / 1.5 + tabHeight + 20
        : document.documentElement.clientHeight / 5 + tabHeight + 20

    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null }
        break
      }

      const item: any = itemsClientRef.current[i]

      if (
        item.node &&
        item.node.offsetTop < document.documentElement.scrollTop + scrollFrom
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
      setMenuCategoryIdState(active.cateroryId)
    }
  }, [activeState, itemsServer])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166)

  const handleClick = (hash, cateroryId) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)
    let obj = document.getElementById(hash)
    if (activeState !== hash && obj) {
      setActiveState(hash)
      setMenuCategoryIdState(cateroryId)
      if (window)
        window.scrollTo({
          top: obj.getBoundingClientRect().top + window.pageYOffset - 50,
          behavior: "smooth",
        })
    }
  }
  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    [],
  )

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget

      if (y > window.scrollY) {
        setScrollPosition("scrolling up")
      } else if (y < window.scrollY) {
        setScrollPosition("down")
      }
      setY(window.scrollY)
    },
    [window.scrollY],
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])
  const _val = activeState ? activeState : itemsServer[0].hash
  const controlRef = useRef()
  const foodTypeRef = useRef()
  const drinkTypeRef = useRef()
  return (
    <>
      <div
        className="sticky z-10 overflow-hidden bg-white"
        style={{ top: "4rem" }}
      >
        {showFoodDivision ? (
          <SegmentedControl
            name="group-1"
            callback={(val) => {
              setMenuCategoryIdState(tabsInScroll?.[0]?.cateroryId || "")
              setY(window.scrollY)
              setScrollPosition("")
              setActiveState(null)
              setSelectedValue?.(val)
            }}
            controlRef={controlRef}
            segments={[
              {
                label: languageStrings["GENERAL_FOOD"],
                value: "1",
                src: FoodType,
                ref: foodTypeRef,
              },
              {
                label: languageStrings["GENERAL_DRINK"],
                value: "2",
                src: DrinkType,
                ref: drinkTypeRef,
              },
            ]}
          />
        ) : null}

        <div
          style={{
            borderBottom: "1px solid #E3E3E3",
          }}
        >
          <nav className={"ml-8"} style={{ top: "4rem" }}>
            <StyledTabs value={_val}>
              {itemsServer.map((item2, index) => {
                return (
                  <StyledTab
                    key={item2.hash}
                    label={item2.text}
                    onClick={() => handleClick(item2.hash, item2.cateroryId)()}
                    value={item2.hash}
                    style={{
                      minHeight: 30,
                      height: 30,
                      borderRadius: 50,
                      backgroundColor:
                        _val === item2.hash ? typography : "transparent",
                      color: appTheme.main.primary,
                      marginTop: 10,
                      transition: "all .5s ease",
                      WebkitTransition: "all .5s ease",
                      MozTransition: "all .5s ease",
                      fontFamily: "ProductSansRegular",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  />
                )
              })}
            </StyledTabs>
          </nav>
        </div>
      </div>

      {popularItems?.length ? (
        <div
          style={{
            backgroundColor: "#f6f6f6",
          }}
        >
          <AppHeading4Bold
            style={{
              color: theme.status.danger2,
              paddingLeft: "0.8rem",
              paddingTop: "0.8rem",
              marginBottom: "0.6rem",
            }}
          >
            {languageStrings["GENERAL_POPULAR"]}
          </AppHeading4Bold>
          <div className="flex no-scrollbar px-8 overflow-auto w-full">
            {popularItems?.map((item) => (
              <PopularCard
                onAddClick={() => onPopularAdd(item)}
                onClick={onPopularCardClick}
                item={item}
              />
            ))}
          </div>
        </div>
      ) : null}

      <div className="container">
        {itemsServer.map((item1) => (
          <article id={item1.hash} key={item1.text}>
            {item1.component}
          </article>
        ))}
      </div>
    </>
  )
}

export default ScrollSpyTabs
