// ? Please note below format reference taken from moment library

export const DATE_TIME_FORMATS = {
  DEFAULT: "DD/MM/YYYY HH:mm",
  DATE: {
    DEFAULT: "DD/MM/YYYY",
  },
  TIME: {
    DEFAULT: "HH:mm",
    SECONDS: "HH:mm:ss",
    MS: "HH:mm:ss.SSS",
  },
  DAY: {
    DEFAULT: "dddd",
  },
}
