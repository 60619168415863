import React from "react"
import { Modal, Card, Grid } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { Menu } from "@models/Menu"
import { MenuModalCard } from "@modules/homePage/components/MenuModalCard"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
interface Props {
  title: string
  menus: Menu[]
  open: boolean
  close?: boolean
  onClose: () => void
  onButtonClick: (value?: string) => void
}

const MenuModal = ({
  menus,
  title,
  open,
  close = true,
  onButtonClick,
  onClose,
}: Props) => {
  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Card
          className="flex flex-col shadow-none z-10 self-center outline-none md:w-2/5 w-11/12 p-8 md:px-18"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="flex flex-row items-center w-full">
            {close && (
              <ArrowBackIosIcon
                style={{
                  color: "black",
                  fontSize: 32,
                  left: "1",
                  alignSelf: "left",
                }}
                onClick={onClose}
              />
            )}
            <ScreenHeading
              containerStyle={{ marginTop: "1.2rem" }}
              heading={title}
            />
          </div>

          <Grid container spacing={2} className="overflow-scroll ">
            {menus.map((menu) => (
              <MenuModalCard
                item={menu}
                onClick={() => {
                  if (menu && menu.menuUrl) {
                    window.open(menu.menuUrl)
                    return
                  }
                  onButtonClick(menu._id)
                }}
              />
            ))}
          </Grid>
        </Card>
      </Modal>
    </div>
  )
}
export default MenuModal
