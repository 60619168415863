import React from "react"
import {
  Box,
  createStyles,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core"
import { Heading2, Body15Bold, Heading4 } from "@components/Text/Text"
import LockIcon from "@material-ui/icons/Lock"
import { convertCommaToDecimal } from "@helpers/utilsHelper"
import { LocationState, PaymentMethodState, TipState } from "@models/Location"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { theme } from "config/theme"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { useBrand } from "@hooks/useBrand"
import { PriceButtonV3 } from "@components/buttons/PriceButton"
import { TextInput } from "@components/textInput/TextInput"
import {
  AppHeading4,
  AppHeading4Medium,
  AppHeading6,
} from "@components/Text/AppText"
import useClientCurrency from "@hooks/useClientCurrency"
interface Props {
  paymentMethod: PaymentMethodState
  showModal: any
  onCloseModal: () => any
  onSelected?: () => void
  onTipSelect?: (e: TipState) => void
  typography?: string
  onCheckTipProcess?: () => void
  tipDetail?: TipState
  title?: string
  isPaid?: string
  routeState: LocationState
  children: any
}
const useColorlibStepIconStyles = makeStyles(() =>
  createStyles({
    paper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  }),
)

export const TipModal: React.FC<Props> = ({
  onCloseModal,
  showModal,
  onTipSelect = () => {},
  onCheckTipProcess,
  tipDetail,
  paymentMethod,
  routeState,
  children,
}) => {
  const { primaryColor, secondaryColor, thirdColor } = useBrand()
  const typography = primaryColor
  const { languageStrings } = useLanguageHelper()
  const { amount = 0 } = routeState || {}
  const classes = useColorlibStepIconStyles()
  const isRemoveDisabled = tipDetail?.type === 0
  const { clientCurrency } = useClientCurrency()
  const canBeSubmited = () => {
    let _tipAMount = 0
    if (tipDetail && tipDetail.amount) _tipAMount = tipDetail.amount
    return _tipAMount >= 0
  }
  const tip = [
    {
      percantage: 5,
      title: "5",
      amount: (Number(amount) * 0.05).toFixed(2),
      type: 1,
    },
    {
      percantage: 10,
      title: "10",
      amount: (Number(amount) * 0.1).toFixed(2),
      type: 1,
    },
    {
      percantage: 20,
      title: "20",
      amount: (Number(amount) * 0.2).toFixed(2),
      type: 1,
    },
    {
      percantage: 0,
      title: languageStrings["OTHER"],
      amount: 0,
      type: 2,
    },
  ]
  const calculateTotal = () => {
    return (
      Number(amount) +
      Number(
        tipDetail && tipDetail?.amount && tipDetail?.amount > 0
          ? tipDetail?.amount
          : 0,
      )
    )
  }

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={showModal}
      onClose={onCloseModal}
      onOpen={() => ""}
      classes={{ paper: classes.paper }}
    >
      <div className="flex flex-col">
        <Heading2
          style={{
            fontFamily: "ProductSansRegular",
            fontWeight: 400,
          }}
          className="text-center p-2 pt-5 mt-1"
        >
          {languageStrings["TIP_TITLE"]}
        </Heading2>

        <Box className="flex flex-row justify-evenly items-center mt-12">
          <>
            {tip.map((item) => {
              const _selectedTip = tipDetail && tipDetail.title === item.title
              const isOther = item.type === 2
              return (
                <Box
                  onClick={() => {
                    onTipSelect({
                      amount: Number(item.amount),
                      percantage: item.percantage,
                      type: item.type,
                      title: item.title,
                    })
                  }}
                  border={0}
                  borderColor={"transparent"}
                  borderRadius={8}
                  style={{
                    backgroundColor: _selectedTip
                      ? theme.secondary.lightOrange
                      : theme.secondary.secondary,
                  }}
                  className="flex flex-col cursor-pointer min-h-44 max-h-44 w-44"
                >
                  <div
                    className="flex flex-col cursor-pointer min-h-44 max-h-44 w-44"
                    style={{
                      alignItems: isOther ? "center" : "flex-start",
                      justifyContent: "center",
                      marginLeft: isOther ? undefined : "0.6rem",
                    }}
                  >
                    {isOther ? (
                      <AppHeading4Medium style={styles.chipTitle}>
                        {item.title}
                      </AppHeading4Medium>
                    ) : (
                      <AppHeading6
                        style={{
                          color: theme.main.black,
                        }}
                      >
                        {item.title}
                        {isOther ? "" : "%"}
                      </AppHeading6>
                    )}
                    <AppHeading4Medium style={styles.chipTitle}>
                      {isOther ? "" : `${item.amount} ${clientCurrency.symbol}`}
                    </AppHeading4Medium>
                  </div>
                </Box>
              )
            })}
          </>
        </Box>
        {tipDetail && tipDetail.type === 2 ? (
          <div className="flex flex-col w-full mt-20 mb-20 self-center">
            <div
              className="flex flex-col justify-evenly py-6"
              style={{
                backgroundColor: theme.main.dark5,
              }}
            >
              <Heading4
                style={{
                  marginLeft: "0.6rem",
                  color: theme.status.danger2,
                  fontFamily: "ProductSansRegular",
                  fontWeight: 700,
                }}
              >
                {languageStrings["ADD_TIP"]}
              </Heading4>
            </div>
            <div className="flex flex-col w-full px-6 self-center mt-12">
              <TextInput
                label={languageStrings["ENTER_AMOUNT"]}
                inputType="number"
                value={
                  tipDetail && tipDetail.amount ? `${tipDetail.amount}` : ""
                }
                onValueChange={(e) => {
                  const newValue = convertCommaToDecimal(e.target.value)
                  const _amount = newValue < 0 ? 0 : Number(newValue)
                  onTipSelect({
                    amount: _amount,
                    percantage: 0,
                    type: 2,
                    title:
                      tipDetail && tipDetail.title ? tipDetail.title : "5%",
                  })
                }}
              />
            </div>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center py-12">
            <ButtonIcon
              disabled={isRemoveDisabled}
              title={languageStrings["TIP_REMOVE"]}
              style={{
                display: "flex",
                borderRadius: "25px",
                backgroundColor: isRemoveDisabled
                  ? theme.main.dark5
                  : "transparent",
                border: `1px solid ${
                  isRemoveDisabled ? "transparent" : theme.main.newOrange
                }`,
                width: "50%",
                height: "2.4rem",
                marginBottom: "0",
                justifyContent: "center",
              }}
              textStyle={{
                fontSize: "14px",
                color: isRemoveDisabled
                  ? theme.main.darkBlack
                  : theme.main.newOrange,
              }}
              onClick={() => {
                onTipSelect({
                  type: 0,
                  amount: 0,
                  percantage: 0,
                  title: "",
                })
              }}
            />
          </div>
        )}
        <div
          className="flex flex-col mt-6 w-full"
          style={{ backgroundColor: theme.secondary.light }}
        >
          <Box className="flex justify-center px-5 py-5">
            {children ? (
              children
            ) : (
              <div className="w-full">
                <PriceButtonV3
                  backgroundColor={
                    !canBeSubmited() ? theme.main.grey : theme.main.newOrange
                  }
                  onClick={onCheckTipProcess}
                >
                  <div className="flex flex-row justify-center items-center h-full w-full">
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        fontFamily: "ProductSansRegular",
                        textTransform: "capitalize",
                        color: theme.main.white,
                      }}
                    >
                      {`${languageStrings["GENERAL_PAGAR"]} `}
                    </span>
                    <div className="w-4" />
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        fontFamily: "ProductSansRegular",
                        color: !canBeSubmited()
                          ? theme.main.black
                          : theme.main.white,
                      }}
                    >
                      {calculateTotal().toFixed(2)} {clientCurrency.symbol}
                    </span>
                  </div>
                </PriceButtonV3>
              </div>
            )}
          </Box>

          <Box className="flex justify-center items-center pb-12">
            <LockIcon className="text-grey-dark text-center mr-6" />
            <AppHeading4
              style={{
                color: theme.main.blackLight,
              }}
            >
              {languageStrings["SECURE_PAYMENTS"]}
            </AppHeading4>
          </Box>
        </div>
      </div>
    </SwipeableDrawer>
  )
}
const styles = {
  chipTitle: {
    color: theme.main.primary,
  },
}
