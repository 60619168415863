import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"

import { cartCounter } from "@store/cart/cartHelpers"
import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import { Typography } from "@material-ui/core"
import { CartType } from "@models/Cart"

import { Label19Bold, Heading3, Heading5 } from "@components/Text/Text"
import { formatImageUrl, getTaxes } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"

interface Props {
  items: any
  discountVoucherId?: string
  discountVoucherAmount?: string
  typography?: string
}

const ItemsCard = ({
  items,
  discountVoucherId,
  discountVoucherAmount,
  typography,
}: Props) => {
  const useStyles = makeStyles(() => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
    }),
  }))

  const Classes = useStyles()
  const { clientCurrency } = useClientCurrency()
  const taxDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { taxId } = taxDetail || {}
  const { taxType, taxPercentage, totalTax } = getTaxes(taxId)
  const { totalPrice } = cartCounter(items, totalTax)

  const cart = useSelector(({ cart }: RootState) => cart)
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)

  const [deliveryPrice, setDeliveryPrice] = useState(0)
  const total = parseFloat(totalPrice)
  const { languageStrings, changeLanguage } = useLanguageHelper()

  useEffect(() => {
    if (
      selectedOption === CartType.DELIVERY &&
      venueDetail.orderInfo &&
      venueDetail.orderInfo.minDeliveryPrice
    ) {
      setDeliveryPrice(Number(venueDetail.orderInfo.minDeliveryPrice))
    }
  }, [venueDetail])

  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center justify-start md:w-full w-11/12 mt-16 bg-white shadow-2xl rounded-4",
        Classes.root,
      ].join()}
    >
      <Heading3 style={{ marginTop: 8, marginBottom: 8, marginLeft: 14 }}>
        {languageStrings["PROFILE.MY_ORDER"]}
      </Heading3>
      <>
        <>
          {items.map((item) => (
            <>
              <div className={"flex flex-row w-full p-8"}>
                {item.image && (
                  <img
                    className="object-cover rounded"
                    src={formatImageUrl(item.image)}
                    alt={item.title}
                    style={{
                      width: "60px",
                      height: "60px",
                    }}
                  />
                )}
                <Label19Bold
                  style={{
                    color: typography,
                    marginLeft: 5,
                  }}
                >
                  {item.quantity}
                </Label19Bold>
                <div className={"flex flex-row w-full justify-between"}>
                  <div className={"flex flex-col ml-2"}>
                    <Label19Bold>{item.title}</Label19Bold>
                    {item.extraCategories &&
                      item.extraCategories.length > 0 &&
                      item.extraCategories.map(({ title, items }) => {
                        if (hasFamilyPropertyItemSelected(items)) {
                          return (
                            <>
                              <Typography variant="subtitle2">
                                {title}
                              </Typography>
                              {items.map(({ title, selected }) => {
                                if (selected) {
                                  return (
                                    <Typography
                                      style={{ color: "#c3c3c3" }}
                                      variant="body2"
                                    >
                                      {title}
                                    </Typography>
                                  )
                                }
                                return null
                              })}
                            </>
                          )
                        }
                        return null
                      })}
                  </div>
                  <div className={"flex flex-col items-center"}>
                    <Label19Bold
                      style={{
                        color: typography,
                      }}
                    >
                      {clientCurrency.symbol} {item.price.toFixed(2)}
                    </Label19Bold>
                  </div>
                </div>
              </div>
              <div
                className={"flex self-center h-px bg-grey mt-2 mb-4"}
                style={{ width: "96%" }}
              />
            </>
          ))}
        </>
        <div
          className={"flex flex-col items-end mr-8"}
          style={{ marginTop: -18 }}
        >
          {taxPercentage !== 0 ? (
            <div className={"flex flex-row justify-between w-7/12"}>
              <Heading3
                style={{ textAlign: "right" }}
              >{`${taxType} - (${taxPercentage}%)`}</Heading3>
              <Heading3>
                {(total * totalTax).toFixed(2) + `${clientCurrency.symbol}`}
              </Heading3>
            </div>
          ) : null}
          <div
            className={"flex flex-row justify-between w-7/12"}
            // style={{ marginTop: -22 }}
          >
            <Heading3
              style={{
                textAlign: "left",
              }}
            >
              {languageStrings["GENERAL.TOTAL"]}
            </Heading3>
            <Heading3 className={"text-black"}>
              {deliveryPrice > 0 && selectedOption && selectedOption == "2"
                ? (total + deliveryPrice).toFixed(2) +
                  `${clientCurrency.symbol}`
                : total.toFixed(2) + `${clientCurrency.symbol}`}
            </Heading3>
          </div>
          {discountVoucherId && (
            <>
              <div className={"flex flex-row justify-between w-7/12 mt-4"}>
                <Heading5 className="text-red">
                  {languageStrings["PROFILE.DISCOUNT"]}
                </Heading5>
                <Heading5 className="pull-left text-red">
                  -{discountVoucherAmount}
                  {clientCurrency.symbol}
                </Heading5>
              </div>
              <div className={"flex flex-row justify-between w-7/12 mt-12"}>
                <Heading3 className={"text-black"}>
                  {languageStrings["PROFILE.GRAND_TOTAL"]}
                </Heading3>
                <Heading3 className={"text-black"}>
                  {deliveryPrice > 0 && selectedOption && selectedOption == "2"
                    ? (
                        total +
                        deliveryPrice -
                        Number(discountVoucherAmount)
                      ).toFixed(2) + `${clientCurrency.symbol}`
                    : (total - Number(discountVoucherAmount)).toFixed(2) +
                      `${clientCurrency.symbol}`}
                </Heading3>
              </div>
            </>
          )}
        </div>
      </>
    </Card>
  )
}

export default ItemsCard
