import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"

import ComboExtraCategoriesUni from "../mobile/components/ComboExtraCategoryUni"
import ExtraCategoriesUni from "../mobile/components/ExtraCategoriesUni"
import ExtraCategoriesMulti from "../mobile/components/ExtraCategoriesMulti"
interface Props {
  product: ComboProduct
  typography: string
  selectedItem: string | null
  onUpdateComboCategoryProduct: (c: ExtraCategory) => void
  onRefChange?: any
}

const ComboProduct = ({
  product,
  typography,
  selectedItem,
  onUpdateComboCategoryProduct,
  onRefChange,
}: Props) => {
  const useStyles = makeStyles<Theme>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "1rem 0",
      width: "100%",
    },
    item: {
      textAlign: "left",
      alignItems: "center",
      width: "100%",
    },
  }))
  const classes = useStyles()
  const extraCategories = product?.extraCategories ?? []

  return (
    <div>
      <ComboExtraCategoriesUni item={product} />
      {product._id === selectedItem && (
        <div className={clsx("pl-24")}>
          {extraCategories.map((extraCategory: ExtraCategory) => {
            if (extraCategory.type === "uniSelect" && !extraCategory.multiMax) {
              return (
                <div
                  key={extraCategory._id}
                  className={classes.extracategory_uni}
                >
                  <ExtraCategoriesUni
                    typography={typography}
                    item={extraCategory}
                    onUpdateComboCategoryProduct={onUpdateComboCategoryProduct}
                    onRefChange={onRefChange}
                  />
                </div>
              )
            } else {
              return (
                <div
                  key={extraCategory._id}
                  className={classes.extracategory_multi}
                >
                  <ExtraCategoriesMulti
                    typography={typography}
                    item={extraCategory}
                    onUpdateComboCategoryProduct={onUpdateComboCategoryProduct}
                    onRefChange={onRefChange}
                  />
                </div>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

export default ComboProduct
