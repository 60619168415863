import { CartActionTypes } from "@store/redux/actionTypes"
import { Cart } from "@models/Cart"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import isEqual from "lodash/isEqual"
import findIndex from "lodash/findIndex"
import { findComboIndex } from "@helpers/utilsHelper"
import _ from "lodash"

const emptyItemCart: MenuItem = {
  _id: "",
  menuId: "",
  allergenes: "",
  title: "",
  description: "",
  categoryId: "",
  price: 0,
  position: 0,
  image: "",
  quantity: 0,
  extraCategories: [],
  comboCategories: [],
}

const INITIAL_STATE: CartState = {
  orderMenu: {
    categories: [],
  },
  newItem: emptyItemCart,
  cart: {
    items: [],
  },
  selectedCard: "",
  loading: false,
  address: {
    _id: "",
  },
  secret: "",
  paymentId: "",
  discountCode: "",
  discountType: "",
  discountAmount: 0,
  discountQuantity: 0,
  discountApplicable: false,
  isVoucherMatchWithOrderType: true,
  pendingPaymentOrder: {},
  showStockModal: false,
  stockErrorItems: [],
}

interface Action {
  payload: any
  type: string
}

const VenueReducer = (
  state: CartState = INITIAL_STATE,
  action: Action,
): CartState => {
  switch (action.type) {
    case CartActionTypes.SET_CART_EMPTY:
      return {
        ...INITIAL_STATE,
      }
    case CartActionTypes.SET_SELECTED_ADDRESS:
      return {
        ...state,
        address: action.payload,
      }
    case CartActionTypes.SET_ITEM_SELECTED:
      return {
        ...state,
        newItem: { ...action.payload.item, quantity: 1 },
      }

    case CartActionTypes.SELECT_UNI_SELECT: {
      const selectedItem = action.payload.extraCategory.items.find(
        (item) => item.selected,
      )

      const extraCategories = state.newItem.extraCategories?.map((item) => {
        if (item._id === action.payload.extraCategory._id) {
          return action.payload.extraCategory
        }

        if (
          item.connectedCategory === action.payload.extraCategory._id &&
          selectedItem
        ) {
          const itemCategory = state.orderMenu?.categories?.find(
            (cat) => cat._id === state.newItem.categoryId,
          )
          const menuItem =
            itemCategory &&
            itemCategory.menuList.find((menu) => menu._id === state.newItem._id)
          const extraCategories =
            menuItem &&
            menuItem.extraCategories &&
            menuItem.extraCategories.find(
              (category) => category._id === item._id,
            )

          const newItem = {
            ...item,
            items:
              extraCategories &&
              extraCategories.items.filter(
                (singleItem) =>
                  singleItem.connectedCategoryItem &&
                  singleItem.connectedCategoryItem === selectedItem._id,
              ),
          }

          return newItem
        }
        return item
      })
      return {
        ...state,
        newItem: {
          ...state.newItem,
          extraCategories,
        },
      }
    }

    case CartActionTypes.SET_CART_PENDING_PAYMENT: {
      return {
        ...state,
        pendingPaymentOrder: action.payload,
      }
    }

    case CartActionTypes.GET_ORDER_MENU_LIST_SUCCESS: {
      return {
        ...state,
        orderMenu: action.payload.data,
      }
    }
    case CartActionTypes.ADD_ITEM_CART_START: {
      const newCart: Cart = {
        items: [],
      }
      let exist = false

      if (state.cart.items.length > 0) {
        if (action.payload.item?.isCombo) {
          newCart.items = state.cart.items.map((item, index) => {
            const comboIndex = findComboIndex(
              state.cart.items,
              action.payload.item,
            )
            if (comboIndex !== -1 && comboIndex === index) {
              const comboItem = state.cart.items[comboIndex]
              const newQuantity = action.payload.item.quantity
                ? action.payload.item.quantity
                : 1
              exist = true
              return {
                ...comboItem,
                quantity: comboItem.quantity + newQuantity,
              }
            }
            return item
          })
        } else {
          newCart.items = state.cart.items.map((item) => {
            if (item._id === action.payload.item._id) {
              exist = true
              return {
                ...action.payload.item,
                quantity: action.payload.item.quantity
                  ? action.payload.item.quantity + item.quantity
                  : item.quantity + 1,
              }
            }
            return item
          })
        }
      }

      if (!exist) {
        newCart.items = [
          ...newCart.items,
          {
            ...action.payload.item,
            quantity: action.payload.item.quantity
              ? action.payload.item.quantity
              : 1,
            price: totalPriceCustomItem(action.payload.item),
          },
        ]
      }

      return {
        ...state,
        newItem: emptyItemCart,
        cart: {
          ...state.cart,
          items: newCart.items,
        },
      }
    }

    case CartActionTypes.ADD_MULTI_ITEM_CART_START: {
      const newMultiCart: Cart = {
        items: [],
      }

      let existMulti = false
      let maxId = 1
      if (state.cart.items.length > 0) {
        newMultiCart.items = state.cart.items.map((item) => {
          //FATHER ITEM ALREADY ON BASKET
          if (item._id.substr(0, item._id.indexOf("-")) === state.newItem._id) {
            //EXACTLY FATHER ITEM FOUND
            if (
              JSON.stringify(state.newItem.extraCategories) ===
              JSON.stringify(item.extraCategories)
            ) {
              existMulti = true
              return {
                ...item,
                quantity: item.quantity + state.newItem.quantity,
              }
            } else if (
              Number(item._id.substr(item._id.indexOf("-") + 1)) >= maxId
            ) {
              //NEW SUBITEM FROM FATHER
              maxId = Number(item._id.substr(item._id.indexOf("-") + 1)) + 1
            }
          }
          return item
        })
      }

      if (!existMulti) {
        let newPrice = Number(state.newItem.price)

        state.newItem.extraCategories?.map((item: ExtraCategory) => {
          return item.items?.map((item: ExtraCategoryItem) => {
            if (item.selected && item.price) {
              let _selectedQuantityPrice = 1
              if (item?.selectedQuantity) {
                _selectedQuantityPrice = item?.selectedQuantity
              }
              newPrice =
                Number(newPrice) + Number(item.price) * _selectedQuantityPrice
            }
          })
        })

        newMultiCart.items = [
          ...newMultiCart.items,
          {
            ...state.newItem,
            _id: `${state.newItem._id}-${maxId}`,
            price: newPrice,
          },
        ]
      }

      return {
        ...state,
        newItem: emptyItemCart,
        cart: {
          ...state.cart,
          items: newMultiCart.items,
        },
      }
    }

    case CartActionTypes.INCREASE_ITEM_AMOUNT_ORDER_PAGE: {
      let added = false

      if (action.payload.isCombo) {
        const clonedPayload = _.cloneDeep(action.payload)
        delete clonedPayload.discountedQuantity
        const comboIndex = findIndex(state.cart.items, clonedPayload)
        const updatedCartItems = state.cart.items.map((item, index) => {
          if (index === comboIndex) {
            return { ...item, quantity: item.quantity + 1 }
          }
          return item
        })

        return {
          ...state,
          cart: {
            ...state.cart,
            items: updatedCartItems,
          },
        }
      }
      const cartIncreaseUpdated = state.cart.items.map((item) => {
        if (item._id === action.payload._id) {
          added = true
          return { ...item, quantity: item.quantity + 1 }
        } else {
          return item
        }
      })

      if (!added) {
        cartIncreaseUpdated.push({ ...action.payload.item, quantity: 1 })
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          items: cartIncreaseUpdated,
        },
      }
    }

    case CartActionTypes.DECREASE_ITEM_AMOUNT_ORDER_PAGE: {
      if (action.payload.item.isCombo) {
        const clonedPayload = _.cloneDeep(action.payload.item)
        delete clonedPayload.discountedQuantity
        const comboIndex = findIndex(state.cart.items, clonedPayload)
        const updatedCartItems = state.cart.items.map((item, index) => {
          if (index === comboIndex) {
            return { ...item, quantity: item.quantity - 1 }
          }
          return item
        })
        return {
          ...state,
          cart: {
            ...state.cart,
            items: updatedCartItems.filter((item) => item.quantity > 0),
          },
        }
      } else {
        const cartDecreaseUpdated = state.cart.items.map((item) => {
          if (item._id === action.payload.item._id) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })

        return {
          ...state,
          cart: {
            ...state.cart,
            items: cartDecreaseUpdated.filter((item) => item.quantity > 0),
          },
        }
      }
    }

    case CartActionTypes.INCREASE_NEW_ITEM_CART_START:
      return {
        ...state,
        newItem: {
          ...state.newItem,
          quantity: state.newItem.quantity + 1,
        },
      }
    case CartActionTypes.DECREASE_NEW_ITEM_CART_START: {
      let quantity = state.newItem.quantity
      if (state.newItem.quantity > 1) {
        quantity = quantity - 1
      }
      return {
        ...state,
        newItem: {
          ...state.newItem,
          quantity,
        },
      }
    }

    case CartActionTypes.ORDER_SUBMIT_START:
      return {
        ...state,
        loading: true,
      }

    case CartActionTypes.ORDER_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CartActionTypes.ORDER_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case CartActionTypes.ADD_ITEM_WITH_ERROR:
      return {
        ...state,
        newItem: {
          ...state.newItem,
          sendTried: true,
        },
      }
    case CartActionTypes.SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload,
      }

    case CartActionTypes.GET_CART_SECRET_SUCCESS: {
      return {
        ...state,
        secret: action.payload.secret,
        paymentId: action.payload.id,
        discountApplicable: action.payload.discountApplicable,
      }
    }

    case CartActionTypes.APPLY_DISCOUNT_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case CartActionTypes.APPLY_DISCOUNT_VOUCHER_START:
      return {
        ...state,
        loading: true,
        discountCode: "",
        discountType: "",
        discountAmount: 0,
        discountQuantity: 0,
        discountApplicable: false,
        addMoreItems: false,
        invalidDiscountCode: false,
      }
    case CartActionTypes.EMPTY_ITEM_CART_START:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [],
        },
      }

    case CartActionTypes.APPLY_DISCOUNT_VOUCHER_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case CartActionTypes.VALIDATE_STOCK_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case CartActionTypes.VALIDATE_STOCK_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case CartActionTypes.VALIDATE_STOCK_SUCCESS: {
      return {
        ...state,
        loading: false,
        stockErrorItems: action.payload.cartItems,
        showStockModal: action.payload.stockError,
        cart: {
          ...state.cart,
          items: action.payload.cartItems,
        },
      }
    }
    case CartActionTypes.SET_SHOW_STOCK_MODAL: {
      return {
        ...state,
        showStockModal: action.payload,
      }
    }

    case CartActionTypes.SET_CART_ITEMS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: action.payload,
        },
      }
    }
    case CartActionTypes.GET_PRODUCT_DETAIL_API_START: {
      return {
        ...state,
        newItem: { ...state.newItem, extraCategories: [] },
      }
    }
    case CartActionTypes.GET_PRODUCT_DETAIL_API_SUCCESS: {
      return {
        ...state,
        newItem: { ...state.newItem, extraCategories: action.payload },
      }
    }
    case CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case CartActionTypes.CHECK_CART_ITEMS_AVAILABILITY_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default VenueReducer
