import React, { useRef, useState, useEffect } from "react"
import "./styles.css"
import { useBrand } from "@hooks/useBrand"
import { AppHeading6Bold } from "@components/Text/AppText"
import { theme } from "config/theme"

export const SegmentedControl = ({
  name,
  segments,
  callback,
  defaultIndex = 0,
  controlRef,
}) => {
  const { primaryColor, thirdColor } = useBrand()
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const componentReady = useRef()

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true as any
  }, [])

  useEffect(() => {
    const activeSegmentRef = segments?.[activeIndex]?.ref
    const { offsetWidth, offsetLeft } = activeSegmentRef?.current || {}
    const { style } = controlRef?.current || {}

    style?.setProperty?.("--highlight-width", `${offsetWidth}px`)
    style?.setProperty?.("--highlight-x-pos", `${offsetLeft}px`)
  }, [activeIndex, callback, controlRef, segments])

  const onInputChange = (value, index) => {
    setActiveIndex(index)
    callback(value, index)
  }

  return (
    <div className="flex w-full bg-white justify-center items-center ">
      <div
        className="controls-container"
        ref={controlRef}
        style={{
          backgroundColor: thirdColor,
          width: "95%",
          borderRadius: "50px",
          justifyContent: "space-between",
        }}
      >
        <div
          className={`controls ${componentReady.current ? "ready" : "idle"}`}
          style={{
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          {segments?.map((item, i) => (
            <div
              key={item.value}
              className={`segment ${i === activeIndex ? "active" : "inactive"}`}
              ref={item.ref}
              style={{
                backgroundColor:
                  i === activeIndex ? primaryColor : "transparent",
                borderRadius: "50px",
                width: "100%",
              }}
            >
              <input
                type="radio"
                value={item.value}
                id={item.label}
                name={name}
                onChange={() => onInputChange(item.value, i)}
                checked={i === activeIndex}
              />
              <div className="flex w-full justify-center item-center">
                <img
                  src={item.src}
                  className="object-contain"
                  style={{
                    height: "100%",
                    width: "1rem",
                    alignSelf: "center",
                    marginRight: "0.3rem",
                  }}
                />
                <AppHeading6Bold
                  style={{
                    paddingTop: "0.6rem",
                    paddingBottom: "0.6rem",
                    color: theme.main.primary,
                  }}
                >
                  {item.label}
                </AppHeading6Bold>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
