import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Heading2 } from "@components/Text/Text"
import MobileDetailCard from "@components/MobileDetailCard/MobileDetailCard"
import { userAddressList } from "@store/user/UserActions"
import { getVenueDetail } from "@store/venues/VenueActions"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { useHistory } from "react-router-dom"
import { setSelectedAddress } from "@store/cart/cartActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {}

const MyAddressPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id, venueId } = props.match.params

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const addresses = useSelector(({ user }: any) => user.addresses)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const { languageStrings, changeLanguage } = useLanguageHelper()

  useEffect(() => {
    if (Object.keys(venueDetail).length > 0 && venueDetail && venueDetail._id) {
      dispatch(userAddressList(localStorage.getItem("userId"), venueDetail._id))
    }
  }, [venueDetail])

  useEffect(() => {
    dispatch(getVenueDetail(venueId))
  }, [])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleAddAddress = () => {
    history.push(`/mobileAddressNamePage`)
  }

  const handleSelectAddress = (address) => {
    if (address.isValidAddress) {
      dispatch(setSelectedAddress(address))
      history.push("/menu/basket/" + venueId)
    }
  }

  return (
    <div className={"flex flex-col w-full bg-white"}>
      <div
        className={
          "flex flex-row w-full h-36 items-center justify-between fixed pl-5 pr-4"
        }
        style={{ backgroundColor: typography }}
      >
        <ArrowBackIosIcon
          style={{ color: "white", fontSize: 32 }}
          onClick={() => {
            history.push("/menu/basket/" + venueId)
          }}
        />
        <Heading2 className={"text-white"}>
          {languageStrings["BASKET.MY_ADDRESS"]}
        </Heading2>
        <Heading2 className={"text-white"} onClick={handleAddAddress}>
          {languageStrings["GENERAL.ADD"]}
        </Heading2>
      </div>
      <div className={"mt-40"}>
        {addresses.map((address) => (
          <MobileDetailCard
            title={address.title}
            subTitle={
              address.address && address.address.length > 25
                ? address.address.substr(0, 25) + "..."
                : address.address
            }
            description={address.postcode + ", " + address.city}
            key={address._id}
            isValidAddress={address.isValidAddress}
            onClick={() => handleSelectAddress(address)}
          />
        ))}
      </div>
    </div>
  )
}

export default MyAddressPage
