import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import CustomLoader from "@components/Loaders/CustomLoader"
import MobileHeader from "@components/header/MobileHeader"
import { getOrdersList } from "@store/user/UserActions"
import OrderListItem from "./components/OrderListItem"
import { useLanguageHelper } from "@helpers/LanguageHelper"

const MyOrdersPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { loading, orders } = useSelector(({ user }: any) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    dispatch(getOrdersList())
  }, [])
  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleClick = (orderId) => {
    history.push("/order-details/" + orderId)
  }
  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={languageStrings["PROFILE.MY_ORDERS"]}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex flex-col w-full self-center mt-36"}>
          {orders.map((order) => (
            <OrderListItem
              item={order}
              typography={typography}
              onClick={() => {
                handleClick(order._id)
              }}
            />
          ))}
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default MyOrdersPage
