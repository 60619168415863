import React, { useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { RadioGroup } from "@material-ui/core"
import { Heading5 } from "@components/Text/Text"
import clsx from "clsx"
import { useBrand } from "@hooks/useBrand"
import ComboProduct from "./ComboProduct"
import { useSelector } from "react-redux"
import { Error } from "@material-ui/icons"
import { theme } from "config/theme"
import { getMultiLanguageTitle } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
interface Props {
  item: ComboCategory
  typography: string
  onUpdateComboCategory: (c: ComboCategory) => void
  onUpdateComboCategoryProduct: (c: ExtraCategory, i: string) => void
  onRefChange?: any
}

const ComboCategory = ({
  item,
  typography,
  onUpdateComboCategory,
  onUpdateComboCategoryProduct,
  onRefChange,
}: Props) => {
  const useStyles = makeStyles<Theme>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "1rem 0",
      width: "100%",
    },
    item: {
      textAlign: "left",
      alignItems: "center",
      width: "100%",
    },
  }))
  const classes = useStyles()
  const { menuLanguage } = useLanguageHelper()
  const { secondaryColor } = useBrand()
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const [familyProperty, setFamilyProperty] = useState(item)
  const sendTried = useSelector(({ cart }: RootState) => cart.newItem.sendTried)
  const products = item?.products ?? []
  const hasErr = item.hasError && sendTried
  const _multiTitle = item?.multiTitle || []
  const itemLang = getMultiLanguageTitle(_multiTitle, menuLanguage)
  const _title = itemLang?.title || item.title

  const updateMenuCategoryItem = (id) => {
    setSelectedItem(id)
    const newFamilyItems: ComboProduct[] = familyProperty.products.map(
      (item) => {
        if (item._id === id) {
          return {
            ...item,
            selected: item.selected ? item.selected : !item.selected,
          }
        }
        return { ...item, selected: false }
      },
    )
    const updatedCategory = { ...item, products: newFamilyItems }
    setFamilyProperty(updatedCategory)
    onUpdateComboCategory(updatedCategory)
  }

  return (
    <div key={item._id} className={classes.item}>
      <div
        className={classes.root}
        style={{
          backgroundColor: hasErr ? theme.status.dangerLight : undefined,
        }}
      >
        <Heading5
          style={{
            color: secondaryColor,
          }}
          className={clsx("w-full flex flex-row self-start items-center pl-8")}
        >
          {hasErr ? (
            <Error className="mr-2" style={{ color: theme.status.danger2 }} />
          ) : null}
          {_title}
        </Heading5>
      </div>
      <RadioGroup
        value={selectedItem}
        onChange={(e) => {
          updateMenuCategoryItem(e.target.value)
        }}
      >
        {products.map((product: ComboProduct, index) => {
          return (
            <ComboProduct
              key={index.toString()}
              product={product}
              typography={typography}
              selectedItem={selectedItem}
              onUpdateComboCategoryProduct={(ec: ExtraCategory) => {
                onUpdateComboCategoryProduct(ec, product._id)
              }}
              onRefChange={onRefChange}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

export default ComboCategory
