import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RoundedButtonV2 } from "./components/roundedButton"
import {
  getClientDetail,
  getVenueDetail,
  setSelectedOption,
} from "@store/venues/VenueActions"
import { CartType } from "@models/Cart"
import { emptyCartItems } from "@store/cart/cartActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useBrand } from "@hooks/useBrand"
import { ArrowRightAlt } from "@material-ui/icons"
import { Heading1 } from "@components/Text/Text"

const VirtualBasket = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { permalink, tableId, venueId } = props.match.params
  const menus = useSelector(({ menu }) => menu.menus)
  const { languageStrings } = useLanguageHelper()
  const { primaryColor, restaurantName, restaurantImage } = useBrand()

  useEffect(() => {
    dispatch(getVenueDetail(venueId))
    if (permalink) {
      dispatch(getClientDetail(permalink))
    }
  }, [])

  useEffect(() => {
    dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))

    dispatch(emptyCartItems())
  }, [])

  return (
    <div
      className={`flex flex-col bg-cover w-full h-screen`}
      style={{
        background: restaurantImage,
      }}
    >
      <div className="flex flex-col self-center w-11/12 h-full items-center justify-end pb-10 py-10">
        <Heading1 className="text-white	">{restaurantName || ""}</Heading1>
        <RoundedButtonV2
          title={`${languageStrings["VIRTUAL_BASKET.SEE_THE_MENU"]}`}
          style={{
            backgroundColor: primaryColor,
            borderColor: primaryColor,
          }}
          rightIcon={<ArrowRightAlt className="ml-4" />}
          onClick={() => {
            if (menus?.length === 1 && menus[0]?.menuUrl) {
              window.location.href = `${menus[0].menuUrl}`
            } else {
              history.push({
                pathname: `/${permalink}/${tableId}`,
                state: { isVirtualbasketMenu: true },
              })
            }
          }}
        />
        <RoundedButtonV2
          title={languageStrings["VIRTUAL_BASKET.VIEW_THE_ACCOUNT"]}
          style={{
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
          }}
          onClick={() => {
            history.push(
              `/${permalink}/virtualbasketdetails/${tableId}/${venueId}`,
            )
          }}
        />
      </div>
    </div>
  )
}

export default VirtualBasket
