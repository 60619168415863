import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"

import { useDispatch, useSelector } from "react-redux"

import Button from "@components/buttons/Button"
import MobileHeader from "@components/header/MobileHeader"
import { addAddress, setUserAddress } from "@store/user/UserActions"
import { MyMapComponent } from "@modules/address/MyMapComponent"
import CustomLoader from "@components/Loaders/CustomLoader"
import { useLanguageHelper } from "@helpers/LanguageHelper"

function MobileAddressMapPage(props) {
  let [mapRef, setMapRef] = useState<any>(React.createRef())
  const history = useHistory()
  const dispatch = useDispatch()
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const {
    address: userAddress,
    latitude: lat,
    longitude: long,
    loading,
    addresses,
  } = useSelector(({ user }: any) => user)
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [latitude, setLatitude] = useState<number>(lat)
  const [longitude, setLongitude] = useState<number>(lat)
  const { languageStrings, changeLanguage } = useLanguageHelper()

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  useEffect(() => {
    setLatitude(lat)
    setLongitude(long)
  }, [lat, long])
  const setRef = (ref) => {
    setMapRef(ref)
  }

  const canBeSubmit = () => {
    const { title, address, city, postcode } = userAddress
    if (
      title &&
      title.length > 2 &&
      address &&
      address.length > 2 &&
      city &&
      city.length > 2 &&
      postcode &&
      postcode.length > 4 &&
      latitude &&
      longitude
    ) {
      return false
    }
    return true
  }
  const handleSubmit = () => {
    let isAddressList = false
    let isBasket = false
    addresses.length > 0 ? (isAddressList = true) : (isBasket = true)
    dispatch(
      addAddress(
        {
          title: userAddress.title,
          city: userAddress.city,
          address: userAddress.address,
          postcode: userAddress.postcode,
          details: userAddress.details,
          longitude: longitude.toString(),
          latitude: latitude.toString(),
          userId: localStorage.getItem("userId"),
        },
        venueDetail._id,
        history,
        true,
        isAddressList,
        isBasket,
      ),
    )
  }
  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={languageStrings["BASKET.SELECT_ADDRESS"]}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex flex-col w-full h-screen"}>
          <MyMapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            onMarkerMounted={setRef}
            latitude={latitude}
            longitude={longitude}
            onPositionChanged={() => {
              const position = mapRef.getPosition()
              setLatitude(position.lat())
              setLongitude(position.lng())
            }}
          />

          <Button
            title={languageStrings["BASKET.CREATE"]}
            backgroundColor={typography}
            classes={
              "flex items-center mt-4 mb-4 justify-center self-center w-3/4 fixed h-24  text-white py-2 px-4 border border-transparent cursor-pointer outline-none"
            }
            style={{
              borderRadius: "25px",
              fontSize: "14px",
              bottom: 20,
            }}
            disabled={canBeSubmit()}
            onClick={handleSubmit}
          />
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default MobileAddressMapPage
