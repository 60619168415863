import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import MobileHeader from "@components/header/MobileHeader"
import UpdateProfileForm from "./components/UpdateProfileForm"
import CustomLoader from "@components/Loaders/CustomLoader"
import { useLanguageHelper } from "@helpers/LanguageHelper"

const UpdateProfile = () => {
  const history = useHistory()

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { loading } = useSelector(({ user }: any) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const { languageStrings, changeLanguage } = useLanguageHelper()

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={languageStrings["PROFILE.EDIT_PROFILE"]}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex w-11/12 self-center mt-48"}>
          <UpdateProfileForm typography={typography} />
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default UpdateProfile
