import React, { useContext } from "react"
import { LanguageContext } from "./LanguageContext"

export const useLanguageHelper = () => {
  const { changeLocale, messages, locale, changeMenuLanguage, menuLanguage, saveChangedVenue } =
    useContext(LanguageContext)

  const changeLanguage = (locale, changeOnFrontend) =>
    changeLocale(locale, changeOnFrontend)

  const changeMenuLang = (language) => {
    changeMenuLanguage(language)
  }
  // save clientId when defualt locale is changed
  const changeLangVenue = (clientId) => {
    saveChangedVenue(clientId)
  }

  return {
    languageStrings: messages,
    changeLanguage,
    changeMenuLang,
    changeLangVenue,
    menuLanguage,
    locale,
  }
}
