import React, { FC, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Theme,
} from "@material-ui/core"
import clsx from "clsx"
import { Error } from "@material-ui/icons"
import { setUniProperty } from "@store/cart/cartActions"
import { Heading5, Body13, Label14 } from "@components/Text/Text"
import {
  arrangePrice,
  formatImageUrl,
  getMultiLanguageTitle,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import { ItemDivider } from "./ItemDivider"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { AppHeading6Medium, AppParagraph4 } from "@components/Text/AppText"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"
interface Props {
  item: ExtraCategory
  typography: string
  onRefChange?: any
  onUpdateComboCategoryProduct?: (c: ExtraCategory) => void
}
interface StyleProps {
  typography?: string
}
const hideRadioStyles = makeStyles((theme) => ({
  hideRadio: {
    visibility: "hidden",
  },
}))
const ExtraCategoriesUni: FC<Props> = ({
  item,
  onRefChange,
  onUpdateComboCategoryProduct,
}) => {
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "1rem 0",
    },
    rootForm: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: 0,
    },
    checked: (props) => ({
      color: `${props.typography} !important`,
    }),
    focused: {
      color: "black !important",
    },
  }))
  const {
    title,
    description: itemDescription,
    multiTitle = [],
    multiDescription = [],
  } = item
  const dispatch = useDispatch()
  const { menuLanguage } = useLanguageHelper()
  const categoryLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const categoryLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = categoryLang?.title || title
  const _description = categoryLangDescription?.description || itemDescription
  const { primaryColor, secondaryColor } = useBrand()
  const typography = primaryColor
  const styleProps: StyleProps = { typography: secondaryColor }
  const classes = useStyles(styleProps)
  const sendTried = useSelector(({ cart }: RootState) => cart.newItem.sendTried)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const [familyProperty, setFamilyProperty] = useState(item)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const hideRadio = hideRadioStyles()
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const { clientCurrency } = useClientCurrency()
  useEffect(() => {
    setFamilyProperty(item)
  }, [item])
  const updateMenuExtraCategoryItem = (id) => {
    setSelectedItem(id)
    const newFamilyItems: ExtraCategoryItem[] = familyProperty.items.map(
      (item) => {
        if (item._id === id) {
          return {
            ...item,
            selected: item.selected ? item.selected : !item.selected,
          }
        }
        return { ...item, selected: false }
      },
    )
    const amountSelected = newFamilyItems.filter((item) => item.selected).length
    const familyPropertyUpdated = {
      ...familyProperty,
      hasError: amountSelected === 0,
      items: newFamilyItems,
    }
    let payload = {
      extraCategory: familyPropertyUpdated,
    }
    setFamilyProperty(familyPropertyUpdated)
    if (onUpdateComboCategoryProduct) {
      onUpdateComboCategoryProduct(familyPropertyUpdated)
    } else {
      dispatch(setUniProperty(payload))
    }
  }
  useEffect(() => {
    familyProperty.items?.map((item: ExtraCategoryItem) => {
      if (item.selected) {
        updateMenuExtraCategoryItem(item._id)
      }
      return item
    })
  }, [])
  const hasErr = item.hasError && sendTried
  return (
    <>
      <div
        className={classes.root}
        style={{
          backgroundColor: hasErr ? theme.status.dangerLight : undefined,
        }}
        ref={(ref) => {
          if (hasErr && ref) {
            onRefChange(ref, title)
          }
        }}
        id={item._id}
      >
        <Heading5
          style={{
            color: hasErr ? theme.status.danger2 : secondaryColor,
          }}
          className={clsx("w-full flex flex-row self-start items-center pl-8")}
        >
          {hasErr ? (
            <Error className="mr-2" style={{ color: theme.status.danger2 }} />
          ) : null}
          {_title}
        </Heading5>

        <Label14
          className="w-full flex flex-row self-start items-center pl-8"
          style={{ paddingLeft: "0.8rem" }}
        >
          {_description}
        </Label14>
      </div>
      <FormControl className="w-full" component="fieldset">
        {restaurant.accessLevel && restaurant.accessLevel !== "1" ? (
          <RadioGroup
            value={selectedItem}
            onChange={(e) => {
              updateMenuExtraCategoryItem(e.target.value)
            }}
          >
            {familyProperty.items?.map((item: ExtraCategoryItem, index) => {
              const extraCatMultiTitle = item?.multiTitle || []
              const extraCategoryLang = getMultiLanguageTitle(
                extraCatMultiTitle,
                menuLanguage,
              )
              const _itemTitle = extraCategoryLang?.title || item.title
              return (
                <>
                  <div className="flex flex-row justify-center py-4 items-center">
                    <FormControlLabel
                      className="flex text-xs w-full"
                      classes={{ root: classes.rootForm }}
                      labelPlacement="start"
                      control={
                        <div
                          className="flex items-center justify-end"
                          style={{ minWidth: "20%" }}
                        >
                          {item.price ? (
                            <AppParagraph4
                              style={{
                                color: theme.main.black,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {`${arrangePrice(item.price).sign} ${
                                arrangePrice(item.price)._price
                              }`}{" "}
                              {clientCurrency.symbol}
                            </AppParagraph4>
                          ) : null}
                          <Radio
                            value={item._id}
                            size="medium"
                            classes={{
                              root:
                                excludeOrderType.includes(
                                  venueDetail?.orderType || "",
                                ) ||
                                menuDetail?.isVisible === false ||
                                isClosedKitchen
                                  ? hideRadio.hideRadio
                                  : "",
                              checked: classes.checked,
                            }}
                          />
                        </div>
                      }
                      label={
                        <div className={"flex items-center pl-8"}>
                          {item.image ? (
                            <img
                              src={formatImageUrl(item.image)}
                              style={{
                                maxHeight: "100%",
                                objectFit: "cover",
                                borderRadius: 4,
                                width: "2.5rem",
                              }}
                            />
                          ) : null}
                          <AppHeading6Medium
                            style={{
                              marginLeft: item.image ? "0.8rem" : 0,
                              marginRight: "2.5rem",
                            }}
                          >
                            {_itemTitle}
                          </AppHeading6Medium>
                        </div>
                      }
                    />
                  </div>
                  <ItemDivider />
                </>
              )
            })}
          </RadioGroup>
        ) : (
          familyProperty.items?.map((item: ExtraCategoryItem, index) => {
            const extraCatMultiTitle = item?.multiTitle || []
            const extraCategoryLang = getMultiLanguageTitle(
              extraCatMultiTitle,
              menuLanguage,
            )
            const _itemTitle = extraCategoryLang?.title || item.title
            return (
              <Body13
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  marginLeft: "1.2rem",
                }}
              >
                {_itemTitle}
                {item.price ? (
                  <span className="pl-4" style={{ color: "grey" }}>
                    {arrangePrice(item.price).sign}{" "}
                    {arrangePrice(item.price)._price} {clientCurrency.symbol}
                  </span>
                ) : null}
              </Body13>
            )
          })
        )}
      </FormControl>
    </>
  )
}
export default ExtraCategoriesUni
