import { AppHeading1, AppParagraph3 } from "@components/Text/AppText"
import { theme } from "config/theme"
import React, { CSSProperties, FC } from "react"

interface IProps {
  style?: CSSProperties
  textStyle?: CSSProperties
  containerStyle?: CSSProperties
  heading?: string
  subHeading?: string
}
export const ScreenHeading: FC<IProps> = ({
  style,
  heading,
  subHeading,
  containerStyle,
  textStyle,
}) => {
  return (
    <div style={{ ...containerStyle }} className="flex flex-col w-full">
      <AppHeading1
        style={{
          width: "90%",
          marginBottom: "0.6rem",
          ...style,
        }}
      >
        {heading}
      </AppHeading1>
      <AppParagraph3
        style={{
          textAlign: "left",
          width: "95%",
          color: theme.main.black,
          display: "flex",
          alignSelf: "center",
          marginBottom: "0.6rem",
          ...textStyle,
        }}
      >
        {subHeading}
      </AppParagraph3>
    </div>
  )
}
