import { AppHeading3Bold, AppParagraph4Bold } from "@components/Text/AppText"
import { Menu, MenuItem } from "@material-ui/core"
import { ArrowBack, Notifications } from "@material-ui/icons"
import React, { CSSProperties, FC, useState } from "react"
import { useHistory } from "react-router-dom"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { ItemChip } from "./ItemChip"
import { languagesListData } from "@modules/venueDetails/languageData"
import LangSelector from "@assets/lang_select.png"
import { theme } from "config/theme"

interface IProps {
  backTitle?: string
  children?: React.ReactNode
  style?: CSSProperties
  backTitleStyle?: CSSProperties
  isMobile?: boolean
  showBanner?: boolean
  showTranslate?: boolean
  availableLanguages?: any
  onBackClick?: () => void
  onNotificationPress?: () => void
  count?: number
}
const BackButton: FC<IProps> = ({
  backTitle,
  children,
  style,
  showTranslate = false,
  availableLanguages = [],
  backTitleStyle,
  onBackClick,
}) => {
  const { changeLanguage, changeMenuLang, menuLanguage, changeLangVenue } =
    useLanguageHelper()
  const locale = localStorage.getItem("locale") || "es"
  const languages = languagesListData.filter((language) =>
    availableLanguages.includes(language.name),
  )
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const changeLang = (lang: string, code: number) => {
    const clientId = localStorage.getItem("clientId")
    changeLanguage(lang, true)
    changeMenuLang(code)
    if (clientId) {
      changeLangVenue(clientId)
    }
    handleClose()
  }
  return (
    <>
      <div
        className={"flex flex-col bg-white min-h-screen"}
        style={{ ...style }}
      >
        <div className="flex justify-between bg-white items-center w-full h-40 sticky top-0 z-10 overflow-hidden">
          <div className="flex justify-between items-center ml-6">
            <ArrowBack
              onClick={() => {
                if (onBackClick) {
                  onBackClick?.()
                } else {
                  history.goBack()
                }
              }}
            />
            <AppHeading3Bold
              style={{
                margin: "0rem 0.6rem",
                ...backTitleStyle,
              }}
            >
              {backTitle || ""}
            </AppHeading3Bold>
          </div>
          <div className="flex items-center">
            {showTranslate ? (
              <img
                src={LangSelector}
                className="object-cover mr-12"
                onClick={handleClick}
                style={{
                  minHeight: "1.25rem",
                  maxHeight: "1.25rem",
                  maxWidth: "1.25rem",
                  minWidth: "1.25rem",
                }}
              />
            ) : null}
          </div>
        </div>

        {children}
      </div>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: "1.8rem" }}
      >
        {languages.map((language) => {
          const langLocale = language.locale || "es"
          const langCode = language.code || 1
          return (
            <MenuItem onClick={() => changeLang(langLocale, langCode)}>
              <ItemChip
                isSelected={langLocale === locale}
                imgSrc={language.flag}
                name={language.shortName}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default BackButton
