import moment, { Moment } from "moment"
import { DATE_TIME_FORMATS } from "./Formates/dateTime"

export type TimeRange = {
  start: string
  end: string
}
export type DaysCondition = {
  sunday: Array<TimeRange>
  monday: Array<TimeRange>
  tuesday: Array<TimeRange>
  wednesday: Array<TimeRange>
  thursday: Array<TimeRange>
  friday: Array<TimeRange>
  saturday: Array<TimeRange>
}

export const futureOrderDayRange = ({
  todayString = "Today",
  tomorrowString = "Tomorrow",
  maxFutureDay,
}: {
  todayString: string
  tomorrowString: string
  maxFutureDay: number
}) => {
  return Array(maxFutureDay + 1)
    .fill(0)
    .map((v, i) => {
      let suffix

      if (i === 0) {
        suffix = { suffix: todayString }
      } else if (i === 1) {
        suffix = { suffix: tomorrowString }
      }

      return {
        name: i,
        ...(suffix ? suffix : {}),
        value: i,
        day: moment()
          .add(i, "d")
          .format(DATE_TIME_FORMATS.DAY.DEFAULT)
          .toLocaleLowerCase(),
        date: moment().add(i, "d").startOf("day"),
      }
    })
}

export const getRangesByDays = (visibilityRanges): DaysCondition => {
  const defaultReturnIfNoVisibilityRangePresent = {
    monday: [{ start: "00:00", end: "23:59" }],
    tuesday: [{ start: "00:00", end: "23:59" }],
    wednesday: [{ start: "00:00", end: "23:59" }],
    thursday: [{ start: "00:00", end: "23:59" }],
    friday: [{ start: "00:00", end: "23:59" }],
    saturday: [{ start: "00:00", end: "23:59" }],
    sunday: [{ start: "00:00", end: "23:59" }],
  }
  if (!visibilityRanges || !visibilityRanges.length) {
    return defaultReturnIfNoVisibilityRangePresent
  }

  const daysCondition = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  }
  for (const day in daysCondition) {
    for (const visibilityRange of visibilityRanges) {
      // to check day is not present
      if (!visibilityRange[day]) {
        continue
      }
      let { start, end } = visibilityRange || {}
      if (end === "24:00") {
        end = "23:59"
      }
      daysCondition[day].push({ start, end })
    }
  }
  return daysCondition
}

export const checkTimeInsideVisibilityRange = (
  daysCondition: DaysCondition,
  day: string,
  selectedHour = "0",
  selectedMinute = "0",
) => {
  const passedCondition: Array<Moment> = []
  for (const timeRange of daysCondition[day]) {
    // to check day is not present
    if (!daysCondition[day].length) {
      continue
    }

    // to check range of time is present or not
    let { start, end } = timeRange || {}
    const [startHour, startMinute] = start.split(":").map(Number)
    const [endHour, endMinute] = end.split(":").map(Number)
    const _startDate = moment().set({
      hour: startHour,
      minute: startMinute,
    })
    const _endDate = moment().set({
      hour: endHour,
      minute: endMinute,
    })
    const selectedDate = moment().set({
      hour: Number(selectedHour),
      minute: Number(selectedMinute),
    })
    // to check select time not in range
    if (
      !(
        selectedDate.isSameOrAfter(_startDate) &&
        selectedDate.isSameOrBefore(_endDate)
      )
    ) {
      continue
    }

    passedCondition.push(selectedDate)
  }
  return passedCondition.length > 0
}

export const getTimeSlotsFromRanges = (timeRanges): Array<string> => {
  const timeSlots = new Set()
  // to check time range present or not
  if (!timeRanges && timeRanges?.length <= 0) {
    return Array.from(timeSlots) as Array<string>
  }
  let sortedTimeRanges = timeRanges
    .sort((a, b) => {
      const { start: aStart } = a
      const { start: bStart } = b
      const [aHour] = aStart.split(":").map(Number)
      const [bHour] = bStart.split(":").map(Number)
      return aHour - bHour
    })
    .map((timeRangeItem, index) => {
      // to check it should not first item
      if (index > 0) {
        return timeRangeItem
      }
      const { start, end } = timeRangeItem
      const [startHour, startMinute] = start.split(":").map(Number)
      const _startDate = moment()
        .startOf("day")
        .set({
          hours: startHour,
          minutes: startMinute,
          seconds: 0,
          milliseconds: 0,
        })
        .add(15, "minute")
      return {
        start: _startDate.format(DATE_TIME_FORMATS.TIME.DEFAULT),
        end,
      }
    })
  
  for (let i = 0; i < sortedTimeRanges.length; i++) {
    const { start, end } = sortedTimeRanges[i] || {}
    const [startHour, startMinute] = start.split(":").map(Number)
    const [endHour, endMinute] = end.split(":").map(Number)
    const _startDate = moment()
      .startOf("day")
      .set({
        hours: startHour,
        minutes: startMinute,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate()
    const _endDate = moment()
      .startOf("day")
      .set({
        hours: endHour,
        minutes: endMinute,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate()
    let nextAvailableTime = _startDate

    let currentTimeSlot = new Date(nextAvailableTime)
    while (moment(currentTimeSlot).isSameOrBefore(_endDate)) {
      const formattedTime = moment(currentTimeSlot).format("HH:mm")
      if (moment(currentTimeSlot).isSameOrAfter(_startDate)) {
        timeSlots.add(formattedTime)
      }
      currentTimeSlot.setMinutes(currentTimeSlot.getMinutes() + 15)
    }
  }
  return Array.from(timeSlots) as Array<string>
}

export const getNextAvailableTime = (daysCondition, maxFutureDays = 1) => {
  const now = new Date()
  now.setMinutes(now.getMinutes() + 30)
  let nextAvailableTime = moment(
    new Date(Math.ceil(now.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)),
  )
  const day = nextAvailableTime.format("dddd").toLowerCase()
  const minute = nextAvailableTime.format("mm")
  const hour = nextAvailableTime.format("HH")
  const isNextAvailableTimePresentInRange = checkTimeInsideVisibilityRange(
    daysCondition,
    day,
    hour,
    minute,
  )
  if (isNextAvailableTimePresentInRange) {
    return nextAvailableTime.toDate()
  }
  const oldNextAvailableTime = nextAvailableTime.toDate()
  // going through maxFutureDays from current date
  for (let index = 0; index <= maxFutureDays; index++) {
    const nextAvailableTime = moment(oldNextAvailableTime)
      .add(index, "day")
      .startOf("day")
    const day = nextAvailableTime.format("dddd").toLowerCase()
    const availableTimeSlots = getTimeSlotsFromRanges(
      daysCondition[day],
    ) as Array<string>
    // going through all possible available slots
    for (const slot of availableTimeSlots) {
      const [hour, minute] = slot.split(":").map(Number)
      if (
        checkTimeInsideVisibilityRange(
          daysCondition,
          day,
          hour.toString(),
          minute.toString(),
        )
      ) {
        return nextAvailableTime
          .set({
            hours: hour,
            minutes: minute,
          })
          .toDate()
      }
    }
  }
}
