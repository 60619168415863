import React from "react"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"

interface Props {
  children: any
  showModal: boolean
  onCloseModal: () => void
  backdropClickDisabled?: boolean
}
export default function CustomModal({
  children,
  onCloseModal,
  showModal,
  backdropClickDisabled = false,
}: Props) {
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (reason !== "backdropClick") {
      onCloseModal()
    }
  }
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      open={showModal}
      onClose={backdropClickDisabled ? handleClose : onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {children}
    </Modal>
  )
}
