import { useBrand } from "@hooks/useBrand"
import { TextField } from "@material-ui/core"
import { theme } from "config/theme"
import React, { CSSProperties, FC, useState } from "react"

interface IProps {
  style?: CSSProperties
  label?: string
  onValueChange?: (v: any) => void
  value?: string
  inputProps?: any
  inputType?: string
  inputName?: string
  isError?: boolean
}
export const TextInput: FC<IProps> = ({
  style = {},
  label = "",
  onValueChange,
  inputProps,
  value,
  inputType,
  inputName,
  isError,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const { primaryColor } = useBrand()
  const borderColor = isError
    ? theme.status.red
    : isFocused
    ? primaryColor
    : theme.main.black
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        border: `1px solid ${borderColor}`,
        justifyContent: "center",
        alignItems: "center",
        minHeight: "4rem",
        maxHeight: "4rem",
        borderRadius: "8px",
        ...style,
      }}
    >
      <TextField
        type={inputType}
        name={inputName}
        value={value}
        onChange={onValueChange}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        id="standard-basic"
        label={label}
        inputProps={inputProps}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          style: {
            color: theme.main.black,
            transform: isFocused || value ? undefined : "translateY(100%)",
          }, // Set the desired color
        }}
        style={{
          backgroundColor: theme.main.white,
          width: "90%",
          minHeight: "3rem",
          maxHeight: "3rem",
        }}
      />
    </div>
  )
}
