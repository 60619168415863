import React, { FC, useEffect } from "react"
import ExtraCategoriesModalLeft from "./ExtraCategoriesModalLeft"
import { useSelector } from "react-redux"
import {
  formatImageUrl,
  truncateText,
  getMultiLanguageTitle,
  isSellingFormatProduct,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import classes from "../../menuItem.module.css"
import { useBrand } from "@hooks/useBrand"
import { AppHeading4Medium, AppParagraph4 } from "@components/Text/AppText"
import { theme } from "config/theme"
import { AddCircle } from "@components/addCircle/AddCircle"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import useClientCurrency from "@hooks/useClientCurrency"
interface IProps {
  onClick: () => void
  showItemDetail: () => void
  item: MenuItem
  quantity?: number
  onItemDecrease: () => void
  iconFontSize?: "inherit" | "default" | "small" | "large"
  onAdd?: () => void
}
export const MenuItemCard: FC<IProps> = ({
  onClick,
  item,
  showItemDetail,
  quantity,
  onItemDecrease,
  iconFontSize,
  onAdd,
}) => {
  const { menuLanguage } = useLanguageHelper()
  const { title, price, image, multiTitle = [], multiDescription = [] } = item
  const itemLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const itemLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = itemLang?.title || title
  const _description = itemLangDescription?.description || item?.description
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { isSellingFormat, basePrice } = isSellingFormatProduct(item)
  const { clientCurrency } = useClientCurrency()
  const _price = isSellingFormat
    ? `A partir de ${basePrice} ${clientCurrency.symbol}`
    : `${price?.toFixed(2)} ${clientCurrency.symbol}`
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen
  const { primaryColor } = useBrand()
  return (
    <div className="flex flex-col ml-8">
      <div className="flex">
        <div className="flex flex-col w-full justify-between">
          <div className={classes.info} onClick={showItemDetail}>
            {image && image.length > 0 ? (
              <div
                className={classes.image}
                style={{
                  marginRight: "0.8rem",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: `url('${formatImageUrl(
                      image,
                    )}') center center / cover no-repeat`,
                    height: 60,
                    width: 60,
                  }}
                />
              </div>
            ) : (
              <div style={{ height: 60 }} />
            )}
            <div className="flex flex-col min-h-full max-h-full justify-center">
              <AppHeading4Medium
                style={{ color: theme.main.primary, lineHeight: "1.2" }}
              >
                {_title}
              </AppHeading4Medium>
              <AppParagraph4
                style={{
                  color: theme.main.black,
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                  lineHeight: "1.2",
                }}
                className={"lineClamp2"}
              >
                {_description || ""}
              </AppParagraph4>
              <AppParagraph4 style={{ color: theme.main.primary }}>
                {_price}
              </AppParagraph4>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center pb-1 pr-1 mr-8">
          {restaurant.accessLevel &&
          restaurant.accessLevel !== "1" &&
          !excludeOrderType.includes(venueDetail?.orderType || "") &&
          isMenuHide ? (
            <AddCircle
              iconFontSize={iconFontSize}
              onClick={onAdd ? onAdd : onClick}
              iconStyle={{ color: theme.main.primary }}
            />
          ) : null}
        </div>
      </div>

      {!item?.isCombo &&
        quantity &&
        restaurant.accessLevel &&
        restaurant.accessLevel !== "1" && (
          <ExtraCategoriesModalLeft
            typography={primaryColor}
            quantity={quantity}
            handleClick={onItemDecrease}
            iconFontSize={iconFontSize}
            price={price}
            onIncrease={onClick}
            title={_title}
          />
        )}
    </div>
  )
}
