import React, { useState } from "react"
import { Modal } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { addItemInCart } from "@store/cart/cartActions"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import classes from "../modals.module.css"
import {
  formatImageUrl,
  getMultiLanguageTitle,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import CrossImage from "@components/buttons/CrossImage"
import { OrderStates } from "./OrderStates"
import { theme } from "config/theme"
import { AppHeading2, AppParagraph3 } from "@components/Text/AppText"
import { AllergensImages } from "@components/allergensImages/AllergensImages"
import { ModalDetail } from "./ModalDetail"
interface Props {
  item: MenuItem
  onClose: () => void
  typography: string
  isMobile?: boolean
}

const ItemDetailModal = ({ onClose, item, typography, isMobile }: Props) => {
  const {
    image,
    title,
    description,
    multiTitle = [],
    multiDescription = [],
    allergenes,
  } = item
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen
  const { languageStrings, changeLanguage, menuLanguage } = useLanguageHelper()
  const itemLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const itemLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = itemLang?.title || title
  const _description = itemLangDescription?.description || description

  const onSubmit = () => {
    const payload = {
      item: {
        ...item,
        quantity,
      },
    }

    dispatch(addItemInCart(payload))
    onClose()
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  return (
    <Modal
      className="flex justify-between items-center"
      open={true}
      onClose={onClose}
    >
      <div
        style={{
          width: isMobile ? "90%" : "20rem",
          borderRadius: "12px",
          backgroundColor: theme.main.white,
        }}
        className="outline-none mx-12 relative"
      >
        <div className="relative">
          <CrossImage
            margin
            onClick={onClose}
            style={{ marginRight: 10, position: "absolute" }}
            color={image ? "white" : "black"}
          />
          <div
            className={classes.modal_right}
            style={{
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {image && (
              <div
                className={"flex w-full"}
                style={{
                  minHeight: "30%",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <img
                  src={formatImageUrl(image)}
                  style={{ minHeight: "12.5rem", maxHeight: "12.5rem" }}
                  className="w-full object-cover"
                />
              </div>
            )}
          </div>
        </div>
        <ModalDetail
          allergenes={allergenes}
          title={_title}
          description={_description}
          image={image}
        />
        <div className={classes.extracategory_uni} />

        {!excludeOrderType.includes(venueDetail?.orderType || "") &&
        isMenuHide ? (
          <OrderStates
            onAdd={() => setQuantity(quantity + 1)}
            onRemove={decreaseQuantity}
            onSubmit={onSubmit}
            price={(quantity * totalPriceCustomItem(item)).toFixed(2)}
            quantity={quantity}
            btnTitle={languageStrings["MENU_ITEM.ADD_TO_ORDER"]}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default ItemDetailModal
