// MAIN COLORS
const PRIMARY = "rgba(3, 28, 29, 1)"
const SECONDARY = "#FAF6F6"
const DARK_BLACK = "#828B8B"
const BLACK = "#828B8B"
const BLACK_OPACITY = "rgba(246, 246, 246, 1)"
const BLACK_LIGHT = "#515A5A"
const GREY = "#E3E3E3"
const GREY_LIGHT = "#CCCCD4"
const GREY_LIGHTER = "#DAE1E7"
const GREY_LIGHTER_WITHOUT_OPACITY = "#F6F1EF"
const DARK_1 = "#000000"
const DARK_5 = "#F6F6F6"
const INDIGO = "#722379"
const INDIGO_LIGHT = "#E3D3E4"
const INDIGO_LIGHTER = "rgba(114,35,121, 0.1)"
const WHITE = "#FFFFFF"
const WHITE_OPACITY = "rgba(255, 255, 255, 0.2)"
const WHITE_OPACITY_LIGHT = "rgba(255, 255, 255, 0.7)"
const NEW_ORAGE = "#FE6D4E"

// SECONDARY COLORS
const DEEP_BLUE = "#00008B"
const BLUE_LIGHT = "#E0E6F6"
const GOLD = "#FFD700"
const PINK = "#FFC0CB"
const PINK_LIGHT = "#FFDFE5"
const PINK_LIGHTER = "#FDEAFF"
const CHECKBOX_GREEN = "#9CFBB0"
const NUDE = "#E3BC9A"
const IMAGE_BACKGROUND = "#F1E9F2"
const ORANGE = "#FF7A00"
const LIGHT_ORANGE = "#FFEDEA"
const LIGHT = "#FAF6F6"
const LIGTH_GREY = "#BABABA"

// STATUS COLORS
const INFO = "#0064FF"
const INFO_LIGHT = "#E4EFFF"
const SUCCESS = "#37AC50"
const SUCCESS_LIGHT = "#DEFFE5"
const WARNING = "#FFC800"
const WARNING_LIGHT = "#FFF6D7"
const DANGER = "#FF3200"
const DANGER2 = "#872813"
const DANGER_LIGHT = "#FFEDEA"
const CART_SELECTED = "#FE6D4E"
const RED = "#FE6D4E"

const theme = {
  main: {
    primary: PRIMARY,
    darkBlack: DARK_BLACK,
    black: BLACK,
    blackOpacity: BLACK_OPACITY,
    blackLight: BLACK_LIGHT,
    grey: GREY,
    greyLight: GREY_LIGHT,
    greyLighter: GREY_LIGHTER,
    greyLighterWithoutOpacity: GREY_LIGHTER_WITHOUT_OPACITY,
    indigo: INDIGO,
    indigoLight: INDIGO_LIGHT,
    indigoLighter: INDIGO_LIGHTER,
    white: WHITE,
    whiteOpacity: WHITE_OPACITY,
    whiteOpacityLight: WHITE_OPACITY_LIGHT,
    dark1: DARK_1,
    dark5: DARK_5,
    newOrange: NEW_ORAGE,
  },
  secondary: {
    secondary: SECONDARY,
    deepBlue: DEEP_BLUE,
    blueLight: BLUE_LIGHT,
    gold: GOLD,
    pink: PINK,
    pinkLight: PINK_LIGHT,
    pinkLighter: PINK_LIGHTER,
    nude: NUDE,
    checkboxGreen: CHECKBOX_GREEN,
    imageBackground: IMAGE_BACKGROUND,
    orange: ORANGE,
    cartSelected: CART_SELECTED,
    light: LIGHT,
    lightOrange: LIGHT_ORANGE,
    lightGrey: LIGTH_GREY,
  },
  status: {
    info: INFO,
    infoLight: INFO_LIGHT,
    success: SUCCESS,
    successLight: SUCCESS_LIGHT,
    warning: WARNING,
    warningLight: WARNING_LIGHT,
    danger: DANGER,
    danger2: DANGER2,
    dangerLight: DANGER_LIGHT,
    red: RED,
  },
}

export { theme }
