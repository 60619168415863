import { AppBtnTxt } from "@components/Text/AppText"
import React from "react"

export const FixedRoundedButton = ({
  onClick,
  title,
  style,
  bottom = "2rem",
}) => {
  return (
    <div className="flex self-center">
      <div className=" w-full text-center	" style={{ bottom }}>
        <button
          className="cursor-pointer text-white font-semibold border py-6 w-9/12 lg:w-2/6 md:w-2/6 "
          style={{
            fontSize: 16,
            textTransform: "uppercase",
            borderRadius: "2.4rem",
            backgroundColor: "#000",
            borderColor: "#000",
            ...style,
          }}
          onClick={onClick}
        >
          {title || ""}
        </button>
      </div>
    </div>
  )
}

export const RoundedButton = ({ onClick, title, style }) => {
  return (
    <div className="flex self-center justify-center  ">
      <button
        className="mt-10 cursor-pointer text-white font-semibold border self-end  py-6 w-11/12 lg:w-2/6 md:w-2/6 "
        style={{
          fontSize: 16,
          textTransform: "uppercase",
          borderRadius: "2.4rem",
          backgroundColor: "#000",
          borderColor: "#000",
          ...style,
        }}
        onClick={onClick}
      >
        {title || ""}
      </button>
    </div>
  )
}
export const RoundedButtonV2 = ({
  onClick,
  title,
  style,
  leftIcon,
  rightIcon,
}) => {
  return (
    <button
      className="flex items-center justify-center mt-10 cursor-pointer text-white font-semibold border  py-8 w-11/12 lg:w-2/6 md:w-2/6 "
      style={{
        fontSize: 16,
        textTransform: "capitalize",
        borderRadius: "2.4rem",
        backgroundColor: "#000",
        borderColor: "#000",
        ...style,
      }}
      onClick={onClick}
    >
      {leftIcon ? leftIcon : null}
      <AppBtnTxt>{title || ""}</AppBtnTxt>
      {rightIcon ? rightIcon : null}
    </button>
  )
}
