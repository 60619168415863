import React, { Component } from "react"
import { connect } from "react-redux"

import { LanguageContext } from "./LanguageContext"
import { changeUserLanguage } from "@store/auth/AuthActions"
import { axiosInstance as axios } from "@api/axios"
import english from "./Languages/english"
import spanish from "./Languages/spanish"
import french from "./Languages/french"
import chinese from "./Languages/chinese"
interface OwnProps {
  initialLocale: string
  children?: any
}

interface State {
  locale: string
  messages: any
  menuLanguage: number
  venue: string
}

type Props = OwnProps & ReturnType<any> & typeof mapDispatchToProps

class LanguageProvider extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      locale: this.props.locale || "es",
      messages: spanish,
      menuLanguage: this.props.menuLanguage || 1,
      venue: this.props.venue,
    }
  }

  componentDidMount() {
    const storedMenuLanguage = localStorage.getItem("menuLanguage")
    const storedLocale = localStorage.getItem("locale")
    const defaultLocale = storedLocale || "es"
    let code = this.setLanguageToLocale(defaultLocale)
    if (storedMenuLanguage) {
      this.setState({ menuLanguage: Number(storedMenuLanguage) })
    } else {
      this.changeMenuLanguage(Number(code))
    }
    if (storedLocale) {
      this.chooseLanguage(storedLocale)
    }
  }

  componentDidUpdate(prevProps) {
    const { locale, venue: prevVenue } = prevProps
    const { venue } = this.props
    const storedLocale = localStorage.getItem("locale")

    if (!storedLocale && this.props.locale && locale !== this.props.locale) {
      this.chooseLanguage(this.props.locale)
      let code = this.setLanguageToLocale(this.props.locale)
      this.changeMenuLanguage(Number(code))
    }
  }

  chooseLanguage = (locale) => {
    locale = locale.toLowerCase()
    localStorage.setItem("locale", locale)
    axios.defaults.headers.common["Accept-Language"] = locale

    switch (locale) {
      case "es":
        this.setState({ messages: spanish })
        break
      case "en":
        this.setState({ messages: english })
        break
      case "fr":
        this.setState({ messages: french })
        break
      case "eus":
        this.setState({ messages: spanish })
        break
      case "cat":
        this.setState({ messages: spanish })
        break
      case "it":
        this.setState({ messages: english })
        break
      case "de":
        this.setState({ messages: english })
        break
      case "cn":
        this.setState({ messages: english })
        break
      case "jp":
        this.setState({ messages: english })
        break
      case "pt":
        this.setState({ messages: english })
        break
      case "ru":
        this.setState({ messages: english })
        break
      case "gr":
        this.setState({ messages: english })
        break
      case "zh":
        this.setState({ messages: chinese })
        break
      default:
        break
    }
  }

  changeLocale = (locale, changeOnFrontend) => {
    locale = locale.toLowerCase()
    this.chooseLanguage(locale)

    if (!changeOnFrontend) {
      this.props.changeUserLanguage(locale)
    }
  }

  changeMenuLanguage = (language) => {
    this.setState({ menuLanguage: language })
    localStorage.setItem("menuLanguage", language)
  }

  saveChangedVenue = (venue) => {
    localStorage.setItem("venueChange", venue)
  }

  setLanguageToLocale = (locale = "es") => {
    let code = 1
    switch (locale) {
      case "es":
        code = 1
        break
      case "en":
        code = 2
        break
      case "fr":
        code = 3
        break
      case "zh":
        code = 13
        break
      default:
        code = 1
    }
    return code
  }

  render() {
    const { children } = this.props
    return (
      <LanguageContext.Provider
        value={{
          locale: this.state.locale,
          menuLanguage: this.state.menuLanguage,
          changeMenuLanguage: this.changeMenuLanguage,
          changeLocale: this.changeLocale,
          messages: this.state.messages,
          saveChangedVenue: this.saveChangedVenue,
        }}
      >
        {children}
      </LanguageContext.Provider>
    )
  }
}

const mapStateToProps = ({ venues }) => {
  const clientId = localStorage.getItem("clientId")
  return {
    locale: venues.restaurant.locale,
    venue: clientId ? clientId : "",
  }
}

const mapDispatchToProps = {
  changeUserLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider)
