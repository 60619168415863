import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import PriceButton from "@components/buttons/PriceButton"
import { cartCounter } from "@store/cart/cartHelpers"
import ScrollSpyTabs from "@components/Tabs/ScrolleSpyTabs"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { AccordionCell } from "./AccordionCell"
import {
  arrangeFoodAndDrink,
  getPopularItems,
  getMultiLanguageTitle,
} from "@helpers/utilsHelper"

interface Props {
  category: any
  tabValue: number
  typography: string
  handleMenuItemClick: (item: any) => void
  onClick?: any
  scrollToRef?: (ref: any) => void
  setTabValue?: any
  iconFontSize?: "inherit" | "default" | "small" | "large"
}

const MobileCenter = ({
  category,
  handleMenuItemClick,
  typography,
  onClick,
  iconFontSize,
}: Props) => {
  const cartItems = useSelector(({ cart }: RootState) => cart.cart.items)
  const [selectedValue, setSelectedValue] = useState("1")
  const isCategoryDivide = useSelector(
    ({ menu }: RootState) => menu.menuList.isCategoryDivide,
  )
  const { languageStrings, menuLanguage } = useLanguageHelper()
  const {
    categories,
    popularItems,
    isCategoryDivide: showFoodDivision,
  } = useMemo(
    () => arrangeFoodAndDrink(isCategoryDivide, category, selectedValue),
    [selectedValue, category],
  )

  let content
  if (categories) {
    const { totalItems, totalPrice } = cartCounter(cartItems)
    const getCategories = () => {
      const _result: any = []
      categories.map((cat) => {
        const hasMenuList = !!(cat && cat.menuList && cat.menuList.length)
        if (hasMenuList) {
          _result.push(cat)
        }
      })
      return _result
    }
    const _category = getCategories()
    content = (
      <>
        {_category && Array.isArray(_category) && _category.length ? (
          <>
            <ScrollSpyTabs
              onPopularAdd={handleMenuItemClick}
              setSelectedValue={setSelectedValue}
              popularItems={popularItems}
              showFoodDivision={showFoodDivision}
              tabsInScroll={_category.map((cat) => {
                const _multiTitle = cat?.multiTitle || []
                const _multiDescription = cat?.multiDescription || []
                const catLang = getMultiLanguageTitle(_multiTitle, menuLanguage)
                const catLangDescription = getMultiLanguageTitle(
                  _multiDescription,
                  menuLanguage,
                )
                return {
                  text: catLang?.title || cat.title,
                  heading: catLang?.title || cat.title,
                  description:
                    catLangDescription?.description || cat.description,
                  cateroryId: cat._id,
                  component: (
                    <div>
                      <AccordionCell
                        val={cat}
                        categoryTitle={catLang?.title || cat.title}
                        handleMenuItemClick={handleMenuItemClick}
                        cartItems={cartItems}
                        categoryDescription={
                          catLangDescription?.description || cat.description
                        }
                        iconFontSize={iconFontSize}
                      />
                    </div>
                  ),
                }
              })}
              typography={typography}
            />
          </>
        ) : null}

        {totalItems > 0 && (
          <div className="flex justify-center">
            <div
              className="fixed bottom-2 px-8 w-full"
              style={{ bottom: "2rem" }}
            >
              <PriceButton
                backgroundColor={typography}
                title={languageStrings["GENERAL_PEDIR"]}
                price={totalPrice}
                quantity={totalItems}
                onClick={onClick}
                style={{
                  justifyContent: "space-between",
                  paddingLeft: "0.8rem",
                  paddingRight: "0.8rem",
                }}
              />
            </div>
          </div>
        )}
      </>
    )
  } else {
    content = null
  }

  return content
}

export default MobileCenter
