import React, { FC, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
} from "@material-ui/core"

import classes from "../../modals.module.css"
import { setUniProperty } from "@store/cart/cartActions"
import { Heading5, Body13, Label14 } from "@components/Text/Text"
import { Error } from "@material-ui/icons"
import clsx from "clsx"
import {
  arrangePrice,
  formatImageUrl,
  getMultiLanguageTitle,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import { AddCircle, RemoveCircle } from "@components/addCircle/AddCircle"
import { useBrand } from "@hooks/useBrand"
import { ItemDivider } from "./ItemDivider"
import { theme } from "config/theme"
import { AllergensImages } from "@components/allergensImages/AllergensImages"
import { AppHeading6Medium, AppParagraph4 } from "@components/Text/AppText"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"

interface Props {
  item: ExtraCategory
  typography: string
  onRefChange?: any
  onUpdateComboCategoryProduct?: (c: ExtraCategory) => void
}

interface StyleProps {
  typography: string
}

const hideCheckboxStyles = makeStyles((theme) => ({
  hideCheck: {
    visibility: "hidden",
  },
}))

const ExtraCategoriesMulti: FC<Props> = ({
  item,
  onRefChange,
  onUpdateComboCategoryProduct,
}) => {
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
      "&$checked": {
        color: "orange",
      },
    },
    checked: ({ typography }) => ({
      color: `${typography} !important`,
    }),
  }))
  const {
    title,
    description: itemDescription,
    type: categoryType,
    multiTitle = [],
    multiDescription = [],
  } = item
  const [familyProperty, setFamilyProperty] = useState(item)
  const [disabled, setDisabled] = useState(false)
  const { primaryColor, secondaryColor } = useBrand()
  const sendTried = useSelector(({ cart }: RootState) => cart.newItem.sendTried)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen
  const typography = primaryColor
  const { clientCurrency } = useClientCurrency()
  const styleProps: StyleProps = { typography }
  const matClasses = useStyles(styleProps)
  const hideCheckbox = hideCheckboxStyles()

  const dispatch = useDispatch()
  const { menuLanguage } = useLanguageHelper()

  const categoryLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const categoryLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = categoryLang?.title || title
  const _description = categoryLangDescription?.description || itemDescription

  useEffect(() => {
    setFamilyProperty(item)
  }, [item])

  useEffect(() => {
    if (familyProperty && familyProperty.items) {
      const selectedItems = familyProperty.items.filter(
        (item) => item.selected,
      ).length
      if (
        selectedItems === 0 &&
        item.hasMaxMin &&
        item.minQuantity &&
        item.minQuantity !== 0
      ) {
        const payload = {
          extraCategory: { ...item, hasError: true },
        }
        if (onUpdateComboCategoryProduct) {
          onUpdateComboCategoryProduct({ ...item, hasError: true })
        } else {
          dispatch(setUniProperty(payload))
        }
      }
    }
  }, [])

  const updateMenuExtraCategoryItem = (index) => {
    const newFamilyItems: ExtraCategoryItem[] = familyProperty.items.map(
      (item) => {
        if (
          item.title === familyProperty.items[index].title &&
          item.position === familyProperty.items[index].position
        ) {
          return { ...item, selected: !item.selected }
        }
        return item
      },
    )

    const amountSelected = newFamilyItems.filter((item) => item.selected).length

    const hasError = item.hasMaxMin && item.minQuantity!! > amountSelected

    const familyPropertyUpdated = {
      ...familyProperty,
      hasError,
      items: newFamilyItems,
    }

    const payload = {
      extraCategory: familyPropertyUpdated,
    }

    if (item.maxQuantity !== 0 && item.maxQuantity === amountSelected) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    setFamilyProperty(familyPropertyUpdated)
    if (onUpdateComboCategoryProduct) {
      onUpdateComboCategoryProduct(familyPropertyUpdated)
    } else {
      dispatch(setUniProperty(payload))
    }
  }

  const onClickMultiSelect = (index, isAdd = false) => {
    //HANDLING NEW CHECK SELECTED
    const newFamilyItems: ExtraCategoryItem[] = familyProperty.items.map(
      (item) => {
        if (item.title === familyProperty.items[index].title) {
          let _selectedQuantity = item?.selectedQuantity || 0
          if (isAdd) {
            _selectedQuantity += 1
          } else {
            _selectedQuantity -= 1
          }
          return {
            ...item,
            selected: _selectedQuantity > 0 ? true : false,
            selectedQuantity: _selectedQuantity,
          }
        }
        return { ...item, selectedQuantity: item?.selectedQuantity || 0 }
      },
    )
    let amountSelected = 0

    newFamilyItems.map((item) => {
      if (item.selectedQuantity !== undefined) {
        amountSelected += item.selectedQuantity
      }
    })

    const hasError = item.hasMaxMin && item.minQuantity!! > amountSelected

    const familyPropertyUpdated = {
      ...familyProperty,
      hasError,
      items: newFamilyItems,
    }

    const payload = {
      extraCategory: familyPropertyUpdated,
    }
    if (onUpdateComboCategoryProduct) {
      onUpdateComboCategoryProduct(familyPropertyUpdated)
    } else {
      dispatch(setUniProperty(payload))
    }
    setFamilyProperty(familyPropertyUpdated)
  }
  const isDisabled = (extrraCategoryItem: ExtraCategoryItem) => {
    let totalSelectedQuantity = 0
    const { maxQuantity = 0, minQuantity = 0, multiMax = 0 } = item
    familyProperty.items?.map((item) => {
      totalSelectedQuantity +=
        item && item.selectedQuantity ? item.selectedQuantity : 0
    })
    let isMinusError = false
    let isPlusError = false

    if (multiMax && extrraCategoryItem) {
      const { selectedQuantity = 0 } = extrraCategoryItem
      isPlusError = selectedQuantity >= multiMax
      return { isMinusError, isPlusError }
    }
    if (maxQuantity) {
      isPlusError = totalSelectedQuantity >= maxQuantity
    }
    //if totalSelectedQuantity < minQuantity
    if (minQuantity && totalSelectedQuantity < minQuantity) {
      isMinusError = true
    }

    return { isMinusError, isPlusError }
  }
  const hasErr = item.hasError && sendTried
  return (
    <>
      <div
        className={classes.category_title}
        style={{
          backgroundColor: hasErr ? theme.status.dangerLight : undefined,
        }}
        ref={(ref) => {
          if (hasErr && ref) {
            onRefChange(ref, title)
          }
        }}
      >
        <Heading5
          style={{
            color: hasErr ? theme.status.danger2 : secondaryColor,
          }}
          className={clsx("w-full flex flex-row self-start items-center pl-8")}
        >
          {hasErr ? (
            <Error className="mr-2" style={{ color: theme.status.danger2 }} />
          ) : null}
          {_title}
        </Heading5>
        <Label14
          className="w-full flex flex-row self-start items-center pl-8"
          style={{ paddingLeft: "0.8rem" }}
        >
          {_description}
        </Label14>
      </div>
      {restaurant.accessLevel && restaurant.accessLevel !== "1" ? (
        <div className={[classes.options_items].join(" ")}>
          {familyProperty.items?.map((item: ExtraCategoryItem, index) => {
            const categoryMultiTitle = item?.multiTitle || []
            const categoryLang = getMultiLanguageTitle(
              categoryMultiTitle,
              menuLanguage,
            )
            const _title = categoryLang?.title || item.title
            const disabledFinal = disabled && !item.selected
            const _selectedQuantity =
              item && item.selectedQuantity ? item.selectedQuantity : 0
            const _isDeliverect =
              item && item.isDeliverectMenuItemExtraCategoryItem
                ? item.isDeliverectMenuItemExtraCategoryItem
                : false
            const isQuantitySelect =
              categoryType === "multiselectQuantity" ? true : false
            const isRemoveDiabled =
              isDisabled(item).isMinusError ||
              !_selectedQuantity ||
              _selectedQuantity <= 0
            const isAddDisabled = isDisabled(item).isPlusError

            return (
              <>
                <div>
                  <FormControlLabel
                    labelPlacement="start"
                    className="w-full my-2"
                    classes={matClasses}
                    control={
                      <>
                        {_isDeliverect || isQuantitySelect ? (
                          <div
                            className={
                              "flex justify-end items-center w-1/4 pr-8"
                            }
                            style={{ minWidth: "38%" }}
                          >
                            {item.price ? (
                              <AppParagraph4
                                style={{
                                  color: theme.main.black,
                                  paddingRight: "0.6rem",
                                  whiteSpace: "nowrap",
                                  paddingLeft: "0.6rem",
                                }}
                              >
                                {arrangePrice(item.price).sign}{" "}
                                {arrangePrice(item.price)._price}{" "}
                                {clientCurrency.symbol}
                              </AppParagraph4>
                            ) : (
                              <div style={{}} />
                            )}
                            <RemoveCircle
                              containerStyle={{
                                backgroundColor: isRemoveDiabled
                                  ? theme.main.grey
                                  : typography,
                              }}
                              iconStyle={{ color: theme.main.primary }}
                              onClick={() =>
                                isRemoveDiabled
                                  ? null
                                  : onClickMultiSelect(index, false)
                              }
                              containerClass={
                                excludeOrderType.includes(
                                  venueDetail?.orderType || "",
                                ) ||
                                menuDetail?.isVisible === false ||
                                isClosedKitchen
                                  ? hideCheckbox.hideCheck
                                  : "cursor-pointer text-xs align-middle"
                              }
                              iconFontSize="small"
                            />
                            {!excludeOrderType.includes(
                              venueDetail?.orderType || "",
                            ) && isMenuHide ? (
                              <div className="px-6">{_selectedQuantity}</div>
                            ) : null}
                            <AddCircle
                              containerStyle={{
                                backgroundColor: isAddDisabled
                                  ? theme.main.grey
                                  : typography,
                              }}
                              iconStyle={{ color: theme.main.primary }}
                              onClick={() =>
                                isAddDisabled
                                  ? null
                                  : onClickMultiSelect(index, true)
                              }
                              containerClass={
                                excludeOrderType.includes(
                                  venueDetail?.orderType || "",
                                ) ||
                                menuDetail?.isVisible === false ||
                                isClosedKitchen
                                  ? hideCheckbox.hideCheck
                                  : "cursor-pointer text-xs align-middle"
                              }
                              iconFontSize="small"
                            />
                          </div>
                        ) : (
                          <div
                            className="flex items-center w-1/4 justify-end"
                            style={{ minWidth: "20%" }}
                          >
                            {item.price ? (
                              <AppParagraph4
                                style={{
                                  color: theme.main.black,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {`${arrangePrice(item.price).sign} ${
                                  arrangePrice(item.price)._price
                                }`}{" "}
                                {clientCurrency.symbol}
                              </AppParagraph4>
                            ) : null}
                            <Checkbox
                              disabled={disabledFinal}
                              classes={{
                                root:
                                  excludeOrderType.includes(
                                    venueDetail?.orderType || "",
                                  ) ||
                                  menuDetail?.isVisible === false ||
                                  isClosedKitchen
                                    ? hideCheckbox.hideCheck
                                    : "",
                                checked: matClasses.checked,
                              }}
                              disableRipple
                              onChange={() =>
                                updateMenuExtraCategoryItem(index)
                              }
                            />
                          </div>
                        )}
                      </>
                    }
                    label={
                      <div className={"flex items-center pl-8"}>
                        {item.image ? (
                          <img
                            src={formatImageUrl(item.image)}
                            style={{
                              maxHeight: "100%",
                              objectFit: "cover",
                              borderRadius: 4,
                              width: "2.5rem",
                            }}
                          />
                        ) : null}
                        <AppHeading6Medium
                          style={{
                            marginLeft: item.image ? "0.8rem" : 0,
                            flex: 4,
                          }}
                        >
                          {_title}
                        </AppHeading6Medium>
                      </div>
                    }
                  />
                </div>
                <ItemDivider />
              </>
            )
          })}
        </div>
      ) : (
        <div className={[classes.options_items].join(" ")}>
          {familyProperty.items?.map((item: ExtraCategoryItem, index) => {
            const categoryLangMultiTitle = item?.multiTitle || []
            const categoryLang = getMultiLanguageTitle(
              categoryLangMultiTitle,
              menuLanguage,
            )
            const _title = categoryLang?.title || item.title
            return (
              <Body13
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
                className={classes.title}
              >
                {_title}
                {item.price ? (
                  <span style={{ color: "grey" }}>
                    {arrangePrice(item.price).sign}{" "}
                    {arrangePrice(item.price)._price} {clientCurrency.symbol}
                  </span>
                ) : null}
              </Body13>
            )
          })}
        </div>
      )}
    </>
  )
}

export default ExtraCategoriesMulti
