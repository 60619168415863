import { validateEmail, validateNumber } from "@helpers/utilsHelper"

/**
 * 0 = today
 * 1 = tomorrow
 * 2 = today, tomorrow, and next date from tomorrow
 */
export const DEFAULT_NUMBER_OF_FUTURE_DAYS = 1

export const POWERED_BY_REDIRECTION_LINK = "https://checkqrpay.com/"

export const NOTIFICATION_TYPES = [
  {
    type: "by-email",
    headingLanguageString: "NOTIFIED_VIA_EMAIL",
    subHeadingLanguageString: "SEND_EMAIL_NOTIFICATION",
    placeHolderTextLanguageString: "EMAIL_ADDRESS",
    inputLabelLanguageString: "ENTER_YOUR_EMAIL_ADDRESS",
    validator: validateEmail,
  },
  {
    type: "by-whatsapp",
    headingLanguageString: "NOTIFIED_VIA_WHATSAPP",
    subHeadingLanguageString: "SEND_WHATSAPP_NOTIFICATION",
    placeHolderTextLanguageString: "PHONE_NUMBER",
    inputLabelLanguageString: "ENTER_YOUR_PHONE_NUMBER",
    validator: validateNumber,
  },
  {
    type: "by-sms",
    headingLanguageString: "NOTIFIED_VIA_SMS",
    subHeadingLanguageString: "SEND_SMS_NOTIFICATION",
    placeHolderTextLanguageString: "PHONE_NUMBER",
    inputLabelLanguageString: "ENTER_YOUR_PHONE_NUMBER",
    validator: validateNumber,
  },
]
