import React, { FC } from "react"
import { Label17, Label17Bold } from "@components/Text/Text"

interface Props {
  title?: string
  classes?: string
  onClick?: (e?: any) => void
  disabled?: boolean
  value?: string
  txtColor?: string
  backgroundColor?: string
  color?: string
  style?: any
  icon?: any
  fontSize?: any
}

const RoundedButton: FC<Props> = ({
  title,
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
  color,
  style,
  icon,
  fontSize,
  txtColor,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: disabled ? "#c3c3c3" : backgroundColor,
        color,

        ...style,
      }}
      className={[classes, "cursor-pointer outline-none"].join(" ")}
      value={value}
      disabled={disabled}
    >
      {icon}
      <Label17Bold
        style={{
          fontSize: fontSize ? fontSize : "16px",
          color: txtColor ?? "white",
          fontWeight: "bold",
          fontFamily: "ProductSansBold",
        }}
      >
        {title}
      </Label17Bold>
    </button>
  )
}

export default RoundedButton
