import { SocialButtons } from "@components/SocialPayButtons"
import { PaymentType } from "@helpers/utilsHelper"
import { LocationState, PaymentMethodState, TipState } from "@models/Location"
import { PaymentRequest } from "@stripe/stripe-js"
import React from "react"
import { useSelector } from "react-redux"
import SquareSocialPay from "./SquareSocialPay"

interface Props {
  amount: number
  tableId: string
  venueId: string
  routeState: LocationState
  payload?: any
  isPaid?: string
  tip?: TipState
  show: boolean
  isTipEnabled?: boolean
  paymentRequest?: PaymentRequest
  paymentMethod: PaymentMethodState
  onOpenTipModal?: (val: boolean) => void
  setSelectedPayment?: (method: PaymentType) => void
  isPaymentOnTipModal?: boolean
  setSquareSocialPayLoading?: (val: boolean) => void
}

const SocialPayment = ({
  amount = 0,
  tableId,
  venueId,
  payload,
  isPaid = "false",
  tip,
  show,
  paymentRequest,
  paymentMethod,
  isTipEnabled,
  onOpenTipModal,
  setSelectedPayment,
  isPaymentOnTipModal,
  routeState,
  setSquareSocialPayLoading,
}: Props) => {
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const tipEnabledOnVenue =
    venueDetail && venueDetail.tip === true ? true : false
  const getAmount = () => {
    let _amount = Number(amount)
    if (tip && tip.amount && Number(tip.amount) && tipEnabledOnVenue)
      _amount += Number(tip.amount)
    return _amount.toFixed(2)
  }
  const handleClick = () => {
    if (isTipEnabled) {
      onOpenTipModal?.(true)
    } else {
      let _amount = Number(amount)
      if (tip && tip.amount && Number(tip.amount) && tipEnabledOnVenue)
        _amount += Number(tip.amount)
      paymentRequest?.update({
        total: {
          label: "Virtual Basket Payment",
          amount: Number((_amount * 100).toFixed(0)),
        },
      })
      paymentRequest?.show()
      onOpenTipModal?.(false)
    }
  }
  return venueDetail.squareAccessToken && isPaid != "true" && show ? (
    <div className="t-w-screen">
      <SquareSocialPay
        amount={getAmount()}
        tableId={tableId}
        venueId={venueId}
        payload={payload}
        tip={tip}
        routeState={routeState}
        setSquareSocialPayLoading={setSquareSocialPayLoading}
        paymentMethod={paymentMethod}
      />
    </div>
  ) : paymentRequest && isPaid != "true" && show ? (
    <SocialButtons
      amount={getAmount()}
      type={paymentMethod}
      onClick={handleClick}
      setSelectedPayment={setSelectedPayment}
      isPaymentOnTipModal={isPaymentOnTipModal}
    />
  ) : null
}
export default SocialPayment
