import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import CustomLoader from "@components/Loaders/CustomLoader"
import MobileHeader from "@components/header/MobileHeader"
import { getOrderDetail } from "@store/user/UserActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import ItemsCard from "./components/ItemsCard"

const PendingOrdersDetails = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderId } = props.match.params

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { loading, orderDetail } = useSelector(({ user }: any) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    dispatch(getOrderDetail(orderId))
  }, [])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const onBack = () => {
    history.goBack()
  }
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={languageStrings["PROFILE.ORDER_DETAIL"]}
        typography={typography}
        onBack={onBack}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex flex-col w-full self-center mt-36 mb-8"}>
          <>
            <div className={`flex flex-col w-full`}>
              {orderDetail && orderDetail.items && (
                <ItemsCard items={orderDetail.items} typography={typography} />
              )}
            </div>
          </>
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default PendingOrdersDetails
