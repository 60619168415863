import React from "react"

import { Heading3 } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"

interface Props {
  typography?: string
}

const Header = ({ typography }: Props) => {
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <div
      className={"flex items-center justify-start w-full h-28 pl-8"}
      style={{ backgroundColor: typography }}
    >
      <Heading3 className={"text-white"}>
        {languageStrings["HEADER.ORDER_COMPLETED"]}
      </Heading3>
    </div>
  )
}

export default Header
