import { AppHeading5Bold } from "@components/Text/AppText"
import { formatImageUrl, truncateText } from "@helpers/utilsHelper"
import { useBrand } from "@hooks/useBrand"
import { Card, Grid } from "@material-ui/core"
import { Menu } from "@models/Menu"
import { theme } from "config/theme"
import React, { FC } from "react"
import ArrowMenu from "@assets/arrow_menu.png"

interface IProps {
  item?: Menu
  onClick?: () => void
}
export const MenuModalCard: FC<IProps> = ({ item, onClick }) => {
  const { primaryColor } = useBrand()
  return (
    <Grid className="py-12" item xs={6} sm={6} md={4} lg={3}>
      <Card
        elevation={3}
        onClick={onClick}
        className="h-full w-full pb-8"
        style={{ borderRadius: 10 }}
      >
        <div
          className="overflow-hidden relative"
          style={{
            minHeight: "7.5rem",
            maxHeight: "7.5rem",
            width: "100%",
          }}
        >
          <img
            src={formatImageUrl(item?.image || "")}
            alt="Menu"
            className="w-full h-full object-cover"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </div>
        <div className="flex flex-col justify-center min-h-32 max-h-32 pl-6">
          <AppHeading5Bold style={{ color: theme.main.primary }}>
            {truncateText(item?.title || "", 50)}
          </AppHeading5Bold>
        </div>
        <div
          className="flex p-8 h-16 w-16 justify-center items-center rounded-full mr-6 float-right"
          style={{ backgroundColor: primaryColor }}
        >
          <img
            src={ArrowMenu}
            style={{
              height: "0.62rem",
              width: "0.625rem",
              objectFit: "cover",
            }}
          />
        </div>
      </Card>
    </Grid>
  )
}
