import React, { FC } from "react"
import { Label14 } from "@components/Text/Text"
import { theme } from "config/theme"
import banzzuLogo from "@assets/Images/new_check_logo.png"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { POWERED_BY_REDIRECTION_LINK } from "@helpers/constants"

interface IProps {
  isMenu?: boolean
}
export const MobileFooter: FC<IProps> = ({ isMenu }) => {
  const { languageStrings } = useLanguageHelper()
  const handleClick = (e) => {
    e.preventDefault()
    const url = POWERED_BY_REDIRECTION_LINK
    window.open(`${url}`, "_blank")
  }
  return (
    <div
      className={
        isMenu
          ? "flex flex-col w-full items-center pb-20"
          : "flex flex-col w-full items-center pb-20 px-12 mt-12"
      }
    >
      <Label14
        style={{
          textTransform: "capitalize",
          color: theme.main.black,
          marginBottom: "1.2rem",
        }}
      >
        {languageStrings["MOBILE_ORDER_CONFIRMED.POWERED_BY"]}
      </Label14>
      <a className="cursor-pointer" onClick={handleClick}>
        <img width="70" src={banzzuLogo} alt="logo" />
      </a>
    </div>
  )
}
