import React, { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { useStripe } from "@stripe/react-stripe-js"
import { useHistory } from "react-router-dom"
import TableRight from "@components/tables/tableright/TableRight"
import {
  getRedsysParam,
  setCartPendingPayment,
  submitOrder,
  validateCartItemsAvailability,
} from "@store/cart/cartActions"
import { CartType, ItemOrderMenu } from "@models/Cart"
import CommentsCard from "../CommentsCard"
import VenueCard from "../VenueCard"
import { getUser } from "@store/user/UserActions"
import CartOptionsCard from "@components/optionsCard/CartOptionsCard"
import { setSelectedAddress, selectCard } from "@store/cart/cartActions"
import { userAddressList, getCardList } from "@store/user/UserActions"
import { setSelectedOption } from "@store/venues/VenueActions"
import { clientSetting } from "config"
import { useLanguageHelper } from "@helpers/LanguageHelper"

import {
  getMinimunOrderPrice,
  venueOrderType,
  venueIsBusy,
  handleRouting,
  goToBasketPayment,
  checkPosTableStatus,
} from "@helpers/utilsHelper"
import { toast } from "react-toastify"
import { getDiscountedPrice } from "@store/cart/cartHelpers"

interface Props {
  typography: string
  venueId: string
  restaurant: Restaurant
}

const CheckoutForm = ({ typography, venueId, restaurant }: Props) => {
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const addresses = useSelector(({ user }: any) => user.addresses)
  const cards = useSelector(({ user }: any) => user.cards)
  const menuList = useSelector(({ menu }: RootState) => menu.menuList)
  const { languageStrings } = useLanguageHelper()
  const stripe = useStripe()
  const history = useHistory()

  const { address: selectedAddress, selectedCard } = useSelector(
    ({ cart }: any) => cart,
  )
  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )
  const settings = useSelector(
    ({ venues }: RootState) => venues.restaurant.settings,
  )
  const cart = useSelector(({ cart }: RootState) => cart.cart)

  const cartDetail = useSelector(({ cart }: RootState) => cart)
  const hasRedsys = settings?.redsys ? settings?.redsys : false

  const cartItems = cart.items
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const { _id: userId } = useSelector(({ user }: RootState) => user.user)
  const { user } = useSelector(({ user }: RootState) => user)

  const [comments, setComments] = useState("")
  const [selectedPaymentValue, setSelectedPaymentValue] = useState("withCard")
  const { isVirtualBasket, autoServices } = venueOrderType(venueDetail)
  const serviceFee =
    venueDetail &&
    venueDetail.venueService &&
    venueDetail.venueService.serviceFee &&
    !isVirtualBasket
      ? venueDetail.venueService.serviceFee
      : 0
  const _minimumOrderPrice = Number(
    cartDetail && cartDetail.address && cartDetail.address.minOrderPrice
      ? cartDetail.address.minOrderPrice
      : 0,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (clientSetting.isCheck) {
      dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))
    }
  }, [])
  useEffect(() => {
    if (
      selectedAddress &&
      Object.keys(selectedAddress).length < 1 &&
      addresses &&
      addresses.length > 0 &&
      selectedOption == "2"
    ) {
      const address = addresses.find(
        (address) => address.isValidAddress == true,
      )
      address && dispatch(setSelectedAddress(address))
    }
    if (
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      selectedPaymentValue == "withCard"
    )
      dispatch(selectCard(cards[0].id))
  }, [cards, addresses])

  useEffect(() => {
    const payByCash = restaurant.settings && restaurant.settings.payByCash
    const payByCard = restaurant.settings && restaurant.settings.payByCard

    if (payByCash && !payByCard) {
      setSelectedPaymentValue("withCash")
    }
    if (!payByCash && payByCard) {
      setSelectedPaymentValue("withCard")
    }
  }, [restaurant])

  useEffect(() => {
    let clientId = localStorage.getItem("clientId")
    if (clientId) {
      dispatch(getUser(clientId))
    }
    if (clientSetting.getCardList) {
      dispatch(getCardList())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(venueDetail) && venueDetail && venueDetail._id) {
      if (clientSetting.getCardList) {
        dispatch(
          userAddressList(localStorage.getItem("userId"), venueDetail._id),
        )
      }
    }
  }, [venueDetail])

  const handleAddAddress = () => {
    // setOpenAddress(true)
  }
  const getTotalOrderPrice = () => {
    let totalPrice = 0
    let discountVoucherAmount = 0
    cart.items.map((item: ItemOrderMenu) => {
      totalPrice = totalPrice + Number(item.price) * item.quantity
    })
    if (
      cartDetail &&
      cartDetail.discountAmount &&
      Number(cartDetail.discountAmount)
    ) {
      const _discountedPrice = getDiscountedPrice(
        totalPrice,
        cartDetail.discountApplicable,
        cartDetail.discountType,
        Number(cartDetail.discountAmount),
      )
      if (_discountedPrice && Number(_discountedPrice)) {
        discountVoucherAmount = Number(
          (totalPrice - Number(Number(_discountedPrice).toFixed(2))).toFixed(2),
        )
        totalPrice = Number(Number(_discountedPrice).toFixed(2))
      }
    }
    if (serviceFee) totalPrice += serviceFee

    return { totalPrice, discountVoucherAmount }
  }
  const handleOrderSubmitV2 = async () => {
    if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      orderFromTable &&
      !orderFromTable._id
    ) {
      toast.error(
        languageStrings
          ? languageStrings["NO_TABLE_ID_ERROR"]
          : "Tuvimos un problema identificando tu mesa. Escanea el Codigo QR otra vez",
      )
      return
    }
    const { totalPrice, discountVoucherAmount = 0 } = getTotalOrderPrice()
    let deliveryPrice = 0

    let payload = {
      clientId:
        orderFromTable && orderFromTable.clientId
          ? orderFromTable.clientId
          : null,
      userId,
      price: Number(totalPrice.toFixed(2)),
      discountVoucherAmount,
      items: cart.items,
      type: selectedOption,
      addressId: cartDetail.address._id,
      venueId:
        orderFromTable && orderFromTable.venueId
          ? orderFromTable.venueId
          : null,
      deliveryPrice,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedPaymentValue == "withCard" ? cartDetail.selectedCard : "",
      payByCard: selectedPaymentValue == "withCard" ? true : false,
      discountVoucherId: cartDetail.discountVoucherId,
      payByRedsys:
        hasRedsys && selectedPaymentValue == "withCard" ? true : false,
      isFromSinqro: menuList.isSinqro === true,
    }
    if (
      !payload.clientId ||
      !payload.userId ||
      !payload.orderTable ||
      !payload.venueId
    ) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      return
    }

    if (canBeSubmit()) {
      payload.payByCard = false
      payload.payByRedsys = false
      if (
        totalPrice < 0 &&
        discountVoucherAmount > 0 &&
        cartDetail &&
        cartDetail.discountVoucherId
      ) {
        payload.price = 0
      }

      dispatch(
        submitOrder(payload, stripe, venueDetail.autoServices, languageStrings),
      )
      return
    }
  }

  const handleOrderSubmit = async () => {
    if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      orderFromTable &&
      !orderFromTable._id
    ) {
      toast.error(
        languageStrings
          ? languageStrings["NO_TABLE_ID_ERROR"]
          : "Tuvimos un problema identificando tu mesa. Escanea el Codigo QR otra vez",
      )
      return
    }
    const { totalPrice, discountVoucherAmount = 0 } = getTotalOrderPrice()
    let deliveryPrice = 0
    if (
      selectedOption === CartType.DELIVERY &&
      cartDetail.address &&
      cartDetail.address.minDeliveryPrice
    ) {
      deliveryPrice = Number(cartDetail.address.minDeliveryPrice)
    }
    let payload = {
      clientId:
        orderFromTable && orderFromTable.clientId
          ? orderFromTable.clientId
          : null,
      userId,
      price: Number(totalPrice.toFixed(2)),
      items: cart.items,
      discountVoucherAmount,
      type: selectedOption,
      addressId: cartDetail.address._id,
      venueId:
        orderFromTable && orderFromTable.venueId
          ? orderFromTable.venueId
          : null,
      deliveryPrice,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedPaymentValue == "withCard" ? cartDetail.selectedCard : "",
      payByCard: selectedPaymentValue == "withCard" ? true : false,
      discountVoucherId: cartDetail.discountVoucherId,
      payByRedsys:
        hasRedsys && selectedPaymentValue == "withCard" ? true : false,
      isFromSinqro: menuList.isSinqro === true,
    }
    if (
      !payload.clientId ||
      !payload.userId ||
      !payload.orderTable ||
      !payload.venueId
    ) {
      toast.error(languageStrings["NO_TABLE_ID_ERROR"])
      return
    }

    if (canBeSubmit()) {
      if (!user.phone || user.phone == "") {
        // setPhoneModal(true)/
        return
      }
      if (selectedOption === CartType.ORDER_FROM_TABLE) {
        if (
          totalPrice < 0 &&
          discountVoucherAmount > 0 &&
          cartDetail.discountApplicable
        ) {
          payload.payByCard = false
          payload.payByRedsys = false
          payload.card = ""
          payload.price = 0
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
          return
        } else if (isVirtualBasket) {
          payload.payByCard = false
          payload.payByRedsys = false
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
          return
        } else if (!isVirtualBasket) {
          const isVenuBusy = await venueIsBusy(venueId)
          if (isVenuBusy) {
            toast.error(languageStrings["BUSY_VENUE"])
            return
          }
          if (autoServices) {
            const autoserviceData = {
              payload,
              totalPrice,
              tableId: orderFromTable._id,
              venueId,
            }
            const venueDetails =
              venueDetail && venueDetail.notifications
                ? venueDetail.notifications
                : []
            const { routeData } = handleRouting(autoserviceData, venueDetails)
            history.push(routeData)
          } else {
            const __payload = {
              payload,
              tableId: orderFromTable._id,
              amount: `${totalPrice.toFixed(2)}`,
              venueId,
              total: totalPrice,
            }
            goToBasketPayment(history, __payload)
          }

          return
        } else if (payload.payByRedsys) {
          dispatch(setCartPendingPayment(payload))
          dispatch(getRedsysParam(payload))
        } else {
          dispatch(submitOrder(payload, stripe, autoServices, languageStrings))
        }
      }
    }
  }
  const canBeSubmit = () => {
    if (selectedOption == "2" && cartDetail.address._id == undefined) {
      return false
    }

    return true
  }
  const onAddressClick = (address) => {
    if (address.isValidAddress) dispatch(setSelectedAddress(address))
  }

  const onCardClick = (card) => {
    dispatch(selectCard(card.id))
  }

  const handleAddCard = () => {
    // setShowModal(true)
  }

  const handleRadioChange = (e) => {
    setSelectedPaymentValue(e.target.value)
    if (e.target.value == "withCash") {
      dispatch(selectCard(""))
    }

    if (
      e.target.value == "withCard" &&
      selectedCard == "" &&
      cards &&
      cards.length > 0
    ) {
      dispatch(selectCard(cards[0].id))
    }
  }
  const showPaymentMethods = () => {
    if (selectedOption === CartType.ORDER_FROM_TABLE && isVirtualBasket) {
      return false
    } else if (
      selectedOption === CartType.ORDER_FROM_TABLE &&
      !isVirtualBasket &&
      !clientSetting.showPayByCard
    ) {
      return false
    }
    return clientSetting.showPayByCard
  }
  const checkOrderMethod = async () => {
    if (!isVirtualBasket) {
      const posTable = await checkPosTableStatus(orderFromTable._id)
      if (posTable === true) {
        toast.error(languageStrings["BUSY_TABLE"])
        return null
      }
    }
    if (!menuList._id) {
      toast.error(languageStrings["NO_MENU_ID_ERROR"])
      return
    }
    dispatch(
      validateCartItemsAvailability(
        cartItems,
        orderFromTable?.clientId,
        orderFromTable?.programmedOrderDate,
        menuList._id,
        ({ isAvailable, message }) => {
          if (!isAvailable) {
            toast.error(message)
            return
          }
          if (venueDetail && venueDetail.orderType === "1") {
            handleOrderSubmitV2()
            return
          }

          handleOrderSubmit()
        },
      ),
    )
  }

  return (
    <Box
      className={"flex w-full justify-end mb-8 md:mt-4 md:pb-4 md:pl-4"}
      style={{ marginTop: "4rem" }}
    >
      <Box className={"w-full md:w-1/2 md:mt-2"}>
        <>
          {selectedOption === "1" || selectedOption === "3" ? (
            <VenueCard venueName={venueDetail.title} typography={typography} />
          ) : (
            <CartOptionsCard
              title={languageStrings["ADDRESS.CHOOSE_SHOPPING_ADDRESS"]}
              addTitle={languageStrings["ADDRESS.ADD_ADDRESS"]}
              innerCards={addresses.map((address) => ({
                ...address,
                description:
                  address.address && address.address.length > 85
                    ? address.address.substr(0, 85) + "..."
                    : address.address,
                isValidAddress: address.isValidAddress,
              }))}
              itemClickHandler={onAddressClick}
              addClickHandler={handleAddAddress}
              selectedValue={cartDetail.address._id}
              typography={typography}
              address={true}
            />
          )}
          {showPaymentMethods() && (
            <div className={"mt-12"}>
              <CartOptionsCard
                title={languageStrings["ADDRESS.CHOOSE_PAYMENT_METHOD"]}
                addTitle={languageStrings["ADDRESS.ADD_CARD"]}
                innerCards={
                  cards && cards.length > 0
                    ? cards.map((card) => ({
                        ...card,
                        _id: card.id,
                        title: `**** **** **** ${card.last4}`,
                      }))
                    : []
                }
                itemClickHandler={onCardClick}
                addClickHandler={handleAddCard}
                selectedValue={cartDetail.selectedCard}
                typography={typography}
                paymentOptionCard
                selectedPaymentValue={selectedPaymentValue}
                onRadioChange={handleRadioChange}
              />
            </div>
          )}
          <CommentsCard
            value={comments}
            onValueChange={(v) => {
              setComments(v.currentTarget.value)
            }}
            typography={typography}
          />
        </>
      </Box>
      <TableRight
        serviceFee={serviceFee}
        serviceName={
          venueDetail &&
          venueDetail.venueService &&
          venueDetail.venueService.service &&
          !isVirtualBasket
            ? venueDetail.venueService.service
            : ""
        }
        showDeliveryPrice={true}
        deliveryPrice={Number(
          cartDetail &&
            cartDetail.address &&
            cartDetail.address.minDeliveryPrice
            ? cartDetail.address.minDeliveryPrice
            : 0,
        )}
        minOrderPrice={getMinimunOrderPrice(venueDetail, _minimumOrderPrice)}
        items={cartItems}
        onClick={checkOrderMethod}
        canBeSubmit={!canBeSubmit()}
        checkout
      />
    </Box>
  )
}

export default CheckoutForm
