import React, { FC, useState, useEffect } from "react"
import { Sticky, StickyContainer } from "react-sticky"
import {
  RemoveCircleOutline,
  AddCircleOutline,
  ShoppingCartOutlined,
} from "@material-ui/icons"
import { TextField, makeStyles, Theme } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"

import classes from "./tableRight.module.css"
import Button from "@components/buttons/Button"
import { ItemOrderMenu, CartType } from "@models/Cart"
import { cartCounter, getDiscountedPrice } from "@store/cart/cartHelpers"
import {
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
  applyDiscountVoucher,
} from "@store/cart/cartActions"
import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import {
  Label10,
  Heading2,
  Label14Bold,
  Label13,
  Label17,
  Label14,
} from "@components/Text/Text"
import clsx from "clsx"
import { getClientDetail } from "@store/venues/VenueActions"
import { calculatePrices, getVenueProperties } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { clientSetting } from "config"
import useClientCurrency from "@hooks/useClientCurrency"

interface onTipSelectProps {
  type: number
  amount: number
  percantage: number
}
interface Props {
  deliveryPrice: number
  minOrderPrice: number
  serviceFee?: number
  serviceName?: string
  items?: ItemOrderMenu[]
  onClick?: () => void
  canBeSubmit?: boolean
  checkout?: boolean
  showDeliveryPrice?: boolean
}

interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
}))

const TableRight: FC<Props> = ({
  items,
  onClick,
  deliveryPrice,
  minOrderPrice,
  canBeSubmit,
  checkout,
  showDeliveryPrice = false,
  serviceFee = 0,
  serviceName = "",
}) => {
  const { languageStrings } = useLanguageHelper()

  const [subTotal, setSubTotal] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [discountVoucherCode, setDiscountVoucherCode] = useState("")

  const { restaurant, selectedOption, venueDetail } = useSelector(
    ({ venues }: RootState) => venues,
  )
  const {
    discountApplicable,
    discountAmount,
    discountType,
    discountCode,
    addMoreItems,
    discountQuantity,
    invalidDiscountCode,
    alreadyUsed,
    isVoucherMatchWithOrderType = true,
  } = useSelector(({ cart }: RootState) => cart)
  const isNegative = totalPrice + serviceFee <= 0 && !!discountApplicable
  const { venueHasDiscountVoucher = false } = getVenueProperties(venueDetail)
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const { clientCurrency } = useClientCurrency()
  useEffect(() => {
    const permalink = localStorage.getItem("clientPermalink")
    if (permalink) {
      dispatch(getClientDetail(permalink))
    }
    if (discountCode) {
      dispatch(applyDiscountVoucher(discountVoucherCode))
    }
  }, [])

  useEffect(() => {
    if (discountCode && !discountVoucherCode) {
      setDiscountVoucherCode(discountCode)
    }
  }, [discountCode])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const dispatch = useDispatch()
  useEffect(() => {
    if (items) {
      let { totalPrice } = cartCounter(items)

      let discountedPrice = getDiscountedPrice(
        totalPrice,
        discountApplicable,
        discountType,
        discountAmount,
      )

      let grandTotal = Number(discountedPrice.toFixed(2)) + serviceFee

      if (discountQuantity && discountQuantity > Number(totalPrice)) {
        dispatch(applyDiscountVoucher(discountVoucherCode))
      }
      if (grandTotal < 0 && discountApplicable) {
        if (grandTotal < 0) {
          setTotalPrice(0)
          setSubTotal(0)
        } else {
          setTotalPrice(grandTotal)
        }
      } else {
        setTotalPrice(grandTotal)
        setSubTotal(Number(totalPrice))
      }
    }
  }, [items, deliveryPrice, discountApplicable, serviceFee])

  const decreaseQuantity = (item) => {
    dispatch(decreaseItemAmountOrderPage({ item }))
  }

  const increaseQuantity = (item) => {
    dispatch(increaseItemAmountOrderPage(item))
  }
  const showError = () => clientSetting.isCheck && minOrderPrice
  const getDiscountPrice = () => {
    let discount = ""
    if (discountAmount && Number(discountAmount)) {
      if (discountType === "percentage") {
        discount = ((subTotal * Number(discountAmount)) / 100).toFixed(2)
      } else {
        discount = Number(discountAmount).toFixed(2)
      }
    }
    return discount
  }
  const _isNegative = totalPrice <= 0 && !!discountApplicable
  return (
    <StickyContainer className="w-4/12 lg:w-3/12 mx-12 hidden md:block">
      <Sticky>
        {({ style, isSticky }) => {
          return (
            <div style={style} className={isSticky ? classes.rightSticky : ""}>
              <div className={classes.container}>
                <div className={classes.cart_box}>
                  <div className={classes.cart_items}>
                    <Heading2>
                      {languageStrings["TABLE_RIGHT.YOUR_ORDER"]}
                    </Heading2>
                    <ShoppingCartOutlined />
                  </div>

                  <table>
                    <tbody>
                      {items &&
                        items.map((item, idx) => (
                          <>
                            {idx !== 0 && <hr></hr>}
                            <tr
                              className={[
                                classes.tablerow,
                                "w-full table",
                              ].join(" ")}
                              key={idx}
                            >
                              <td>
                                <RemoveCircleOutline
                                  style={{ color: typography }}
                                  onClick={() => decreaseQuantity(item)}
                                  className="cursor-pointer text-xs align-middle"
                                />
                              </td>
                              <td>
                                <Label14Bold
                                  className={[
                                    classes.quantity,
                                    "overflow-hidden whitespace-no-wrap",
                                  ].join(" ")}
                                >
                                  {item.quantity + " x"}
                                </Label14Bold>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Label17>{item.title}</Label17>
                                </div>
                                {item &&
                                  item.extraCategories &&
                                  item.extraCategories.length > 0 && (
                                    <ul className="p-4 m-0 ml-12">
                                      {item.extraCategories.map(
                                        ({ title, items }) => {
                                          if (
                                            hasFamilyPropertyItemSelected(items)
                                          ) {
                                            return (
                                              <>
                                                <Label13>{title}</Label13>
                                                {items.map(
                                                  ({
                                                    selected,
                                                    title,
                                                    selectedQuantity = 0,
                                                  }) => {
                                                    if (selected) {
                                                      let quantity = ""
                                                      if (selectedQuantity > 0)
                                                        quantity = `x ${selectedQuantity}`
                                                      return (
                                                        <li
                                                          style={{
                                                            color: typography,
                                                          }}
                                                        >
                                                          <Label10>
                                                            {title} {quantity}
                                                          </Label10>
                                                        </li>
                                                      )
                                                    }
                                                    return null
                                                  },
                                                )}
                                              </>
                                            )
                                          }
                                        },
                                      )}
                                    </ul>
                                  )}
                              </td>
                              <td>
                                <AddCircleOutline
                                  style={{ color: typography }}
                                  onClick={() => increaseQuantity(item)}
                                  className="cursor-pointer text-xs align-middle"
                                />
                              </td>
                              <td>
                                <Label14Bold className={classes.price}>
                                  {item.price.toFixed(2)}
                                  {clientCurrency.symbol}
                                </Label14Bold>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                  {/* <hr />
                  <div className={classes.options}>
                    <div className={classes.options_item}>
                      <label>
                        <input
                          type="radio"
                          value=""
                          checked
                          name="option_2"
                          className="icheck"
                        />
                        Delivery
                      </label>
                    </div>
                    <div className={classes.options_item}>
                      <label>
                        <input
                          type="radio"
                          value=""
                          name="option_2"
                          className="icheck"
                        />
                        Take Away
                      </label>
                    </div>
                  </div> */}

                  {checkout && venueHasDiscountVoucher && (
                    <>
                      <hr />
                      <Label14>
                        {languageStrings["TABLE_RIGHT.DO_YOU_A_DISCOUNT_CODE"]}
                      </Label14>

                      <div className="flex flex-row">
                        <TextField
                          label={
                            <Label17>
                              {languageStrings["TABLE_RIGHT.DISCOUNT_CODE"]}
                            </Label17>
                          }
                          name="discountVoucherCode"
                          value={discountVoucherCode}
                          className={clsx(Classes.root, "w-full")}
                          onChange={(e) =>
                            setDiscountVoucherCode(e.target.value)
                          }
                        />

                        <Button
                          title={languageStrings["TABLE_RIGHT.APPLY_TEXT"]}
                          backgroundColor={typography}
                          classes={
                            "flex items-center justify-center self-center w-1/3 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
                          }
                          style={{
                            borderRadius: "25px",
                            fontSize: "8px",
                            bottom: 30,
                          }}
                          onClick={() =>
                            dispatch(
                              applyDiscountVoucher(
                                discountVoucherCode,
                                selectedOption,
                                venueDetail._id,
                              ),
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-row">
                        {addMoreItems && (
                          <Label13
                            className={"text-red"}
                          >{`${languageStrings["TABLE_RIGHT.THE_DISCOUNT_CODE_IS_CORRECT_BUT_THE_MINIMUM_AMOUNT_TO_REQUEST_IS_IT"]} ${discountQuantity}${clientCurrency.symbol}`}</Label13>
                        )}
                        {!isVoucherMatchWithOrderType &&
                          !addMoreItems &&
                          !invalidDiscountCode &&
                          !alreadyUsed && (
                            <Label13 className={"text-red"}>
                              {
                                languageStrings[
                                  "TABLE_RIGHT.COUPONS_NOT_AVAILABLE_FOR_THIS_ORDER_TYPE"
                                ]
                              }
                            </Label13>
                          )}
                        {invalidDiscountCode && (
                          <Label13 className={"text-red"}>
                            {
                              languageStrings[
                                "TABLE_RIGHT.THE_DISCOUNT_CODE_DOSE_NOT_EXIST"
                              ]
                            }
                          </Label13>
                        )}
                        {alreadyUsed && (
                          <Label13 className={"text-red"}>
                            {
                              languageStrings[
                                "TABLE_RIGHT.THE_DISCOUNT_CODE_ALREADY_USE"
                              ]
                            }
                          </Label13>
                        )}
                      </div>
                    </>
                  )}

                  <hr />

                  <div className={classes.items_total}>
                    {(selectedOption == CartType.DELIVERY || showError()) &&
                    items?.length != 0 &&
                    totalPrice < minOrderPrice &&
                    subTotal < minOrderPrice &&
                    showDeliveryPrice ? (
                      <div className={classes.totals}>
                        <Label14Bold className={"text-red"}>
                          {languageStrings["TABLE_RIGHT.MINIMUM_ORDER"]}
                        </Label14Bold>
                        <Label14Bold className="pull-left text-red">
                          {minOrderPrice} {clientCurrency.symbol}
                        </Label14Bold>
                      </div>
                    ) : null}
                    <div className={classes.totals}>
                      <Label14Bold>
                        {languageStrings["TABLE_RIGHT.SUB_TOTAL"]}
                      </Label14Bold>
                      <Label14Bold className="pull-left">
                        {subTotal} {clientCurrency.symbol}
                      </Label14Bold>
                    </div>
                    {serviceName && serviceFee ? (
                      <div className={classes.totals}>
                        <Label14Bold>{serviceName}</Label14Bold>
                        <Label14Bold className="pull-left">
                          {serviceFee} {clientCurrency.symbol}
                        </Label14Bold>
                      </div>
                    ) : null}
                    {checkout && discountApplicable && (
                      <div className={classes.totals}>
                        <Label14Bold className="text-red">
                          {languageStrings["TABLE_RIGHT.DISCOUNT"]}{" "}
                          {discountAmount}
                          {discountType === "percentage"
                            ? "%"
                            : `${clientCurrency.symbol}`}
                        </Label14Bold>
                        <Label14Bold className="pull-left text-red">
                          -{getDiscountPrice()} {clientCurrency.symbol}
                        </Label14Bold>
                      </div>
                    )}

                    {showDeliveryPrice &&
                      selectedOption &&
                      selectedOption == "2" &&
                      deliveryPrice > 0 && (
                        <div className={classes.totals}>
                          <Label14Bold>
                            {languageStrings["TABLE_RIGHT.HOME_DELIVERY"]}
                          </Label14Bold>
                          <Label14Bold className="pull-left">
                            {deliveryPrice} {clientCurrency.symbol}
                          </Label14Bold>
                        </div>
                      )}
                    <div className={classes.grand_total}>
                      <Heading2>
                        {languageStrings["TABLE_RIGHT.TOTAL"]}
                      </Heading2>
                      <span className="pull-left">
                        {totalPrice} {clientCurrency.symbol}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className={classes.buttonContainer}>
                    <Button
                      disabled={
                        items?.length === 0 ||
                        ((selectedOption == CartType.DELIVERY || showError()) &&
                          totalPrice < minOrderPrice &&
                          !discountApplicable &&
                          !isNegative) ||
                        canBeSubmit ||
                        (totalPrice <= 0 && !discountApplicable)
                      }
                      backgroundColor={typography}
                      classes={classes.button}
                      title={languageStrings["TABLE_RIGHT.MAKE_AN_ORDER"]}
                      onClick={onClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Sticky>
    </StickyContainer>
  )
}

export default TableRight
