import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import CustomLoader from "@components/Loaders/CustomLoader"
import MobileCheckoutForm from "@modules/basket/MobileCheckoutForm"
import BackButton from "@components/backButton/BackButton"
import { useLanguageHelper } from "@helpers/LanguageHelper"

const BasketPage = (props) => {
  const { venueId } = props.match.params
  const { languageStrings } = useLanguageHelper()

  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const userLoading = useSelector(({ user }: RootState) => user.loading)
  const authLoading = useSelector(({ auth }: RootState) => auth.loading)
  const venueLoading = useSelector(({ venues }: RootState) => venues.loading)
  const cartLoading = useSelector(({ cart }: RootState) => cart.loading)

  const loading = userLoading || venueLoading || cartLoading || authLoading

  const [stripePromise] = useState<Promise<Stripe | null>>(
    loadStripe(restaurant.stripePublicKey),
  )
  return (
    <CustomLoader loading={loading}>
      <Elements stripe={stripePromise}>
        <BackButton backTitle={languageStrings["ORDER_CONFIRMATION"]}>
          <MobileCheckoutForm venueId={venueId} />
        </BackButton>
      </Elements>
    </CustomLoader>
  )
}

export default BasketPage
