import React from "react"
import { PinDrop } from "@material-ui/icons"
import Button from "@material-ui/core/Button"
import { useDispatch } from "react-redux"
import PersonIcon from "@material-ui/icons/Person"
import { useHistory } from "react-router-dom"

import placeHolder from "@assets/Images/menuplaceholder.png"
import { Heading1, Label15 } from "@components/Text/Text"
import classes from "@modules/venueDetails/venuedetails.module.css"
import { Menu, Venue } from "@models/Venue"
import { formatImageUrl, useTranslatedTextV2 } from "@helpers/utilsHelper"

import bgHeader from "@assets/backgroundimage.jpg"

import { clientSetting } from "config"

interface Props {
  restaurant: Restaurant
  isMobile?: boolean
  typography?: string
  userId?: string
  venueDetail: Venue
  venueId: string
  showLogin?: boolean
  showLanguage?: boolean
  onLoginClick?: () => void
  menuDetails?: any
}

const MainHeader = ({
  restaurant,
  isMobile,
  typography,
  userId,
  venueDetail,
  venueId,
  showLogin,
  showLanguage,
  onLoginClick,
  menuDetails,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const _txt = useTranslatedTextV2({ index: 0, defaultTxt: "Acceder" })

  const handleProfile = () => {
    const pathname = window.location.pathname
    if (userId && pathname != "/profile") {
      history.push("/profile")
    }
  }
  const _descriptionLength = isMobile ? 50 : 220
  const handleMap = () => {
    if (
      clientSetting.searchByAddress &&
      venueDetail.contactInfo &&
      venueDetail.contactInfo.address
    ) {
      window.open(
        `https://maps.google.com/?q=${venueDetail.contactInfo.address}`,
      )
    } else if (
      venueDetail.contactInfo &&
      clientSetting.searchByAddress === false
    ) {
      window.open(
        `https://maps.google.com/?q=${venueDetail.contactInfo.latitude},${venueDetail.contactInfo.longitude}`,
      )
    }
  }
  const headerImage =
    restaurant && restaurant.settings && restaurant.settings.headerImage
      ? `url('${formatImageUrl(
          restaurant.settings.headerImage,
        )}') no-repeat center`
      : `url(${bgHeader})`
  return (
    <>
      <div
        style={{
          background: headerImage,
          backgroundColor: "transparent",
        }}
        className={classes.header}
      >
        <div
          style={{
            backgroundColor: "transparent",
            height: "10rem",
          }}
          className={`${classes.centered} `}
        >
          {/* <LanguagesList
          show={showLanguage}
          onlanguagechange={(ev, preLang, currentLang) => {
            ev.preventDefault()
            dispatch(setMenuLanguage(preLang, currentLang, q))
          }}
        /> */}
          {restaurant.accessLevel &&
            restaurant.accessLevel !== "1" &&
            showLogin &&
            clientSetting.showRegistration && (
              <Button
                color="primary"
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: isMobile ? "0.2rem" : "1.5rem",
                  color: "white",
                }}
                onClick={userId ? handleProfile : onLoginClick}
              >
                {userId ? <PersonIcon style={{ fontSize: 28 }} /> : _txt}
              </Button>
            )}

          <div
            style={{
              alignSelf: clientSetting.showHeaderLogo
                ? "flex-start"
                : "flex-end",
              padding:
                menuDetails &&
                menuDetails.description &&
                menuDetails.description.length > _descriptionLength
                  ? 0
                  : "0 0%",
            }}
            className={classes.sub_content}
          >
            {clientSetting.showHeaderLogo && (
              <div className={classes.image}>
                <img
                  className="object-contain"
                  src={
                    restaurant.logo
                      ? formatImageUrl(restaurant.logo)
                      : placeHolder
                  }
                  alt={restaurant.title ? restaurant.title : ""}
                />
              </div>
            )}

            {clientSetting.showHeaderAddress ? (
              <>
                <Heading1 className="opacity-0">{venueDetail.title}</Heading1>
                <div className={classes.address} onClick={handleMap}>
                  <Label15>
                    <PinDrop />
                    {venueDetail.contactInfo && venueDetail.contactInfo.address}
                  </Label15>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {menuDetails &&
        menuDetails?.description &&
        menuDetails?.description.length && (
          <div
            style={{
              padding: "10px 5px",
              textAlign:
                menuDetails &&
                menuDetails.description &&
                menuDetails.description.length > _descriptionLength
                  ? "left"
                  : "center",

              backgroundColor:
                menuDetails &&
                menuDetails.description &&
                menuDetails.description.length
                  ? "#ffffff"
                  : "transparent",

              width: isMobile ? "100vw" : "100%",
            }}
          >
            <Label15
              style={{
                color:
                  menuDetails &&
                  menuDetails.description &&
                  menuDetails.description.length
                    ? "#000000"
                    : "transparent",
              }}
            >
              {menuDetails?.description}
            </Label15>
          </div>
        )}
    </>
  )
}

export default MainHeader
