import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Card from "@material-ui/core/Card"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import moment from "moment"

import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import { OutlinedInput, Typography } from "@material-ui/core"
import {
  applyDiscountVoucher,
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
} from "@store/cart/cartActions"
import {
  calculatePrices,
  formatImageUrl,
  getTaxes,
  venueOrderType,
  getMultiLanguageTitle,
} from "@helpers/utilsHelper"
import {
  Label13,
  Label13Light,
  Label9Light,
  Label14Light,
} from "@components/Text/Text"
import Button from "@components/buttons/Button"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { theme } from "config/theme"
import { useBrand } from "@hooks/useBrand"
import { AmountChip } from "./components/AmountChip"
import { Remove } from "@material-ui/icons"
import {
  AppHeading1Bold,
  AppHeading4Medium,
  AppHeading5Bold,
  AppParagraph4,
  AppParagraph5,
} from "@components/Text/AppText"
import { DATE_TIME_FORMATS } from "@helpers/Formates/dateTime"
import useClientCurrency from "@hooks/useClientCurrency"

const commonStyles = {
  className: "flex flex-row w-full items-center justify-between mt-4",
}
const commonTextStyles = {
  className: "w-2/4 text-right",
}

const ItemCard = ({
  items,
  total,
  subTotal,
  discountQuantity,
  alreadyUsed,
  deliveryPrice,
  selectedOption,
  addMoreItems,
  invalidDiscountCode,
  discountVoucherCode,
  setDiscountVoucherCode,
  discountApplicable,
  discountType,
  hasDiscountVoucher,
  discountAmount,
  isVoucherMatchWithOrderType = true,
  minOrderPrice = 0,
  cartPrice,
  setOpenProgramModal,
  showChangedOrderTime = false,
  promotionPrice = 0,
}) => {
  const { languageStrings, menuLanguage } = useLanguageHelper()
  const { clientCurrency } = useClientCurrency()
  const dispatch = useDispatch()
  const { primaryColor, secondaryColor, thirdColor } = useBrand()
  const typography = primaryColor
  const taxDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { taxId, venueService } = taxDetail || {}
  const { taxType, taxPercentage, totalTax } = getTaxes(taxId)
  const _quantity = items?.length || 0
  const { isVirtualBasket } = venueOrderType(taxDetail)
  const { programmedOrderDate } = useSelector(
    ({ orderFromTable }: any) => orderFromTable,
  )
  const formatHour = programmedOrderDate
    ? moment(programmedOrderDate).format(DATE_TIME_FORMATS.TIME.DEFAULT)
    : null
  const _itemTxt =
    _quantity <= 1 ? languageStrings["ITEM"] : languageStrings["ITEMS"]
  const getDiscountAmount = () => {
    let _discountAmount = 0
    if (discountType === "percentage") {
      _discountAmount = (subTotal * Number(discountAmount)) / 100
    } else {
      _discountAmount = Number(discountAmount)
    }
    return Number(
      parseFloat(_discountAmount.toFixed(2)) < subTotal
        ? _discountAmount.toFixed(2)
        : subTotal,
    )
  }
  const getPrices = () => {
    const _discountAmount = getDiscountAmount()
    const prices = calculatePrices(
      promotionPrice,
      totalTax,
      venueService,
      taxDetail,
      _discountAmount,
      cartPrice,
    )
    return prices
  }

  const renderComboCard = (combo: Combo) => {
    return (
      <div key={combo._id}>
        {combo.comboCategories?.map((comboCategory) => (
          <div key={comboCategory._id}>
            {comboCategory.products
              ?.filter((product) => product.selected)
              .map((product) => (
                <div key={product._id}>
                  <Typography variant="subtitle2">{product.title}</Typography>
                  {product.extraCategories &&
                    product.extraCategories.length > 0 &&
                    product.extraCategories.map(
                      ({ title, items, multiTitle }) => {
                        if (hasFamilyPropertyItemSelected(items)) {
                          return (
                            <>
                              {items.map(
                                ({
                                  title,
                                  selected,
                                  selectedQuantity = 0,
                                  multiTitle,
                                }) => {
                                  const extraItemLang = getMultiLanguageTitle(
                                    multiTitle ?? [],
                                    menuLanguage,
                                  )
                                  const _extraItemTitle =
                                    extraItemLang?.title || title
                                  let quantity = ""
                                  if (selectedQuantity > 0)
                                    quantity = `x ${selectedQuantity}`
                                  if (selected) {
                                    return (
                                      <Typography
                                        style={{
                                          color: "#c3c3c3",
                                        }}
                                        variant="body2"
                                      >
                                        {_extraItemTitle} {quantity}
                                      </Typography>
                                    )
                                  }
                                  return null
                                },
                              )}
                            </>
                          )
                        }
                        return null
                      },
                    )}
                </div>
              ))}
          </div>
        ))}
      </div>
    )
  }
  const totalWithDelivery = (
    getPrices()._total +
    deliveryPrice -
    getDiscountAmount()
  ).toFixed(2)
  const totalPrice = (getPrices()._total - getDiscountAmount()).toFixed(2)

  return (
    <Card
      elevation={0}
      className={
        "flex flex-col self-center justify-start w-full mt-20 mb-10 bg-white rounded-4"
      }
    >
      <div
        className="flex flex-row items-center w-full"
        style={{ backgroundColor: theme.main.blackOpacity }}
      >
        <AppHeading5Bold
          style={{
            marginTop: 8,
            marginBottom: 8,
            marginLeft: "1rem",
            marginRight: 14,
            color: secondaryColor,
          }}
        >
          {languageStrings["ITEMS_DETAILS.ORDER"]}
        </AppHeading5Bold>
        <AppParagraph5 style={{ color: theme.main.black }}>
          {items?.length || 0} {_itemTxt}
        </AppParagraph5>
      </div>

      <>
        <div className="w-full">
          {items.map((item) => {
            const _multiTitle = item?.multiTitle || []
            const itemLang = getMultiLanguageTitle(_multiTitle, menuLanguage)
            const _title = itemLang?.title || item.title
            const promotionPrice =
              item.price * (item.quantity - (item.discountedQuantity || 0))
            const actualPrice = item.price * item.quantity
            const isPricePromoted = promotionPrice < actualPrice
            return (
              <>
                <div className={"flex flex-row w-full px-8 pt-8 min-h-52"}>
                  {item.image && (
                    <img
                      className="object-cover rounded"
                      src={formatImageUrl(item.image)}
                      alt={item.title}
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  )}
                  <div className={"flex flex-row w-full justify-between"}>
                    <div className={"flex flex-col ml-2"}>
                      <AppHeading4Medium>{_title}</AppHeading4Medium>
                      {item?.isCombo
                        ? renderComboCard(item)
                        : item.extraCategories &&
                          item.extraCategories.length > 0 &&
                          item.extraCategories.map(
                            ({ title, items, multiTitle }) => {
                              const extraItemLang = getMultiLanguageTitle(
                                multiTitle ?? [],
                                menuLanguage,
                              )
                              const _extraItemTitle =
                                extraItemLang?.title || title

                              if (hasFamilyPropertyItemSelected(items)) {
                                return (
                                  <>
                                    <Typography variant="subtitle2">
                                      {_extraItemTitle}
                                    </Typography>
                                    {items.map(
                                      ({
                                        title,
                                        selected,
                                        selectedQuantity = 0,
                                        multiTitle,
                                      }) => {
                                        const extraItemLang =
                                          getMultiLanguageTitle(
                                            multiTitle ?? [],
                                            menuLanguage,
                                          )
                                        const _extraItemTitle =
                                          extraItemLang?.title || title
                                        let quantity = ""
                                        if (selectedQuantity > 0)
                                          quantity = `x ${selectedQuantity}`
                                        if (selected) {
                                          return (
                                            <Typography
                                              style={{
                                                color: "#c3c3c3",
                                              }}
                                              variant="body2"
                                            >
                                              {_extraItemTitle} {quantity}
                                            </Typography>
                                          )
                                        }
                                        return null
                                      },
                                    )}
                                  </>
                                )
                              }
                              return null
                            },
                          )}
                      <div className="flex">
                        <AppParagraph4
                          style={{
                            color: theme.main.primary,
                            marginBottom: "0.6rem",
                            marginTop: "0.2rem",
                          }}
                        >
                          {item.price.toFixed(2)} {clientCurrency.symbol}
                        </AppParagraph4>
                        {item && item.discountedQuantity > 0 ? (
                          <AppParagraph4
                            style={{
                              color: "red",
                              marginBottom: "0.6rem",
                              marginTop: "0.2rem",
                              marginLeft: "0.25rem",
                            }}
                          >
                            {` (${item.discountedQuantity} ${languageStrings["FOR_FREE"]})`}
                          </AppParagraph4>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={
                        "flex flex-col items-center justify-center h-full p-1 pl-2 pb-12"
                      }
                    >
                      <div className="flex flex-col h-full items-center justify-center">
                        <div
                          className={
                            "flex justify-center items-center rounded-full"
                          }
                          style={{
                            backgroundColor: thirdColor,
                            height: "1.4rem",
                            width: "1.4rem",
                          }}
                        >
                          <AddIcon
                            fontSize="small"
                            style={{
                              color: theme.main.primary,
                            }}
                            onClick={() => {
                              dispatch(increaseItemAmountOrderPage(item))
                            }}
                          />
                        </div>

                        <div
                          className={
                            "flex justify-center items-center rounded-full my-6"
                          }
                          style={{
                            backgroundColor: typography,
                            height: "1.4rem",
                            width: "1.4rem",
                          }}
                        >
                          <Label14Light style={{ color: theme.main.primary }}>
                            {item.quantity}
                          </Label14Light>
                        </div>
                        <div
                          className={
                            "flex justify-center items-center rounded-full"
                          }
                          style={{
                            backgroundColor:
                              theme.main.greyLighterWithoutOpacity,
                            height: "1.4rem",
                            width: "1.4rem",
                          }}
                        >
                          <Remove
                            fontSize="small"
                            style={{
                              color: theme.main.primary,
                            }}
                            onClick={() =>
                              dispatch(decreaseItemAmountOrderPage({ item }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"flex self-center h-px mb-4"}
                  style={{ width: "100%", backgroundColor: theme.main.grey }}
                />
              </>
            )
          })}
        </div>
        {hasDiscountVoucher && (
          <div className="mt-12" style={{}}>
            <AppHeading4Medium
              style={{ marginLeft: "1rem", marginBottom: "1.2rem" }}
            >
              {languageStrings["BASKET.DO_YOU_HAVE_A_DISCOUNT_CODE"]}
            </AppHeading4Medium>

            <div className="flex self-center justify-evenly items-center w-full mt-6 mb-5">
              <OutlinedInput
                id="outlined-adornment-weight"
                labelWidth={0}
                className="w-4/6 h-24"
                value={discountVoucherCode}
                onChange={(e) => setDiscountVoucherCode(e.target.value)}
              />

              <Button
                disabled={!discountVoucherCode}
                title={languageStrings["BASKET.APPLY"]}
                backgroundColor={
                  discountVoucherCode ? typography : theme.main.grey
                }
                txtColor={
                  discountVoucherCode ? secondaryColor : theme.main.black
                }
                classes={
                  "flex items-center justify-center self-center w-1/4 h-24 text-white  border border-transparent cursor-pointer outline-none"
                }
                style={{
                  borderRadius: "8px",
                  fontSize: "8px",
                  bottom: 30,
                  color: discountVoucherCode
                    ? secondaryColor
                    : theme.main.black,
                }}
                onClick={() =>
                  dispatch(
                    applyDiscountVoucher(
                      discountVoucherCode,
                      selectedOption,
                      taxDetail._id,
                    ),
                  )
                }
              />
            </div>
            <div className="flex flex-row ml-3">
              {addMoreItems && (
                <Label13
                  className={"text-red"}
                >{`${languageStrings["BASKET.THE_DISCOUNT_CODE_IS_CURRECT_BUT_MINIMUM_AMOUNT_TO_REQUEST_IS_IT"]} ${discountQuantity}`}</Label13>
              )}
              {!isVoucherMatchWithOrderType &&
                !addMoreItems &&
                !invalidDiscountCode &&
                !alreadyUsed && (
                  <Label13 className={"text-red"}>
                    {
                      languageStrings[
                        "BASKET.COUPON_NOT_AVAILABLE_FOR_THIS_TYPE_OF_ORDER"
                      ]
                    }
                  </Label13>
                )}
              {invalidDiscountCode && (
                <Label13 className={"text-red"}>
                  {languageStrings["BASKET.THE_DISCOUNT_CODE_DOES_NOT_EXIST"]}
                </Label13>
              )}
              {alreadyUsed && (
                <Label13 className={"text-red"}>
                  {languageStrings["BASKET.DISCOUNT_CODE_ALREADY_USED"]}
                </Label13>
              )}
            </div>
          </div>
        )}
        {programmedOrderDate && showChangedOrderTime && (
          <div className="ml-12 mt-4 mb-4 w-4/5">
            <Label13Light className={"text-red"}>
              {languageStrings["PICKUP_TIME_CHANGE_MESSAGE"]}
            </Label13Light>
          </div>
        )}
        <div className="flex justify-between items-center">
          {programmedOrderDate ? (
            <Card
              variant="outlined"
              className="flex mt-4 ml-8 p-4 min-h-50 max-h-50 w-1/2 relative justify-center"
              style={{
                backgroundColor: "#EFE4B9",
                borderRadius: 10,
              }}
            >
              <div className="flex flex-col">
                <div
                  className="flex items-center justify-between"
                  onClick={() => setOpenProgramModal(true)}
                >
                  <div className={"mr-4"}>
                    <Label13Light className={"text-black"}>
                      {languageStrings["PICKUP_TIME"]}
                    </Label13Light>
                  </div>
                  <div className={"ml-4"}>
                    <Label9Light className={"text-black"}>
                      {languageStrings["PICKUP_TIME_CHANGE"]}
                      <EditIcon style={{ fontSize: "7px" }} />
                    </Label9Light>
                  </div>
                </div>
                <div className="flex self-center mt-4">
                  <AppHeading1Bold>{formatHour}</AppHeading1Bold>
                </div>
              </div>
            </Card>
          ) : (
            <div className="flex mt-4 ml-8 p-4 min-h-50 max-h-50 w-1/2 relative " />
          )}
          <div
            className={
              "flex flex-col my-4 max-w-100 items-end justify-between mr-8 self-end flex-grow"
            }
          >
            {minOrderPrice ? (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={`text-red ${commonTextStyles.className}`}
                heading={languageStrings["MINIMUM_ORDER"]}
                value={minOrderPrice + ` ${clientCurrency.symbol}`}
                txtColor={theme.status.red}
                width={"w-3/5"}
              />
            ) : null}

            <AmountChip
              containerClassName={commonStyles.className}
              headingClassName={commonTextStyles.className}
              heading={languageStrings["BASKET.BASE_PRICE"]}
              value={
                (getPrices().netPrice || 0).toFixed(2) +
                ` ${clientCurrency.symbol}`
              }
              width={"w-3/5"}
            />

            {venueService &&
            venueService.service &&
            getPrices().netServiceFee &&
            isVirtualBasket !== true ? (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={commonTextStyles.className}
                heading={venueService.service}
                value={
                  (getPrices().netServiceFee || 0).toFixed(2) +
                  ` ${clientCurrency.symbol}`
                }
                width={"w-3/5"}
              />
            ) : null}
            {taxPercentage !== 0 ? (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={commonTextStyles.className}
                heading={`${taxType} (${taxPercentage}%)`}
                value={
                  (getPrices().taxApplied || 0).toFixed(2) +
                  ` ${clientCurrency.symbol}`
                }
                width={"w-3/5"}
              />
            ) : null}
            {hasDiscountVoucher && discountApplicable ? (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={commonTextStyles.className}
                heading={`${
                  languageStrings["BASKET.DISCOUNT"]
                } ${discountAmount} ${
                  discountType === "percentage"
                    ? "%"
                    : ` ${clientCurrency.symbol}`
                }`}
                value={`-${getDiscountAmount()} ${clientCurrency.symbol}`}
                txtColor={theme.status.red}
                width={"w-3/5"}
              />
            ) : null}
            {promotionPrice < total ? (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={commonTextStyles.className}
                heading={`${languageStrings["BASKET.DISCOUNT"]}`}
                value={`${(total - promotionPrice).toFixed(2)} ${
                  clientCurrency.symbol
                }`}
                txtColor={theme.status.red}
                width={"w-3/5"}
              />
            ) : null}
            {selectedOption && selectedOption == "2" && deliveryPrice > 0 && (
              <AmountChip
                containerClassName={commonStyles.className}
                headingClassName={commonTextStyles.className}
                heading={languageStrings["BASKET.HOME_DELIVERY"]}
                value={`${deliveryPrice.toFixed(2)} ${clientCurrency.symbol}`}
                width={"w-3/5"}
              />
            )}
            <AmountChip
              heading={languageStrings["GENERAL.TOTAL"]}
              headingClassName={commonTextStyles.className}
              containerClassName={commonStyles.className}
              value={
                deliveryPrice > 0 && selectedOption && selectedOption == "2"
                  ? totalWithDelivery + ` ${clientCurrency.symbol}`
                  : totalPrice + ` ${clientCurrency.symbol}`
              }
              width={"w-3/5"}
            />
          </div>
        </div>
      </>
      <div className="flex bg-white min-h-40 max-h-40" />
    </Card>
  )
}

export default ItemCard
