import RoundedButton from "@components/buttons/Button"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  makeStyles,
} from "@material-ui/core"
import React, { FC, useState } from "react"
import CustomModal from "./CustomModal"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { AppHeading2 } from "@components/Text/AppText"
import { theme } from "config/theme"
import { ItemDivider } from "@modules/menuitem/mobile/components/ItemDivider"
import { useBrand } from "@hooks/useBrand"
import { useSelector } from "react-redux"
import {
  addSpacesToCreditCardNumber,
  maskCardNumber,
} from "@helpers/utilsHelper"

interface StyleProps {
  typography?: string
  mainNew?: string
}
interface Props {
  showModal: boolean
  onCloseModal: () => void
  onNewCardPress: () => void
  onCardSelect: (value: string) => void
}
export const CardSelectionModal: FC<Props> = ({
  showModal,
  onCloseModal,
  onNewCardPress,
  onCardSelect,
}) => {
  const { primaryColor, secondaryColor } = useBrand()
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "0.6rem",
    },
    rootForm: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
    },
    checked: (props) => ({
      color: `${props.mainNew} !important`,
    }),
    focused: {
      color: "black !important",
    },
  }))
  const styleProps: StyleProps = {
    typography: primaryColor,
    mainNew: theme.main.newOrange,
  }
  const classes = useStyles(styleProps)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  const { languageStrings } = useLanguageHelper()
  const userCards = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.userCards,
  )
  const data = userCards && userCards.length ? userCards : []
  const [value, setValue] = useState("")
  const _isDisabled = !value
  return (
    <>
      <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
        <div
          className={"flex flex-col bg-white py-12 rounded w-11/12 lg:w-1/3"}
        >
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="mb-12 ml-8"
            >
              <AppHeading2 style={{ color: theme.main.primary }}>
                {languageStrings["BASKET.CHOOSE_CARD"]}
              </AppHeading2>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {data.map((item) => {
                const cardNumber = maskCardNumber(item.cardNumber)
                return (
                  <>
                    <FormControlLabel
                      style={{
                        marginLeft: "1.2rem",
                        marginBottom: "0.6rem",
                      }}
                      value={item.cardNumber}
                      control={
                        <Radio
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={cardNumber}
                    />
                    <ItemDivider />
                  </>
                )
              })}
            </RadioGroup>
            <div className="flex  flex-col items-center justify-evenly">
              <RoundedButton
                disabled={_isDisabled}
                title={languageStrings["USE_CARD"]}
                txtColor={_isDisabled ? theme.main.black : theme.main.white}
                style={{
                  backgroundColor: _isDisabled
                    ? theme.main.grey
                    : theme.main.newOrange,
                  borderColor: "transparent",
                  height: "3rem",
                  marginTop: "20px",
                  borderRadius: "50px",
                  width: "90%",
                  alignSelf: "center",
                }}
                onClick={() => {
                  const _value = addSpacesToCreditCardNumber(value)
                  onCardSelect(_value)
                }}
              />
              <RoundedButton
                title={languageStrings["ADD_NEW_CARD"]}
                style={{
                  borderColor: "transparent",
                  height: "3rem",
                  marginTop: "20px",
                  borderRadius: "50px",
                  width: "90%",
                  alignSelf: "center",
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.main.newOrange}`,
                }}
                txtColor={theme.main.newOrange}
                onClick={() => {
                  onNewCardPress()
                  onCloseModal()
                }}
              />
            </div>
          </FormControl>
        </div>
      </CustomModal>
    </>
  )
}
