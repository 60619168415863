import React from "react"
import { Provider } from "react-redux"
import { Router, Route, Switch } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import HomePage from "@modules/homePage/HomePage"
import VenueDetails from "@modules/venueDetails/VenueDetails"
import { store, persistor } from "@store"
import history from "@history"
import BasketPage from "./@modules/mainBasket/BasketPage"
import MyAddressPage from "@modules/basket/MyAddressPage"
import OrderConfirmed from "./@components/OrderConfirmed/OrderConfirmedPage"
import PaymentPage from "@modules/payment/PaymentPage"
import CardsListPage from "@modules/payment/CardsListPage"
import ProfilePage from "./@modules/profile/ProfilePage"
import UpdateProfilePage from "@modules/profile/MobileProfile/UpdateProfile/UpdateProfilePage"
import ChangePasswordPage from "./@modules/profile/MobileProfile/ChangePassword/ChangePasswordPage"
import MyOrdersPage from "./@modules/profile/MobileProfile/MyOrders/MyOrdersPage"
import MyOrderDetailPage from "@modules/profile/MobileProfile/MyOrders/MyOrderDetailPage"
import ForgotPasswordPage from "./@modules/forgotPassword/ForgotPasswordPage"
import Layout from "@layouts/Layout"
import ReactGA from "react-ga"
import MobileAddressNamePage from "@modules/basket/MobileAddress/MobileAddressNamePage"
import MobileAddressDetailsPage from "@modules/basket/MobileAddress/MobileAddressDetailsPage"
import MobileAddressMapPage from "@modules/basket/MobileAddress/MobileAddressMapPage"
import RedsysFormPage from "@modules/payment/RedsysFormPage"
import RedsysSuccessPage from "@modules/payment/RedsysSuccessPage"
import RedsysErrorPage from "@modules/payment/RedsysErrorPage"
import VirtualBasket from "@modules/VirtualBasket/VirtualBasket"
import MyVirtualBasket from "@modules/VirtualBasket/components/MyVirtualBasket"
import BasketPayment from "@modules/VirtualBasket/components/PaymentScreen"
import SocialPayments from "@modules/SocialPayment/SoicalPayments.js"
import { LanguageProvider } from "@helpers/LanguageHelper"
import MobileOrderNotified from "@components/OrderConfirmed/MobileOrderNotified"
import PendingOrdersDetails from "@modules/profile/MobileProfile/MyOrders/PendingOrderDetails"
import NotificationType from "@modules/Autoservice/NotificationType"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { RatingScreen } from "@modules/rating/Rating"
import { clarity } from "react-microsoft-clarity"
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY || "")
clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID as string)

const App = () => {
  history.listen((location) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
      ReactGA.pageview(location.pathname + location.search)
    }
  })

  return (
    <Provider store={store}>
      <LanguageProvider initialLocale="es">
        <ToastContainer />
        <Elements stripe={stripePromise}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Layout>
                <Switch>
                  <Route
                    exact
                    path="/pending-order-details/:orderId?"
                    component={PendingOrdersDetails}
                  />
                  <Route
                    exact
                    path="/orderConfirmed"
                    component={OrderConfirmed}
                  />
                  <Route exact path="/rating" component={RatingScreen} />

                  <Route exact path="/add-card" component={PaymentPage} />
                  <Route
                    exact
                    path="/NotificationType"
                    component={NotificationType}
                  />
                  <Route
                    exact
                    path="/order-notified/:type?/:orderId?"
                    component={MobileOrderNotified}
                  />
                  <Route
                    exact
                    path="/SocialPayment"
                    component={SocialPayments}
                  />
                  <Route
                    exact
                    path="/BasketPayment/:isPaid?"
                    component={BasketPayment}
                  />

                  <Route exact path="/cards-list" component={CardsListPage} />
                  <Route
                    exact
                    path="/:permalink/virtualbasket/:tableId?/:venueId?"
                    component={VirtualBasket}
                    fbasket
                  />
                  <Route
                    exact
                    path="/:permalink/virtualbasketdetails/:tableId?/:venueId?"
                    component={MyVirtualBasket}
                  />
                  <Route
                    exact
                    path="/update-profile"
                    component={UpdateProfilePage}
                  />
                  <Route exact path="/my_orders" component={MyOrdersPage} />
                  <Route
                    exact
                    path="/change-Password"
                    component={ChangePasswordPage}
                  />
                  <Route
                    exact
                    path="/mobileAddressNamePage"
                    component={MobileAddressNamePage}
                  />
                  <Route
                    exact
                    path="/mobileAddressDetailsPage"
                    component={MobileAddressDetailsPage}
                  />
                  <Route
                    exact
                    path="/mobileAddressMapPage"
                    component={MobileAddressMapPage}
                  />
                  <Route
                    exact
                    path="/:permalink/forgot-password/:token"
                    component={ForgotPasswordPage}
                  />

                  <Route
                    exact
                    path="/profile/:orderId?"
                    component={ProfilePage}
                  />

                  <Route
                    exact
                    path="/order-details/:id"
                    component={MyOrderDetailPage}
                  />
                  <Route
                    exact
                    path="/redsysFormPage"
                    component={RedsysFormPage}
                  />
                  <Route
                    exact
                    path="/redsysError/:venueId?"
                    component={RedsysErrorPage}
                  />
                  <Route
                    exact
                    path="/redsysSuccess/:orderId?"
                    component={RedsysSuccessPage}
                  />
                  <Route
                    exact
                    path="/:permalink/:tableId?"
                    component={HomePage}
                  />
                  <Route
                    exact
                    path="/menu/myAddress/:venueId"
                    component={MyAddressPage}
                  />
                  <Route
                    exact
                    path="/menu/basket/:venueId"
                    component={BasketPage}
                  />
                  <Route
                    exact
                    path="/:permalink/menu/:id/:menuId?/:tableId?"
                    component={VenueDetails}
                  />
                </Switch>
              </Layout>
            </Router>
          </PersistGate>
        </Elements>
      </LanguageProvider>
    </Provider>
  )
}

export default App
