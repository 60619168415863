import { VenuePopupActionTypes } from "@store/redux/actionTypes"

const INITIAL_STATE: VenuePopupState = {
  loading: true,
  venueId: "",
  venuePopupActive: false,
  popupImage: "",
  visitedUser: [],
  popupCheck: false,
}

const VenuePopupReducer = (state: VenuePopupState = INITIAL_STATE, action) => {
  switch (action.type) {
    case VenuePopupActionTypes.ADD_DEVICE_POPUP: {
      return {
        ...state,
        loading: true,
        venueId: "",
        venuePopupActive: false,
        popupImage: "",
        visitedUser: [],
      }
    }
    case VenuePopupActionTypes.ADD_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        venueId: action.payload.venueId,
        venuePopupActive: action.payload.popupActive,
        popupImage: action.payload.popupImage,
        visitedUser: action.payload.visitedUser,
      }
    }

    case VenuePopupActionTypes.GET_VENUE_POPUP: {
      return {
        ...state,
        loading: true,
        venueId: "",
        venuePopupActive: false,
        popupImage: "",
        visitedUser: [],
      }
    }
    case VenuePopupActionTypes.GET_VENUE_POPUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        venueId: action.payload.venueId,
        venuePopupActive: action.payload.popupActive,
        popupImage: action.payload.popupImage,
        visitedUser: action.payload.visitedUser,
      }
    }

    case VenuePopupActionTypes.GET_VENUE_POPUP_FAIL: {
      return { ...state, loading: false }
    }

    case VenuePopupActionTypes.ADD_DEVICE_FAIL: {
      return { ...state, loading: false }
    }

    case VenuePopupActionTypes.SET_SHOW_LANGUAGE_POPUP: {
      return { ...state, popupCheck: action.payload }
    }

    default: {
      return state
    }
  }
}
export default VenuePopupReducer
