import { AppHeading6Bold } from "@components/Text/AppText"
import { theme } from "config/theme"
import React, { FC } from "react"
import Check from "@assets/extra/flag_check.png"
interface IProps {
  imgSrc?: string
  name?: string
  isSelected?: boolean
}
export const ItemChip: FC<IProps> = ({ imgSrc, name, isSelected }) => {
  return (
    <div className="flex flex-col w-84 " style={{ padding: "0.2rem 0" }}>
      <div className="flex w-full">
        <div className="flex w-full pb-6 ">
          <img src={imgSrc} alt="ES" height={15} width={25} />
          <AppHeading6Bold
            style={{ color: theme.main.primary, marginLeft: "0.4rem" }}
          >
            {name}
          </AppHeading6Bold>
        </div>
        {isSelected ? (
          <img
            src={Check}
            style={{
              height: "0.629rem",
              width: "0.797rem",
              objectFit: "cover",
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
