import React from "react"
import _ from "lodash"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { TableCell } from "@material-ui/core"

export const orderStatuses = [
  {
    id: 5,
    name: "ORDER_STATUS.DELIVERED",
    color: "#FFF",
    bgColor: "#1F9D55",
  },

  {
    id: 11,
    name: "PARTIAL_DELIVERY",
    color: "#FFF",
    bgColor: "#F2D",
  },
  {
    id: 12,
    name: "IN_PROGRESS",
    color: "#FFF",
    bgColor: "#38C172",
  },
  {
    id: 13,
    name: "PENDIGN_ORDERS",
    color: "#FFF",
    bgColor: "#E3342F",
  },
]

const OrdersStatus = (props) => {
  const _orderStatuses = orderStatuses
  const status = _.find(_orderStatuses, { id: Number(props.status) })
  const { languageStrings } = useLanguageHelper()
  const { name = "" } = status || {}

  return <TableCell align="center">{languageStrings[name]}</TableCell>
}

export default OrdersStatus
