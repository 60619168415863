import React, { CSSProperties } from "react"

import classes from "./text.module.css"

// HEADINGS

interface TextProps {
  className?: string
  children: any
  style?: CSSProperties
  onClick?: (e: any) => void
}

export function Heading1({ className, children, style }: TextProps) {
  return (
    <h1 style={style} className={[classes.heading1, className].join(" ")}>
      {children}
    </h1>
  )
}

export function Heading2(props: TextProps) {
  return (
    <h2 {...props} className={[classes.heading2, props.className].join(" ")}>
      {props.children}
    </h2>
  )
}

export function Heading3(props: TextProps) {
  return (
    <h3 {...props} className={[classes.heading3, props.className].join(" ")}>
      {props.children}
    </h3>
  )
}

export function Heading4(props: TextProps) {
  return (
    <h4 {...props} className={[classes.heading4, props.className].join(" ")}>
      {props.children}
    </h4>
  )
}

export function Heading5({ className, children, style, onClick }: TextProps) {
  return (
    <h4
      onClick={onClick}
      style={style}
      className={[classes.heading5, className].join(" ")}
    >
      {children}
    </h4>
  )
}
export function Heading5Bold({
  className,
  children,
  style,
  onClick,
}: TextProps) {
  return (
    <h4
      onClick={onClick}
      style={style}
      className={[classes.heading5Bold, className].join(" ")}
    >
      {children}
    </h4>
  )
}

export function Heading6(props) {
  return <h6 {...props} className={[classes.heading6, props.className]} />
}
// BODY

export function Body20(props) {
  return <p {...props} style={[textStyles.body20, props.style]} />
}

export function Body20Bold(props) {
  return <p {...props} style={[textStyles.body20Bold, props.style]} />
}

export function Body17(props) {
  return <p {...props} style={[textStyles.body17, props.style]} />
}

export function Body17Bold(props) {
  return <p {...props} style={[textStyles.body17Bold, props.style]} />
}
export function Body16(props) {
  return (
    <p {...props} className={[classes.body16, props.className].join(" ")}>
      {props.children}
    </p>
  )
}

export function Body16Bold(props) {
  return <p {...props} style={[textStyles.body16Bold, props.style]} />
}
export function Body15(props) {
  return (
    <p className={[classes.body15, props.className].join(" ")}>
      {props.children}
    </p>
  )
}

export function Body15Bold(props) {
  return (
    <p {...props} className={[classes.body15Bold, props.className].join(" ")}>
      {props.children}
    </p>
  )
}
export function Body14(props) {
  return (
    <p
      style={props.style}
      className={[classes.body14, props.className].join(" ")}
    >
      {props.children}
    </p>
  )
}
export function Body13(props) {
  return (
    <p
      {...props}
      style={props.style}
      className={[classes.body13, props.className].join(" ")}
    >
      {props.children}
    </p>
  )
}

export function Body11(props) {
  return <p {...props} style={[textStyles.body11, props.style]} />
}

// LABEL

export function Label50(props) {
  return <span {...props} style={[textStyles.label50, props.style]} />
}
export function Label22(props) {
  return <span {...props} className={[classes.label22, props.className]} />
}
export function Label19(props) {
  return (
    <span {...props} className={[classes.label19, props.className].join(" ")}>
      {props.children}
    </span>
  )
}
export function Label19Bold(props) {
  return (
    <span
      {...props}
      className={[classes.label19Bold, props.className].join(" ")}
    >
      {props.children}
    </span>
  )
}
export function Label17({ children, className, style }: TextProps) {
  return (
    <span style={style} className={[classes.label17, className].join(" ")}>
      {children}
    </span>
  )
}
export function Label17Bold(props) {
  return (
    <span
      {...props}
      className={[classes.label17Bold, props.className].join(" ")}
    ></span>
  )
}
export function Label15Bold(props) {
  return (
    <span
      {...props}
      className={[classes.label15Bold, props.className].join(" ")}
    ></span>
  )
}
export function Label15(props) {
  return (
    <span {...props} className={[classes.label15, props.className].join(" ")}>
      {props.children}
    </span>
  )
}
export function Label15Light({ className, children }: TextProps) {
  return (
    <span className={[classes.label15Light, className].join(" ")}>
      {children}
    </span>
  )
}
export function Label14({ style, className, children, onClick }: TextProps) {
  return (
    <span
      style={style}
      onClick={onClick}
      className={[classes.label14, className].join(" ")}
    >
      {children}
    </span>
  )
}
export function Label14Bold({ style, className, children }: TextProps) {
  return (
    <span className={[classes.label14Bold, className].join(" ")} style={style}>
      {children}
    </span>
  )
}
export function Label14Light({ className, children, style }: TextProps) {
  return (
    <span style={style} className={[classes.label14Light, className].join(" ")}>
      {children}
    </span>
  )
}
export function Label13({ className, children }: TextProps) {
  return (
    <span className={[classes.label13, className].join(" ")}>{children}</span>
  )
}
export function Label13Light({ className, children, style }: TextProps) {
  return (
    <span style={style} className={[classes.label13Light, className].join(" ")}>
      {children}
    </span>
  )
}
export function Label12(props) {
  return <span {...props} style={[textStyles.label12, props.style]} />
}
export function Label12Light(props) {
  return <span {...props} style={[textStyles.label12Light, props.style]} />
}
export function Label11(props) {
  return <span {...props} style={[textStyles.label11, props.style]} />
}
export function Label11Light(props) {
  return <span {...props} style={[textStyles.label11Light, props.style]} />
}
export function Label10({ className, children, style }: TextProps) {
  return (
    <span style={style} className={[classes.label10, className].join(" ")}>
      {children}
    </span>
  )
}
export function Label10Light(props) {
  return <span {...props} style={[textStyles.label10Light, props.style]} />
}

export function Label9Light({ className, children, style }: TextProps) {
  return (
    <span style={style} className={[classes.label9Light, className].join(" ")}>
      {children}
    </span>
  )
}

export const textStyles = {
  heading1: {
    fontSize: 34,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  heading2: {
    fontSize: 28,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  heading3: {
    fontSize: 22,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  heading4: {
    fontSize: 20,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  heading5: {
    fontSize: 16,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  heading6: {
    fontSize: 14,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  body20: {
    fontSize: 20,
    fontFamily: "ProductSansMedium",
  },
  body20Bold: {
    fontSize: 20,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  body16: {
    fontSize: 16,
    fontFamily: "ProductSansMedium",
  },
  body16Bold: {
    fontSize: 16,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  body17: {
    fontSize: 17,
    fontFamily: "ProductSansMedium",
  },
  body17Bold: {
    fontSize: 17,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  body15: {
    fontSize: 15,
    fontFamily: "ProductSansMedium",
  },
  body15Bold: {
    fontSize: 15,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  body14: {
    fontSize: 14,
    fontFamily: "ProductSansMedium",
  },
  body11: {
    fontSize: 11,
    fontFamily: "ProductSansMedium",
  },
  label50: {
    fontSize: 50,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label22: {
    fontSize: 22,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },

  label19Bold: {
    fontSize: 19,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label17Bold: {
    fontSize: 17,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label15Bold: {
    fontSize: 15,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label15: {
    fontSize: 15,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label13Light: {
    fontSize: 13,
    fontFamily: "ProductSansMedium",
  },
  label12Light: {
    fontSize: 12,
    fontFamily: "ProductSansMedium",
  },
  label12: {
    fontSize: 12,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label11Light: {
    fontSize: 11,
    fontFamily: "ProductSansMedium",
  },
  label11: {
    fontSize: 11,
    fontFamily: "ProductSansBold",
    fontWeight: "bold",
  },
  label10Light: {
    fontSize: 10,
    fontFamily: "ProductSansMedium",
  },
  label9Light: {
    fontSize: 9,
    fontFamily: "ProductSansMedium",
  },
}
