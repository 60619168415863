import React from "react"
import { PaymentType } from "@helpers/utilsHelper"
import { theme } from "config/theme"
import GPayBlack from "@assets/google_pay_icon_black.png"
import GPayWhite from "@assets/google_pay_icon_white.png"
import ApplePayImgBlack from "@assets/apple_pay_icon_black.png"
import ApplePayImgWhite from "@assets/apple_pay_icon_white.png"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { ButtonIconV2 } from "@components/buttons/ButtonIcon"

interface Props {
  onClick: (type: PaymentType) => any
  type: PaymentType
  setSelectedPayment?: (method: PaymentType) => void
  isPaymentOnTipModal?: boolean
}

export const SocialPayButton = ({
  onClick,
  type,
  setSelectedPayment,
  isPaymentOnTipModal,
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const _imgSrc =
    type === PaymentType.GOOGLE
      ? GPayWhite
      : PaymentType.APPLE
      ? ApplePayImgWhite
      : undefined
  const socialPaymentLogo =
    type === PaymentType.GOOGLE
      ? GPayBlack
      : PaymentType.APPLE
      ? ApplePayImgBlack
      : undefined
  const text = type === PaymentType.GOOGLE ? "Google Pay" : "Apple Pay"

  return isPaymentOnTipModal ? (
    <ButtonIconV2
      style={styles.btnContainer}
      onClick={() => {
        onClick(type)
      }}
      title={`${languageStrings["PAY_WITH"]}`}
      rightIcon={<img src={_imgSrc} style={styles.img} />}
      textStyle={{ color: theme.main.white }}
    />
  ) : (
    <div
      style={{
        height: "15vh",
      }}
      className="border-solid border border-custom-grey w-full rounded-lg mx-1"
    >
      <button
        onClick={() => {
          onClick(type)
          setSelectedPayment?.(type)
        }}
        className="w-full h-full p-0 border-0 rounded-lg"
      >
        <div className="flex h-3/5 justify-center items-center">
          {socialPaymentLogo && (
            <img src={socialPaymentLogo} className="w-3/4 max-w-9vh" />
          )}
        </div>
        <div className="h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
          <h3>{text}</h3>
        </div>
      </button>
    </div>
  )
}

const styles = {
  btnContainer: {
    backgroundColor: theme.main.dark1,
    justifyContent: "center",
  },
  img: { height: "1.5rem", width: "4rem", marginLeft: "0.3rem" },
}
