import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Theme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import { resetPassword, validateToken } from "@store/auth/AuthActions"

import classes from "@modules/homePage/homePage.module.css"
import { formatImageUrl } from "@helpers/utilsHelper"
import placeHolder from "@assets/Images/menuplaceholder.png"
import bg from "@assets/bg.jpg"
import { useLanguageHelper } from "@helpers/LanguageHelper"
interface StyleProps {
  typography?: string
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    background: `url(${bg})`,
    height: "100vh",
  }),
}))
const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    marginTop: "1.5rem",
    "& label.Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: props.typography,
      },
    },
  }),
}))
const ForgotPasswordPage = (props) => {
  const { permalink, token } = props.match.params
  const dispatch = useDispatch()
  const { isValidToken, userId } = useSelector(({ auth }: RootState) => auth)
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)

  const [error, setError] = useState("")
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  useEffect(() => {
    dispatch(validateToken(token))
  }, [])
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  useEffect(() => {
    if (password && password.length < 7) {
      setError(
        languageStrings[
          "FORGET_PASSWORD.PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS"
        ],
      )
      return
    }
    if (confirmPassword && confirmPassword.length < 7) {
      setError(
        languageStrings[
          "FORGET_PASSWORD.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS"
        ],
      )
      return
    }
    if (password && confirmPassword && password !== confirmPassword) {
      setError(
        languageStrings[
          "FORGET_PASSWORD.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME"
        ],
      )
      return
    }
    setError("")
    return
  }, [password, confirmPassword])
  const isFormValid = () => {
    return (
      password &&
      password.length >= 7 &&
      confirmPassword &&
      confirmPassword.length >= 7 &&
      password == confirmPassword
    )
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    dispatch(resetPassword({ userId, password }, permalink))
  }
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)
  return (
    <div
      className={clsx(
        Classes.root,
        "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-10",
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <Card className="w-full max-w-384">
          <CardContent className="flex flex-col items-center justify-center p-32">
            <img
              className={[classes.nav_image, "object-contain"].join(" ")}
              src={
                restaurant.logo ? formatImageUrl(restaurant.logo) : placeHolder
              }
              alt={restaurant.title}
            />
            <Typography variant="h6" className="mt-16 mb-32">
              {isValidToken
                ? languageStrings["FORGET_PASSWORD.RESET_YOUR_PASSWORD"]
                : languageStrings["FORGET_PASSWORD.THE_TOKEN_HAS_EXPIRED"]}
            </Typography>
            {isValidToken && (
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit}
              >
                <TextField
                  className={TextFieldClasses.root}
                  label={languageStrings["FORGET_PASSWORD.NEW_PASSWORD"]}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.currentTarget.value)
                  }}
                  variant="outlined"
                  required
                  fullWidth
                />
                <TextField
                  className={TextFieldClasses.root}
                  label={languageStrings["FORGET_PASSWORD.CONFIRM_PASSWORD"]}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.currentTarget.value)
                  }}
                  variant="outlined"
                  required
                  fullWidth
                />
                {error && (
                  <div className="flex justify-center text-red text-center">
                    {error}
                  </div>
                )}

                <Button
                  variant="contained"
                  className="w-11/12 mx-auto mt-16"
                  aria-label={languageStrings["FORGET_PASSWORD.LOGIN"]}
                  disabled={!isFormValid()}
                  type="submit"
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    alignSelf: "center",
                    backgroundColor: !isFormValid() ? "#e0e0e0" : typography,
                  }}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
export default ForgotPasswordPage
