import React, { useEffect, useState } from "react"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentDevicePendingOrders } from "@store/orderFromTable/OrderFromTableActions"
import { getTotalVenuesPrice } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useBrand } from "@hooks/useBrand"

interface Props {
  onClick?: () => void
  venueId?: string
  typography?: string
}
export const SingleOrderAlert = ({ onClick, venueId }: Props) => {
  const { primaryColor } = useBrand()
  const { orderFromTable: tableDetail, currentDevicePendingList = [] } =
    useSelector(({ orderFromTable }: RootState) => orderFromTable)
  const dispatch = useDispatch()
  const { languageStrings } = useLanguageHelper()
  const [width, setWidth] = useState(window.innerWidth)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const isMobile = width <= 600
  useEffect(() => {
    if (venueId && tableDetail && tableDetail._id) {
      dispatch(getCurrentDevicePendingOrders(venueId, tableDetail._id))
    }
  }, [tableDetail, venueId])
  if (!currentDevicePendingList?.length) {
    return null
  }
  return null
}
