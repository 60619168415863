import React from "react"

import { Heading5, Label14Bold } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"

export interface Props {
  referenceNo: string
  price: number
}

const TopHeader = ({ referenceNo, price }: Props) => {
  const { languageStrings, changeLanguage } = useLanguageHelper()
  const { clientCurrency } = useClientCurrency()
  return (
    <div className={"flex flex-row w-full bg-white p-8"}>
      <div className={"flex flex-col items-center w-1/2"}>
        <Label14Bold style={{ color: "#b3b0b0" }}>
          {languageStrings["PROFILE.N_ORDER"]}
        </Label14Bold>
        <Heading5 style={{ marginTop: 5 }}>{referenceNo}</Heading5>
      </div>
      <div style={{ borderLeft: "1px solid #b3b0b0" }}></div>
      <div className={"flex flex-col items-center w-1/2"}>
        <Label14Bold style={{ color: "#b3b0b0" }}>
          {languageStrings["TOTAL"]}
        </Label14Bold>
        <Heading5 style={{ marginTop: 5 }}>
          {price.toFixed(2) + `${clientCurrency.symbol}`}
        </Heading5>
      </div>
    </div>
  )
}

export default TopHeader
