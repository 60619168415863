import React, { useEffect } from "react"
import { Card } from "@material-ui/core"
import CustomModal from "@components/modals/CustomModal"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { makeStyles } from "@material-ui/core/styles"
import { useBrand } from "@hooks/useBrand"
import { AppHeading3Medium } from "@components/Text/AppText"
import { languagesListData } from "@modules/venueDetails/languageData"
import { useSelector } from "react-redux"

interface Props {
  open: boolean
  close: () => void
  languages: string[]
}

const LanguagePopupModal = ({
  open = false,
  close = () => {},
  languages = [],
}: Props) => {
  const { languageStrings, changeLanguage, changeMenuLang } =
    useLanguageHelper()
  const { primaryColor } = useBrand()
  const availableLanguages = languagesListData.filter((language) =>
    languages.includes(language.name),
  )
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const defaultLanguage = restaurant.defaultLanguage || "Spanish"
  const useStyles = makeStyles({
    title: { textAlign: "left", marginTop: 20 },
    container: {
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      minWidth: "100%",
      maxHeight: "50%",
      width: 300,
    },
    button: {
      borderRadius: "25px",
    },
    noFocusBorder: {
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
    },

    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(75px, 1fr))",
      gridAutoFlow: "dense",
      padding: 2,
      maxWidth: 305,
      marginTop: 20,
      justifyContent: "center",
    },
    card: {
      display: "flex",
      height: "100%",
      width: "100%",
      marginBottom: 20,
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
    },
  })

  const classes = useStyles()

  useEffect(() => {
    const brandLanguage = localStorage.getItem("brandLanguage")
    if (brandLanguage) {
      changeLanguage(brandLanguage, true)
    }
  }, [])

  return (
    <CustomModal
      showModal={open}
      onCloseModal={close}
      backdropClickDisabled={true}
    >
      <div className={`${classes.container} ${classes.noFocusBorder}`}>
        <Card className=" items-center justify-center cursor-pointer text-center pb-1">
          <AppHeading3Medium
            className={classes.title}
            style={{ textAlign: "left", margin: "15px", marginTop: "25px" }}
          >
            {languageStrings["SELECT_LANGUAGE"]}
          </AppHeading3Medium>
          <div className={classes.gridContainer}>
            {availableLanguages.map((item) => (
              <div
                key={item.id}
                className={classes.card}
                onClick={(e) => {
                  e.preventDefault()
                  changeLanguage(item.locale, true)
                  changeMenuLang(item.code)
                  localStorage.setItem("brandLanguage", item.locale)
                  close()
                }}
              >
                <img
                  loading="lazy"
                  width="30"
                  height="30"
                  src={item.flag}
                  alt=""
                  style={{ marginBottom: 6 }}
                />
                {item.shortName}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </CustomModal>
  )
}

export default LanguagePopupModal
