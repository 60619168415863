import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setItemSelected, addItemInCart } from "@store/cart/cartActions"
import MobileCenter from "@components/tables/tablecenter/components/MobileCenter"
import DesktopCenter from "./components/DesktopCenter"
import classes from "./tableCenter.module.css"
import { Heading2 } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useBrand } from "@hooks/useBrand"

interface Props {
  tabValue: number
  category: any
  scrollToRef: any
  isMobile: boolean
  onClick?: any
}

const TableCenter = ({
  category,
  tabValue,
  scrollToRef,
  isMobile,
  onClick,
}: Props) => {
  const { primaryColor } = useBrand()
  const dispatch = useDispatch()
  const { languageStrings } = useLanguageHelper()
  const [selectedValue, setSelectedValue] = useState("1")
  const typography = primaryColor

  const handleMenuItemClick = (item) => {
    if (item.isCombo) {
    } else {
      const connectedItem = item.extraCategories?.find(
        (category) => category.connectedCategory,
      )
      if (connectedItem !== undefined) {
        const newItem = item.extraCategories?.find(
          (category) => category._id === connectedItem.connectedCategory,
        )
        let selectedItem = newItem?.items.find((item) => item.selected)
        if (!selectedItem) {
          selectedItem = newItem?.items[0]
        }
        const itemExtraCategories = item.extraCategories?.map((category) => {
          if (category.connectedCategory) {
            const newCatItem = {
              ...category,
              items: category.items.filter(
                (item) => item.connectedCategoryItem === selectedItem._id,
              ),
            }
            return newCatItem
          }
          return category
        })
        const finalItem = {
          ...item,
          extraCategories: itemExtraCategories,
        }

        const payload = {
          item: finalItem,
        }
        dispatch(setItemSelected(payload))
      } else {
        const payload = {
          item,
        }

        if (item.extraCategories.length > 0) {
          dispatch(setItemSelected(payload))
        } else {
          dispatch(addItemInCart(payload))
        }
      }
    }
  }

  return (
    <div className={classes.centercontainer}>
      <div style={{ backgroundColor: typography }} className={classes.header}>
        <Heading2 className="text-white">
          {languageStrings["TABLE_CENTER.MENU"]}
        </Heading2>
      </div>
      <div className={[classes.tablebody, isMobile && "pb-24"].join(" ")}>
        {isMobile ? (
          <MobileCenter
            typography={typography}
            category={category}
            tabValue={tabValue}
            handleMenuItemClick={handleMenuItemClick}
            onClick={onClick}
            iconFontSize={"small"}
          />
        ) : (
          <DesktopCenter
            tabValue={tabValue}
            category={category}
            scrollToRef={scrollToRef}
            handleMenuItemClick={handleMenuItemClick}
          />
        )}
      </div>
    </div>
  )
}

export default TableCenter
