import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import { Box, Modal } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"

import {
  addMultiItemCart,
  increaseNewItemCart,
  decreaseNewItemCart,
  addItemWithError,
} from "@store/cart/cartActions"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import ExtraCategoriesMulti from "../mobile/components/ExtraCategoriesMulti"
import ExtraCategoriesUni from "../mobile/components/ExtraCategoriesUni"
import classes from "../modals.module.css"
import {
  formatImageUrl,
  getMultiLanguageTitle,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import CrossImage from "@components/buttons/CrossImage"
import { OrderStates } from "./OrderStates"
import { ModalDetail } from "./ModalDetail"
import CrossIcon from "@assets/extra/cross_img.png"
import CustomLoader from "@components/Loaders/CustomLoader"
import { BarLoader } from "react-spinners"
import { useBrand } from "@hooks/useBrand"
interface Props {
  item: MenuItem
  onClose: () => void
  typography: string
  isMobile?: boolean
}

const ExtraCategoriesModalRight = ({
  onClose,
  item,
  typography,
  isMobile,
}: Props) => {
  const { languageStrings, changeLanguage, menuLanguage } = useLanguageHelper()
  const { primaryColor } = useBrand()

  const {
    image,
    title,
    description,
    allergenes,
    multiTitle = [],
    multiDescription = [],
  } = item
  const dispatch = useDispatch()
  const modalRef = useRef<HTMLDivElement>(null)
  const itemLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const itemLangDescription = getMultiLanguageTitle(
    multiDescription,
    menuLanguage,
  )
  const _title = itemLang?.title || title
  const _description = itemLangDescription?.description || description

  const newItem = useSelector(({ cart }: RootState) => cart.newItem)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { menuList: menuDetail, detailLoading } = useSelector(
    ({ menu }: RootState) => menu,
  )
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const [isScrolled, setIsScrolled] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen

  let refs: any = []

  const { quantity } = newItem

  const executeScroll = (r?: any) => {
    if (r && r.offsetTop) {
      modalRef?.current?.scrollTo({ top: r.offsetTop, behavior: "smooth" })
    } else {
      modalRef?.current?.scrollTo(0, modalRef.current.offsetTop)
    }
  }
  const checkMinQuantityForMultiSelectQuantity = (item) => {
    let totalSelectedQuantity = 0
    for (const subItem of item.items) {
      totalSelectedQuantity += subItem.selectedQuantity || 0
    }

    if (totalSelectedQuantity < item.minQuantity) {
      return true // Return true indicating an error
    } else {
      return false
    }
  }
  const onSubmit = () => {
    let error = false
    //LOOK FOR ERRORS
    newItem.extraCategories?.map((item) => {
      if (item.type === "multiselectQuantity") {
        const isLessQuantity = checkMinQuantityForMultiSelectQuantity(item)
        if (isLessQuantity) {
          error = true
        }
      }
      if (item.hasError && !item.parentConnectedCategoryItemId) {
        error = true
      }
    })

    if (error) {
      dispatch(addItemWithError())
    } else {
      dispatch(addMultiItemCart())
      onClose()
    }
  }
  const handleRefChange = (r, title) => {
    refs.push(r)
    executeScroll(refs[0])
  }

  const arrangeExtraCategories = () => {
    const _extraCategories =
      newItem.extraCategories && newItem.extraCategories
        ? newItem.extraCategories
        : []
    const parentCategories = {}
    const newExtraCategories: ExtraCategory[] = []
    _extraCategories.map((item) => {
      if (item.parentConnectedCategoryItemId) {
        parentCategories[item.parentConnectedCategoryItemId] = item
      } else {
        newExtraCategories.push(item)
      }
    })
    return { parentCategories, newExtraCategories }
  }

  const { parentCategories, newExtraCategories } = arrangeExtraCategories()

  const arrangeParentItems = (extraCategoryItems: ExtraCategoryItem[]) => {
    const __ = extraCategoryItems?.find((item) => item.selected === true)
    let foundObject: ExtraCategory | undefined
    if (__) {
      const { _id } = __
      foundObject = parentCategories[_id]
    }

    return foundObject
  }

  const renderConnectedCategory = (item?: ExtraCategory) => {
    if (!item) return null
    return item.type === "uniSelect" ? (
      <ExtraCategoriesUni
        typography={typography}
        item={item}
        onRefChange={handleRefChange}
      />
    ) : (
      <ExtraCategoriesMulti
        typography={typography}
        item={item}
        onRefChange={handleRefChange}
      />
    )
  }

  const handleScroll = useCallback((element: HTMLDivElement) => {
    if (modalRef?.current && modalRef?.current?.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [])

  useEffect(() => {
    if (modalRef?.current) {
      const element = modalRef?.current as HTMLDivElement
      handleScroll(element)
    }
  }, [handleScroll])
  return (
    <Modal
      className="flex justify-between items-center"
      open={true}
      onClose={onClose}
    >
      <div
        style={{ width: isMobile ? "90%" : "20rem" }}
        className={[classes.category_right, "outline-none relative"].join(" ")}
        ref={parentRef}
      >
        <div
          style={{
            position: "relative",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            maxHeight:
              restaurant.accessLevel && restaurant.accessLevel !== "1"
                ? "75%"
                : "100%",
          }}
          ref={modalRef}
          className={classes.modal_right}
          onScroll={(e) => handleScroll(e.currentTarget)}
        >
          <div
            className={`h-20 top-[6px] right-[5px] px-4 fixed flex items-center justify-end z-10 rounded-2 bg-${
              isScrolled ? "white" : "transparent"
            }`}
            style={{ width: isMobile ? "90%" : "20rem" }}
          >
            <CrossImage margin onClick={onClose} />
          </div>

          <div className="relative">
            {image && (
              <div className={"flex w-full"} style={{ minHeight: "30%" }}>
                <img
                  src={formatImageUrl(image)}
                  className="w-full object-cover"
                  style={{ minHeight: "12.5rem", maxHeight: "12.5rem" }}
                />
              </div>
            )}
          </div>

          <ModalDetail
            allergenes={allergenes}
            title={_title}
            description={_description}
            image={image}
          />
          <BarLoader
            color={primaryColor}
            loading={detailLoading}
            width={"100%"}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          {newExtraCategories.map((extraCategory: ExtraCategory) => {
            const isMatched = arrangeParentItems(extraCategory.items)

            if (extraCategory.type === "uniSelect" && !extraCategory.multiMax) {
              return (
                <div className={classes.extracategory_uni}>
                  <ExtraCategoriesUni
                    typography={typography}
                    item={extraCategory}
                    onRefChange={handleRefChange}
                  />
                  {renderConnectedCategory(isMatched)}
                </div>
              )
            } else {
              return (
                <div className={classes.extracategory_multi}>
                  {/* need to hide this  multiselectQuantity  */}
                  <ExtraCategoriesMulti
                    typography={typography}
                    item={extraCategory}
                    onRefChange={handleRefChange}
                  />
                  {renderConnectedCategory(isMatched)}
                </div>
              )
            }
          })}
        </div>
        {/* need to hide this  multiselectQuantity  */}
        {!excludeOrderType.includes(venueDetail?.orderType || "") &&
        isMenuHide ? (
          <OrderStates
            onAdd={() => dispatch(increaseNewItemCart())}
            onRemove={() => dispatch(decreaseNewItemCart())}
            onSubmit={onSubmit}
            price={(quantity * totalPriceCustomItem(newItem)).toFixed(2)}
            quantity={quantity}
            disabled={detailLoading}
            btnTitle={languageStrings["MENU_ITEM.ADD_TO_ORDER"]}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default ExtraCategoriesModalRight
