import React, { useEffect, useState } from "react"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(languageStrings["SOCIAL_PAYMENTS.PAYMENT_SUCCEEDED"])
          break
        case "processing":
          setMessage(
            languageStrings["SOCIAL_PAYMENTS.YOUR_PAYMENT_IS_PROCESSING"],
          )
          break
        case "requires_payment_method":
          setMessage(
            languageStrings[
              "SOCIAL_PAYMENTS.YOUR_PAYMENTS_ARE_NOT_SUCCESFUL_PLEASE_TRY_AGAIN"
            ],
          )
          break
        default:
          setMessage(languageStrings["SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG"])
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
        receipt_email: email,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message)
    } else {
      setMessage(languageStrings["SOCIAL_PAYMENTS.UNEXPETED_ERROR_OCCURED"])
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <input
        id="email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={languageStrings["SOCIAL_PAYMENTS.ENTER_EMAIL_ADDRESS"]}
      />
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            languageStrings["SOCIAL_PAYMENTS.PAY_NOW"]
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
