import { Menu } from "@models/Menu"
import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { theme } from "config/theme"
import { checkClosedKitchenTime } from "@helpers/utilsHelper"

interface IProps {
  isScrolled?: boolean
}
export const MenuHeader: FC<IProps> = ({ isScrolled }) => {
  const menuList: Menu = useSelector(({ menu }: RootState) => menu.menuList)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false

  const contentStyle = {
    transition: "opacity 0.5s ease-in-out",
    opacity: isScrolled ? 0 : 1,
  }
  const menuDescription =
    menuList?.isVisible === false || isClosedKitchen
      ? menuList?.visibilityDescription || ""
      : menuList?.description || ""

  return (
    <div className="bg-white overflow-hidden">
      <ScreenHeading
        style={{
          marginLeft: "0.6rem",
          backgroundColor: theme.main.white,
          marginTop: 0,
          ...contentStyle,
        }}
        heading={menuList?.title}
        subHeading={`${menuDescription || ""}`}
      />
    </div>
  )
}
