import React, { FC } from "react"

import { Box } from "@material-ui/core"
import ChevronRight from "@material-ui/icons/ChevronRight"
import { Heading3, Label15Bold } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"

interface Props {
  title?: string
  subTitle?: string
  description?: string
  onClick?: any
  showIcon?: boolean
  image?: any
  isValidAddress?: boolean
}

const MobileDetailCard: FC<Props> = ({
  title,
  subTitle,
  description,
  onClick,
  showIcon,
  image,
  isValidAddress,
}) => {
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <>
      <Box
        className={"flex flex-col items-start w-full cursor-pointer p-2 pl-12"}
        key={title}
        onClick={onClick}
      >
        <div className="flex flex-row items-center">
          {image && <img className="mr-12" width="50" src={image}></img>}
          <Heading3 className={"m-0"}>{title}</Heading3>
        </div>
        {isValidAddress == false && (
          <Heading3 className={"m-0 text-red"}>
            {languageStrings["MOBILE_DETAIL_CARD.OUT_OF_RANGE"]}
          </Heading3>
        )}
        <Box
          className={"flex flex-row w-full mt-4 justify-between items-start"}
        >
          <Box className={"flex flex-col w-11/12 items-start"}>
            {subTitle && (
              <Label15Bold className={"mt-1 text-left break-normal"}>
                {subTitle}
              </Label15Bold>
            )}
            {description && (
              <Label15Bold className={"mt-1 text-left break-normal"}>
                {description}
              </Label15Bold>
            )}
          </Box>
          {showIcon && (
            <ChevronRight
              style={{ fontSize: 30, color: "red", marginTop: ".5rem" }}
            />
          )}
        </Box>
      </Box>
      <Box className={"w-full h-px bg-gray-400"}></Box>
    </>
  )
}

export default MobileDetailCard
