import React from "react"
import Rating from "@material-ui/lab/Rating"
import { styled } from "@material-ui/styles"
import { theme } from "config/theme"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { Card } from "@material-ui/core"
import { AppHeading4Bold } from "@components/Text/AppText"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { useBrand } from "@hooks/useBrand"
import { TextInput } from "@components/textInput/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { createRating } from "@store/venues/VenueActions"
import CustomLoader from "@components/Loaders/CustomLoader"
import { redirectFromRating } from "@helpers/utilsHelper"

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: theme.status.red,
  },
})
interface Props {
  match?: any
}
export const RatingScreen = ({ match }: Props) => {
  const tableDetails = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.orderFromTable,
  )
  const { venueDetail, restaurant } = useSelector(
    ({ venues }: RootState) => venues,
  )
  const { primaryColor, restaurantName } = useBrand()
  const [value, setValue] = React.useState<number | null>(0)
  const [review, setReview] = React.useState<string>("")
  const { languageStrings } = useLanguageHelper()
  const dispatch = useDispatch()
  const { loading = false } = useSelector(({ venues }: RootState) => venues)
  const isBtnDisabled = !value
  return (
    <CustomLoader loading={loading}>
      <div className="flex flex-col bg-white min-h-screen">
        <div className="flex flex-col h-full w-full">
          <ScreenHeading
            heading={restaurantName}
            style={styles.heading}
            textStyle={{ textAlign: "left", marginBottom: "0.8rem" }}
            subHeading={languageStrings["RATING_SUBHEADING"]}
          />
          <div
            className="flex flex-col w-full p-8 pt-8"
            style={{
              backgroundColor: theme.main.dark5,
              borderBottom: `1px solid ${theme.main.grey}`,
              borderTop: `1px solid ${theme.main.grey}`,
            }}
          >
            <AppHeading4Bold
              style={{
                color: theme.status.danger2,
                marginBottom: "0.8rem",
                paddingTop: "0.6rem",
              }}
            >
              {languageStrings["SELECT_RATING"]}
            </AppHeading4Bold>
            <Card
              elevation={2}
              className="flex flex-col w-full self-center justify-center items-center py-16"
              style={{ borderRadius: "12px" }}
            >
              <StyledRating
                name="simple-controlled"
                value={value}
                size="large"
                style={{
                  width: "45%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onChange={(event, newValue) => {
                  if (newValue === 5) {
                    setReview("")
                  }
                  setValue(newValue)
                }}
              />
              {value && value < 5 ? (
                <TextInput
                  style={{
                    width: "92%",
                    display: "flex",
                    alignSelf: "center",
                    marginTop: "1.2rem",
                  }}
                  label={languageStrings["WRITE_REVIEW"]}
                  value={review}
                  onValueChange={(event) => {
                    setReview(event?.target?.value || "")
                  }}
                />
              ) : null}
            </Card>
            <ButtonIcon
              disabled={isBtnDisabled}
              style={{
                backgroundColor: isBtnDisabled
                  ? theme.main.grey
                  : theme.main.newOrange,
                marginTop: "1.2rem",
              }}
              textStyle={{
                color: isBtnDisabled ? theme.main.black : theme.main.white,
              }}
              title={languageStrings["POST_REVIEW"]}
              onClick={() => {
                if (value && value > 0)
                  dispatch(
                    createRating({
                      tableId: tableDetails?._id || "",
                      rating: value,
                      review,
                    }),
                  )
              }}
            />
            <ButtonIcon
              title={languageStrings["WITHOUT_REVIEW"]}
              style={{
                display: "flex",
                borderRadius: "25px",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: `1px solid ${theme.main.newOrange}`,
                marginTop: "0.6rem",
              }}
              textStyle={{
                color: theme.main.newOrange,
              }}
              onClick={() => {
                redirectFromRating({
                  _orderType: venueDetail?.orderType,
                  permalink: restaurant?.settings?.permalink || "",
                  tableId: tableDetails?._id,
                  venueId: venueDetail?._id,
                })
              }}
            />
          </div>
        </div>
      </div>
    </CustomLoader>
  )
}
const styles = {
  heading: {
    marginLeft: "0.6rem",
    marginTop: "2rem",
  },
}
