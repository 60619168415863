import React, { useState, useEffect } from "react"
import { Heading2, Heading5 } from "@components/Text/Text"
import CheckGIF from "@assets/Images/check_gif.gif"
import { useDispatch, useSelector } from "react-redux"
import { getVenueList } from "@store/venues/VenueActions"
import { VenueActionTypes } from "@store/redux/actionTypes"
import { emptyCartItems } from "@store/cart/cartActions"
import { clientSetting } from "config"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { venueOrderType } from "@helpers/utilsHelper"
import {
  getCocacolaQR,
  cleanCocacolaQR,
} from "@store/orderFromTable/OrderFromTableActions"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { theme } from "config/theme"
import Footer from "@components/footer/Footer"

interface Props {
  typography?: string
  orderId?: string
}

interface VenueSocialInfo {
  facebook?: string
  instagram?: string
  twitter?: string
}

const MobileOrderConfirmed = ({ typography, orderId }: Props) => {
  const venueList = useSelector(({ venues }: RootState) => venues.venueList)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const tableDetail = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.orderFromTable,
  )
  const cocacolaQR = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable.cocacolaQR,
  )
  const { languageStrings, changeLanguage } = useLanguageHelper()
  const isCocacola = restaurant?.cocacola?.cocacolaTerminalId ?? false
  const [qrFetched, setQrFetched] = useState(false)
  const [venueSocialInfo, setVenueSocialInfo] = useState<VenueSocialInfo>({})
  const dispatch = useDispatch()
  const getVenueInfo = (venueId?: string) => {
    const venueDetail = venueList.find((item) => item._id === venueId)
    return venueDetail
  }

  const handleQRdownload = () => {
    if (cocacolaQR) {
      window.open(cocacolaQR, "_blank")
    }
  }

  const permalink = localStorage.getItem("clientPermalink")
  useEffect(() => {
    permalink && dispatch(getVenueList(permalink))
  }, [])
  useEffect(() => {
    isCocacola && dispatch(cleanCocacolaQR())
  }, [])
  useEffect(() => {
    if (tableDetail && tableDetail.venueId) {
      const venueInfo = getVenueInfo(tableDetail.venueId) || {}
      if (venueInfo && venueInfo.contactInfo) {
        setVenueSocialInfo(venueInfo.contactInfo)
      }
    }
  }, [tableDetail])
  useEffect(() => {
    if (isCocacola && orderId && !cocacolaQR && !qrFetched) {
      dispatch(getCocacolaQR(orderId))
      setQrFetched(true)
    }
  }, [dispatch, tableDetail, venueList, cocacolaQR])
  useEffect(() => {
    isCocacola && orderId && dispatch(getCocacolaQR(orderId))
  }, [orderId])
  return (
    <div className={"flex flex-col bg-white justify-evenly h-screen"}>
      <div className={"flex flex-col w-full items-center justify-center"}>
        <img
          src={CheckGIF}
          style={{ height: "60%", width: "80%", objectFit: "contain" }}
        />
        <Heading2
          style={{
            fontWeight: 400,
            fontFamily: "ProductSansRegular",
          }}
        >
          {languageStrings["MOBILE_ORDER_CONFIRMED.CONGRATULATION"]}
        </Heading2>
        <Heading5
          style={{
            color: theme.main.black,
            display: "flex",
            alignSelf: "center",
            marginTop: "0.5rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {languageStrings["MOBILE_ORDER_CONFIRMED.YOUR_ORDER_HAS_BEEN_SENT"]}
        </Heading5>
        <div className="flex flex-col mt-16 w-full px-6">
          {isCocacola && (
            <ButtonIcon
              title={languageStrings["DOWNLOAD_QR"]}
              style={{ backgroundColor: theme.main.newOrange }}
              textStyle={{
                color: theme.main.white,
              }}
              onClick={() => {
                handleQRdownload()
              }}
            />
          )}
          <ButtonIcon
            title={languageStrings["MOBILE_ORDER_CONFIRMED.CONTINUE"]}
            style={{ backgroundColor: theme.main.newOrange }}
            textStyle={{
              color: theme.main.white,
            }}
            onClick={() => {
              dispatch(emptyCartItems())
              const venueDetail = getVenueInfo(tableDetail.venueId)
              const { isVirtualBasket } = venueOrderType(venueDetail)
              if (!isVirtualBasket && clientSetting.isCheck) {
                window.location.href = `${permalink}/${tableDetail._id}`
              } else if (
                isVirtualBasket &&
                tableDetail._id &&
                permalink &&
                clientSetting.isCheck === true
              ) {
                dispatch({
                  type: VenueActionTypes.GET_VENUE_DETAIL_SUCCESS,
                  payload: venueDetail,
                })

                window.location.href = `${permalink}/virtualbasketdetails/${tableDetail._id}/${tableDetail.venueId}`
              } else {
                window.location.href = `/order-details/${orderId}`
              }
            }}
          />
        </div>
      </div>
      <Footer
        showLogo={false}
        facebook={
          venueSocialInfo && venueSocialInfo.facebook
            ? venueSocialInfo.facebook
            : ""
        }
        instagram={
          venueSocialInfo && venueSocialInfo.instagram
            ? venueSocialInfo.instagram
            : ""
        }
        twitter={
          venueSocialInfo && venueSocialInfo.twitter
            ? venueSocialInfo.twitter
            : ""
        }
      />
    </div>
  )
}

export default MobileOrderConfirmed
