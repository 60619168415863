import React, { useCallback, useEffect, useRef, useState } from "react"
import { Modal } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import CrossImage from "@components/buttons/CrossImage"
import classes from "@modules/menuitem/modals.module.css"
import _ from "lodash"
import { AppHeading2, AppParagraph3 } from "@components/Text/AppText"
import { theme } from "config/theme"
import { useBrand } from "@hooks/useBrand"
import Button from "@components/buttons/Button"
import { CartActionTypes } from "@store/redux/actionTypes"

interface Props {
  onClose: () => void
  onContinue: () => void
}

const StockModal = ({ onClose, onContinue }: Props) => {
  const dispatch = useDispatch()
  const { languageStrings } = useLanguageHelper()
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { primaryColor } = useBrand()
  const [isScrolled, setIsScrolled] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const cartItems = useSelector(({ cart }: RootState) => cart.cart.items)

  const onCloseModal = () => {
    onClose()
  }

  const handleScroll = useCallback((element: HTMLDivElement) => {
    if (modalRef?.current && modalRef?.current?.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [])
  return (
    <Modal
      className="flex justify-between items-center"
      open={true}
      onClose={onCloseModal}
    >
      <div
        style={{ width: "90%" }}
        className={[classes.category_right, "outline-none relative"].join(" ")}
        ref={parentRef}
      >
        <div
          style={{
            position: "relative",
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px",
            maxHeight:
              restaurant.accessLevel && restaurant.accessLevel !== "1"
                ? "75%"
                : "100%",
          }}
          ref={modalRef}
          className={classes.modal_right}
          onScroll={(e) => handleScroll(e.currentTarget)}
        >
          <div
            className={`h-20 top-[6px] right-[5px] px-4 fixed flex items-center justify-end z-10 rounded-2 bg-${
              isScrolled ? "white" : "transparent"
            }`}
            style={{ width: "90%" }}
          >
            <CrossImage margin onClick={onCloseModal} />
          </div>
          <div
            className="flex flex-col px-8"
            style={{
              paddingTop: "10px",
              paddingBottom: "8px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <AppHeading2
              style={{
                color: theme.main.primary,
                marginBottom: 20,
              }}
            >
              {languageStrings["ITEMS_NOT_AVAILABLE"]}
            </AppHeading2>
            {cartItems
              .filter((i) => i.stockError)
              .map((item) => {
                return (
                  <AppParagraph3
                    key={item._id.toString()}
                    style={{ color: theme.main.primary }}
                  >
                    - {item.title} {languageStrings["PRODUCT_NOT_AVAILABLE"]}
                  </AppParagraph3>
                )
              })}
          </div>
        </div>
        <div
          className="flex flex-col bg-white"
          style={{
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            paddingTop: "12px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            title={languageStrings["VIEW_CART"]}
            backgroundColor={primaryColor}
            txtColor={theme.main.primary}
            classes={
              "flex items-center justify-center self-center w-11/12 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
            }
            style={{
              borderRadius: "25px",
              fontSize: "14px",
              marginBottom: 10,
            }}
            onClick={onCloseModal}
          />
          <Button
            title={languageStrings["CONTINUE"]}
            backgroundColor={primaryColor}
            txtColor={theme.main.primary}
            classes={
              "flex items-center justify-center self-center w-11/12 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
            }
            style={{
              borderRadius: "25px",
              fontSize: "14px",
              marginBottom: 15,
            }}
            onClick={onContinue}
          />
        </div>
      </div>
    </Modal>
  )
}

export default StockModal
