export const GET_MENU = "app/order/menuByVenue"

export const ORDER_MENU_BY_ID = "/web/getMenu"

export const GET_ALL_MENUS = "/app/menu"
export const GET_PRODUCT_EXTRA = "/web/product/extra"
export const GET_COMBO_EXTRA = "/web/combo/extra"

export const AUTH_CLIENT = "web/auth/client/"

export const VENUE = "web/venue"

export const SECRET = "web/secret"

export const REDSYS_FORM = "app/order/redsysForm"

export const REDSYS_FORM_WEB = "web/order/redsysForm"

export const CONFIRM_PENDING_ORDER = "web/order/confirmPendingOrder"
export const VALIDATE_STOCK = "validateStock"

export const ORDER = "web/order"
export const APPLY_DISCOUNT_VOUCHER = "web/apply-promo-code"
export const POS_TABLE = "pos/table-status"

export const COCACOLA_URL = "/cocacolaQR"
export const USER_CARDS = "/user-cards"
export const USER_CARD = "/user-card"
export const RATING = "/rating"

export const getVenueListUrl = () => {
  return encodeURI(VENUE + "?clientId=" + localStorage.getItem("clientId"))
}

export const LOGIN = "web/auth/webUser/login"
export const LOGIN_SOCIAL = "web/auth/socialLogin"

export const REGISTER = "web/auth/webUser"

export const CHECK_TOKEN = "web/auth/validateToken"
export const RESET_PASSWORD = "web/auth/resetPassword"
export const PAY_VIRTUAL_BASKET = "order/table/payVirtualBasket"
export const PAY_FRONT_REST = "order/table/payFrontRest"
export const SOCIAL_PAY_VIRTUAL_BASKET = "order/table/socialPayVirtualBasket"
export const INVOICE_TO_EMAIL = "order/table/sendInvoiceDetailsMail"
export const ORDER_TIMING_ESTIMATION =
  "/order/table/sendOrderTimingEstimationMail"
export const ORDER_TIMING_ESTIMATION_TWILIO =
  "/order/table/sendOrderTimingEstimationMessage"

export const SECURE_PAYMENT_ORDER = "order/table/securePaymentOrder"
export const GET_TICKET_BAI = "/order/table/getTicketBai"

export const CURRENT_DEVICE_PENDING_ORDERS = "/currentDevicePendingOrders"

export const PAY_BY_CASH = "order/table/payByCash"
export const LOYALTY_PROGRAM = "loyalty"

export const address = "web/user/address"
export const getMenuListUrl = (id, type) => {
  return encodeURI(GET_MENU + "?venueId=" + id + "&type=" + type)
}

export const CHECK_CART_ITEMS_AVAILABILITY = "web/availability/availability"
export const getOrderMenuByIdUrl = (menuId, type, date, table) => {
  if (table) {
    return encodeURI(
      ORDER_MENU_BY_ID +
        "?menuId=" +
        menuId +
        "&type=" +
        type +
        "&date=" +
        date +
        "&table=" +
        table,
    )
  }
  return encodeURI(
    ORDER_MENU_BY_ID + "?menuId=" + menuId + "&type=" + type + "&date=" + date,
  )
}
export const getProductDetailByIdUrl = (productId, type, date, table) => {
  if (table) {
    return encodeURI(
      GET_PRODUCT_EXTRA +
        "?productId=" +
        productId +
        "&type=" +
        type +
        "&date=" +
        date +
        "&table=" +
        table,
    )
  }
  return encodeURI(
    ORDER_MENU_BY_ID +
      "?productId=" +
      productId +
      "&type=" +
      type +
      "&date=" +
      date,
  )
}
export const getComboDetailByIdUrl = (comboId, type, date, table) => {
  if (table) {
    return encodeURI(
      GET_COMBO_EXTRA +
        "?comboId=" +
        comboId +
        "&type=" +
        type +
        "&date=" +
        date +
        "&table=" +
        table,
    )
  }
  return encodeURI(
    GET_COMBO_EXTRA + "?comboId=" + comboId + "&type=" + type + "&date=" + date,
  )
}

export const getAllMenuListUrl = (clientId) => {
  return encodeURI(GET_ALL_MENUS + "/?clientId=" + clientId)
}

export const getUserDetailUrl = (clientId) => {
  return encodeURI("web/auth/webUser/" + clientId)
}

export const getLogoutUserUrl = () => {
  return encodeURI("web/auth/webUser/logout")
}

export const saveCardDetailUrl = () => {
  return encodeURI("web/payment/card")
}

export const getCardListUrl = () => {
  return encodeURI(
    "web/payment/getCardsByUser?userId=" + localStorage.getItem("userId"),
  )
}

export const getTableUrl = (id) => {
  return encodeURI("/web/orderFromTable/" + id)
}
export const getVirtualBasketUrl = () => {
  return encodeURI("/order/table/getUserOrderFromTable")
}

export const getForgotPasswordUrl = () => {
  return encodeURI("/web/auth/forgotPassword")
}

export const getCheckAvailabilityCollectionUrl = (clientId) => {
  return encodeURI(ORDER + "/checkValidCollection/" + clientId)
}

export const getCheckAvailabilityDeliveryUrl = (clientId) => {
  return encodeURI(ORDER + "/checkValidDelivery/" + clientId)
}
export const getUpdateUserUrl = () => {
  return encodeURI("/web/auth/updateUser")
}

export const getChangePasswordUrl = () => {
  return encodeURI("/web/auth/changePassword")
}
export const getOrderListUrl = (userId) => {
  return encodeURI("/web/order/userOrders/" + userId)
}
export const getOrderDetailUrl = (orderId) => {
  return encodeURI("/web/order/" + orderId)
}
export const getOrderCancelUrl = () => {
  return encodeURI("/web/order/cancelOrder")
}
export const getRedsysFormUrl = (clientId, price, referenceNo) => {
  return encodeURI(
    REDSYS_FORM +
      "?clientId=" +
      clientId +
      "&price=" +
      price +
      "&referenceNo=" +
      referenceNo,
  )
}
export const addDevicePopupUrl = () => {
  return encodeURI("/web/venue/venuePopup")
}
export const getVenuePopupUrl = (venueId) => {
  return encodeURI("/web/venue/menu/" + venueId + "/venuePopup")
}
