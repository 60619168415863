import { axiosInstance } from "@api/axios"
import { VenuePopupActionTypes } from "@store/redux/actionTypes"
import { getDeviceToken } from "@helpers/utilsHelper"
import { addDevicePopupUrl, getVenuePopupUrl } from "@api/endPoints"

export const addDevice = (venueId) => {
  return (dispatch) => {
    dispatch({
      type: VenuePopupActionTypes.ADD_DEVICE_POPUP,
    })
    const deviceId = getDeviceToken()
    const url = addDevicePopupUrl()
    const reqData = {
      venueId,
      deviceId,
    }
    axiosInstance
      .post(url, reqData)
      .then((res) => {
        const { data } = res.data
        addDeviceSuccess(dispatch, data)
      })
      .catch(() => {
        addDeviceFail(dispatch, "There was an error connection")
      })
  }
}

const addDeviceSuccess = (dispatch, data) => {
  dispatch({
    type: VenuePopupActionTypes.ADD_DEVICE_SUCCESS,
    payload: data,
  })
}

const addDeviceFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuePopupActionTypes.ADD_DEVICE_FAIL,
    payload: {
      errorMessage,
    },
  })
}

export const getVenuePopup = (venueId) => {
  return (dispatch) => {
    dispatch({
      type: VenuePopupActionTypes.GET_VENUE_POPUP,
    })
    const url = getVenuePopupUrl(venueId)
    const params = venueId
    axiosInstance
      .get(url, { params })
      .then((res) => {
        const { data } = res.data
        getVenuePopupSuccess(dispatch, data)
      })
      .catch(() => {
        getVenuePopupFail(dispatch, "Could not retrieve venue popup")
      })
  }
}

const getVenuePopupSuccess = (dispatch, data) => {
  dispatch({
    type: VenuePopupActionTypes.ADD_DEVICE_SUCCESS,
    payload: data,
  })
}

const getVenuePopupFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuePopupActionTypes.ADD_DEVICE_FAIL,
    payload: {
      errorMessage,
    },
  })
}
export const setPopupCheck = (value) => {
  return (dispatch) => {
    dispatch({
      type: VenuePopupActionTypes.SET_SHOW_LANGUAGE_POPUP,
      payload: value,
    })
  }
}
