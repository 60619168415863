import { AppHeading2, AppParagraph3 } from "@components/Text/AppText"
import { AllergensImages } from "@components/allergensImages/AllergensImages"
import { theme } from "config/theme"
import React, { FC } from "react"
import classes from "../modals.module.css"

interface IProps {
  image?: string
  title?: string
  description?: string
  allergenes?: string
}
export const ModalDetail: FC<IProps> = ({
  image,
  title,
  description,
  allergenes,
}) => {
  return (
    <div
      className={[classes.info].join(" ")}
      style={{ marginTop: image ? 0 : 15, marginLeft: "0.3rem" }}
    >
      <div className="h-8" />
      <AppHeading2 style={{ color: theme.main.primary }}>{title}</AppHeading2>
      {description ? <div className="h-6" /> : null}
      <AppParagraph3 style={{ color: theme.main.black }}>
        {description}
      </AppParagraph3>
      <AllergensImages allergenes={allergenes} />
    </div>
  )
}
