import React, { useMemo } from "react"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import "./schedule-modal-styles.css"
import moment, { Moment } from "moment"
import CustomModal from "@components/modals/CustomModal"
import Button from "@components/buttons/Button"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { Heading4 } from "@components/Text/Text"
import { setProgrammedOrderDate } from "@store/orderFromTable/OrderFromTableActions"
import CrossIcon from "@assets/extra/cross_img.png"
import {
  futureOrderDayRange,
  getNextAvailableTime,
  getRangesByDays,
  getTimeSlotsFromRanges,
} from "@helpers/utilsDateTimeHelpers"
import { DATE_TIME_FORMATS } from "@helpers/Formates/dateTime"
import { DEFAULT_NUMBER_OF_FUTURE_DAYS } from "@helpers/constants"

interface Props {
  open: boolean
  close: any
  confirm?: any
}

const ScheduleOrderModal = ({
  open = false,
  close = () => {},
  confirm = () => {},
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const { primaryColor } = useBrand()
  const dispatch = useDispatch()

  const menu = useSelector(({ menu }: RootState) => menu)
  const venues = useSelector(({ venues }: RootState) => venues)
  const orderFromTable = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const { menuList } = menu
  const { visibilityRanges = [], visibilityTime, visibility } = menuList
  const { venueDetail } = venues
  const { programmedOrderDate } = orderFromTable

  const isMenuVisibilityShowOnly = useMemo(
    () => visibility === "3",
    [visibility],
  )
  const maxFutureDay = useMemo(
    () => venueDetail?.numberOfFutureDays ?? DEFAULT_NUMBER_OF_FUTURE_DAYS,
    [venueDetail?.numberOfFutureDays],
  )
  const daysWithPossibleRanges = useMemo(() => {
    return getRangesByDays(isMenuVisibilityShowOnly ? visibilityRanges : [])
  }, [isMenuVisibilityShowOnly, visibilityRanges])
  const nextAvailableTime = useMemo(
    () => getNextAvailableTime(daysWithPossibleRanges, maxFutureDay),
    [daysWithPossibleRanges, maxFutureDay],
  )
  const listOfMaxFutureDayRange = useMemo(
    () =>
      futureOrderDayRange({
        todayString: languageStrings["PICKUP_TIME_TODAY"],
        tomorrowString: languageStrings["PICKUP_TIME_TOMORROW"],
        maxFutureDay,
      }),
    [languageStrings, maxFutureDay],
  )
  const [selectedDateTime, setSelectedDateTime] = useState(
    moment(programmedOrderDate || nextAvailableTime),
  )
  const day = moment(selectedDateTime).format("dddd").toLowerCase()
  const isToday = selectedDateTime.isSame(nextAvailableTime, "date")
  const availableTimeSlotOfSelectedDay = getTimeSlotsFromRanges(
    daysWithPossibleRanges[day],
  )
  // Event change handlers
  const handleDateSelect = (e) => {
    let updatedSelectedDate = moment(
      e.target.value,
      DATE_TIME_FORMATS.DATE.DEFAULT,
    ).startOf("day")

    const isSelectedDateNextAvailableDate = updatedSelectedDate.isSame(
      nextAvailableTime,
      "date",
    )

    if (!isSelectedDateNextAvailableDate) {
      const day = moment(updatedSelectedDate).format("dddd").toLowerCase()
      const availableTimeSlotOfSelectedDay = getTimeSlotsFromRanges(
        daysWithPossibleRanges[day],
      )
      const [hour, minute] = availableTimeSlotOfSelectedDay?.[0].split(":")
      updatedSelectedDate = updatedSelectedDate.set({
        hours: Number(hour),
        minutes: Number(minute),
      })
      setSelectedDateTime(updatedSelectedDate)
      return
    }

    setSelectedDateTime(moment(nextAvailableTime))
  }
  const handleTimeSelect = (value: Moment) => {
    setSelectedDateTime(value)
  }
  const handleSelection = () => {
    dispatch(setProgrammedOrderDate(selectedDateTime.toISOString()))
    confirm()
  }

  // Filter methods for time picker
  const filterSelectedHours = () => {
    const menuHours = availableTimeSlotOfSelectedDay
    const allHours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ]
    const availableHours = new Set()
    if (!isToday) {
      for (const slot of menuHours) {
        const [hour, minute] = slot.split(":").map(Number)
        availableHours.add(hour)
      }
      return allHours.filter((item) => !availableHours.has(item))
    }
    const [currentHour, currentMinute] = moment(nextAvailableTime)
      .format(DATE_TIME_FORMATS.TIME.DEFAULT)
      .split(":")
      .map(Number)
    for (const slot of menuHours) {
      const [hour, minute] = slot.split(":").map(Number)
      if (hour > currentHour) {
        availableHours.add(hour)
      } else if (hour === currentHour) {
        if (minute >= currentMinute) {
          availableHours.add(hour)
        }
      }
    }
    return allHours.filter((item) => !availableHours.has(item))
  }
  const filterSelectedMinutes = (selectedHour) => {
    const allMinutes = [0, 15, 30, 45]
    const availableMinutes = new Set()
    const menuHours = availableTimeSlotOfSelectedDay.filter((slot) => {
      const [hour, minute] = slot.split(":").map(Number)
      return hour === selectedHour
    })
    if (!isToday) {
      for (const slot of menuHours) {
        const [hour, minute] = slot.split(":").map(Number)
        availableMinutes.add(minute)
      }
      return allMinutes.filter((item) => !availableMinutes.has(item))
    }
    const [currentHour, currentMinute] = moment(nextAvailableTime)
      .format(DATE_TIME_FORMATS.TIME.DEFAULT)
      .split(":")
      .map(Number)
    for (const slot of menuHours) {
      const [hour, minute] = slot.split(":").map(Number)
      if (currentHour !== hour) {
        availableMinutes.add(minute)
      } else if (minute > currentMinute) {
        availableMinutes.add(minute)
      } else if (minute === currentMinute) {
        availableMinutes.add(minute)
      }
    }
    return allMinutes.filter((item) => !availableMinutes.has(item))
  }

  useEffect(() => {
    // to check schedule order enable then we have to update state else not
    const showScheduleOrder =
      venueDetail?.orderType === "3" &&
      venueDetail?.isScheduleOrdersEnabled &&
      visibility !== "2"
    // to stop infinite rendering for menuV1
    if (!visibilityTime && showScheduleOrder) {
      setSelectedDateTime(moment(programmedOrderDate || nextAvailableTime))
    }
  }, [Object.keys(menuList).length])

  return (
    <CustomModal showModal={open} onCloseModal={close}>
      <div className="w-4/5">
        <Card className="mb-12 pb-12 pt-16 w-full h-full items-center justify-center cursor-pointer text-center relative">
          <div
            style={{
              height: "1rem",
              width: "1rem",
            }}
            className={"absolute top-0 right-0 m-8 cursor-pointer"}
            onClick={close}
          >
            <img src={CrossIcon} className="h-full w-full object-cover" />
          </div>
          <div className={"ml-8"}>
            <Heading4 className="text-black text-center mb-4">
              {languageStrings["HOME_PAGE.TAKE_AWAY"]}
            </Heading4>
          </div>

          <div className="mt-8 mx-8 flex items-center gap-8">
            <FormControl className={"w-2/3"} variant={"outlined"} size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                {languageStrings["PICKUP_TIME_DAY"]}
              </InputLabel>
              <Select
                label="Future Day"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={handleDateSelect}
                value={selectedDateTime.format(DATE_TIME_FORMATS.DATE.DEFAULT)}
              >
                {listOfMaxFutureDayRange
                  .filter((item) => {
                    // to check if menu visibility not set to show only
                    if (!isMenuVisibilityShowOnly) {
                      return true
                    }
                    const { day } = item || {}
                    const shouldDayFilter =
                      isMenuVisibilityShowOnly &&
                      daysWithPossibleRanges?.[day]?.length > 0
                    return shouldDayFilter
                  })
                  .map((item, i) => {
                    const { date, suffix } = item || {}
                    return (
                      <MenuItem
                        key={i}
                        className="text-center min-h-8 mt-8"
                        value={date.format(DATE_TIME_FORMATS.DATE.DEFAULT)}
                      >
                        {suffix || date.format(DATE_TIME_FORMATS.DATE.DEFAULT)}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <div className="w-1/3">
              <TimePicker
                showHour={true}
                showSecond={false}
                minuteStep={15}
                defaultValue={moment(programmedOrderDate || nextAvailableTime)}
                value={selectedDateTime}
                allowEmpty={false}
                format={DATE_TIME_FORMATS.TIME.DEFAULT}
                className="custom-time-picker"
                onChange={handleTimeSelect}
                popupClassName="custom-time-picker-popup"
                disabledHours={() => filterSelectedHours()}
                disabledMinutes={(hour: number) => filterSelectedMinutes(hour)}
                hideDisabledOptions={true}
                inputReadOnly={true}
                placeholder="Time"
                use12Hours={false}
              />
            </div>
          </div>

          <Button
            backgroundColor={primaryColor}
            txtColor={theme.main.primary}
            classes={
              "w-4/5 md:w-11/12 h-32 mt-12 md:mt-10 mb-2 font-semibold py-2 px-4 border-none rounded outline-none"
            }
            title={languageStrings["PICKUP_TIME_CONFIRM"]}
            onClick={handleSelection}
            disabled={!selectedDateTime}
            style={{
              borderRadius: "25px",
            }}
          />
        </Card>
      </div>
    </CustomModal>
  )
}

export default ScheduleOrderModal
