import React from "react"
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Theme,
  Card,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Label17 } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {
  typography?: string
  onRadioChange?: (e?: any) => void
  selectedValue?: string
}
interface StyleProps {
  typography?: string
}

const RadioPayByCard = ({
  typography,
  onRadioChange,
  selectedValue,
}: Props) => {
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: () => ({
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 15px 0px, rgba(0, 0, 0, 0.04) 0px 0px 0px 0px",
      borderRadius: "8px",
    }),
    checked: ({ typography }) => ({
      color: `${typography} !important`,
    }),
  }))
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)
  return (
    <RadioGroup value={"selectedItem"} onChange={onRadioChange}>
      <div
        className={
          "flex flex-row justify-around items-center w-full mt-16 pl-12 pr-12"
        }
      >
        <Card
          elevation={0}
          className={["h-24 bg-white pl-2 shadow-md", classes.root].join()}
          style={{ width: "46%" }}
        >
          <FormControlLabel
            checked={true}
            value="payByCard"
            control={<Radio classes={{ checked: classes.checked }} />}
            label={<Label17>{languageStrings["ADDRESS.PAY_BY_CARD"]}</Label17>}
          />
        </Card>
      </div>
    </RadioGroup>
  )
}

export default RadioPayByCard
