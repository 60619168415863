import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import CrossIcon from "@assets/extra/cross_img.png"

interface Props {
  color?: string
  onClick: () => void
  margin?: boolean
  style?: any
}

interface StyleProps {
  color?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  close: ({ color }) => ({
    position: "absolute",
    right: "5px",
    top: "6px",
    width: "1rem",
    height: "1rem",
    opacity: 1,
    zIndex: 9,
    cursor: "pointer",
    "&::hover": {
      opacity: 1,
    },
    "&::before, &&::after": {
      position: "absolute",
      left: "10px",
      content: '" "',
      height: "24px",
      width: "2px",
    },
  }),
}))

const CrossImage = ({ color, onClick, style }: Props) => {
  const styleProps: StyleProps = { color }
  const classes = useStyles(styleProps)
  return (
    <div style={style} onClick={onClick} className={classes.close}>
      <img src={CrossIcon} className="h-full w-full object-cover" />
    </div>
  )
}

export default CrossImage
