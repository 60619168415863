export const VenueActionTypes = {
  GET_VENUE_LIST_START: " GET_VENUE_LIST_START",
  GET_VENUE_LIST_SUCCESS: "  GET_VENUE_LIST_SUCCESS",
  GET_VENUE_LIST_FAIL: "  GET_VENUE_LIST_FAIL",

  GET_VENUE_DETAIL_START: " GET_VENUE_DETAIL_START",
  GET_VENUE_DETAIL_SUCCESS: "  GET_VENUE_DETAIL_SUCCESS",
  GET_VENUE_DETAIL_FAIL: "  GET_VENUE_DETAIL_FAIL",

  GET_CLIENT_DETAIL_START: " GET_CLIENT_DETAIL_START",
  GET_CLIENT_DETAIL_SUCCESS: "  GET_CLIENT_DETAIL_SUCCESS",
  GET_CLIENT_DETAIL_FAIL: "  GET_CLIENT_DETAIL_FAIL",

  SET_SELECT_OPTION: "SET_SELECT_OPTION",

  CHECK_DELIVERY_AVAILABILITY_START: "CHECK_DELIVERY_AVAILABILITY_START",
  CHECK_DELIVERY_AVAILABILITY_SUCCESS: "CHECK_DELIVERY_AVAILABILITY_SUCCESS",
  CHECK_DELIVERY_AVAILABILITY_FAIL: "CHECK_DELIVERY_AVAILABILITY_FAIL",

  CHECK_COLLECTION_AVAILABILITY_START: "CHECK_COLLECTION_AVAILABILITY_START",
  CHECK_COLLECTION_AVAILABILITY_SUCCESS:
    "CHECK_COLLECTION_AVAILABILITY_SUCCESS",
  CHECK_COLLECTION_AVAILABILITY_FAIL: "CHECK_COLLECTION_AVAILABILITY_FAIL",

  EMPTY_VENUE_DETAIL: "EMPTY_VENUE_DETAIL",

  SET_PAYMENT_SETTING: "SET_PAYMENT_SETTING",

  CREATE_RATING_START: " CREATE_RATING_START",
  CREATE_RATING_SUCCESS: "  CREATE_RATING_SUCCESS",
  CREATE_RATING_FAIL: "  CREATE_RATING_FAIL",
}

export const MenuActionTypes = {
  GET_ALL_MENU_LIST_START: " GET_ALL_MENU_LIST_START",
  GET_ALL_MENU_LIST_SUCCESS: "  GET_ALL_MENU_LIST_SUCCESS",
  GET_ALL_MENU_LIST_FAIL: "  GET_ALL_MENU_LIST_FAIL",

  GET_MENU_LIST_START: " GET_MENU_LIST_START",
  GET_MENU_LIST_SUCCESS: "  GET_MENU_LIST_SUCCESS",
  GET_MENU_LIST_FAIL: "  GET_MENU_LIST__FAIL",

  GET_MENU_DETAIL_START: " GET_MENU_DETAIL_START",
  GET_MENU_DETAIL_SUCCESS: "  GET_MENU_DETAIL_SUCCESS",
  GET_MENU_DETAIL_FAIL: "  GET_MENU_DETAIL_FAIL",

  GET_ALLERGENS_LIST_START: " GET_ALLERGENS_LIST_START",
  GET_ALLERGENS_LIST_SUCCESS: "GET_ALLERGENS_LIST_SUCCESS",
  GET_ALLERGENS_LIST_FAIL: "GET_ALLERGENS_LIST__FAIL",

  MENU_CHANGE_LANGUAGE: "MENU_CHANGE_LANGUAGE",
  MENU_LANGUAGE_TRANSLATION: "MENU_LANGUAGE_TRANSLATION",
  MENU_LANGUAGE_TRANSLATION_SUCCESS: "MENU_LANGUAGE_TRANSLATION_SUCCESS",

  GET_PRODUCT_DETAIL_START: "GET_PRODUCT_DETAIL_START",
  GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_FAIL: "GET_PRODUCT_DETAIL_FAIL",

  GET_COMBO_DETAIL_START: "GET_COMBO_DETAIL_START",
  GET_COMBO_DETAIL_SUCCESS: "GET_COMBO_DETAIL_SUCCESS",
  GET_COMBO_DETAIL_FAIL: "GET_COMBO_DETAIL_FAIL",
}

export const CartActionTypes = {
  SELECT_UNI_SELECT: "SELECT_UNI_SELECT",

  SET_ITEM_SELECTED: "SET_ITEM_SELECTED",

  GET_ORDER_MENU_LIST_SUCCESS: "GET_ORDER_MENU_LIST_SUCCESS",

  ADD_ITEM_CART_START: "ADD_ITEM_CART_START",
  EMPTY_ITEM_CART_START: "EMPTY_ITEM_CART_START",

  ADD_MULTI_ITEM_CART_START: "ADD_MULTI_ITEM_CART_START",

  SET_CART_PENDING_PAYMENT: "SET_CART_PENDING_PAYMENT",

  ORDER_SUBMIT_START: "ORDER_SUBMIT_START",
  ORDER_SUBMIT_SUCCESS: "ORDER_SUBMIT_SUCCESS",
  ORDER_SUBMIT_FAIL: "ORDER_SUBMIT_FAIL",

  INCREASE_ITEM_AMOUNT_ORDER_PAGE: "INCREASE_ITEM_AMOUNT_ORDER_PAGE",

  DECREASE_ITEM_AMOUNT_ORDER_PAGE: "DECREASE_ITEM_AMOUNT_ORDER_PAGE",

  INCREASE_NEW_ITEM_CART_START: "INCREASE_NEW_ITEM_CART_START",

  DECREASE_NEW_ITEM_CART_START: "DECREASE_NEW_ITEM_CART_START",

  ADD_ITEM_WITH_ERROR: "ADD_ITEM_WITH_ERROR",

  SET_SELECTED_ADDRESS: "SET_SELECTED_ADDRESS",

  SET_CART_EMPTY: " SET_CART_EMPTY",

  SET_SELECTED_CARD: "SET_SELECTED_CARD",

  GET_CART_SECRET_START: " GET_CART_SECRET_START",
  GET_CART_SECRET_SUCCESS: "  GET_CART_SECRET_SUCCESS",
  GET_CART_SECRET_FAIL: "  GET_CART_SECRET_FAIL",

  APPLY_DISCOUNT_VOUCHER_START: " APPLY_DISCOUNT_VOUCHER_START",
  APPLY_DISCOUNT_VOUCHER_SUCCESS: "  APPLY_DISCOUNT_VOUCHER_SUCCESS",
  APPLY_DISCOUNT_VOUCHER_FAIL: "  APPLY_DISCOUNT_VOUCHER_FAIL",

  GET_REDSYS_FORM_START: "GET_REDSYS_FORM_START",
  GET_REDSYS_FORM_SUCCESS: "GET_REDSYS_FORM_SUCCESS",
  GET_REDSYS_FORM_FAIL: "GET_REDSYS_FORM_FAIL",

  CONFIRM_PENDING_ORDER_START: "CONFIRM_PENDING_ORDER_START",
  CONFIRM_PENDING_ORDER_SUCCESS: "CONFIRM_PENDING_ORDER_SUCCESS",
  CONFIRM_PENDING_ORDER_FAIL: "CONFIRM_PENDING_ORDER_FAIL",

  VALIDATE_STOCK_START: "VALIDATE_STOCK_START",
  VALIDATE_STOCK_SUCCESS: "VALIDATE_STOCK_SUCCESS",
  VALIDATE_STOCK_FAIL: "VALIDATE_STOCK_FAIL",

  SET_SHOW_STOCK_MODAL: "SET_SHOW_STOCK_MODAL",
  SET_CART_ITEMS: "SET_CART_ITEMS",

  GET_PRODUCT_DETAIL_API_START: "GET_PRODUCT_DETAIL_API_START",
  GET_PRODUCT_DETAIL_API_SUCCESS: "GET_PRODUCT_DETAIL_API_SUCCESS",

  CHECK_CART_ITEMS_AVAILABILITY_START: "CHECK_CART_ITEMS_AVAILABILITY_START",
  CHECK_CART_ITEMS_AVAILABILITY_SUCCESS:
    "CHECK_CART_ITEMS_AVAILABILITY_SUCCESS",
  CHECK_CART_ITEMS_AVAILABILITY_FAIL: "CHECK_CART_ITEMS_AVAILABILITY_FAIL",
}

export const AuthActionTypes = {
  USER_LOGIN_START: " USER_LOGIN_START",
  USER_LOGIN_SUCCESS: "  USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "  USER_LOGIN__FAIL",

  USER_REGISTER_START: " USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "  USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "  USER_REGISTER__FAIL",

  USER_LOGOUT_START: " USER_LOGOUT_START",
  USER_LOGOUT_SUCCESS: "  USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "  USER_LOGOUT__FAIL",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  CHECK_VALID_TOKEN_START: "CHECK_VALID_TOKEN_START",
  CHECK_VALID_TOKEN_SUCCESS: "CHECK_VALID_TOKEN_SUCCESS",
  CHECK_VALID_TOKEN_FAIL: "CHECK_VALID_TOKEN_FAIL",

  CHANGE_LANGUAGE_START: "CHANGE_LANGUAGE_START",
  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_FAIL: "CHANGE_LANGUAGE_FAIL",
}

export const UserActionTypes = {
  USER_ADD_ADDRESS_START: " USER_ADD_ADDRESS_START",
  USER_ADD_ADDRESS_SUCCESS: "  USER_ADD_ADDRESS_SUCCESS",
  USER_ADD_ADDRESS_FAIL: "  USER_ADD_ADDRESS__FAIL",

  GET_POSTION_FROM_ADDRESS_START: "GET_POSTION_FROM_ADDRESS_START",
  GET_POSTION_FROM_ADDRESS_SUCCESS: "GET_POSTION_FROM_ADDRESS_SUCCESS",
  GET_POSTION_FROM_ADDRESS_FAIL: "GET_POSTION_FROM_ADDRESS_FAIL",

  ADD_USER_PHONE_START: " ADD_USER_PHONE_START",
  ADD_USER_PHONE_SUCCESS: "  ADD_USER_PHONE_SUCCESS",
  ADD_USER_PHONE_FAIL: "  ADD_USER_PHONE__FAIL",

  USER_ADDRESS_LIST_START: " USER_ADDRESS_LIST_START",
  USER_ADDRESS_LIST_SUCCESS: "  USER_ADDRESS_LIST_SUCCESS",
  USER_ADDRESS_LIST_FAIL: "  USER_ADDRESS_LIST__FAIL",

  SET_USER_ADDRESS: "SET_USER_ADDRESS",

  GET_USER_DETAILS_START: "GET_USER_DETAILS_START",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAIL: "GET_USER_DETAILS__FAIL",

  GET_CARD_LIST_START: "GET_CARD_LIST_START",
  GET_CARD_LIST_SUCCESS: "GET_CARD_LIST_SUCCESS",
  GET_CARD_LIST_FAIL: "GET_CARD_LIST__FAIL",

  SAVE_CARD_DETAILS_START: "SAVE_CARD_DETAILS_START",
  SAVE_CARD_DETAILS_SUCCESS: "SAVE_CARD_DETAILS_SUCCESS",
  SAVE_CARD_DETAILS_FAIL: "SAVE_CARD_DETAILS__FAIL",

  DELETE_USER_DETAILS_SUCCESS: "DELETE_USER_DETAILS_SUCCESS",

  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER__FAIL",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  GET_ORDERS_LIST_START: "GET_ORDERS_LIST_START",
  GET_ORDERS_LIST_SUCCESS: "GET_ORDERS_LIST_SUCCESS",
  GET_ORDERS_LIST_FAIL: "GET_ORDERS_LIST__FAIL",

  GET_ORDER_DETAIL_START: "GET_ORDER_DETAIL_START",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAIL: "GET_ORDER_DETAIL__FAIL",

  ORDER_CANCEL_START: "ORDER_CANCEL_START",
  ORDER_CANCEL_SUCCESS: "ORDER_CANCEL_SUCCESS",
  ORDER_CANCEL_FAIL: "ORDER_CANCEL_FAIL",

  SET_ORDER_ID: "SET_ORDER_ID",
  SET_LOYALTY_CLIENT: "SET_LOYALTY_CLIENT",
}

export const OrderFromTableActionTypes = {
  GET_TABLE_START: "GET_TABLE_START",
  GET_TABLE_SUCCESS: "GET_TABLE_SUCCESS",
  GET_TABLE_FAIL: "GET_TABLE_FAIL",

  GET_VIRTUAL_BASKET_START: "GET_VIRTUAL_BASKET_START",
  GET_VIRTUAL_BASKET_SUCCESS: "GET_VIRTUAL_BASKET_SUCCESS",
  GET_VIRTUAL_BASKET_FAIL: "GET_VIRTUAL_BASKET_FAIL",
  PAY_VIRTUAL_BASKET_SUCCESS: "PAY_VIRTUAL_BASKET_SUCCESS",
  SET_BASKET_CARD: "SET_BASKET_CARD",

  SET_SHOW_INVOICE_MODE_SUCCESS: "SET_SHOW_INVOICE_MODE_SUCCESS",
  SET_SHOW_ORDER_TIME_ESTIMATION_MODE_START:
    "SET_SHOW_ORDER_TIME_ESTIMATION_MODE_START",
  SET_SHOW_ORDER_TIME_ESTIMATION_MODE_SUCCESS:
    "SET_SHOW_ORDER_TIME_ESTIMATION_MODE_SUCCESS",
  SET_SHOW_ORDER_TIME_ESTIMATION_MODE_FAIL:
    "SET_SHOW_ORDER_TIME_ESTIMATION_MODE_FAIL",

  SET_SHOW_PENDING_ORDER_MODE_START: "SET_SHOW_PENDING_ORDER_MODE_START",
  SET_SHOW_PENDING_ORDER_MODE_SUCCESS: "SET_SHOW_PENDING_ORDER_MODE_SUCCESS",
  SET_SHOW_PENDING_ORDER_MODE_FAIL: "SET_SHOW_PENDING_ORDER_MODE_FAIL",

  SET_ORDER_CLIENT_NAME: "SET_ORDER_CLIENT_NAME",

  SET_ORDER_CLIENT_ADDRESS: "SET_ORDER_CLIENT_ADDRESS",
  GET_CURRENT_DEVICE_PENDING_ORDERS_START:
    "GET_CURRENT_DEVICE_PENDING_ORDERS_START",
  GET_CURRENT_DEVICE_PENDING_ORDERS_SUCCESS:
    "GET_CURRENT_DEVICE_PENDING_ORDERS_SUCCESS",
  GET_CURRENT_DEVICE_PENDING_ORDERS_FAIL:
    "GET_CURRENT_DEVICE_PENDING_ORDERS_FAIL",

  PAY_CASH_START: "PAY_CASH_START",
  PAY_CASH_SUCCESS: "PAY_CASH_SUCCESS",
  PAY_CASH_FAIL: "PAY_CASH_FAIL",

  GET_COCACOLA_QR_START: "GET_COCACOLA_QR_START",
  GET_COCACOLA_QR_SUCCESS: "GET_COCACOLA_QR_SUCCESS",
  GET_COCACOLA_QR_FAIL: "GET_COCACOLA_QR_FAIL",
  CLEAN_COCACOLA_QR: "CLEAN_COCACOLA_QR",

  GET_USER_CARDS: "GET_USER_CARDS",
  GET_USER_CARDS_START: "GET_USER_CARDS_START",
  GET_USER_CARDS_SUCCESS: "GET_USER_CARDS_SUCCESS",
  GET_USER_CARDS_FAIL: "GET_USER_CARDS_FAIL",
  GET_TICKET_BAI: "GET_TICKET_BAI",
  GET_TICKET_BAI_START: "GET_TICKET_BAI_START",
  GET_TICKET_BAI_SUCCESS: "GET_TICKET_BAI_SUCCESS",
  GET_TICKET_BAI_FAIL: "GET_TICKET_BAI_FAIL",
  CREATE_USER_CARD: "CREATE_USER_CARD",
  CREATE_USER_CARD_START: "CREATE_USER_CARD_START",
  CREATE_USER_CARD_SUCCESS: "CREATE_USER_CARD_SUCCESS",
  CREATE_USER_CARD_FAIL: "CREATE_USER_CARD_FAIL",

  SET_ORDER_DATE: "SET_ORDER_DATE",
}

export const VenuePopupActionTypes = {
  ADD_DEVICE_POPUP: "ADD_DEVICE_POPUP",
  ADD_DEVICE_SUCCESS: "ADD_DEVICE_SUCCESS",
  ADD_DEVICE_FAIL: "ADD_DEVICE_FAIL",
  GET_VENUE_POPUP: "GET_VENUE_POPUP",
  GET_VENUE_POPUP_SUCCESS: "GET_VENUE_POPU_SUCCESS",
  GET_VENUE_POPUP_FAIL: "GET_VENUE_POPU_FAIL",
  SET_SHOW_LANGUAGE_POPUP: "SET_SHOW_LANGUAGE_POPUP",
}
