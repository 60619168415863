import React, { FC, useState, useEffect } from "react"
import { AddCircleOutline } from "@material-ui/icons"
import { useSelector } from "react-redux"

import { Label15, Body14 } from "@components/Text/Text"
import SimpleItemModal from "../components/SimpleItemModal"
import classes from "../menuItem.module.css"
import ComplexItemModal from "../components/ComplexItemModal"
import {
  getAllergens,
  formatImageUrl,
  checkClosedKitchenTime,
  excludeOrderType,
} from "@helpers/utilsHelper"
import useClientCurrency from "@hooks/useClientCurrency"

interface Props {
  title: string
  subtitle: string
  price: number
  image: string
  extraCategories: ExtraCategory[]
  onClick: () => void
  item: MenuItem
}

const MenuItem: FC<Props> = ({
  title,
  subtitle,
  price,
  image,
  extraCategories,
  item,
  onClick,
}) => {
  const { clientCurrency } = useClientCurrency()
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const allergensList = useSelector(({ menu }: RootState) => menu.allergensList)
  const menuDetail = useSelector(({ menu }: RootState) => menu.menuList)
  const { programmedOrderDate = "" } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const isClosedKitchen =
    restaurant?.closeKitchenTime && !programmedOrderDate
      ? checkClosedKitchenTime(restaurant?.closeKitchenTime)
      : false
  const isMenuHide = !(menuDetail?.isVisible === false) && !isClosedKitchen

  const { allergenes } = item

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [modalVisible, setModalVisible] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [allergensImages, setAllergensImages] = useState<any>([])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleClick = () => {
    onClick()
    extraCategories.length > 0 && setModalVisible(!modalVisible)
  }

  const showItemDetail = () => {
    if (extraCategories && extraCategories.length > 0) {
      setModalVisible(true)
      onClick()
    } else {
      setShowDetail(true)
    }
  }

  const getFilterAllergens = () => {
    const allergens = getAllergens(allergenes)
    if (
      allergens &&
      allergens.length > 0 &&
      allergensList &&
      allergensList.length > 0
    ) {
      const temp: any = []
      return allergensList.map((item) => {
        if (allergens.find((i) => i == item._id)) {
          temp.push(item.title)
          setAllergensImages(temp)
        }
      })
    }
  }
  useEffect(() => {
    getFilterAllergens()
  }, [allergensList])
  return (
    <div className={"flex flex-col"}>
      <div className={classes.menu_item}>
        <div className={classes.info} onClick={showItemDetail}>
          {image && image.length > 0 && (
            <div className={[classes.image, "self-start"].join(" ")}>
              <div
                style={{
                  background: `url('${formatImageUrl(
                    image,
                  )}') no-repeat center`,
                  backgroundSize: "cover",
                  height: 60,
                  width: 60,
                }}
              />
            </div>
          )}
          <div className={classes.details}>
            <Label15>{title}</Label15>
            <Body14 className={classes.subtitle}>{subtitle}</Body14>
          </div>
        </div>
        <Label15
          className={[
            classes.itemPrice,
            "whitespace-no-wrap flex-1 text-center",
          ].join(" ")}
        >
          {price ? price.toFixed(2) : 0} {clientCurrency.symbol}
        </Label15>

        <div className="flex-1 text-center">
          {!excludeOrderType.includes(venueDetail?.orderType || "") &&
          isMenuHide ? (
            <AddCircleOutline
              className={classes.icon}
              style={{ color: typography }}
              onClick={handleClick}
            />
          ) : null}
          {modalVisible && (
            <ComplexItemModal
              typography={typography}
              item={item}
              onClose={() => setModalVisible(false)}
            />
          )}

          {showDetail && (
            <SimpleItemModal
              typography={typography}
              item={item}
              onClose={() => setShowDetail(false)}
            />
          )}
        </div>
      </div>
      <div
        className={`flex flex-wrap w-11/12 pb-1 ${
          image && image.length > 0 ? "-mt-8" : "-mt-16"
        }`}
      >
        {allergensImages &&
          allergensImages.length > 0 &&
          allergensImages.map((item) => (
            <img
              src={require(`@assets/allergenes/${item}.png`)}
              className={"w-16 h-16 object-cover m-1"}
            />
          ))}
      </div>
    </div>
  )
}

export default MenuItem
