import { formatImageUrl } from "@helpers/utilsHelper"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { theme } from "config/theme"

export const useBrand = () => {
  const [primaryColor, setPrimaryColor] = useState<string>("")
  const [secondaryColor, setSecondaryColor] = useState<string>("")
  const [thirdColor, setThirdColor] = useState<string>("")
  const [restaurantName, setRestaurantName] = useState<string>("")
  const [restaurantImage, setRestaurantImage] = useState<string>("")
  const [restaurantLanguage, setRestaurantLanguage] =
    useState<string>("Spanish")
  const [venueOrderType, setVenueOrderType] = useState<string>("")
  const [isCashAvailable, setIsCashAvailable] = useState<boolean>(false)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const disabledColor = "#dddddd"
  useEffect(() => {
    const headerImage =
      restaurant && restaurant.settings && restaurant.settings.headerImage
        ? `url('${formatImageUrl(restaurant.settings.headerImage)}')`
        : ``
    const typography1 = restaurant.settings?.typography || ""
    const typography2 = restaurant.settings?.typography2 || "black"
    const typography3 = restaurant.settings?.typography3 || theme.main.greyLight
    setRestaurantImage(headerImage)
    setPrimaryColor(typography1)
    setSecondaryColor(typography2)
    setThirdColor(typography3)
    setRestaurantLanguage(restaurant.defaultLanguage || "Spanish")
  }, [restaurant])
  useEffect(() => {
    const venueName = venueDetail?.title || ""
    setIsCashAvailable(venueDetail?.payCash || false)
    if (venueName) {
      setRestaurantName(venueName || "")
    }

    if (venueDetail?.orderType) {
      setVenueOrderType(venueDetail?.orderType || "")
    }
  }, [venueDetail])
  return {
    primaryColor,
    secondaryColor,
    thirdColor,
    restaurantName,
    restaurantImage,
    venueOrderType,
    isCashAvailable,
    disabledColor,
    restaurantLanguage,
  }
}
