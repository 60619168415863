export default {
  "ADDRESS.CHOOSE_SHOPPING_ADDRESS": "Choose shipping address",
  "ADDRESS.ADD_ADDRESS": "Add address",
  "ADDRESS.CHOOSE_PAYMENT_METHOD": "Choose payment method",
  "ADDRESS.ADD_CARD": "Add card",
  "ADDRESS.PAY_BY_CARD": "Payment by card",

  "BASKET.ENTER_YOUR_ADDRESS": "Enter your address",
  "BASKET.ADDRESS": "Address",
  "BASKET.POSTAL_CODE": "Postal Code",
  "BASKET.CITY": "City",
  "BASKET.SPECIAL_DETAIL": "Special Details (optional)",
  "BASKET.SELECT_ADDRESS": "Select Address",
  "BASKET.CREATE": "Create Address",
  "BASKET.NAME": "Name",
  "BASKET.MY_ORDER": "My Order",
  "BASKET.DO_YOU_HAVE_A_DISCOUNT_CODE": "Do you have a discount code?",
  "BASKET.DISCOUNT_CODE": "Discount Code",
  "BASKET.APPLY": "Apply",
  "BASKET.THE_DISCOUNT_CODE_IS_CURRECT_BUT_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "The discount code is correct but the minimum amount to apply is",
  "BASKET.COUPON_NOT_AVAILABLE_FOR_THIS_TYPE_OF_ORDER":
    "Coupon not available for this type of order",
  "BASKET.THE_DISCOUNT_CODE_DOES_NOT_EXIST": "Discount code does not exist",
  "BASKET.DISCOUNT_CODE_ALREADY_USER": "Discount code applied",
  "BASKET.HOME_DELIVERY": "Shipping to your home",
  "BASKET.SHOPPING_ADDRESS": "Shipping address",
  "BASKET.CHOOSE_ADDRESS": "Choose Address",
  "BASKET.ADD_NEW_ADDRESS": "Add new address",
  "BASKET.MINIMUM_ORDER": "Minimum order",
  "BASKET.MAKE_AN_ORDER": "Order now",
  "BASKET.MY_ADDRESS": "My Addresses",
  "BASKET.PAYMENT_METHOD": "Payment method",
  "BASKET.ADD_NEW_CARD": "Add new card",
  "BASKET.N_ORDER": "N Orden",
  "BASKET.EARRING": "Pending",

  "FORGET_PASSWORD.PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "Password must be longer than 6 characters",
  "FORGET_PASSWORD.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "Confirm password must be longer than 6 characters",
  "FORGET_PASSWORD.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Password and Confirm password must be the same",
  "FORGET_PASSWORD.RESET_YOUR_PASSWORD": "Reset your password",
  "FORGET_PASSWORD.THE_TOKEN_HAS_EXPIRED": "Token has expired",
  "FORGET_PASSWORD.NEW_PASSWORD": "New password",
  "FORGET_PASSWORD.CONFIRM_PASSWORD": "Confirm password",
  "FORGET_PASSWORD.LOGIN": "LOG IN",

  "HOME.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "There are no menus for this restaurant",
  "HOME.SELECT_MENU": "Select Menu",

  "LOGIN.PLEASE_ENTER_A_VALID_EMAIL": "Enter a valid email address",
  "LOGIN.EMAIL": "Your email address",
  "LOGIN.PASSWORD": "Password",
  "LOGIN.RECOVER_PASSWORD": "Forgot your password?",
  "LOGIN.YOU_DON'T_HAVE_AN_ACCOUNT_EASILY_CREATE_IT":
    "You don't have an account, create one easily",
  "LOGIN.SIGN_IN_WITH_GOOGLE": "Sign in with Google",
  "LOGIN.ARE_YOU_SURE_YOU_WANT_TO_RECOVER_YOUR_PASSWORD":
    "Are you sure you want to recover your password?",

  "MENU_ITEM.ADD_TO_ORDER": "Add to cart",

  "PAYMENT.PAYMENT_METHOD": "Payment method",
  "PAYMENT.NAME": "Name",
  "PAYMENT.NUMBER": "Number",
  "PAYMENT.CVC": "CVC",
  "PAYMENT.MAKE_PAYMENT": "Pay",
  "PAYMENT.TIP_ERROR": "Tip amount cannot be less than 0",

  "PROFILE.PROFILE": "Profile",
  "PROFILE.EDIT_PROFILE": "Edit Profile",
  "PROFILE.CHANGE_PASSWORD": "Change Password",
  "PROFILE.MY_ORDERS": "My Orders",
  "PROFILE.LEAVE": "Exit",
  "PROFILE.TO_CALCEL_AN_ORDER": "Cancel order",
  "PROFILE.PASSWORD": "Password",
  "PROFILE.PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Password must be longer than 5 characters",
  "PROFILE.CONFIRM_PASSWORD": "Confirm password",
  "PROFILE.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Confirm password must be longer than 5 characters",
  "PROFILE.MY_ORDER": "My Order",
  "PROFILE.GRAND_TOTAL": "Grand Total",
  "PROFILE.SHIPPING_ADDRESS": "Shipping address",
  "PROFILE.ORDER_PLACED": "Order Placed",
  "PROFILE.CONFIRMED": "Confirmed",
  "PROFILE.IN_KITCHEN": "In Kitchen",
  "PROFILE.IN_SHOPPING": "In Shipment",
  "PROFILE.PICK_UP_LIST": "Ready for pick up",
  "PROFILE.COMPLETED": "Completed",
  "PROFILE.TO_CANCEL_AN_ORDER": "Cancel order",
  "PROFILE.NAME": "Name",
  "PROFILE.SURNAME": "Surname",
  "PROFILE.DATE_OF_BIRTH": "Date of Birth",
  "PROFILE.PHONE": "Telephone",
  "PROFILE.SAVE_CHANGES": "Save changes",

  "SIGNUP.PLEASE_ENTER_A_VALID_EMAIL": "Please enter a valid email address",
  "SIGNUP.THE_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Password must be longer than 5 characters",
  "SIGNUP.CONFIRM": "Confirm password must be longer than 5 characters",
  "SIGNUP.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Password and Confirm password must be the same",
  "SIGNUP.NAME": "Name",
  "SIGNUP.SURNAME": "Surname",
  "SIGNUP.EMAIL": "E-mail",
  "SIGNUP.PASSWORD": "Password",
  "SIGNUP.CONFIRM_PASSWORD": "Confirm password",
  "SIGNUP.I_ACCEPT_THE_TERMS_AND_CONDITIN": "I accept the terms and conditions",
  "SIGNUP.DO_YOU_ALREADY_HAVE_AN_ACCOUNT_TRY_TO_ACCESS_WITH_IT":
    "Already have an account? Try logging in with it",

  "SOCUAL_PAYMENT.ENTER_EMAIL_ADDRESS": "Enter your email address",

  "VENUE_DETAIL.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "There are no menus for this restaurant",
  "VENUE_DETAIL.HOW_DO_YOU_WANT_TO_ORDER": "How do you want your order?",
  "VENUE_DETAIL.SELECT_MENU": "Select Menu",

  "VIRTUAL_BASKET.YOUR_GONNA_PAY": "You are going to pay",
  "VIRTUAL_BASKET.NAME": "Name",
  "VIRTUAL_BASKET.FULL_NAME": "Name and Surname",
  "VIRTUAL_BASKET.NUMBER": "Number",
  "VIRTUAL_BASKET.CVC": "CVC",
  "VIRTUAL_BASKET.SUCCESS_PAYMENT_RECEIVED": "Payment successfully received!",
  "VIRTUAL_BASKET.PAYMENT_PROCESSING_WE'LL_UPDATE_YOU_WHEN_PAYMENT_IS_RECEIVED":
    "Processing payment. We will notify you when payment has been received.",
  "VIRTUAL_BASKET.PAYMENT_FAIL.PLEASETRY_ANOTHER_PAYMENT_METHOD":
    "Payment Failed. Please use another payment method.",
  "VIRTUAL_BASKET.SOMETHING_WENT_WRONG": "Something went wrong",
  "VIRTUAL_BASKET.VIRTUAL_BASKET_PAYMENT": "Virtual Cart Checkout",
  "VIRTUAL_BASKET.SEE_THE_MENU": "View menu",
  "VIRTUAL_BASKET.PAY_THE_BILL": "Pay bill",
  "VIRTUAL_BASKET.PAYMENT_METHOD": "Payment method",
  "VIRTUAL_BASKET.CHOOSE_CARD": "Choose Card",
  "VIRTUAL_BASKET.ADD_NEW_CARD": "Add new card",
  "VIRTUAL_BASKET.VIEW_THE_ACCOUNT": "View account",
  "VIRTUAL_BASKET.MY_ORDER": "My Order",
  "VIRTUAL_BASKET.BASE_PRICE": "Base Price",
  "VIRTUAL_BASKET.AMOUNT_PAID": "Amount paid",
  "VIRTUAL_BASKET.PENDING_AMOUNT": "Pending amount",
  "VIRTUAL_BASKET.DISCOUNT": "Discount",

  "GENERAL.COMMENTS": "Comments",
  "GENERAL.ADD": "Add",
  "GENERAL.TOTAL": "Total",
  "GENERAL.SAVE": "Save",
  "GENERAL.ACCESS": "Access",
  "GENERAL.EITHER": "Or",
  "GENERAL.CONFIRM": "Confirm",
  "GENERAL.CANCEL": "Cancel",
  "GENERAL.SEND": "Send",
  "GENERAL.PAY": "Pay",
  "GENERAL.DISCOUNT": "Discount",

  "BASKET.ENTER_NAME": "Enter name",
  "BASKET.FOR_EXAMPLE_HOME_WORK": "For example: Home Work",
  "BASKET.DISCOUNT_CODE_ALREADY_USED": "Discount code already used",
  "BASKET.DISCOUNT": "Discount",
  "BASKET.BASE_PRICE": "Base Price",
  "BASKET.CHOOSE_CARD": "Select Card",

  "PROFILE.DISCOUNT": "DISCOUNT",
  "PROFILE.SORRY_YOUR_ORDER_HAS_BEEN_CANCELED":
    "Sorry, your order has been cancelled",
  "PROFILE.N_ORDER": "Order No.",
  "PROFILE.ORDER_DETAIL": "Order Details",

  "SIGN_UP.SIGN_UP": "Sign in",

  "SOCIAL_PAYMENTS.PAYMENT_SUCCEEDED": "Payment succeeded!",
  "SOCIAL_PAYMENTS.YOUR_PAYMENT_IS_PROCESSING": "Your payment is processing.",
  "SOCIAL_PAYMENTS.YOUR_PAYMENTS_ARE_NOT_SUCCESFUL_PLEASE_TRY_AGAIN":
    "Your payment was not successful, please try again.",
  "SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG": "Something went wrong.",
  "SOCIAL_PAYMENTS.UNEXPETED_ERROR_OCCURED": "An unexpected error occured.",
  "SOCIAL_PAYMENTS.ENTER_EMAIL_ADDRESS": "Enter email address",
  "SOCIAL_PAYMENTS.PAY_NOW": "Pay now",

  "VIRTUAL_BASKET.GRAND_TOTAL": "Grand total",

  "ADDRESS_CARD.OUT_OF_RANGE": "Out of range",
  "CUSTOM_LOADER.CHARGING": "Loading...",
  "MOBILE_DETAIL_CARD.OUT_OF_RANGE": "Out of range",
  "ADD_CARD_MODEL.NAME": "Name",
  "ADD_CARD_MODEL.NUMBER": "Number",
  "ADD_CARD_MODEL.CVC": "CVC",
  "ADD_PHONE_NUMBER_MODEL.PLEASE_ADD_YOUR_PHONE":
    "Please add your phone number!",
  "ADD_PHONE_NUMBER_MODEL.LEAVE_YOUR_TELEPHONE_NUMBER_TO_CONTACT_YOU_REGARDING_THE ORDER":
    "Leave us your phone number so we can contact you regarding your order.",
  "ADD_PHONE_NUMBER_MODEL.PHOME ORDER": "Phone",
  "ADDRESS_DETAIL_MODEL.SHOPPING_ADDRESS": "Shipping address",
  "ADDRESS_DETAIL_MODEL.NAME": "Name",
  "ADDRESS_DETAIL_MODEL.HOME_OFFICE": "Home, Office...",
  "ADDRESS_DETAIL_MODEL.STREET": "Street",
  "ADDRESS_DETAIL_MODEL.POSTAL_CADE": "Zip/Postal Code",
  "ADDRESS_DETAIL_MODEL.CITY": "City",
  "ADDRESS_DETAIL_MODEL.TO_ACCESS_PRESS_THE_RED_BELL":
    "To access click on the red bell",
  "ADDRESS_DETAIL_MODEL.FOLLOWING": "Next",
  "ADDRESS_MAP_MODEL.CREATE_ADDRESS": "Create Address",
  "INVOICE_MODEL.DO_YOU_NEED_AN_INVOICE_ENTER_EMAIL_TO_RECEIVE_IT":
    "Need a receipt? Enter your email address to receive it",
  "INVOICE_MODEL.EMAIL": "Email",
  "INVOICE_MODEL.REQUEST_INVOICE": "Request receipt",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "The amount is required",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "The amount exceeds the amount to be paid",
  "SPLIT_BILL_MODEL.PAYMENT": "Payment",
  "SPLIT_BILL_MODEL.PAY_FULL_AMOUNT": "Pay full bill",
  "SPLIT_BILL_MODEL.PARTIAL_PAYMENY": "Partial payment",
  "SPLIT_BILL_MODEL.ENTER_AMOUNT_TO_PAY": "Enter amount to pay",
  "SPLIT_BILL_MODEL.DECIMALS": "Enter a max. of two decimal places",
  "HEADER.ORDER_COMPLETED": "Order completed!",
  "ITEMS_DETAILS.ORDER": "Order",
  "ITEMS_DETAILS.BASIC_PRICE": "Base Price",
  "ITEMS_DETAILS.SHOPPING_PRICE": "Delivery fee",
  "ITEMS_DETAILS.DISCOUNT": "Discount",
  "ITEMS_DETAILS.GRAND_TOTAL": "Grand Total",

  "MOBILE_ORDER_CONFIRMED.CONGRATULATION": "Congratulations!",
  "MOBILE_ORDER_CONFIRMED.YOUR_ORDER_HAS_BEEN_SENT":
    "Your order has been shipped",
  "MOBILE_ORDER_CONFIRMED.CONTINUE": "Continue",
  "MOBILE_ORDER_CONFIRMED.POWERED_BY": "Powered by",
  "ORDER_CONFRMED_PAGE.THANK_YOU": "Thank you!",
  "ORDER_CONFRMED_PAGE.CONTINUE": "Continue",
  "RADIO_GROUP_CARD.CASH": "In Cash",

  "TABLE_CENTER.MENU": "Menu",
  "TABLE_LEFT.CATEGORIES": "Categories",
  "TABLE_LEFT.THIS_MENU_IS_A_DEMO_GET_YOURS_FOR_FREE":
    "This menu is a demo, get yours for free",
  "TABLE_LEFT.SIGN_UP_FOR_FREE": "Sign up for free",

  "TABLE_RIGHT.YOUR_ORDER": "Your Order",
  "TABLE_RIGHT.MAKE_AN_ORDER": "Place Order",
  "TABLE_RIGHT.DO_YOU_A_DISCOUNT_CODE": "Do you have a discount code?",
  "TABLE_RIGHT.DISCOUNT_CODE": "Discount Code",
  "TABLE_RIGHT.APPLY_TEXT": "applyText",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_DOSE_NOT_EXIST":
    "Discount code does not exist",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_ALREADY_USE": "Discount code already used",
  "TABLE_RIGHT.MINIMUM_ORDER": "Minimum order",
  "TABLE_RIGHT.HOME_DELIVERY": "Delivery",
  "TABLE_RIGHT.TOTAL": "Total",
  "TABLE_RIGHT.SUB_TOTAL": "Subtotal",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_IS_CORRECT_BUT_THE_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "The discount code is correct but the minimum amount to order is",
  "TABLE_RIGHT.COUPONS_NOT_AVAILABLE_FOR_THIS_ORDER_TYPE":
    "Coupon not available for this type of order",
  "TABLE_RIGHT.DISCOUNT": "Discount",

  "VENUE_ORDER.HOW_DO_WANT_YOUR_ORDER": "How do you want your order?",
  "NOT_FOUND_PAGE.RESTORENT_NOT_FOUND": "Restaurant not found",

  SEE_ORDERS: "VIEW ORDER",

  ORDER_PREPARATION_TIMING_HEADER_MODEL:
    "Please enter the email to see order preparation timing",
  ORDER_PREPARATION_BUTTON: "Receipt",
  HOW_DO_YOU_WANT_TO_BE_NOTIFIED: "How do you want to be notified",
  GENERAL_AUTOSERVICE: "Self Service",
  I_DONT_WANT_TO_BE_NOTIFIED: "I don't want to be notified",
  ENTER_YOUR_EMAIL_ADDRESS: "Enter your email address",
  EMAIL_ADDRESS: "Email Address",
  NOTIFY_ME: "Notify me",
  ENTER_YOUR_PHONE_NUMBER: "Enter your phone number",
  PHONE_NUMBER: "Phone number",
  ORDER_REF: "Order Ref",
  TOTAL_AMOUNT: "Total amount",
  CREATING_TIME: "Creation time",
  "GENERAL:ORDER_IN_PROGRESS": "Order in Progress",
  "GENERAL:ORDER_IN_PROGRESS_PARGRAPH":
    "Click here to view pending order details",
  RESTAURANT_BUSY: "Restaurant is Busy Right now",
  STATUS: "Status",
  PARTIAL_DELIVERY: "Partial Delivery",
  IN_PROGRESS: "In Progress",
  "ORDER_STATUS.DELIVERED": "Delivered",
  NO_TABLE_ID_ERROR:
    "There is a problem with your table please try scanning the QR again",
  TICKET_NO: "Ticket #",
  MINIMUM_ORDER: "Minimum order",
  SELECT_PAYMENT_METHOD: "Select a payment method",
  CONTINUE_TO_PAY: "Continue with payment",
  SECURE_PAYMENTS: "Your payment is encrypted and secured",
  INVALID_CARD_NUMBER: "The card number is invalid",
  INVALID_EXPIRY_DATE: "The expiry date is invalid",
  MONTH_RANGE: "The expiration month must be between 01 and 12",
  YEAR_RANGE: "The expiry year cannot be in the past",
  DATE_RANGE: "Expiry date cannot be in the past",
  INVALID_CVV: "The security code is invalid",
  CREDIT_CARD: "Credit Card",
  ADD_TIP: "Adding a tip",
  TIP: "Tip",
  TIP_TITLE: "Say thank you with a tip",
  TIP_REMOVE: "Remove tip",
  OTHER: "Other",
  ENTER_AMOUNT: "Enter amount",
  TERMS_CONDITION: "By continuing, I agree to the",
  TERMS_CONDITION2: "Terms and Conditions and Privacy Policy.",
  BUSY_TABLE: "The table does not accept orders at this time.",
  BUSY_VENUE: "The restaurant does not accept orders at the moment.",
  INVALID_EMAIL_ERROR: "Check the information provided",
  EMPTY_NAME_ERROR: "Please provide your name",
  GENERAL_CLOSE: "Close",
  TOTAL_WITHOUT_PROMO: "Total without Promo",
  PAY_IN_CASH: "Pay in cash",
  CASH_MAKE_ORDER: "Place Order",
  EMPTY_CART: "This basket is empty",
  DOWNLOAD_QR: "Download QR",
  MENU_HEADER1: "This is a Menu for the",
  MENU_HEADER2: "which is the best in Town.",
  GENERAL_ITEMS: "Items",
  GENERAL_ITEM: "Item",
  GENERAL_CASH: "Cash",
  GENERAL_VENUE_MESSAGE:
    "This venue will notify you when your order is ready to pick up",
  NOTIFIED_VIA_EMAIL: "Notification via email",
  SEND_EMAIL_NOTIFICATION: "We will send notification via this email",
  SEND_WHATSAPP_NOTIFICATION: "We will send notification via this whatsapp",
  SEND_SMS_NOTIFICATION: "We will send notification to this telephone number",
  NOTIFIED_VIA_WHATSAPP: "Notification via whatsapp",
  NOTIFIED_VIA_SMS: "Notification via SMS",
  GENERAL_WHATSAPP: "Whatsapp",
  GENERAL_SMS: "SMS",
  NOTIFICATION_METHOD: "Notification via",
  CHOOSE_PAYMENT_METHOD: "Choose your payment method options available",
  PAY_WITH: "Pay with",
  CARD_NUMBER: "Card number",
  EXPIRY_DATE: "Expiry date",
  CVC_CODE: "CVC code",
  NEED_RECEIPT: "Need a receipt?",
  NEED_RECEIPT_SUB: "Enter your email address to receive it",
  POST_REVIEW: "Leave a review",
  ORDER_LIST: "Order list",
  PAY_ORDER: "Pay order",
  GENERAL_POPULAR: "Popular",
  GENERAL_FOOD: "Food",
  GENERAL_DRINK: "Drinks",
  SELECT_RATING: "Your opinion is important to us",
  WRITE_REVIEW: "Write a review",
  WITHOUT_REVIEW: "Continue with no review",
  ORDER_CONFIRMATION: "Order Confirmation",
  GENERAL_PEDIR: "Order",
  GENERAL_PAGAR: "Pay",
  RATING_SUBHEADING: "Rate our establishment",
  "HOME_PAGE.SEE_THE_MENU": "Order",
  "HOME_PAGE.SEE_THE_MENU_VB": "View Menu",
  "HOME_PAGE.PAY_ORDER": "Pay",
  "HOME_PAGE.SCHEDULE_ORDER": "Schedule Order",
  "HOME_PAGE.TAKE_AWAY": "Take Away",
  "HOME_PAGE.TAKE_AWAY_ORDER": "Schedule order",
  SECURE_STORE_CARD:
    "Encrypt and securely store my card details for future orders.",
  VIEW_MENU: "View Menu",
  USE_CARD: "Use card",
  PICKUP_TIME: "Pickup Time",
  PICKUP_TIME_TODAY: "Today",
  PICKUP_TIME_TOMORROW: "Tomorrow",
  PICKUP_TIME_CHANGE: "Change",
  PICKUP_TIME_CONFIRM: "Confirm",
  PICKUP_TIME_DAY: "Day",
  PICKUP_TIME_HOUR: "Hour",
  PICKUP_TIME_CHANGE_MESSAGE:
    "The pickup time has been updated. You can change it if you wish.",
  MENU_HIDE_MESSAGE_PART_1: "The kitchen of the restaurant",
  MENU_HIDE_MESSAGE_PART_2:
    "is not open at the moment. Please check with our staff for opening hours",
  ADD_NEW_CARD: "Use another card",
  CLOSED_KITCHEN_MESSAGE:
    "We're sorry, at the moment we are not accepting new orders. Please try again later",
  AUTOSERVICE_NAME: "Name",
  AUTOSERVICE_ADD_NAME: "Please add your name",
  NOT_AVAILABLE: "Not available",
  FOR_FREE: "for Free",
  ITEMS_NOT_AVAILABLE:
    "One or more items in your basket are no longer available",
  VIEW_CART: "View cart",
  CONTINUE: "Continue",
  ANOTHER_PRODUCT_ERROR: "Please select another product",
  PRODUCT_NOT_AVAILABLE: "not available",
  ERROR_DATE_PAST:
    "The selected date has already passed. Please choose a future date",
  SELECT_LANGUAGE: "Select a Language",
  AUTOSERVICE_ADDRESS: "Address",
  AUTOSERVICE_ADD_ADDRESS: "Please add your address",
  "LANGUAGE.CHINESE": "Chinese",
  ITEM: "Item",
  ITEMS: "Items",
  NO_MENU_ID_ERROR: "No menu id found",
}
