export default {
  "ADDRESS.CHOOSE_SHOPPING_ADDRESS": "Elige dirección de envío",
  "ADDRESS.ADD_ADDRESS": "Añadir dirección",
  "ADDRESS.CHOOSE_PAYMENT_METHOD": "Elige metodo de pago",
  "ADDRESS.ADD_CARD": "Añadir tarjeta",
  "ADDRESS.PAY_BY_CARD": "Pago con Tarjeta",

  "BASKET.ENTER_YOUR_ADDRESS": "Introduce tu dirección",
  "BASKET.ADDRESS": "Dirección",
  "BASKET.POSTAL_CODE": "Còdigo Postal",
  "BASKET.CITY": "Ciudad",
  "BASKET.SPECIAL_DETAIL": "Detalles Especials (opcional)",
  "BASKET.SELECT_ADDRESS": "Seleccionar Dirección",
  "BASKET.CREATE": "Crear Dirección",
  "BASKET.NAME": "Nombre",
  "BASKET.MY_ORDER": "Mi Pedido",
  "BASKET.DO_YOU_HAVE_A_DISCOUNT_CODE": "¿Tienes un código de descuento?",
  "BASKET.DISCOUNT_CODE": "Código Descuento",
  "BASKET.APPLY": "Aplicar",
  "BASKET.THE_DISCOUNT_CODE_IS_CURRECT_BUT_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "El código de descuento es correcto pero la cantidad minima para solicitarlo es",
  "BASKET.COUPON_NOT_AVAILABLE_FOR_THIS_TYPE_OF_ORDER":
    "Cupón no disponible para este tipo de orden.",
  "BASKET.THE_DISCOUNT_CODE_DOES_NOT_EXIST": "El codigo de descuento no existe",
  "BASKET.DISCOUNT_CODE_ALREADY_USER": "Código descuento aplicado",
  "BASKET.HOME_DELIVERY": "Envío a Domicilio",
  "BASKET.SHOPPING_ADDRESS": "Dirección de envío",
  "BASKET.CHOOSE_ADDRESS": "Elegir Dirección",
  "BASKET.ADD_NEW_ADDRESS": "Añadir nueva dirección",
  "BASKET.MINIMUM_ORDER": "Mínimo orden",
  "BASKET.MAKE_AN_ORDER": "Hacer pedido",
  "BASKET.MY_ADDRESS": "Mis Direcciones",
  "BASKET.PAYMENT_METHOD": "Método de pago",
  "BASKET.ADD_NEW_CARD": "Añadir nueva tarjeta",
  "BASKET.N_ORDER": "N Orden",
  "BASKET.EARRING": "Pendiente",

  "FORGET_PASSWORD.PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "La contraseña debe tener más de 6 caracteres",
  "FORGET_PASSWORD.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_6_CHARACTERS":
    "Confirmar contraseña debe tener más de 6 caracteres",
  "FORGET_PASSWORD.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Contraseña y confirmar la contraseña debe ser la misma",
  "FORGET_PASSWORD.RESET_YOUR_PASSWORD": "Reinicia tu contraseña",
  "FORGET_PASSWORD.THE_TOKEN_HAS_EXPIRED": "El token ha caducado",
  "FORGET_PASSWORD.NEW_PASSWORD": "Nueva contraseña",
  "FORGET_PASSWORD.CONFIRM_PASSWORD": "Confirmar contraseña",
  "FORGET_PASSWORD.LOGIN": "LOG IN",

  "HOME.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "No existe menus para este restaurante",
  "HOME.SELECT_MENU": "Selecciona Menú",

  "LOGIN.PLEASE_ENTER_A_VALID_EMAIL": "Introduzca un correo electrónico válido",
  "LOGIN.EMAIL": "Correo electrónico",
  "LOGIN.PASSWORD": "Contraseña",
  "LOGIN.RECOVER_PASSWORD": "¿Recuperar contraseña?",
  "LOGIN.YOU_DON'T_HAVE_AN_ACCOUNT_EASILY_CREATE_IT":
    "No tienes cuenta, crea una fácilmente",
  "LOGIN.SIGN_IN_WITH_GOOGLE": "Accede con Google",
  "LOGIN.ARE_YOU_SURE_YOU_WANT_TO_RECOVER_YOUR_PASSWORD":
    "¿Estás seguro quieres recuperar tu contraseña?",

  "MENU_ITEM.ADD_TO_ORDER": "Añadir al pedido",

  "PAYMENT.PAYMENT_METHOD": "Método de pago",
  "PAYMENT.NAME": "Nombre",
  "PAYMENT.NUMBER": "Número",
  "PAYMENT.CVC": "CVC",
  "PAYMENT.MAKE_PAYMENT": "Realizar pago",
  "PAYMENT.TIP_ERROR": "El monto de la propina no puede ser menor a 0",

  "PROFILE.PROFILE": "Perfil",
  "PROFILE.EDIT_PROFILE": "Editar Perfil",
  "PROFILE.CHANGE_PASSWORD": "Cambiar Contraseña",
  "PROFILE.MY_ORDERS": "Mis Órdenes",
  "PROFILE.LEAVE": "Salir",
  "PROFILE.TO_CALCEL_AN_ORDER": "Cancelar orden",
  "PROFILE.PASSWORD": "Contraseña",
  "PROFILE.PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "La contraseña debe tener más de 5 caracteres",
  "PROFILE.CONFIRM_PASSWORD": "Confirmar contraseña",
  "PROFILE.CONFIRM_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "Confirmar contraseña debe tener más de 5 caracteres",
  "PROFILE.MY_ORDER": "Mi Pedido",
  "PROFILE.GRAND_TOTAL": "Grand Total",
  "PROFILE.SHIPPING_ADDRESS": "Dirección de envío",
  "PROFILE.ORDER_PLACED": "Orden Realizada",
  "PROFILE.CONFIRMED": "Confirmada",
  "PROFILE.IN_KITCHEN": "En Cocina",
  "PROFILE.IN_SHOPPING": "En Envío",
  "PROFILE.PICK_UP_LIST": "Lista para recoger",
  "PROFILE.COMPLETED": "Completada",
  "PROFILE.TO_CANCEL_AN_ORDER": "Cancelar orden",
  "PROFILE.NAME": "Nombre",
  "PROFILE.SURNAME": "Apellidos",
  "PROFILE.DATE_OF_BIRTH": "Fecha de Nacimiento",
  "PROFILE.PHONE": "Teléfono",
  "PROFILE.SAVE_CHANGES": "Guardar cambios",

  "SIGNUP.PLEASE_ENTER_A_VALID_EMAIL":
    "Introduzca un correo electrónico válido",
  "SIGNUP.THE_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS":
    "La contraseña debe tener más de 5 caracteres.",
  "SIGNUP.CONFIRM": "Confirmar contraseña debe tener más de 5 caracteres",
  "SIGNUP.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME":
    "Contraseña y Confirmar contraseña deben ser iguales",
  "SIGNUP.NAME": "Nombre",
  "SIGNUP.SURNAME": "Apellidos ",
  "SIGNUP.EMAIL": "Correo electrónico",
  "SIGNUP.PASSWORD": "Contraseña",
  "SIGNUP.CONFIRM_PASSWORD": "Confirmar contraseña",
  "SIGNUP.I_ACCEPT_THE_TERMS_AND_CONDITIN": "Acepto los términos y condiciones",
  "SIGNUP.DO_YOU_ALREADY_HAVE_AN_ACCOUNT_TRY_TO_ACCESS_WITH_IT":
    "¿Ya tienes una cuenta? Prueba a acceder con ella",

  "SOCUAL_PAYMENT.ENTER_EMAIL_ADDRESS": "Introduce correo electrónico",

  "VENUE_DETAIL.THERE_ARE_NO_MENUES_FOR_THIS_RESTAURANT":
    "No existe menus para este restaurante",
  "VENUE_DETAIL.HOW_DO_YOU_WANT_TO_ORDER": "¿Cómo quieres tu pedido?",
  "VENUE_DETAIL.SELECT_MENU": "Selecciona Menú",

  "VIRTUAL_BASKET.YOUR_GONNA_PAY": "Vas a pagar",
  "VIRTUAL_BASKET.NAME": "Nombre",
  "VIRTUAL_BASKET.FULL_NAME": "Nombre y Apellido",
  "VIRTUAL_BASKET.NUMBER": "Número",
  "VIRTUAL_BASKET.CVC": "CVC",
  "VIRTUAL_BASKET.SUCCESS_PAYMENT_RECEIVED": "Pago recibido con éxito!.",
  "VIRTUAL_BASKET.PAYMENT_PROCESSING_WE'LL_UPDATE_YOU_WHEN_PAYMENT_IS_RECEIVED":
    "Procesando pago. Te avisaremos cuando el pago haya sido recibido.",
  "VIRTUAL_BASKET.PAYMENT_FAIL.PLEASETRY_ANOTHER_PAYMENT_METHOD":
    "Pago Fallido. Por favor utilice otro método de pago.",
  "VIRTUAL_BASKET.SOMETHING_WENT_WRONG": "Algo salió mal",
  "VIRTUAL_BASKET.VIRTUAL_BASKET_PAYMENT": "Pago Carrito Virtual",
  "VIRTUAL_BASKET.SEE_THE_MENU": "Ver carta",
  "VIRTUAL_BASKET.PAY_THE_BILL": "Pagar cuenta",
  "VIRTUAL_BASKET.PAYMENT_METHOD": "Método de pago",
  "VIRTUAL_BASKET.CHOOSE_CARD": "Elegir Tarjeta",
  "VIRTUAL_BASKET.ADD_NEW_CARD": "Añadir nueva tarjeta",
  "VIRTUAL_BASKET.VIEW_THE_ACCOUNT": "PAGAR CUENTA",
  "VIRTUAL_BASKET.MY_ORDER": "Mi Pedido",
  "VIRTUAL_BASKET.BASE_PRICE": "Precio Base",
  "VIRTUAL_BASKET.AMOUNT_PAID": "Importe pagado",
  "VIRTUAL_BASKET.PENDING_AMOUNT": "Importe pendiente",
  "VIRTUAL_BASKET.DISCOUNT": "Descuento",

  "GENERAL.COMMENTS": "Comentarios",
  "GENERAL.ADD": "Añadir",
  "GENERAL.TOTAL": "Total",
  "GENERAL.SAVE": "Guardar",
  "GENERAL.ACCESS": "Accede",
  "GENERAL.EITHER": "Ó",
  "GENERAL.CONFIRM": "Confirmar",
  "GENERAL.CANCEL": "Cancelar",
  "GENERAL.SEND": "Enviar",
  "GENERAL.PAY": "Pagar",
  "GENERAL.DISCOUNT": "Descuento",

  "BASKET.ENTER_NAME": "Introduce nombre",
  "BASKET.FOR_EXAMPLE_HOME_WORK": "Por Ejemplo: Casa Trabajo",
  "BASKET.DISCOUNT_CODE_ALREADY_USED": "El descuento ya ha sido usado",
  "BASKET.DISCOUNT": "Descuento",
  "BASKET.BASE_PRICE": "Precio Base",
  "BASKET.CHOOSE_CARD": "Elegir Tarjeta",

  "PROFILE.DISCOUNT": "DESCUENTO",
  "PROFILE.SORRY_YOUR_ORDER_HAS_BEEN_CANCELED":
    "Lo sentimos, tu orden ha sido cancelada",
  "PROFILE.N_ORDER": "N Order",
  "PROFILE.ORDER_DETAIL": "Detalle Orden",

  "SIGN_UP.SIGN_UP": "Regístrate",

  "SOCIAL_PAYMENTS.PAYMENT_SUCCEEDED": "Pago exitoso",
  "SOCIAL_PAYMENTS.YOUR_PAYMENT_IS_PROCESSING": "Tu pago se está procesando",
  "SOCIAL_PAYMENTS.YOUR_PAYMENTS_ARE_NOT_SUCCESFUL_PLEASE_TRY_AGAIN":
    "Tu pago no se ha completado con éxito, por favor inténtelo de nuevo",
  "SOCIAL_PAYMENTS.SOMETHING_WENT_WRONG": "Algo salió mal",
  "SOCIAL_PAYMENTS.UNEXPETED_ERROR_OCCURED": "Ha ocurrido un error inesperado",
  "SOCIAL_PAYMENTS.ENTER_EMAIL_ADDRESS":
    "Por favor, introduce tu correo electrónico",
  "SOCIAL_PAYMENTS.PAY_NOW": "Pagar ahora",

  "VIRTUAL_BASKET.GRAND_TOTAL": "Total",

  "ADDRESS_CARD.OUT_OF_RANGE": "Fuera de rango",
  "CUSTOM_LOADER.CHARGING": "Cargando...",
  "MOBILE_DETAIL_CARD.OUT_OF_RANGE": "Fuera de rango",
  "ADD_CARD_MODEL.NAME": "Nombre",
  "ADD_CARD_MODEL.NUMBER": "Número",
  "ADD_CARD_MODEL.CVC": "CVC",
  "ADD_PHONE_NUMBER_MODEL.PLEASE_ADD_YOUR_PHONE":
    "¡Por favor añade tu teléfono!",
  "ADD_PHONE_NUMBER_MODEL.LEAVE_YOUR_TELEPHONE_NUMBER_TO_CONTACT_YOU_REGARDING_THE ORDER":
    "Déjanos tu teléfono para contactarte en relación con tu pedido",
  "ADD_PHONE_NUMBER_MODEL.PHOME ORDER": "Telefono",
  "ADDRESS_DETAIL_MODEL.SHOPPING_ADDRESS": "Dirección de envío",
  "ADDRESS_DETAIL_MODEL.NAME": "Nombre",
  "ADDRESS_DETAIL_MODEL.HOME_OFFICE": "Casa, Oficina...",
  "ADDRESS_DETAIL_MODEL.STREET": "Calle",
  "ADDRESS_DETAIL_MODEL.POSTAL_CADE": "Código Postal",
  "ADDRESS_DETAIL_MODEL.CITY": "Ciudad",
  "ADDRESS_DETAIL_MODEL.TO_ACCESS_PRESS_THE_RED_BELL":
    "Para acceder pulse la campana roja",
  "ADDRESS_DETAIL_MODEL.FOLLOWING": "Siguiente",
  "ADDRESS_MAP_MODEL.CREATE_ADDRESS": "Crear Dirección",
  "INVOICE_MODEL.DO_YOU_NEED_AN_INVOICE_ENTER_EMAIL_TO_RECEIVE_IT":
    "¿Necesitas Comprobante? Introduce tu correo electrónico para recibirlo",
  "INVOICE_MODEL.EMAIL": "Correo electrónico",
  "INVOICE_MODEL.REQUEST_INVOICE": "Solicitar comprobante",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "El importe es obligatorio",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "El importe excede de la cantidad a pagar",
  "SPLIT_BILL_MODEL.PAYMENT": "Pagar",
  "SPLIT_BILL_MODEL.PAY_FULL_AMOUNT": "Pagar cuenta completa",
  "SPLIT_BILL_MODEL.PARTIAL_PAYMENY": "Pago parcial",
  "SPLIT_BILL_MODEL.ENTER_AMOUNT_TO_PAY": "Introduce importe a pagar",
  "SPLIT_BILL_MODEL.DECIMALS": "Introduce un máximo de dos decimales",
  "HEADER.ORDER_COMPLETED": "Orden completada!",
  "ITEMS_DETAILS.ORDER": "Pedido",
  "ITEMS_DETAILS.BASIC_PRICE": "Precio Base",
  "ITEMS_DETAILS.SHOPPING_PRICE": "Precio envio",
  "ITEMS_DETAILS.DISCOUNT": "Descuento",
  "ITEMS_DETAILS.GRAND_TOTAL": "Total",

  "MOBILE_ORDER_CONFIRMED.CONGRATULATION": "¡Felicidades!",
  "MOBILE_ORDER_CONFIRMED.YOUR_ORDER_HAS_BEEN_SENT": "Tu orden ha sido enviada",
  "MOBILE_ORDER_CONFIRMED.CONTINUE": "Continuar",
  "MOBILE_ORDER_CONFIRMED.POWERED_BY": "Powered by",
  "ORDER_CONFRMED_PAGE.THANK_YOU": "Gracias!",
  "ORDER_CONFRMED_PAGE.CONTINUE": "Continuar",
  "RADIO_GROUP_CARD.CASH": "En Efectivo",

  "TABLE_CENTER.MENU": "Menú",
  "TABLE_LEFT.CATEGORIES": "Categorías",
  "TABLE_LEFT.THIS_MENU_IS_A_DEMO_GET_YOURS_FOR_FREE":
    "Este menu es una demo, obtén el tuyo gratis",
  "TABLE_LEFT.SIGN_UP_FOR_FREE": "Regístrate Gratis",

  "TABLE_RIGHT.YOUR_ORDER": "Tu Orden",
  "TABLE_RIGHT.MAKE_AN_ORDER": "Hacer Pedido",
  "TABLE_RIGHT.DO_YOU_A_DISCOUNT_CODE": "¿Tienes un código de descuento?",
  "TABLE_RIGHT.DISCOUNT_CODE": "Código descuento",
  "TABLE_RIGHT.APPLY_TEXT": "aplicarText",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_DOSE_NOT_EXIST":
    "El codigo de descuento no existe",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_ALREADY_USE": "Código descuento ya utilizado",
  "TABLE_RIGHT.MINIMUM_ORDER": "Mínimo orden",
  "TABLE_RIGHT.HOME_DELIVERY": "Envío a Domicilio",
  "TABLE_RIGHT.TOTAL": "Total",
  "TABLE_RIGHT.SUB_TOTAL": "Subtotal",
  "TABLE_RIGHT.THE_DISCOUNT_CODE_IS_CORRECT_BUT_THE_MINIMUM_AMOUNT_TO_REQUEST_IS_IT":
    "El código de descuento es correcto pero la cantidad minima para solicitarlo es",
  "TABLE_RIGHT.COUPONS_NOT_AVAILABLE_FOR_THIS_ORDER_TYPE":
    "Cupón no disponible para este tipo de orden",
  "TABLE_RIGHT.DISCOUNT": "Descuento",

  "VENUE_ORDER.HOW_DO_WANT_YOUR_ORDER": "¿Cómo quieres tu pedido?",
  "NOT_FOUND_PAGE.RESTORENT_NOT_FOUND": "Restaurante no encontrado",

  SEE_ORDERS: "Ver Pedido",

  ORDER_PREPARATION_TIMING_HEADER_MODEL:
    "Please enter the email to see order preparation timing",
  ORDER_PREPARATION_BUTTON: "Receipt",

  HOW_DO_YOU_WANT_TO_BE_NOTIFIED: "¿Cómo quiere ser notificado?",
  GENERAL_AUTOSERVICE: "Autoservicio",
  I_DONT_WANT_TO_BE_NOTIFIED: "No quiero ser notificado",
  ENTER_YOUR_EMAIL_ADDRESS: "Introduzca su dirección de correo electrónico",
  EMAIL_ADDRESS: "Dirección de correo electrónico",
  NOTIFY_ME: "Notifícame",
  ENTER_YOUR_PHONE_NUMBER: "Introduzca su número de teléfono",
  PHONE_NUMBER: "Número de teléfono",
  ORDER_REF: "Ref. del pedido",
  TOTAL_AMOUNT: "Importe total",
  CREATING_TIME: "Tiempo de creación",
  "GENERAL:ORDER_IN_PROGRESS": "Orden en Progreso",
  "GENERAL:ORDER_IN_PROGRESS_PARGRAPH":
    "Click aquí para ver el detalle de la orden pendiente",
  RESTAURANT_BUSY: "El restaurante está ocupado ahora mismo",
  STATUS: "Estado",
  PARTIAL_DELIVERY: "Entrega Parcial",
  IN_PROGRESS: "En Proceso",
  "ORDER_STATUS.DELIVERED": "Completado",
  NO_TABLE_ID_ERROR:
    "Tuvimos un problema identificando tu mesa. Escanea el Codigo QR otra vez",
  TICKET_NO: "Ticket #",
  MINIMUM_ORDER: "Importe Mínimo",
  SELECT_PAYMENT_METHOD: "Selecciona un método de pago",
  EMPTY_NAME_ERROR: "Por favor, escribe tu nombre",
  CONTINUE_TO_PAY: "Continuar con el pago",
  SECURE_PAYMENTS: "Tu pago está encriptado y securizado",
  INVALID_CARD_NUMBER: "El número de la tarjeta es inválido",
  INVALID_EXPIRY_DATE: "La fecha de expiración es inválida",
  MONTH_RANGE: "El mes de expiración debe estar entre 01 y 12",
  YEAR_RANGE: "El año de expiración no puede estar en el pasado",
  DATE_RANGE: "La fecha de expiración no puede estar en el pasado",
  INVALID_CVV: "El código de seguridad es inválido",
  CREDIT_CARD: "Tarjeta de crédito",
  ADD_TIP: "Añadir propina",
  TIP: "Propina",
  TIP_TITLE: "Da las gracias con una propina",
  OTHER: "Otra",
  TIP_REMOVE: "Quitar propina",
  ENTER_AMOUNT: "Introduce cantidad",
  TERMS_CONDITION: "Al continuar, declaro que acepto los",
  TERMS_CONDITION2: "Términos y Condiciones y la Política de Privacidad.",
  BUSY_TABLE: "La mesa no admite ordenes en este momento.",
  BUSY_VENUE: "Le restaurant n'accepte pas de commandes pour le moment.",
  INVALID_EMAIL_ERROR: "Revisa la información facilitada",
  GENERAL_CLOSE: "Cerrar",
  PAY_IN_CASH: "Efectivo",
  CASH_MAKE_ORDER: "Hacer Pedido",
  TOTAL_WITHOUT_PROMO: "Total sin promoción",
  EMPTY_CART: "No hay pedidos en el carrito",
  DOWNLOAD_QR: "Descarga QR",
  MENU_HEADER1: "Este es un Menú para el",
  MENU_HEADER2: "que es el mejor de la ciudad.",
  GENERAL_ITEMS: "Productos",
  GENERAL_ITEM: "Productos",
  GENERAL_CASH: "Efectivo",
  GENERAL_VENUE_MESSAGE:
    "Este local le notificará cuando su pedido esté listo para recoger",
  NOTIFIED_VIA_EMAIL: "Notificación vía email",
  SEND_EMAIL_NOTIFICATION: "Enviaremos notificación a este correo electrónico",
  SEND_WHATSAPP_NOTIFICATION: "Enviaremos notificación a este whatsapp",
  SEND_SMS_NOTIFICATION: "Enviaremos notificación a este número de teléfono",
  NOTIFIED_VIA_WHATSAPP: "Notificación vía whatsapp",
  NOTIFIED_VIA_SMS: "Notificación vía SMS",
  GENERAL_WHATSAPP: "Whatsapp",
  GENERAL_SMS: "SMS",
  NOTIFICATION_METHOD: "Notificación por",
  CHOOSE_PAYMENT_METHOD: "Elige el método de pago",
  PAY_WITH: "Pagar con",
  CARD_NUMBER: "Número de la tarjeta",
  EXPIRY_DATE: "Fecha de expiración",
  CVC_CODE: "CVC",
  NEED_RECEIPT: "¿Necesitas un recibo?",
  NEED_RECEIPT_SUB: "Introduce tu correo electrónico para recibirlo",
  POST_REVIEW: "Dejar reseña",
  ORDER_LIST: "Lista de pedidos",
  PAY_ORDER: "Pagar pedido",
  GENERAL_POPULAR: "Popular",
  GENERAL_FOOD: "Comida",
  GENERAL_DRINK: "Bebida",
  SELECT_RATING: "Tu opinión es importante para nosotros",
  WRITE_REVIEW: "Escribe una reseña",
  WITHOUT_REVIEW: "Continuar sin reseña",
  ORDER_CONFIRMATION: "Confirmación de pedido",
  GENERAL_PEDIR: "Pedir",
  GENERAL_PAGAR: "Pagar",
  RATING_SUBHEADING: "Valora nuestro establecimiento",
  "HOME_PAGE.SEE_THE_MENU": "Pedir",
  "HOME_PAGE.SEE_THE_MENU_VB": "Ver Carta",
  "HOME_PAGE.PAY_ORDER": "Pagar",
  "HOME_PAGE.SCHEDULE_ORDER": "Programar pedido",
  "HOME_PAGE.TAKE_AWAY": "Take Away",
  "HOME_PAGE.TAKE_AWAY_ORDER": "Programar Pedido",
  SECURE_STORE_CARD:
    "Cifrar y almacenar de forma segura los datos de mi tarjeta para futuros pedidos.",
  VIEW_MENU: "Ver la carta",
  USE_CARD: "Usar tarjeta",
  PICKUP_TIME: "Horario Recogida",
  PICKUP_TIME_TODAY: "Hoy",
  PICKUP_TIME_TOMORROW: "Mañana",
  PICKUP_TIME_CHANGE: "Modificar",
  PICKUP_TIME_CONFIRM: "Confirmar",
  PICKUP_TIME_DAY: "Día",
  PICKUP_TIME_HOUR: "Hora",
  PICKUP_TIME_CHANGE_MESSAGE:
    "La hora de recogida ha sido actualizada. Puede modificarla si lo desea.",
  MENU_HIDE_MESSAGE_PART_1: "La cocina del restaurante",
  MENU_HIDE_MESSAGE_PART_2:
    "no está abierta en estos momentos. Consulte con nuestro personal el horario de apertura",
  ADD_NEW_CARD: "Usar otra tarjeta",
  CLOSED_KITCHEN_MESSAGE:
    "Lo sentimos, en estos momentos no aceptamos nuevos pedidos. Vuelve a intentarlo más tarde",
  AUTOSERVICE_NAME: "Nombre",
  AUTOSERVICE_ADD_NAME: "Por favor, introduzca su nombre",
  NOT_AVAILABLE: "No disponible",
  FOR_FREE: "Gratis",
  ITEMS_NOT_AVAILABLE:
    "Uno o varios artículos de su cesta ya no están disponibles",
  VIEW_CART: "Ver carta",
  CONTINUE: "Continuar",
  ANOTHER_PRODUCT_ERROR: "Por favor, seleccione otro producto",
  PRODUCT_NOT_AVAILABLE: "No disponible",
  ERROR_DATE_PAST:
    "La fecha seleccionada ya ha pasado. Por favor, elige una fecha futura",
  SELECT_LANGUAGE: "Seleccione un idioma",
  AUTOSERVICE_ADDRESS: "Dirección",
  AUTOSERVICE_ADD_ADDRESS: "Por favor agregue su dirección",
  "LANGUAGE.CHINESE": "Chino",
  ITEM: "Artículo",
  ITEMS: "Artículos",
  NO_MENU_ID_ERROR: "No se encontró ninguna ID de menú",
}
