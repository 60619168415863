import React, { useState, useEffect } from "react"
import { LockOutlined, CancelOutlined } from "@material-ui/icons"
import { GoogleLogin } from "react-google-login"
import { useDispatch, useSelector } from "react-redux"
import { FaFacebookF, FaGoogle } from "react-icons/fa"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

import Button from "@components/buttons/Button"
import CustomModal from "@components/modals/CustomModal"
import CustomLoader from "@components/Loaders/CustomLoader"
import { login, socialLogin, forgotPassword } from "@store/auth/AuthActions"
import { validateEmail } from "@helpers/utilsHelper"
import { Heading2, Body13, Body14, Heading5 } from "@components/Text/Text"
import { useHistory } from "react-router-dom"
import { useLanguageHelper } from "@helpers/LanguageHelper"

interface Props {
  showModal: boolean
  onCloseModal: () => void
  onSignUpClick?: () => void
  error?: string
  typography?: string
  venueId: string
  isBasket?: boolean
  checkDelivery?: boolean
  checkCollection?: boolean
  orderFromTable?: boolean
}
const Login = ({
  showModal,
  onCloseModal,
  onSignUpClick,
  error,
  typography,
  isBasket,
  venueId,
  checkDelivery,
  checkCollection,
  orderFromTable,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState("")
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)

  const loading = useSelector(({ auth }: RootState) => auth.loading)
  const { languageStrings, changeLanguage } = useLanguageHelper()

  useEffect(() => {
    if (showModal === false) {
      setEmail("")
      setPassword("")
    }
  }, [showModal])

  const canBeSubmit = () => {
    if (email && password) {
      return false
    }
    return true
  }

  const onSubmit = () => {
    dispatch(
      login(
        venueId,
        { email, password },
        isBasket,
        checkDelivery,
        checkCollection,
        history,
        orderFromTable,
      ),
    )
  }

  const responseFacebook = (response) => {
    const { name, image, email } = response

    if (name && name.length > 0) {
      const username = name.split(" ")
      const firstName = username[1]
      const lastName = username[0]
      dispatch(
        socialLogin(
          venueId,
          { firstName, lastName, image, email },
          isBasket,
          checkDelivery,
          checkCollection,
          history,
          orderFromTable,
        ),
      )
    }
  }

  const responseGoogle = (response) => {
    const { profileObj } = response

    if (profileObj) {
      const { name, imageUrl, email } = profileObj

      if (name && name.length > 0) {
        const username = name.split(" ")
        const firstName = username[1]
        const lastName = username[0]
        dispatch(
          socialLogin(
            venueId,
            { firstName, lastName, image: imageUrl, email },
            isBasket,
            checkDelivery,
            checkCollection,
            history,
          ),
        )
      }
    }
  }

  const responseGoogleFail = (err) => {
    console.log("Error", err)
  }
  const modalCloseHandler = () => {
    setEmailError("")
    setForgotPasswordModal(false)
  }
  const handleConfirm = () => {
    if (!validateEmail(email)) {
      setForgotPasswordModal(false)
      setEmailError(languageStrings["LOGIN.PLEASE_ENTER_A_VALID_EMAIL"])
      return
    }
    setEmailError("")
    dispatch(forgotPassword(email))
    setForgotPasswordModal(false)
  }
  return (
    <CustomLoader loading={loading}>
      <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
        <Box
          style={{ backgroundColor: typography }}
          className={"flex flex-col w-4/5 md:w-2/5 p-4 z-10"}
        >
          <CancelOutlined
            className={"flex self-end mt-2 mr-2 cursor-pointer"}
            style={{ color: "white" }}
            onClick={() => {
              onCloseModal()
            }}
          />

          <Box className={"flex flex-col items-center w-full p-4 md:p-10"}>
            <LockOutlined style={{ color: "white", fontSize: "60px" }} />
            <input
              style={{ backgroundColor: typography }}
              className={
                "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
              }
              type="text"
              placeholder={languageStrings["LOGIN.EMAIL"]}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
            />
            <input
              type="password"
              style={{ backgroundColor: typography }}
              className={
                "w-4/5 md:w-11/12 h-24 mt-6 md:mt-8 border border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
              }
              placeholder={languageStrings["LOGIN.PASSWORD"]}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <Box
              className={
                "flex flex-row w-4/5 mtLOGIN.PASSWORD-4 md:w-11/12 md:mt-8 justify-between"
              }
            >
              <Body13
                className={"text-white cursor-pointer"}
                onClick={() => {
                  setEmailError("")
                  setForgotPasswordModal(true)
                }}
              >
                {languageStrings["LOGIN.RECOVER_PASSWORD"]}
              </Body13>
              <label
                className={"text-white ml-8 md:ml-1"}
                style={{ fontSize: "13px" }}
                onClick={onSignUpClick}
              >
                {
                  languageStrings[
                    "LOGIN.YOU_DON'T_HAVE_AN_ACCOUNT_EASILY_CREATE_IT"
                  ]
                }
              </label>
            </Box>
            {emailError ? (
              <Body13
                variant="subtitle2"
                className={"flex mt-8 md:mt-4 text-red"}
              >
                {emailError}
              </Body13>
            ) : (
              error && (
                <Body13
                  variant="subtitle2"
                  className={"flex mt-8 md:mt-4 text-red"}
                >
                  {error}
                </Body13>
              )
            )}
            <Button
              color={typography}
              classes={
                canBeSubmit()
                  ? "w-4/5 md:w-11/12 h-24 mt-3 md:mt-10 mb-8 font-semibold  py-2 px-4 border-none rounded cursor-not-allowed"
                  : "w-4/5 md:w-11/12 h-24 mt-3 md:mt-10 mb-8 font-semibold  py-2 px-4 border-none rounded outline-none"
              }
              title={languageStrings["GENERAL.ACCESS"]}
              onClick={onSubmit}
              style={{ backgroundColor: "white" }}
              disabled={canBeSubmit()}
            />
            <Typography
              variant="subtitle1"
              className={"flex mt-8 md:mt-12 text-white"}
            >
              {languageStrings["GENERAL.EITHER"]}
            </Typography>
            {/* <FacebookLogin
              appId="1091024154646758"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  color="#ffff"
                  backgroundColor="#445c8e"
                  classes={
                    "w-4/5 md:w-11/12 border-none h-24 md:mt-12 mb-1 bg-white hover:bg-gray-700 font-semibold hover:text-white py-2 px-4 rounded outline-none"
                  }
                  title="Accede con Facebook"
                  icon={<FaFacebookF className="mr-6" />}
                  onClick={renderProps.onClick}
                />
              )}
            /> */}
            <GoogleLogin
              clientId={
                "573257472764-hufqlv6einslt53t3e0b0t0pdim364i7.apps.googleusercontent.com"
              }
              onSuccess={responseGoogle}
              onFailure={responseGoogleFail}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  color={"#db3236"}
                  icon={<FaGoogle className="mx-6" />}
                  classes={
                    "w-4/5 md:w-11/12 h-24 border-none md:mt-0 mb-1 bg-white font-semibold text-red py-2 px-4 rounded outline-none"
                  }
                  title={languageStrings["LOGIN.SIGN_IN_WITH_GOOGLE"]}
                />
              )}
            />
          </Box>
        </Box>
      </CustomModal>
      <CustomModal
        showModal={forgotPasswordModal}
        onCloseModal={modalCloseHandler}
      >
        <div className={"flex flex-col bg-white p-16 rounded"}>
          <Heading2 style={{ color: typography }}>
            {languageStrings["LOGIN.RECOVER_PASSWORD"]}
          </Heading2>
          <Body14 className={"ml-0 mr-4 mt-4"}>
            {
              languageStrings[
                "LOGIN.ARE_YOU_SURE_YOU_WANT_TO_RECOVER_YOUR_PASSWORD"
              ]
            }
          </Body14>
          <div className={"flex flex-row justify-between self-end w-4/6 mt-6"}>
            <Heading5 className={"cursor-pointer"} onClick={handleConfirm}>
              {languageStrings["GENERAL.CONFIRM"]}
            </Heading5>
            <Heading5 className={"cursor-pointer"} onClick={modalCloseHandler}>
              {languageStrings["GENERAL.CANCEL"]}
            </Heading5>
          </div>
        </div>
      </CustomModal>
    </CustomLoader>
  )
}
export default Login
