import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getVenueList } from "@store/venues/VenueActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import SmsIcon from "@material-ui/icons/SmsOutlined"
import { useHistory } from "react-router"
import { ItemOrderMenu } from "@models/Cart"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { MobileFooter } from "@components/footer/MobileFooter"
import MailOutline from "@material-ui/icons/MailOutline"
import { AppParagraph3 } from "@components/Text/AppText"
import { TextInput } from "@components/textInput/TextInput"
import {
  setOrderClientName,
  setOrderClientAddress,
} from "@store/orderFromTable/OrderFromTableActions"
import { set } from "react-ga"

interface Props {
  orderId?: string
  orderState?: {
    payload?: ItemOrderMenu
    totalPrice?: number
    tableId?: string
    venueId?: string
    amount?: string | number
  }
}

const MobileOrderConfirmedAutoService = ({ orderState }: Props) => {
  const { primaryColor, secondaryColor, thirdColor } = useBrand()

  const { languageStrings } = useLanguageHelper()
  const history = useHistory()
  const dispatch = useDispatch()
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)

  const loyaltyClientName = useSelector(
    ({ user }: RootState) => user.loyaltyClient?.name,
  )
  const { orderClientName } = useSelector(
    ({ orderFromTable }: any) => orderFromTable,
  )
  const { orderClientAddress } = useSelector(
    ({ orderFromTable }: any) => orderFromTable,
  )
  const isNameRequired = (venueDetail && venueDetail?.isNameRequired) || false
  const isAddressRequired =
    (venueDetail && venueDetail?.isAddressRequired) || false
  const [name, setName] = useState(orderClientName || "")
  const [error, setError] = useState(false)
  const [address, setAddress] = useState(orderClientAddress || "")
  const [errorAddress, setErrorAddress] = useState(false)
  const permalink = localStorage.getItem("clientPermalink")
  useEffect(() => {
    permalink && dispatch(getVenueList(permalink))
  }, [permalink])
  if (!orderState) return null

  const { payload, totalPrice = 0, venueId, tableId, amount } = orderState
  let discountVoucherAmount = 0

  const isBySmsNotification = venueDetail?.notifications?.find(
    (item) => item == "3",
  )
  const isByWhatsappNotification = venueDetail?.notifications?.find(
    (item) => item == "1",
  )
  const isByEmailNotification = venueDetail?.notifications?.find(
    (item) => item == "2",
  )
  const isOrderTypeAutoService = venueDetail?.orderType === "3"
  const isNameValid = !isNameRequired || (name && name.length > 2)
  const isAddressValid = !isAddressRequired || (address && address.length > 2)
  return (
    <div className={"flex flex-col"}>
      <div
        className="flex flex-col px-12 py-16 justify-center items-center"
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("@assets/Images/autoservice.png")}
          style={{ width: "95%", height: "95%" }}
        />
        {isNameRequired ? (
          <div className="w-full">
            <div className="flex flex-col px-12  w-full ">
              <TextInput
                style={{
                  marginTop: "2rem",
                }}
                label={languageStrings["AUTOSERVICE_NAME"]}
                value={name}
                inputProps={{
                  maxLength: 100,
                }}
                onValueChange={(e) => {
                  setName(e.target.value)
                  setError(false)
                }}
              />

              {error && (
                <div className="flex flex-col w-full items-start self-center md:w-6/12">
                  <AppParagraph3
                    style={{ color: theme.status.red, marginTop: "0.6rem" }}
                  >
                    {name
                      ? "El nombre debe incluir al menos 3 caracteres"
                      : languageStrings["AUTOSERVICE_ADD_NAME"]}
                  </AppParagraph3>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {isAddressRequired ? (
          <div className="w-full">
            <div className="flex flex-col px-12  w-full ">
              <TextInput
                style={{
                  marginTop: "2rem",
                }}
                label={languageStrings["AUTOSERVICE_ADDRESS"]}
                value={address}
                inputProps={{
                  maxLength: 150,
                }}
                onValueChange={(e) => {
                  setAddress(e.target.value)
                  setErrorAddress(false)
                }}
              />
              {errorAddress && (
                <div className="flex flex-col w-full items-start self-center md:w-6/12">
                  <AppParagraph3
                    style={{ color: theme.status.red, marginTop: "0.6rem" }}
                  >
                    {address
                      ? "La dirección debe incluir al menos 3 caracteres"
                      : languageStrings["AUTOSERVICE_ADD_ADDRESS"]}
                  </AppParagraph3>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <h6 className="font-thin" style={{ color: theme.main.greyLight }}>
          {languageStrings["NOTIFICATION_METHOD"]}
        </h6>
        <div className={"flex flex-row w-full -mt-8"}>
          {isByEmailNotification && (
            <div
              style={{
                height: "12vh",
                flex: 1,
              }}
              className="border border-solid border-custom-grey w-full rounded-lg mx-1"
            >
              <button
                type="button"
                className="w-full h-full p-0 border-0 rounded-lg"
                onClick={() => {
                  if (payload && payload.discountVoucherAmount) {
                    discountVoucherAmount = payload.discountVoucherAmount
                  }
                  if (
                    (isNameRequired ? name && name.length > 2 : true) &&
                    (isAddressRequired ? address && address.length > 2 : true)
                  ) {
                    dispatch(setOrderClientName(name))
                    dispatch(setOrderClientAddress(address))
                    history.push({
                      pathname: `/order-notified/${"by-email"}`,
                      state: {
                        payload,
                        tableId,
                        venueId,
                        totalPrice,
                        amount,
                      },
                    })
                    setError(false)
                    setErrorAddress(false)
                  } else {
                    if (!isNameValid) {
                      setError(true)
                    }
                    if (!isAddressValid) {
                      setErrorAddress(true)
                    }
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.main.newOrange,
                  }}
                  className="flex flex-col h-full rounded-lg justify-center items-center text-white"
                >
                  <MailOutline
                    style={{
                      color: theme.main.white,
                      marginTop: 16,
                      marginBottom: -10,
                      fontSize: "2.5rem",
                      fontWeight: 100,
                    }}
                  />
                  <h4 className="font-thin">
                    {languageStrings["INVOICE_MODEL.EMAIL"]}
                  </h4>
                </div>
              </button>
            </div>
          )}
          {isByWhatsappNotification && (
            <div
              style={{
                height: "12vh",
                flex: 1,
              }}
              className="border border-solid border-custom-grey w-full rounded-lg mx-1"
            >
              <button
                type="button"
                className="w-full h-full p-0 border-0 rounded-lg"
                onClick={() => {
                  if (
                    (isNameRequired ? name && name.length > 2 : true) &&
                    (isAddressRequired ? address && address.length > 2 : true)
                  ) {
                    dispatch(setOrderClientName(name))
                    dispatch(setOrderClientAddress(address))
                    let _orderPrice = 0
                    if (totalPrice) {
                      _orderPrice = totalPrice
                    }
                    history.push({
                      pathname: `/order-notified/${"by-whatsapp"}`,
                      state: {
                        payload,
                        tableId,
                        venueId,
                        totalPrice,
                      },
                    })
                    setError(false)
                    setErrorAddress(false)
                  } else {
                    if (!isNameValid) {
                      setError(true)
                    }
                    if (!isAddressValid) {
                      setErrorAddress(true)
                    }
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.main.newOrange,
                  }}
                  className="flex flex-col h-full rounded-lg justify-center items-center text-white"
                >
                  <WhatsAppIcon
                    style={{
                      color: theme.main.white,
                      marginTop: 16,
                      marginBottom: -10,
                      fontSize: "2.5rem",
                      fontWeight: 100,
                    }}
                  />
                  <h4 className="font-thin">
                    {languageStrings["GENERAL_WHATSAPP"]}
                  </h4>
                </div>
              </button>
            </div>
          )}
          {isOrderTypeAutoService && isBySmsNotification && (
            <div
              style={{
                height: "12vh",
                flex: 1,
              }}
              className="border border-solid border-custom-grey w-full rounded-lg mx-1"
            >
              <button
                type="button"
                className="w-full h-full p-0 border-0 rounded-lg"
                onClick={() => {
                  if (
                    (isNameRequired ? name && name.length > 2 : true) &&
                    (isAddressRequired ? address && address.length > 2 : true)
                  ) {
                    dispatch(setOrderClientName(name))
                    dispatch(setOrderClientAddress(address))
                    history.push({
                      pathname: `/order-notified/${"by-sms"}`,
                      state: {
                        payload,
                        tableId,
                        venueId,
                        totalPrice,
                      },
                    })
                    setError(false)
                    setErrorAddress(false)
                  } else {
                    if (!isNameValid) {
                      setError(true)
                    }
                    if (!isAddressValid) {
                      setErrorAddress(true)
                    }
                  }
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.main.newOrange,
                  }}
                  className="flex flex-col h-full rounded-lg justify-center items-center text-white"
                >
                  <SmsIcon
                    style={{
                      color: theme.main.white,
                      marginTop: 16,
                      marginBottom: -10,
                      fontSize: "2.5rem",
                      fontWeight: 100,
                    }}
                  />
                  <h4 className="font-thin">
                    {languageStrings["GENERAL_SMS"]}
                  </h4>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default MobileOrderConfirmedAutoService
