import React from "react"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"


import {
  Heading3,
  Heading5,
} from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"

const EmptyBasketCard = () => {
  const { languageStrings, changeLanguage } = useLanguageHelper()
  const useStyles = makeStyles(() => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
    }),
  }))
  const Classes = useStyles()
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center justify-start md:w-full w-11/12 mt-16 bg-white shadow-2xl rounded-4",
        Classes.root,
      ].join()}
    >
      <Heading3
        style={{
          marginTop: 8,
          marginBottom: 8,
          marginLeft: 14,
          textTransform: "uppercase",
        }}
      >
        {languageStrings["VIRTUAL_BASKET.MY_ORDER"]}
      </Heading3>
      <Heading5 className="self-center pb-16 pt-6">{languageStrings["EMPTY_CART"]}</Heading5>
    </Card>
  )
}

export default EmptyBasketCard
