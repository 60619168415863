import { ItemOrderMenu } from "@models/Cart"
import AmericanCard from "@assets/Images/american-card.png"
import MasterCard from "@assets/Images/mastercard-card.png"
import visaCard from "@assets/Images/visa-card.png"
import otherCard from "@assets/Images/other-card.png"

export const cartCounter = (
  itemsCart: ItemOrderMenu[],
  totalTax: number = 0,
) => {
  let totalItems = 0
  let totalPrice = 0
  let taxApplied = 0
  let netPrice = 0
  let promotionPrice = 0

  if (itemsCart) {
    itemsCart.map((item) => {
      totalItems = totalItems + Number(item.quantity)
      totalPrice = totalPrice + Number(item.price * item.quantity)
      promotionPrice =
        promotionPrice +
        item.price * (item.quantity - (item.discountedQuantity || 0))
    })
  }
  netPrice = totalPrice / (1 + totalTax)
  taxApplied = netPrice * totalTax

  return {
    totalItems,
    totalPrice: totalPrice.toFixed(2),
    netPrice: netPrice.toFixed(2),
    taxApplied: taxApplied.toFixed(2),
    promotionPrice,
  }
}
export const virtualBasketCounter = (
  itemsCart: ItemOrderMenu[],
  totalTax: number = 0,
  isHosteltactil: boolean = false,
  posPrice: number = 0,
  isFrontrest: boolean = false,
  originalPrice: number = 0,
) => {
  let totalItems = 0
  let totalPrice = 0
  let taxApplied = 0
  let netPrice = 0
  let promotionPrice = 0

  if (itemsCart) {
    itemsCart.map((item) => {
      const disCountedQuantity = item.discountedQuantity ?? 0
      totalItems = totalItems + Number(item.quantity)
      totalPrice = totalPrice + Number(item.price)
      promotionPrice =
        promotionPrice + (item?.unitPrice ?? 0) * disCountedQuantity
    })
  }
  if (isHosteltactil) {
    totalPrice = posPrice
  }
  if (isFrontrest && originalPrice) {
    totalPrice = originalPrice
  }
  netPrice = totalPrice / (1 + totalTax)
  taxApplied = netPrice * totalTax
  return {
    totalItems,
    totalPrice: totalPrice.toFixed(2),
    netPrice: netPrice.toFixed(2),
    taxApplied: taxApplied.toFixed(2),
    promotionPrice,
  }
}

export const getDiscountedPrice = (
  totalPrice,
  discountApplicable,
  discountType,
  discountAmount,
) => {
  let discountedPrice = Number(totalPrice)
  if (discountApplicable) {
    if (discountType === "percentage") {
      if (discountAmount === 100) {
        discountedPrice = Number(totalPrice)
      } else {
        discountedPrice =
          discountedPrice - (discountedPrice * Number(discountAmount)) / 100
      }
    } else if (discountType === "quantity") {
      discountedPrice = discountedPrice - Number(discountAmount)
    }
  }

  return Number(discountedPrice.toFixed(2))
}

export const totalPriceCustomItem = (item: ItemOrderMenu) => {
  let newPrice = Number(item.totalPrice ?? item.price)
  if (item.isCombo) {
    item?.comboCategories?.forEach((comboCategory) => {
      comboCategory.products?.forEach((product) => {
        if (product.selected && product.comboSupplementPrice) {
          newPrice += Number(product.comboSupplementPrice)
        }

        product.extraCategories?.forEach((extraCategory) => {
          extraCategory.items.forEach((item) => {
            let selectedQuantity = 1
            if (item?.selectedQuantity) {
              selectedQuantity = item?.selectedQuantity
            }
            if (item.selected && item.price) {
              if (item.selected && item.price) {
                newPrice =
                  Number(newPrice) +
                  Number(item.price * Number(selectedQuantity))
              }
            }
          })
        })
      })
    })
  } else {
    item.extraCategories?.map((item: ExtraCategory) => {
      item.items?.map((item: ExtraCategoryItem) => {
        let _totalPrice = 1

        if (item?.selectedQuantity) {
          _totalPrice = item?.selectedQuantity
        }
        if (item.selected && item.price) {
          newPrice = Number(newPrice) + Number(item.price * Number(_totalPrice))
        }
      })
    })
  }

  return newPrice
}

export const hasFamilyPropertyItemSelected = (items: ExtraCategoryItem[]) => {
  let hasSelected = false

  items?.map((item: ExtraCategoryItem) => {
    if (item.selected) {
      hasSelected = true
    }
  })

  return hasSelected
}

export const getCardImage = (card?: string) => {
  let cardImage

  switch (card) {
    case "Visa": {
      cardImage = visaCard
      break
    }
    case "MasterCard": {
      cardImage = MasterCard
      break
    }
    case "American Express": {
      cardImage = AmericanCard
      break
    }
    default: {
      cardImage = otherCard
      break
    }
  }

  return cardImage
}
