import React from "react"
import { StickyContainer, Sticky } from "react-sticky"

import classes from "@components/tables/tableleft/tableLeft.module.css"
import EditProfileButton from "./EditProfileButton"
import { Heading2 } from "@components/Text/Text"
import { clientSetting } from "config"
import { useLanguageHelper } from "@helpers/LanguageHelper"

export interface Props {
  typography?: string
  onLogoutClick?: any
  onEditProfileClick?: any
  onChangePasswordClick?: any
  onMyOrderClick?: any
  selectedOption?: string
}

const ProfileLeftTable = ({
  typography,
  onLogoutClick,
  onEditProfileClick,
  onChangePasswordClick,
  onMyOrderClick,
  selectedOption,
}: Props) => {
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <StickyContainer className="w-4/12 lg:w-3/12 mx-12 pt-5">
      {clientSetting.showProfileTable && (
        <Sticky disableCompensation>
          {({ style, isSticky }) => {
            return (
              <div style={style} className={isSticky ? classes.stickyLeft : ""}>
                <div className={classes.container}>
                  <div className={classes.categoriesTitle}>
                    <Heading2>{languageStrings["PROFILE.PROFILE"]}</Heading2>
                  </div>
                  <div className={classes.tablelist}>
                    <ul className={classes.tableul}>
                      <EditProfileButton
                        title={languageStrings["PROFILE.EDIT_PROFILE"]}
                        selected={
                          selectedOption ==
                          languageStrings["PROFILE.EDIT_PROFILE"]
                            ? true
                            : false
                        }
                        typography={typography}
                        onClick={onEditProfileClick}
                      />
                      <EditProfileButton
                        title={languageStrings["PROFILE.CHANGE_PASSWORD"]}
                        typography={typography}
                        onClick={onChangePasswordClick}
                        selected={
                          selectedOption ==
                          languageStrings["PROFILE.CHANGE_PASSWORD"]
                            ? true
                            : false
                        }
                      />
                      <EditProfileButton
                        title={languageStrings["PROFILE.MY_ORDERS"]}
                        typography={typography}
                        onClick={onMyOrderClick}
                        selected={
                          selectedOption == languageStrings["PROFILE.MY_ORDERS"]
                            ? true
                            : false
                        }
                      />
                      <EditProfileButton
                        title={languageStrings["PROFILE.LEAVE"]}
                        typography={typography}
                        onClick={onLogoutClick}
                        selected={
                          selectedOption == languageStrings["PROFILE.LEAVE"]
                            ? true
                            : false
                        }
                      />
                    </ul>
                  </div>
                </div>
              </div>
            )
          }}
        </Sticky>
      )}
    </StickyContainer>
  )
}

export default ProfileLeftTable
