import {
  AppHeading5,
  AppHeading5Bold,
  AppHeading5Medium,
} from "@components/Text/AppText"
import { Heading4, Heading5, Label14 } from "@components/Text/Text"
import React, { FC } from "react"

interface IProps {
  heading: string
  value: string
  containerClassName?: string
  headingClassName?: string
  valueClassName?: string
  width?: string
}
export const AmountChip: FC<IProps> = ({
  heading = "",
  value = "",
  containerClassName,
  headingClassName,
  txtColor,
  width,
}) => {
  return (
    <div className={containerClassName}>
      <div className={`${headingClassName} flex ${width} justify-end`}>
        <AppHeading5Bold style={{ color: txtColor }}>{heading}</AppHeading5Bold>
      </div>
      <AppHeading5 style={{ color: txtColor }}>{value}</AppHeading5>
    </div>
  )
}
interface IProps {
  heading: string
  value: string
  containerClassName?: string
  headingClassName?: string
  txtColor?: string
}
export const TotalAmountChip: FC<IProps> = ({
  heading = "",
  value = "",
  containerClassName,
  headingClassName,
  txtColor,
}) => {
  return (
    <div className={containerClassName}>
      <div className={`${headingClassName} flex w-3/4 justify-end`}>
        <AppHeading5Bold style={{ color: txtColor }}>{heading}</AppHeading5Bold>
      </div>
      <AppHeading5 style={{ color: txtColor }}>{value}</AppHeading5>
    </div>
  )
}
