import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import classes from "@modules/venueDetails/venuedetails.module.css"
import VirtualBasketDetails from "./VirtualBasketDetail"
import {
  getTable,
  getVirtualBasket,
} from "@store/orderFromTable/OrderFromTableActions"
import CustomLoader from "@components/Loaders/CustomLoader"
import { useHistory } from "react-router-dom"
import { useBrand } from "@hooks/useBrand"
import { getVenueDetail } from "@store/venues/VenueActions"

const MyVirtualBasket = (props) => {
  const { tableId, venueId, permalink } = props.match.params

  const dispatch = useDispatch()
  const history = useHistory()
  const virtualBasket = useSelector(
    ({ orderFromTable }) => orderFromTable.virtualBasket,
  )
  const { loading } = useSelector(({ orderFromTable }) => orderFromTable)

  useEffect(() => {
    if (venueId && tableId) {
      dispatch(getVirtualBasket(venueId, tableId, history))
    }
  }, [venueId, tableId])
  useEffect(() => {
    if (tableId) {
      dispatch(getTable(tableId))
    }
  }, [tableId])
  useEffect(() => {
    dispatch(getVenueDetail(venueId))
  }, [venueId])
  const { primaryColor } = useBrand()
  const typography = primaryColor
  return (
    <CustomLoader loading={loading}>
      <div className={classes.container}>
        <VirtualBasketDetails
          tableId={tableId}
          orders={virtualBasket}
          typography={typography}
          venueId={venueId}
          permalink={permalink}
        />
      </div>
    </CustomLoader>
  )
}

export default MyVirtualBasket
