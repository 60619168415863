import { OrderFromTableActionTypes } from "@store/redux/actionTypes"

const INITIAL_STATE: OrderFromTableState = {
  orderFromTable: {},
  virtualBasket: [],
  loading: false,
  virtualBasketCard: "",
  receiptId: "",
  showInvoiceModel: false,
  isPosPayment: false,
  showOrderTmingExtimationModel: false,
  showPendingOrderTimingDetailsModel: false,
  currentDevicePendingList: [],
  cocacolaQR: "",
  createdOrder: {},
  userCards: [],
  programmedOrderDate: "",
  orderClientName: "",
  orderClientAddress: "",
  tbai: "",
  qrUrl: "",
}

interface Action {
  payload: any
  type: string
}

const OrderFromTableReducer = (
  state: OrderFromTableState = INITIAL_STATE,
  action: Action,
): OrderFromTableState => {
  switch (action.type) {
    case OrderFromTableActionTypes.GET_TABLE_START: {
      return { ...state, loading: true, orderFromTable: {} }
    }
    case OrderFromTableActionTypes.GET_TABLE_SUCCESS: {
      return {
        ...state,
        orderFromTable: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_TABLE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_VIRTUAL_BASKET_START: {
      return { ...state, loading: true, isPosPayment: false }
    }
    case OrderFromTableActionTypes.GET_VIRTUAL_BASKET_SUCCESS: {
      return {
        ...state,
        virtualBasket: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_VIRTUAL_BASKET_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_BASKET_CARD: {
      return {
        ...state,
        virtualBasketCard: action.payload,
      }
    }
    case OrderFromTableActionTypes.SET_SHOW_INVOICE_MODE_SUCCESS: {
      return {
        ...state,
        isPosPayment: action.payload.isPosPayment,
        receiptId:
          action.payload && action.payload.receiptId
            ? action.payload.receiptId
            : "",
        showInvoiceModel:
          action.payload.isPosPayment === true
            ? false
            : action.payload.showModel,
        createdOrder:
          action.payload && action.payload.data ? action.payload.data : "",
      }
    }
    case OrderFromTableActionTypes.SET_SHOW_ORDER_TIME_ESTIMATION_MODE_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case OrderFromTableActionTypes.SET_SHOW_ORDER_TIME_ESTIMATION_MODE_SUCCESS: {
      return {
        ...state,
        showOrderTmingExtimationModel: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_SHOW_ORDER_TIME_ESTIMATION_MODE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_SHOW_PENDING_ORDER_MODE_SUCCESS: {
      return {
        ...state,
        showPendingOrderTimingDetailsModel: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_CURRENT_DEVICE_PENDING_ORDERS_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case OrderFromTableActionTypes.GET_CURRENT_DEVICE_PENDING_ORDERS_SUCCESS: {
      return {
        ...state,
        currentDevicePendingList: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_CURRENT_DEVICE_PENDING_ORDERS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.PAY_CASH_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case OrderFromTableActionTypes.GET_COCACOLA_QR_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case OrderFromTableActionTypes.GET_COCACOLA_QR_SUCCESS: {
      return {
        ...state,
        cocacolaQR: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_COCACOLA_QR_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.CLEAN_COCACOLA_QR: {
      return {
        ...state,
        cocacolaQR: "",
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_USER_CARDS_START: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_USER_CARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userCards: action.payload,
      }
    }
    case OrderFromTableActionTypes.GET_USER_CARDS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_ORDER_DATE: {
      return {
        ...state,
        programmedOrderDate: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_ORDER_CLIENT_NAME: {
      return {
        ...state,
        orderClientName: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.SET_ORDER_CLIENT_ADDRESS: {
      return {
        ...state,
        orderClientAddress: action.payload,
        loading: false,
      }
    }
    case OrderFromTableActionTypes.GET_TICKET_BAI_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case OrderFromTableActionTypes.GET_TICKET_BAI_SUCCESS: {
      return {
        ...state,
        loading: false,
        tbai: action.payload.tbai,
        qrUrl: action.payload.qrUrl,
      }
    }
    case OrderFromTableActionTypes.GET_TICKET_BAI_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default OrderFromTableReducer
