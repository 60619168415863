import React, { useState, useEffect } from "react"
import MobileProfile from "./MobileProfile/MobileProfilePage"
import DesktopProfile from "./DesktopProfile/DesktopProfilePage"
import { clientSetting } from "config"
import { useHistory } from "react-router-dom"

const ProfilePage = (props) => {
  const [width, setWidth] = useState(window.innerWidth)
  const history = useHistory()
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const isMobile = width <= 600
  const getMobileProfile = () => {
    if (clientSetting.showProfileTable) {
      return <MobileProfile {...props} />
    } else {
      history.push("/my_orders")
    }
  }
  return <>{isMobile ? getMobileProfile() : <DesktopProfile {...props} />}</>
}

export default ProfilePage
