import React, { createContext } from "react"

export const LanguageContext = createContext({
  locale: "es",
  changeLocale: (locale: string, changeOnFrontend: boolean) => {},
  menuLanguage: 1,
  changeMenuLanguage: (language: number) => {},
  messages: {},
  saveChangedVenue: (clientId: string) => {}
})
