import React, { useEffect, useMemo, useState } from "react"
import { Heading3 } from "@components/Text/Text"
import { useDispatch, useSelector } from "react-redux"
import { getVenueList } from "@store/venues/VenueActions"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import "./style.css"
import PhoneInput, { Value } from "react-phone-number-input"

import { useLocation, useHistory } from "react-router-dom"
import CustomLoader from "@components/Loaders/CustomLoader"
import { goToBasketPayment } from "@helpers/utilsHelper"
import { LocationState } from "@models/Location"
import BackButton from "@components/backButton/BackButton"
import { ButtonIcon } from "@components/buttons/ButtonIcon"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import { TextInput } from "@components/textInput/TextInput"
import { MobileFooter } from "@components/footer/MobileFooter"
import { ScreenHeading } from "@components/screenHeading/ScreenHeading"
import { AppParagraph3 } from "@components/Text/AppText"
import { NOTIFICATION_TYPES } from "@helpers/constants"
import useWindowSize from "@hooks/useWindowSize"

const MobileOrderNotified = (props) => {
  const { isMobile } = useWindowSize()
  const loyaltyClientEmail = useSelector(
    ({ user }: RootState) => user.loyaltyClient?.email,
  )
  const [fieldValue, setFieldValue] = useState(loyaltyClientEmail || "")
  const { languageStrings } = useLanguageHelper()
  const { type } = props.match.params
  const dispatch = useDispatch()
  const history = useHistory()
  const { primaryColor } = useBrand()
  const { loading = false } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  const location = useLocation<LocationState>()
  const payload = location.state

  const permalink = localStorage.getItem("clientPermalink")
  const [value, setValue] = useState<Value>()
  const {
    isByEmail,
    isByWhatsapp,
    isBySMS,
    headingLanguageString,
    subHeadingLanguageString,
    validator,
    inputLabelLanguageString,
    placeHolderTextLanguageString,
  } = useMemo(() => {
    const isByEmail = type === "by-email"
    const isByWhatsapp = type === "by-whatsapp"
    const isBySMS = type === "by-sms"

    const {
      headingLanguageString,
      subHeadingLanguageString,
      validator,
      inputLabelLanguageString,
      placeHolderTextLanguageString,
    } = NOTIFICATION_TYPES.find((item) => item.type === type) || {}

    return {
      isByEmail,
      isByWhatsapp,
      isBySMS,
      headingLanguageString,
      subHeadingLanguageString,
      validator,
      inputLabelLanguageString,
      placeHolderTextLanguageString,
    }
  }, [type])
  useEffect(() => {
    permalink && dispatch(getVenueList(permalink))
  }, [])

  if (
    !payload ||
    !payload.payload ||
    !payload.tableId ||
    !payload.totalPrice ||
    !payload.venueId
  ) {
    return null
  }
  const sentEmail = async () => {
    const { payload: cartItems, tableId, totalPrice = 0, venueId } = payload
    let orderItems = { ...cartItems, email: "", whatsapp: "" }
    const { discountVoucherAmount = 0 } = cartItems
    const fullyDiscounted =
      discountVoucherAmount.toFixed(2) === totalPrice.toFixed(2)

    if (isByEmail) {
      orderItems.email = fieldValue
    } else if (isBySMS) {
      orderItems.phoneNumber = value
    } else {
      orderItems.whatsapp = value
    }

    const __payload = {
      payload: orderItems,
      tableId,
      amount: payload.amount
        ? payload.amount
        : `${(totalPrice - discountVoucherAmount).toFixed(2)}`,
      venueId,
      total: totalPrice,
    }
    if (fullyDiscounted) {
      const permalink = localStorage.getItem("clientPermalink")
      window.location.href = `/${permalink}/${tableId}`
    } else {
      goToBasketPayment(history, __payload)
    }
  }
  const validate = validator && validator(isByEmail ? fieldValue : `${value}`)
  return (
    <CustomLoader loading={loading}>
      <BackButton>
        <ScreenHeading
          heading={languageStrings[headingLanguageString || ""]}
          style={{ marginLeft: "0.6rem" }}
          subHeading={languageStrings[subHeadingLanguageString || ""]}
        />
        <div style={{ backgroundColor: theme.main.dark5 }}>
          <div>
            <div className="flex flex-col px-12  w-full ">
              <Heading3
                style={{
                  marginTop: "2rem",
                  fontWeight: 400,
                  fontFamily: "ProductSansRegular",
                }}
              >
                {languageStrings[inputLabelLanguageString || ""]}
              </Heading3>
              {isByEmail && (
                <TextInput
                  style={{
                    width: isMobile ? "100%" : "50%",
                  }}
                  label={languageStrings[placeHolderTextLanguageString || ""]}
                  value={fieldValue}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onValueChange={(e) => {
                    setFieldValue(e.target.value)
                  }}
                />
              )}
              {isByWhatsapp && (
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="ES"
                  value={value}
                  onChange={setValue}
                  style={{
                    border: "0px",
                  }}
                />
              )}
              {isBySMS && (
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="ES"
                  value={value}
                  onChange={setValue}
                  style={{
                    border: "0px",
                  }}
                />
              )}

              <div className="flex flex-col w-full items-start self-center md:w-6/12">
                <AppParagraph3
                  style={{ color: theme.status.red, marginTop: "0.6rem" }}
                >
                  {isByEmail && !validate && fieldValue.length !== 0
                    ? languageStrings["INVALID_EMAIL_ERROR"]
                    : ""}
                </AppParagraph3>
              </div>
            </div>
          </div>
          <div className={"flex flex-col items-center pb-20 mx-12 mt-20"}>
            <ButtonIcon
              disabled={!validate}
              title={languageStrings["NOTIFY_ME"]}
              style={{
                borderRadius: "25px",
                fontSize: "12px",
                backgroundColor: validate
                  ? theme.main.newOrange
                  : theme.main.grey,
                border: "0px",
              }}
              textStyle={{
                color: validate ? theme.main.white : theme.main.darkBlack,
              }}
              onClick={() => {
                sentEmail()
              }}
            />
          </div>
        </div>
        <MobileFooter />
      </BackButton>
    </CustomLoader>
  )
}

export default MobileOrderNotified
