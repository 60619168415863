import React, { CSSProperties, FC } from "react"
import { Add, Remove } from "@material-ui/icons"
import { useBrand } from "@hooks/useBrand"
import classes from "./modals.module.css"
import { theme } from "config/theme"

interface IProps {
  onClick: (e?: any) => void
  iconFontSize?: "inherit" | "default" | "small" | "large"
  containerStyle?: CSSProperties
  iconStyle?: CSSProperties
  containerClass?: string
  iconClass?: string
}
export const AddCircle: FC<IProps> = ({
  onClick,
  iconFontSize = "default",
  containerStyle = {},
  iconStyle = {},
  containerClass = "",
  iconClass = "",
}) => {
  const { primaryColor, secondaryColor, venueOrderType } = useBrand()
  return (
    <div
      style={{
        height: "1.5rem",
        width: "1.5rem",
        backgroundColor: primaryColor,
        ...(containerStyle || {}),
        visibility: venueOrderType === "5" ? "hidden" : undefined,
      }}
      className={[
        "flex justify-center items-center rounded-full",
        containerClass,
      ].join(" ")}
    >
      <Add
        style={{ color: secondaryColor, ...(iconStyle || {}) }}
        fontSize={iconFontSize}
        onClick={onClick}
        className={[classes.buttons, iconClass].join(" ")}
      />
    </div>
  )
}
export const RemoveCircle: FC<IProps> = ({
  onClick,
  iconFontSize = "default",
  containerStyle = {},
  iconStyle = {},
  containerClass = "",
  iconClass = "",
}) => {
  const { primaryColor, secondaryColor, venueOrderType } = useBrand()
  return (
    <div
      style={{
        height: "1.5rem",
        width: "1.5rem",
        backgroundColor: primaryColor,
        ...(containerStyle || {}),
        visibility: venueOrderType === "5" ? "hidden" : undefined,
      }}
      className={[
        "flex justify-center items-center rounded-full",
        containerClass,
      ].join(" ")}
    >
      <Remove
        style={{ color: secondaryColor, ...(iconStyle || {}) }}
        fontSize={iconFontSize}
        onClick={onClick}
        className={[classes.buttons, iconClass].join(" ")}
      />
    </div>
  )
}
