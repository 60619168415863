import RoundedButton from "@components/buttons/Button"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  makeStyles,
} from "@material-ui/core"
import React, { FC, useState } from "react"
import CustomModal from "./CustomModal"
import { useHistory } from "react-router-dom"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { goToBasketPayment } from "@helpers/utilsHelper"
import { AppHeading2, AppParagraph3 } from "@components/Text/AppText"
import { theme } from "config/theme"
import { ItemDivider } from "@modules/menuitem/mobile/components/ItemDivider"
import { useBrand } from "@hooks/useBrand"
import { TextInput } from "@components/textInput/TextInput"
import useClientCurrency from "@hooks/useClientCurrency"
interface StyleProps {
  typography?: string
  mainNew?: string
}
interface Props {
  showModal: boolean
  typography: string
  tableId: string
  venueId: string
  totalAmount?: number
  amountPaid?: number
  onCloseModal: () => any
}
const SplitBillModal: FC<Props> = ({
  showModal,
  onCloseModal,
  totalAmount = 0,
  amountPaid = 0,
  tableId = "",
  venueId = "",
}) => {
  const { primaryColor } = useBrand()
  const { clientCurrency } = useClientCurrency()
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
      textAlign: "left",
      backgroundColor: "#dedede57",
      alignItems: "center",
      padding: "0.6rem",
    },
    rootForm: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
    },
    checked: (props) => ({
      color: `${props.mainNew} !important`,
    }),
    focused: {
      color: "black !important",
    },
  }))
  const styleProps: StyleProps = {
    typography: primaryColor,
    mainNew: theme.main.newOrange,
  }
  const classes = useStyles(styleProps)
  const [value, setValue] = useState("full")
  const [amount, setAmount] = useState("")

  const [isFocused, setIsFocused] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  const { languageStrings } = useLanguageHelper()

  const history = useHistory()

  const decimalCheck = () => {
    if (amount) {
      let decimals = ""
      const _amount = amount.toString().replace(",", ".")
      if (_amount.includes(".")) {
        decimals = _amount.split(".")[1]
      }
      return decimals?.length > 2
    } else return false
  }

  const errorAmount = () => {
    let errorMSg = ""
    let isError = false
    const decimalAmountCheck = decimalCheck()
    const _amount = Number(amount)
    const _amountPaid = amountPaid ? Number(amountPaid.toFixed(2)) : 0
    if (_amount <= 0 && isFocused) {
      errorMSg = languageStrings["SPLIT_BILL_MODEL.AMONT_IS_MANDATORY"]
      isError = true
    } else if (_amount > totalAmount - _amountPaid && isFocused) {
      errorMSg =
        languageStrings[
          "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID"
        ]
      isError = true
    } else if (!isFocused && value === "split" && !_amount) {
      isError = true
    } else if (decimalAmountCheck) {
      isError = true
      errorMSg = languageStrings["SPLIT_BILL_MODEL.DECIMALS"]
    }
    return { errorMSg, isError: value === "full" ? false : isError }
  }
  const payBasket = () => {
    let _amount = 0
    if (value === "full") {
      _amount = totalAmount - amountPaid
    } else {
      _amount = Number(amount)
    }
    if (_amount <= totalAmount - amountPaid) {
      setIsFocused(false)
      onCloseModal()
      const __payload = {
        payload: null,
        tableId,
        amount: `${_amount.toFixed(2)}`,
        venueId,
        total: totalAmount,
      }
      goToBasketPayment(history, __payload)
    }
  }
  const isErr = errorAmount().isError
  return (
    <>
      <CustomModal
        showModal={showModal}
        onCloseModal={() => {
          setIsFocused(false)
          onCloseModal()
        }}
      >
        <div
          className={"flex flex-col bg-white py-24 rounded w-11/12 lg:w-1/3"}
        >
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="mb-12 ml-8"
            >
              <AppHeading2 style={{ color: theme.main.primary }}>
                {languageStrings["SPLIT_BILL_MODEL.PAYMENT"]}
              </AppHeading2>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                style={{
                  marginLeft: "1.2rem",
                  marginBottom: "0.6rem",
                }}
                value="full"
                control={
                  <Radio
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                }
                label={`${
                  languageStrings["SPLIT_BILL_MODEL.PAY_FULL_AMOUNT"]
                } ${(totalAmount - amountPaid).toFixed(2)}${
                  clientCurrency.symbol
                }`}
              />
              <ItemDivider />

              <FormControlLabel
                style={{
                  marginLeft: "1.2rem",
                  marginBottom: "0.6rem",
                }}
                value="split"
                control={
                  <Radio
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                }
                label={languageStrings["SPLIT_BILL_MODEL.PARTIAL_PAYMENY"]}
              />
            </RadioGroup>
            {value === "split" && (
              <div
                style={{
                  width: "92%",
                  alignSelf: "center",
                }}
              >
                <TextInput
                  label={
                    languageStrings["SPLIT_BILL_MODEL.ENTER_AMOUNT_TO_PAY"]
                  }
                  inputName="longitude"
                  inputType={"number"}
                  value={amount}
                  inputProps={{
                    min: 0,
                  }}
                  isError={errorAmount().isError}
                  onValueChange={(event) => {
                    setIsFocused(true)
                    setAmount(event.target.value)
                  }}
                />
                {!!errorAmount() ? (
                  <AppParagraph3
                    style={{ color: theme.status.red, marginTop: "0.6rem" }}
                  >
                    {errorAmount().errorMSg}
                  </AppParagraph3>
                ) : null}
              </div>
            )}
            <RoundedButton
              disabled={isErr}
              title={languageStrings["SPLIT_BILL_MODEL.PAYMENT"]}
              txtColor={isErr ? theme.main.black : theme.main.white}
              style={{
                backgroundColor: isErr ? theme.main.grey : theme.main.newOrange,
                borderColor: "transparent",
                height: "3rem",
                marginTop: "20px",
                borderRadius: "50px",
                width: "92%",
                alignSelf: "center",
              }}
              onClick={payBasket}
            />
          </FormControl>
        </div>
      </CustomModal>
    </>
  )
}

export default SplitBillModal
