import { AppHeading4Medium, AppHeading6 } from "@components/Text/AppText"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import useClientCurrency from "@hooks/useClientCurrency"
import { Card } from "@material-ui/core"
import { theme } from "config/theme"
import React, { FC } from "react"

interface IProps {
  total: number | string
}
const TotalCard: FC<IProps> = ({ total }) => {
  const { languageStrings } = useLanguageHelper()
  const { clientCurrency } = useClientCurrency()
  return (
    <Card
      className="flex h-44 w-11/12 my-12 self-center"
      elevation={0}
      style={{
        backgroundColor: "#FAF6F6",
        minHeight: "3.5rem",
        maxHeight: "3.5rem",
      }}
    >
      <div className="flex flex-col w-full justify-center ml-6">
        <AppHeading6 style={{ color: theme.main.black }}>
          {languageStrings["GENERAL.TOTAL"]}
        </AppHeading6>
        <div style={{ height: "0.4rem" }} />
        <AppHeading4Medium style={{ color: theme.main.primary }}>
          {total} {clientCurrency.symbol}
        </AppHeading4Medium>
      </div>
    </Card>
  )
}

export default TotalCard
