import React from "react"
import { PaymentType } from "@helpers/utilsHelper"
import { SocialPayButton } from "./SocialPayButton"
import { PaymentMethodState } from "@models/Location"
interface Props {
  type: PaymentMethodState
  amount: String
  onClick: (type?: PaymentType) => any
  setSelectedPayment?: (method: PaymentType) => void
  isPaymentOnTipModal?: boolean
}
export const SocialButtons = ({
  type,
  onClick,
  amount = "",
  setSelectedPayment,
  isPaymentOnTipModal,
}: Props) => {
  const SocialButton = () => {
    if (type && type.payments.includes(PaymentType.APPLE))
      return (
        <SocialPayButton
          type={PaymentType.APPLE}
          onClick={onClick}
          setSelectedPayment={setSelectedPayment}
          isPaymentOnTipModal={isPaymentOnTipModal}
        />
      )
    else if (type && type.payments.includes(PaymentType.GOOGLE))
      return (
        <SocialPayButton
          type={PaymentType.GOOGLE}
          onClick={onClick}
          setSelectedPayment={setSelectedPayment}
          isPaymentOnTipModal={isPaymentOnTipModal}
        />
      )
    else return null
  }

  return (
    <>
      <SocialButton />
    </>
  )
}
