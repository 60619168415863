import React from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useLanguageHelper } from "@helpers/LanguageHelper"

interface Props {
  match?: any
}

const RedsysFormPage = ({ match }: Props) => {
  const location = useLocation()

  const { isProd, merchant, signature }: any = location?.state

  const urlForm = isProd
    ? "https://sis.redsys.es/sis/realizarPago"
    : "https://sis-t.redsys.es:25443/sis/realizarPago"
  const { languageStrings, changeLanguage } = useLanguageHelper()

  return (
    <>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `var button = document.getElementById('clickButton');
            button.form.submit();`,
          },
        ]}
      />
      <form name="from" action={urlForm} method="POST">
        <input
          type="hidden"
          name="Ds_SignatureVersion"
          value="HMAC_SHA256_V1"
        />
        <input type="hidden" name="Ds_MerchantParameters" value={merchant} />
        <input type="hidden" name="Ds_Signature" value={signature} />
        <input
          id="clickButton"
          type="submit"
          value={languageStrings["PAYMENT.MAKE_PAYMENT"]}
        />
      </form>
    </>
  )
}

export default RedsysFormPage
