import React, { useEffect } from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepConnector from "@material-ui/core/StepConnector"
import { StepIconProps } from "@material-ui/core/StepIcon"
import MoodIcon from "@material-ui/icons/Mood"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import KitchenIcon from "@material-ui/icons/Kitchen"
import RestaurantIcon from "@material-ui/icons/Restaurant"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import DoneIcon from "@material-ui/icons/Done"
import TopHeader from "./TopHeader"
import { Heading5 } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"
interface Props {
  typography: string
  status: number
  delivery: boolean
  order: any
}

export default function CustomizedSteppers({
  typography,
  status,
  delivery,
  order,
}: Props) {
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundColor: typography,
      },
    },
    completed: {
      "& $line": {
        backgroundColor: typography,
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector)

  const useColorlibStepIconStyles = makeStyles(() =>
    createStyles({
      root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
      },
      active: {
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        backgroundColor: typography,
      },
      completed: {
        backgroundColor: typography,
      },
    }),
  )

  function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons: { [index: string]: React.ReactElement } = {
      1: <ShoppingCartIcon />,
      2: <ThumbUpIcon />,
      3: <KitchenIcon />,
      4: delivery ? <LocalShippingIcon /> : <RestaurantIcon />,
      5: <DoneIcon />,
    }
    const delivery_Icons: { [index: string]: React.ReactElement } = {
      1: <ShoppingCartIcon />,
      2: <DoneIcon />,
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {order.type == "3"
          ? delivery_Icons[String(props.icon)]
          : icons[String(props.icon)]}
      </div>
    )
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: () => ({
        width: "100%",
        "& .MuiStepper-root": {
          padding: 0,
          paddingTop: "10px !important",
          paddingBottom: "10px  !important",
        },
      }),
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    }),
  )

  function getSteps() {
    return [
      languageStrings["PROFILE.ORDER_PLACED"],
      languageStrings["PROFILE.CONFIRMED"],
      languageStrings["PROFILE.IN_KITCHEN"],
      delivery
        ? languageStrings["PROFILE.IN_SHOPPING"]
        : languageStrings["PROFILE.PICK_UP_LIST"],
      languageStrings["PROFILE.COMPLETED"],
    ]
  }
  function getOrerFromTableSteps() {
    return [
      languageStrings["PROFILE.ORDER_PLACED"],
      languageStrings["PROFILE.COMPLETED"],
    ]
  }
  const labelStyles = makeStyles({
    root: () => ({
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        fontSize: 11,
        marginTop: 10,
      },
    }),
  })
  const classes = useStyles()
  const classes1 = labelStyles()
  const [activeStep, setActiveStep] = React.useState(status)

  useEffect(() => {
    setActiveStep(status)
  }, [status])
  const steps = order.type == "3" ? getOrerFromTableSteps() : getSteps()

  let orderPrice = order.discountVoucherAmount
    ? order.price - Number(order.discountVoucherAmount)
    : order.price

  return (
    <div
      className={`flex flex-col w-full md:relative fixed shadow-lg bg-white ${
        status == 6 ? "pb-4" : "pb-1"
      }`}
    >
      <TopHeader referenceNo={order.referenceNo} price={orderPrice} />
      <div className={classes.root}>
        {activeStep == 6 ? (
          <div className={"flex items-center justify-center"}>
            <MoodIcon style={{ color: typography }} />
            <Heading5 style={{ color: typography, marginLeft: 4 }}>
              Lo sentimos, tu orden ha sido cancelada
            </Heading5>
          </div>
        ) : (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  className={classes1.root}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
    </div>
  )
}
