import React from "react"
import { Card, CardMedia } from "@material-ui/core"
import CustomModal from "@components/modals/CustomModal"
import Button from "@components/buttons/Button"
import { formatImageUrl } from "@helpers/utilsHelper"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { makeStyles } from "@material-ui/core/styles"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"

interface Props {
  open: boolean
  close: any
  image: string
}

const VenuePopupModal = ({
  open = false,
  close = () => {},
  image = "",
}: Props) => {
  const { languageStrings } = useLanguageHelper()
  const { primaryColor, secondaryColor } = useBrand()

  const useStyles = makeStyles({
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "10px",
    },
  })

  const imageClasses = useStyles()

  return (
    <CustomModal showModal={open} onCloseModal={close}>
      <div className={imageClasses.imageContainer}>
        <Card className="mb-12 pb-12 w-full h-full items-center justify-center cursor-pointer text-center">
          <CardMedia
            image={formatImageUrl(image)}
            component="img"
            height="100%"
            style={{ objectFit: "contain" }}
          />
          <Button
            backgroundColor={primaryColor}
            txtColor={theme.main.primary}
            classes={
              "w-4/5 md:w-11/12 h-32 mt-3 md:mt-10 mb-2 font-semibold py-2 px-4 border-none rounded outline-none"
            }
            title={languageStrings["GENERAL_CLOSE"]}
            onClick={close}
            style={{
              borderRadius: "25px",
            }}
          />
        </Card>
      </div>
    </CustomModal>
  )
}

export default VenuePopupModal
