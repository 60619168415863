import React, { useState, useEffect } from "react"
import { LockOutlined, CancelOutlined } from "@material-ui/icons"
import { useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { useHistory } from "react-router-dom"

import Button from "@components/buttons/Button"
import CustomModal from "@components/modals/CustomModal"
import { validateEmail } from "@helpers/utilsHelper"
import { registerUser } from "@store/auth/AuthActions"
import { Body13 } from "@components/Text/Text"
import { useLanguageHelper } from "@helpers/LanguageHelper"

interface Props {
  showModal: boolean
  onCloseModal: () => void
  onLinkClick?: () => void
  error?: string
  typography?: string
  venueId: string
  isBasket?: boolean
  checkCollection?: boolean
  checkDelivery?: boolean
  orderFromTable?: boolean
}

const Signup = ({
  showModal,
  onCloseModal,
  error,
  onLinkClick,
  typography,
  venueId,
  isBasket,
  checkCollection,
  checkDelivery,
  orderFromTable,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { languageStrings, changeLanguage } = useLanguageHelper()

  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [check, setCheck] = useState(false)
  const [fieldError, setFieldError] = useState("")

  useEffect(() => {
    if (email && password && confirmPassword) {
      if (!validateEmail(email)) {
        setTimeout(() => {
          setFieldError(languageStrings["SIGNUP.PLEASE_ENTER_A_VALID_EMAIL"])
        }, 500)
      } else if (password.length < 4) {
        setTimeout(() => {
          setFieldError(
            languageStrings[
              "SIGNUP.THE_PASSWORD_MUST_BE_MORE_THEN_5_CHARACTERS"
            ],
          )
        }, 500)
      } else if (confirmPassword.length < 4) {
        setTimeout(() => {
          setFieldError(languageStrings["SIGNUP.CONFIRM"])
        }, 500)
      } else if (password !== confirmPassword) {
        setTimeout(() => {
          setFieldError(
            languageStrings[
              "SIGNUP.PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_THE_SAME"
            ],
          )
        }, 1500)
      } else {
        setTimeout(() => {
          setFieldError("")
        }, 1500)
      }
    }
  }, [email, password, confirmPassword])

  useEffect(() => {
    if (showModal === false) {
      setEmail("")
      setFirstName("")
      setLastName("")
      setPassword("")
      setConfirmPassword("")
      setCheck(false)
      setFieldError("")
    }
  }, [showModal])
  const canBeSubmit = () => {
    if (
      email &&
      validateEmail(email) &&
      firstName &&
      lastName &&
      password &&
      password.length > 5 &&
      confirmPassword &&
      confirmPassword.length > 5 &&
      check === true &&
      password === confirmPassword
    ) {
      return false
    }
    return true
  }
  const handleSubmit = () => {
    dispatch(
      registerUser(
        venueId,
        { email, firstName, lastName, password },
        isBasket,
        checkDelivery,
        checkCollection,
        history,
        orderFromTable,
      ),
    )
  }
  return (
    <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
      <Box
        style={{ backgroundColor: typography }}
        className={"flex flex-col w-4/5 md:w-2/5 p-4 z-10"}
      >
        <CancelOutlined
          className={"flex self-end mt-2 mr-2"}
          style={{ color: "white" }}
          onClick={() => {
            onCloseModal()
          }}
        />

        <Box className={"flex flex-col items-center w-full p-4 md:p-10"}>
          <LockOutlined style={{ color: "white", fontSize: "60px" }} />
          <input
            style={{ backgroundColor: typography }}
            className={
              "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
            }
            type="text"
            placeholder={languageStrings["SIGNUP.NAME"]}
            onChange={(e) => {
              setFirstName(e.target.value)
            }}
            value={firstName}
          />
          <input
            type="text"
            style={{ backgroundColor: typography }}
            className={
              "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
            }
            placeholder={languageStrings["SIGNUP.SURNAME"]}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value)
            }}
          />
          <input
            style={{ backgroundColor: typography }}
            className={
              "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
            }
            type="email"
            placeholder={languageStrings["SIGNUP.EMAIL"]}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
          <input
            type="password"
            style={{ backgroundColor: typography }}
            className={
              "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
            }
            placeholder={languageStrings["SIGNUP.PASSWORD"]}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <input
            type="password"
            style={{ backgroundColor: typography }}
            className={
              "w-4/5 md:w-11/12 h-24 border mt-6 md:mt-8 border-white border-solid rounded-sm pl-2 shadow-none placeholder-gray-300 text-white outline-none"
            }
            placeholder={languageStrings["SIGNUP.CONFIRM_PASSWORD"]}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
          />
          <Box
            className={
              "flex flex-row items-center mt-2 md:mt-4 w-5/6 md:w-4/5 md:mr-40"
            }
          >
            <input
              style={{ backgroundColor: typography }}
              className={"w-16 h-16 border border-white border-solid"}
              type="checkbox"
              onChange={(e) => {
                setCheck(!check)
              }}
            />
            <Body13 className={"mr-0 text-white"}>
              {languageStrings["SIGNUP.I_ACCEPT_THE_TERMS_AND_CONDITIN"]}
            </Body13>
          </Box>
          {fieldError && fieldError.length > 0 ? (
            <Body13 className={"flex mt-1 md:mt-4 text-red"}>
              {fieldError}
            </Body13>
          ) : (
            error && (
              <Body13 className={"flex mt-4 md:mt-8 text-red"}>{error}</Body13>
            )
          )}

          <Button
            color={typography}
            classes={
              canBeSubmit()
                ? "w-4/5 md:w-11/12 h-24 mt-3 md:mt-10 mb-2 font-semibold  py-2 px-4 border-none rounded cursor-not-allowed"
                : "w-4/5 md:w-11/12 h-24 mt-3 md:mt-10 mb-2 font-semibold  py-2 px-4 border-none rounded outline-none"
            }
            title={languageStrings["SIGN_UP.SIGN_UP"]}
            style={{ backgroundColor: "white" }}
            disabled={canBeSubmit()}
            onClick={handleSubmit}
          />
          <Box className={"w-4/5 md:w-11/12"}>
            <Typography
              variant="subtitle2"
              className={"text-white cursor-pointer text-center"}
              onClick={onLinkClick}
            >
              {
                languageStrings[
                  "SIGNUP.DO_YOU_ALREADY_HAVE_AN_ACCOUNT_TRY_TO_ACCESS_WITH_IT"
                ]
              }
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  )
}
export default Signup
