import { AppHeading3Medium } from "@components/Text/AppText"
import { Label17 } from "@components/Text/Text"
import { useBrand } from "@hooks/useBrand"
import { theme } from "config/theme"
import React, { CSSProperties, FC } from "react"
interface IProps {
  title?: string
  onClick?: (e?: any) => void
  disabled?: boolean
  value?: string
  style?: CSSProperties
  textStyle?: CSSProperties
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
}
export const ButtonIcon: FC<IProps> = ({
  title,
  onClick,
  disabled,
  style,
  leftIcon,
  rightIcon,
  textStyle,
}) => {
  const { primaryColor, secondaryColor } = useBrand()
  return (
    <button
      onClick={onClick}
      className={"flex h-32 w-full items-center justify-between"}
      disabled={disabled}
      style={{
        borderRadius: "25px",
        fontSize: "12px",
        backgroundColor: disabled ? theme.main.grey : primaryColor,
        border: "0px",
        marginBottom: "0.8rem",
        ...style,
      }}
    >
      {leftIcon || <div style={{ marginLeft: "2.5rem" }} />}
      <Label17
        style={{
          color: disabled ? theme.main.black : secondaryColor,
          fontWeight: 700,
          fontFamily: "ProductSansRegular",
          fontSize: "16px",
          ...textStyle,
        }}
      >
        {title}
      </Label17>
      {rightIcon || <div style={{ marginRight: "2.5rem" }} />}
    </button>
  )
}
export const ButtonIconV2: FC<IProps> = ({
  title,
  onClick,
  disabled,
  style,
  leftIcon,
  rightIcon,
  textStyle,
}) => {
  const { primaryColor } = useBrand()
  return (
    <button
      onClick={onClick}
      className={"flex h-32 w-full items-center justify-between"}
      disabled={disabled}
      style={{
        borderRadius: "25px",
        fontSize: "12px",
        backgroundColor: disabled ? theme.main.grey : primaryColor,
        border: "0px",
        marginBottom: "0.8rem",
        ...style,
      }}
    >
      {leftIcon || <div style={{ marginLeft: "2.5rem" }} />}
      <AppHeading3Medium
        style={{
          color: theme.main.white,
          ...textStyle,
        }}
      >
        {title}
      </AppHeading3Medium>
      {rightIcon || <div style={{ marginRight: "2.5rem" }} />}
    </button>
  )
}
