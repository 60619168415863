import { getPaymentMethodType, PaymentType } from "@helpers/utilsHelper"
import { socialPayVirtualBasket } from "@store/orderFromTable/OrderFromTableActions"
import { useStripe } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { LocationState, TipState, LoyaltyClientState } from "@models/Location"
import { axiosInstance } from "@api/axios"

interface Props {
  amount: number
  tableId: string
  venueId: string
  routeState: LocationState
  payload?: any
  isPaid?: string
  tip?: TipState
  paymentRequest?: PaymentRequest
  isScheduledOrder: boolean
}
interface IVProps {
  paymentRequest?: PaymentRequest
}
export const useSocialButtons = (
  amount: number = 0,
  tableId: string,
  venueId: string,
  availableMethods: (val: PaymentType) => any,
  routeState: LocationState,
  payload?: any,
  tip?: TipState,
  loyaltyClient?: LoyaltyClientState,
  orderClientName?: string,
  orderClientAddress?: string,
  isScheduledOrder: boolean = false,
): IVProps => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const stripe = useStripe()
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const dispatch = useDispatch()
  const history = useHistory()
  const { languageStrings } = useLanguageHelper()
  const { orderFromTable } = useSelector(
    ({ orderFromTable }: RootState) => orderFromTable,
  )
  useEffect(() => {
    if (tip && tip.amount && tip.amount < 0) {
      toast.error(languageStrings["PAYMENT.TIP_ERROR"])
      return
    }
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "ES",
        currency: "eur",
        total: {
          label: "Virtual Basket Payment",
          amount: Number((Number(amount) * 100).toFixed(0)),
        },
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr as any)
          const _pm = getPaymentMethodType(result)
          availableMethods(_pm)
        }
      })
      pr?.on("paymentmethod", async (e) => {
        try {
          const clientId = restaurant && restaurant._id ? restaurant._id : ""
          if ((!clientId || !venueId || !tableId) && !payload) {
            toast.error(languageStrings["NO_TABLE_ID_ERROR"])
            e.complete("fail")
            return
          }
          const frontRestOrderId =
            orderFromTable &&
            orderFromTable.frontRestData &&
            orderFromTable.frontRestData.orderId
              ? orderFromTable.frontRestData.orderId
              : ""

          const body = {
            tableId,
            amount,
            clientId,
            cartItems: null,
            frontRestOrderId,
            tip,
          }
          if (payload) {
            if (
              !payload.clientId ||
              !payload.venueId ||
              !payload.orderTable ||
              !payload.userId
            ) {
              toast.error(languageStrings["NO_TABLE_ID_ERROR"])
              e.complete("fail")
              return
            }
            body.cartItems = payload
          }
          const url = "order/table/socialPayment"
          const { data } = await axiosInstance.post(url, body)

          if (data && data.data) {
            const { paymentIntent = {} } = data.data

            const { client_secret = "" } = paymentIntent

            if (client_secret) {
              const paymentRes = await stripe?.confirmCardPayment(
                client_secret,
                {
                  payment_method: e.paymentMethod.id,
                },
                { handleActions: false },
              )
              const { error, paymentIntent: Intent } = paymentRes || {}
              if (error) {
                e.complete("fail")
                return
              }
              e.complete("success")

              dispatch(
                socialPayVirtualBasket(
                  tableId,
                  amount,
                  venueId,
                  routeState,
                  history,
                  stripe,
                  client_secret,
                  e,
                  clientId,
                  payload,
                  paymentIntent,
                  frontRestOrderId,
                  tip,
                  loyaltyClient,
                  orderClientName,
                  orderClientAddress,
                  isScheduledOrder,
                ),
              )
            }
          }
        } catch (error) {
          const err: any = error
          let _msg = "Algo salió mal"
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            _msg = err.response.data.message
          }
          toast.error(_msg)
          e.complete("fail")
        }
      })
    }
  }, [stripe, tip])
  return { paymentRequest }
}
