import axios, { AxiosResponse, AxiosRequestConfig } from "axios"

export const axiosInstanceV2 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_V2,
})

const ResponseInterceptor = (response: AxiosResponse) => {
  return response
}
const RequestInterceptor = (config: AxiosRequestConfig) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("accessToken")
  return config
}
axiosInstanceV2.interceptors.request.use(RequestInterceptor)
// Alter defaults after instance has been created
axiosInstanceV2.interceptors.response.use(ResponseInterceptor, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  if (!expectedErrors) {
    return
  } else {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("userId")
      window.location.href = "/" + localStorage.getItem("clientPermalink")
    }
    return Promise.reject(error)
  }
})
