import { getAllergens } from "@helpers/utilsHelper"
import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"

interface IProps {
  allergenes?: string
}
export const AllergensImages: FC<IProps> = ({ allergenes }) => {
  const allergensList = useSelector(({ menu }: RootState) => menu.allergensList)
  const [allergensImages, setAllergensImages] = useState<any>([])
  const getFilterAllergens = () => {
    const allergens = getAllergens(allergenes)
    if (
      allergens &&
      allergens.length > 0 &&
      allergensList &&
      allergensList.length > 0
    ) {
      const temp: any = []
      return allergensList.map((item) => {
        if (allergens.find((i) => i == item._id)) {
          temp.push(item)
          setAllergensImages(temp)
        }
      })
    }
  }
  useEffect(() => {
    getFilterAllergens()
  }, [allergensList])
  return allergensImages && allergensImages.length > 0 ? (
    <div className={"flex flex-wrap mt-6"}>
      {allergensImages.map((item) => (
        <img
          src={require(`@assets/allergenes/${item.title}.png`)}
          className={"w-12 h-12 object-cover mr-1"}
        />
      ))}
    </div>
  ) : null
}
