import React from "react"
import { useDispatch } from "react-redux"
import { ItemOrderMenu } from "@models/Cart"
import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import {
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
} from "@store/cart/cartActions"
import { Label14Light } from "@components/Text/Text"
import { AddCircle, RemoveCircle } from "@components/addCircle/AddCircle"
import { theme } from "config/theme"
import { getMultiLanguageTitle } from "@helpers/utilsHelper"
import {
  AppHeading3,
  AppHeading5Bold,
  AppParagraph4,
} from "@components/Text/AppText"
import { useBrand } from "@hooks/useBrand"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { Typography } from "@material-ui/core"
import useClientCurrency from "@hooks/useClientCurrency"
interface Props {
  item: ItemOrderMenu
  typography: string
}

const MenuItemsCartDetails = ({ item }: Props) => {
  const { secondaryColor } = useBrand()
  const { title, extraCategories, price, quantity, multiTitle = [] } = item
  const dispatch = useDispatch()
  const { menuLanguage } = useLanguageHelper()
  const multiLang = getMultiLanguageTitle(multiTitle, menuLanguage)
  const { clientCurrency } = useClientCurrency()
  const renderComboCard = (combo: ItemOrderMenu) => {
    return (
      <div key={combo._id}>
        {combo.comboCategories?.map((comboCategory) => (
          <div key={comboCategory._id}>
            {comboCategory.products
              .filter((p) => p.selected === true)
              .map((product) => (
                <div key={product._id}>
                  <Typography variant="subtitle2">{product.title}</Typography>
                  {product.extraCategories &&
                    product.extraCategories.length > 0 &&
                    product.extraCategories.map(
                      ({ title, items, multiTitle }) => {
                        return (
                          <>
                            {items.map(
                              ({
                                title,
                                selected,
                                selectedQuantity = 0,
                                multiTitle,
                              }) => {
                                const extraItemLang = getMultiLanguageTitle(
                                  multiTitle ?? [],
                                  menuLanguage,
                                )
                                const _extraItemTitle =
                                  extraItemLang?.title || title
                                let quantity = ""
                                if (selectedQuantity > 0)
                                  quantity = `x ${selectedQuantity}`
                                if (selected) {
                                  return (
                                    <Typography
                                      style={{
                                        color: "#c3c3c3",
                                      }}
                                      variant="body2"
                                    >
                                      {_extraItemTitle} {quantity}
                                    </Typography>
                                  )
                                }
                                return null
                              },
                            )}
                          </>
                        )
                      },
                    )}
                </div>
              ))}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div
      className="flex flex-col justify-between"
      style={{
        backgroundColor: theme.secondary.secondary,
        margin: "0.8rem",
        padding: "0.5rem",
        borderLeft: `3px solid ${theme.status.red}`,
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col mt-2 ml-2 items-start pb-2">
          <AppHeading5Bold style={{ color: theme.main.primary }}>
            {multiLang?.title || title}
          </AppHeading5Bold>
          {item?.isCombo
            ? renderComboCard(item)
            : extraCategories &&
              extraCategories.length > 0 &&
              extraCategories.map(({ title, items, multiTitle = [] }) => {
                const catLang = getMultiLanguageTitle(multiTitle, menuLanguage)
                if (hasFamilyPropertyItemSelected(items)) {
                  return (
                    <>
                      <AppHeading5Bold style={{ marginTop: "0.3rem" }}>
                        {catLang?.title || title}
                      </AppHeading5Bold>
                      {items.map(
                        ({
                          title,
                          selected,
                          selectedQuantity = 0,
                          multiTitle = [],
                        }) => {
                          const catLang = getMultiLanguageTitle(
                            multiTitle,
                            menuLanguage,
                          )
                          if (selected) {
                            let quantity = ""
                            if (selectedQuantity > 0)
                              quantity = `x ${selectedQuantity}`
                            return (
                              <AppParagraph4
                                style={{ color: theme.main.black }}
                              >
                                {catLang?.title || title} {quantity}
                              </AppParagraph4>
                            )
                          }
                          return null
                        },
                      )}
                    </>
                  )
                }
                return null
              })}
        </div>
        <div className="mt-2 mr-2 pb-2 flex flex-col justify-between items-end">
          <Label14Light>
            {price.toFixed(2)} {clientCurrency.symbol}
          </Label14Light>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <RemoveCircle
          iconStyle={{ color: theme.main.primary }}
          containerStyle={{
            height: "1.8rem",
            width: "1.8rem",
          }}
          onClick={() => dispatch(decreaseItemAmountOrderPage({ item }))}
        />
        <AppHeading3
          style={{
            color: secondaryColor,
            marginRight: "0.8rem",
            marginLeft: "0.8rem",
          }}
        >
          {quantity}x
        </AppHeading3>
        <AddCircle
          iconStyle={{ color: theme.main.primary }}
          containerStyle={{
            height: "1.8rem",
            width: "1.8rem",
          }}
          onClick={() => {
            dispatch(increaseItemAmountOrderPage(item))
          }}
        />
      </div>
    </div>
  )
}

export default MenuItemsCartDetails
