import React from "react"
import { AddCircle, RemoveCircle } from "@components/addCircle/AddCircle"
import { AppHeading3, AppHeading5Bold } from "@components/Text/AppText"
import { theme } from "config/theme"
import { Label14Light } from "@components/Text/Text"
import { useBrand } from "@hooks/useBrand"
import useClientCurrency from "@hooks/useClientCurrency"

interface Props {
  padding?: boolean
  handleClick: () => void
  onIncrease: () => void
  quantity: number
  typography: string
  title?: string
  price?: number
  iconFontSize?: "inherit" | "default" | "small" | "large"
}

const ExtraCategoriesModalLeft = ({
  quantity,
  handleClick,
  padding,
  typography,
  iconFontSize = "default",
  price,
  title,
  onIncrease,
}: Props) => {
  const { secondaryColor } = useBrand()
  const { clientCurrency } = useClientCurrency()
  return (
    <div
      className="flex flex-col mr-8 mb-6"
      style={{
        backgroundColor: theme.secondary.secondary,
        marginTop: "0.8rem",
        padding: "0.5rem",
        borderLeft: `3px solid ${theme.status.red}`,
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col mt-2 ml-2 items-start pb-2">
          <AppHeading5Bold style={{ color: theme.main.primary }}>
            {title}
          </AppHeading5Bold>
        </div>
        <div className="mt-2 mr-2 pb-2 flex flex-col justify-between items-end">
          <Label14Light>
            {price?.toFixed(2)} {clientCurrency.symbol}
          </Label14Light>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <RemoveCircle
          iconStyle={{ color: theme.main.primary }}
          containerStyle={{
            height: "1.8rem",
            width: "1.8rem",
          }}
          onClick={handleClick}
        />
        <AppHeading3
          style={{
            color: secondaryColor,
            marginRight: "0.8rem",
            marginLeft: "0.8rem",
          }}
        >
          {quantity}x
        </AppHeading3>
        <AddCircle
          iconStyle={{ color: theme.main.primary }}
          containerStyle={{
            height: "1.8rem",
            width: "1.8rem",
          }}
          onClick={onIncrease}
        />
      </div>
    </div>
  )
}

export default ExtraCategoriesModalLeft
