import { axiosInstance } from "@api/axios"
import { useLanguageHelper } from "@helpers/LanguageHelper"
import { socialPayVirtualBasket } from "@store/orderFromTable/OrderFromTableActions"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import ApplePayImgBlack from "@assets/apple_pay_icon_black.png"
import GPayBlack from "@assets/google_pay_icon_black.png"
import { useBrand } from "@hooks/useBrand"
const appId = process.env.REACT_APP_SQUARE_APP_ID
const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID

const SquareSocialPay = ({
  amount,
  tableId,
  venueId,
  payload,
  tip,
  routeState,
  setSquareSocialPayLoading,
  paymentMethod,
}) => {
  const { isCashAvailable } = useBrand()
  const restaurant = useSelector(({ venues }) => venues.restaurant)
  const venueDetail = useSelector(({ venues }) => venues.venueDetail)
  const dispatch = useDispatch()
  const history = useHistory()
  const { languageStrings } = useLanguageHelper()
  const {
    orderFromTable,
    programmedOrderDate = "",
    orderClientName = "",
    orderClientAddress = "",
  } = useSelector(({ orderFromTable }) => orderFromTable)
  const { loyaltyClient } = useSelector(({ user }) => user)
  const isScheduledOrder =
    programmedOrderDate && venueDetail && venueDetail.isScheduleOrdersEnabled

  const [payments, setPayments] = useState(null)
  const [googlePayInitialized, setGooglePayInitialized] = useState(false)
  const [applePayInitialized, setApplePayInitialized] = useState(false)
  const googlePayButtonRef = useRef(null)
  const applePayButtonRef = useRef(null)

  useEffect(() => {
    const loadSquareScript = () => {
      const script = document.createElement("script")
      script.src = process.env.REACT_APP_SQUARE_URL
      script.async = true
      script.onload = () => initializeSquare()
      script.onerror = () => console.error("Square.js failed to load properly")
      document.head.appendChild(script)
    }

    const initializeSquare = async () => {
      if (!window.Square) {
        console.error("Square.js failed to load properly")
        return
      }

      let paymentsInstance
      try {
        paymentsInstance = window.Square.payments(appId, locationId)
        setPayments(paymentsInstance)
      } catch (error) {
        console.error("Error initializing Square payments", error)
        return
      }

      await initializeGooglePay(paymentsInstance)
      await initializeApplePay(paymentsInstance)
    }

    const initializeGooglePay = async (paymentsInstance) => {
      try {
        const paymentRequest = paymentsInstance.paymentRequest({
          countryCode: "ES",
          currencyCode: "EUR",
          total: {
            amount: amount.toString(),
            label: "Total",
          },
        })

        const googlePay = await paymentsInstance.googlePay(paymentRequest)
        await googlePay.attach(googlePayButtonRef.current)
        setGooglePayInitialized(true)
        googlePayButtonRef.current.addEventListener("click", async (event) => {
          await handlePaymentMethodSubmission(
            paymentsInstance,
            event,
            googlePay,
          )
        })
      } catch (error) {
        console.error("Initializing Google Pay failed", error)
      }
    }

    const initializeApplePay = async (paymentsInstance) => {
      try {
        const paymentRequest = paymentsInstance.paymentRequest({
          countryCode: "ES",
          currencyCode: "EUR",
          total: {
            amount: amount.toString(),
            label: "Total",
          },
        })

        const applePay = await paymentsInstance.applePay(paymentRequest)
        await applePay.attach(applePayButtonRef.current)
        setApplePayInitialized(true)
        applePayButtonRef.current.addEventListener("click", async (event) => {
          await handlePaymentMethodSubmission(paymentsInstance, event, applePay)
        })
      } catch (error) {
        console.error("Initializing Apple Pay failed", error)
      }
    }

    loadSquareScript()
  }, [amount])

  const createPayment = async (token, verificationToken) => {
    try {
      const clientId = restaurant && restaurant._id ? restaurant._id : ""
      if ((!clientId || !venueId || !tableId) && !payload) {
        toast.error(languageStrings["NO_TABLE_ID_ERROR"])
        return
      }
      const frontRestOrderId =
        orderFromTable &&
        orderFromTable.frontRestData &&
        orderFromTable.frontRestData.orderId
          ? orderFromTable.frontRestData.orderId
          : ""

      const body = {
        tableId,
        amount,
        clientId,
        cartItems: null,
        frontRestOrderId,
        tip,
        squarePaymentToken: token,
        squareVerificationToken: verificationToken,
      }
      if (payload) {
        if (
          !payload.clientId ||
          !payload.venueId ||
          !payload.orderTable ||
          !payload.userId
        ) {
          toast.error(languageStrings["NO_TABLE_ID_ERROR"])
          return
        }
        body.cartItems = payload
      }
      const url = "order/table/socialPayment"
      const { data } = await axiosInstance.post(url, body)
      if (data && data.data) {
        dispatch(
          socialPayVirtualBasket(
            tableId,
            amount,
            venueId,
            routeState,
            history,
            null, // stripe null as this is for square
            null, // stripe client_secret null
            null, // event null
            clientId,
            payload,
            data.data.paymentIntent,
            frontRestOrderId,
            tip,
            loyaltyClient,
            orderClientName,
            orderClientAddress,
            isScheduledOrder,
            true, // isSquarePayment true
          ),
        )
      }
    } catch (error) {
      const err = error
      let _msg = "Algo salió mal"
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        _msg = err.response.data.message
      }
      toast.error(_msg)
    }
  }

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize()
    if (tokenResult.status === "OK") {
      return tokenResult.token
    }
  }

  const verifyBuyer = async (payments, token) => {
    const verificationDetails = {
      amount: amount.toString(),
      billingContact: {},
      currencyCode: "EUR",
      intent: "CHARGE",
    }

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails,
    )
    return verificationResults.token
  }

  const handlePaymentMethodSubmission = async (
    payments,
    event,
    paymentMethod,
  ) => {
    event.preventDefault()

    try {
      setSquareSocialPayLoading(true)
      const token = await tokenize(paymentMethod)
      let verificationToken = await verifyBuyer(payments, token)
      const paymentResults = await createPayment(token, verificationToken)
    } catch (e) {
      console.error(e.message)
    } finally {
      setSquareSocialPayLoading(false)
    }
  }

  return (
    <div className="flex">
      <div
        id="google-pay-button"
        className={`${
          googlePayButtonRef.current && googlePayInitialized
            ? "!border !border-custom-grey mr-1"
            : "hidden"
        } `}
        style={{
          width:
            paymentMethod.payments.length >= 1 && isCashAvailable
              ? "30vw"
              : "47vw",
        }}
        ref={googlePayButtonRef}
      >
        {googlePayInitialized && (
          <>
            <div className="flex h-3/5 justify-center items-center">
              <img src={GPayBlack} alt="Google Pay" className="w-3/5" />
            </div>
            <div className="w-full h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
              <p className="font-bold text-center">Google Pay</p>
            </div>
          </>
        )}
      </div>
      <div
        id="apple-pay-button"
        ref={applePayButtonRef}
        className={`${
          applePayButtonRef.current && applePayInitialized
            ? "!border !border-custom-grey mr-2"
            : "hidden"
        } `}
        style={{
          width:
            paymentMethod.payments.length >= 1 && isCashAvailable
              ? "30vw"
              : "47vw",
        }}
      >
        {applePayInitialized && (
          <>
            <div className="flex h-3/5 justify-center items-center">
              <img src={ApplePayImgBlack} alt="Apple Pay" className="w-3/4" />
            </div>
            <div className="w-full h-2/5 border-solid border-0 border-t border-custom-grey rounded-b-lg bg-white justify-center items-center flex">
              <h3 className="font-bold text-center">Apple Pay</h3>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SquareSocialPay
