// import React from "react"
// import ReactDOM from "react-dom"
// import { Elements } from "@stripe/react-stripe-js"
// import { loadStripe } from "@stripe/stripe-js"
// import { CardElement } from "@stripe/react-stripe-js"

// import CheckoutForm from "./CheckoutForms"

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   "pk_test_51KdGRuHg5u7XWHC21mVqeC3UKlfjARRKNTsFQIKkGO5DDvuQgBAuqYyYDguBbIBWPINgb5TMcwHvcxt50ahaWMH100tGVRAG5P",
// )

// const SocialPayments = () => {
//   const options = {
//     // passing the client secret obtained in step 2
//     clientSecret:
//       "sk_test_51KdGRuHg5u7XWHC2eHjFW8JPRxOAsUu84l998DtTYQc57vEhUwZnGANhRQV0biXXYS7zSHoawF67br52vyOJWF7p00Mt47h4PV",
//     // Fully customizable with appearance API.
//     // appearance: {
//     //   theme: "stripe",
//     // },
//   }

//   return (
//     <Elements stripe={stripePromise} options={options}>
//       <CardElement />
//     </Elements>
//   )
// }

// export default SocialPayments

import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "./CheckoutForms"

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51KdGRuHg5u7XWHC21mVqeC3UKlfjARRKNTsFQIKkGO5DDvuQgBAuqYyYDguBbIBWPINgb5TMcwHvcxt50ahaWMH100tGVRAG5P",
)
export default function SocialPayments() {
  const [clientSecret, setClientSecret] = useState("")

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
  }, [])

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#171a1c",
    },
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}
