import { Divider } from "@material-ui/core"
import { theme } from "config/theme"
import React, { CSSProperties, FC } from "react"

interface IProps {
  style?: CSSProperties
}
export const ItemDivider: FC<IProps> = ({ style }) => {
  return (
    <Divider
      style={{ backgroundColor: theme.main.grey, marginTop: 0, ...style }}
    />
  )
}
